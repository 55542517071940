import axios from 'axios';
import { auth, resError } from '@services/Helpers/ServiceInterceptor';

const api = axios.create({
  baseURL: process.env.REACT_APP_API_URL + '/application/getapplicationheader/',
});


api.interceptors.request.use((config) => auth(config));
api.interceptors.response.use(
  (response) => {
    return response;
  },
  (error) => resError(error)
);

class ApplicationsHeaderService {

  constructor() {
    if (!ApplicationsHeaderService.instance) {
      ApplicationsHeaderService.instance = this;
    }
    return ApplicationsHeaderService.instance;
  }

  read = (id) => {
    return api.get('/' + id);
  };

}

const instance = new ApplicationsHeaderService();
Object.freeze(instance);

export default instance;