import React, { useEffect, useState, useContext } from 'react';
import { Alert, Col, Row, Table } from 'reactstrap';
import VReportsStaticPoolAnalysisService from '@services/VReportsStaticPoolAnalysisService';
import VReportsStaticPoolDetailsService from '@services/VReportsStaticPoolDetailsService';
import FilteringFields from './FilteringFields';
import AppContext from '@contexts/AppContext';
import fileDownload from 'js-file-download';
import './Styles.css';
const PayoffStaticPoolAnalysis = () => {
  let appContext = useContext(AppContext);
  const [filters, setFilters] = useState(appContext.staticPoolAnalysisReportFilters);
  const [isLoading, setIsLoading] = useState(false);
  const [staticPoolAnalysisReport, setStaticPoolAnalysisReport] = useState([]);

  const makeMenuItemActive = () => {
    document.querySelector('#menuitem-reports').click();
    document.querySelector('#kt_header_menu > ul > li').classList.add('menu-item-active');
  };

  const moneyFormatter = new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: 'USD',
  });

  useEffect(() => makeMenuItemActive(), []);

  function getAll() {
    let tempFilters = {
      advanceDateRange: 'YTD',
      payoffDateRange: 'YTD',
    };

    setFilters(tempFilters);
    appContext.payoffStaticPoolAnalysisReportFilters = tempFilters;
    localStorage.setItem('payoffStaticPoolAnalysisReportFilters', JSON.stringify(tempFilters));

    searchWithFilters(tempFilters);
  }

  function update(filters) {
    let reqFilters = { ...filters };

    if (reqFilters?.payoffDateRange == '') delete reqFilters.payoffDateRange;

    if (reqFilters?.accidentTypeName == '') delete reqFilters.accidentTypeName;

    if (reqFilters.portfolioName === null) delete reqFilters.portfolioName;

    if (reqFilters && reqFilters.lawFirm) {
      reqFilters = { ...reqFilters, LawFirmPublicId: reqFilters.lawFirm.publicId };
    }
    if (reqFilters.accidentTypeName) {
      reqFilters.AccidentTypeName = reqFilters.accidentTypeName;
      delete reqFilters.accidentTypeName;
    }
    if (reqFilters.portfolioName) {
      reqFilters.PortfolioPublicId = reqFilters.portfolioName;
      delete reqFilters.portfolioName;
    }
    if (reqFilters.accountManager) {
      reqFilters.AccountManagerPublicId = reqFilters.accountManager.publicId;
      delete reqFilters.accountManager;
    }
    if (reqFilters.broker) {
      reqFilters.BrokerPublicId = reqFilters.broker.publicId;
      delete reqFilters.broker;
    }
    if (reqFilters.state) {
      reqFilters.AccidentStateCode = reqFilters.state.stateCode;
      if (reqFilters?.AccidentStateCode == '') delete reqFilters.AccidentStateCode;

      delete reqFilters.state;
    }
    setIsLoading(true);

    // call api here
    VReportsStaticPoolAnalysisService.search(reqFilters, '', 'ASC', 1, null, 1)
      .then((response) => {
        if (response.status === 200) {
          setStaticPoolAnalysisReport(response?.data?.data);
        }
      })
      .catch((error) => {})
      .finally(() => {
        setIsLoading(false);
      });
  }

  function exportPayoffDetailsReport(filters) {
    let reqFilters = { ...filters };

    if (reqFilters?.payoffDateRange == '') delete reqFilters.payoffDateRange;

    if (reqFilters?.accidentTypeName == '') delete reqFilters.accidentTypeName;

    if (reqFilters.portfolioName === null) delete reqFilters.portfolioName;

    if (reqFilters && reqFilters.lawFirm) {
      reqFilters = { ...reqFilters, LawFirmPublicId: reqFilters.lawFirm.publicId };
    }
    if (reqFilters.accidentTypeName) {
      reqFilters.AccidentTypeName = reqFilters.accidentTypeName;
      delete reqFilters.accidentTypeName;
    }
    if (reqFilters.portfolioName) {
      reqFilters.PortfolioPublicId = reqFilters.portfolioName;
      delete reqFilters.portfolioName;
    }
    if (reqFilters.accountManager) {
      reqFilters.AccountManagerPublicId = reqFilters.accountManager.publicId;
      delete reqFilters.accountManager;
    }
    if (reqFilters.broker) {
      reqFilters.BrokerPublicId = reqFilters.broker.publicId;
      delete reqFilters.broker;
    }
    if (reqFilters.state) {
      reqFilters.AccidentStateCode = reqFilters.state.stateCode;
      if (reqFilters?.AccidentStateCode == '') delete reqFilters.AccidentStateCode;

      delete reqFilters.state;
    }
    setIsLoading(true);

    // call api here
    VReportsStaticPoolDetailsService.export(reqFilters, '', 'ASC', 1, null, 1)
      .then((response) => {
        const contentDisposition = response.headers['content-disposition'];
        let fileName = 'Data-Export.xlsx';
        if (contentDisposition) {
          const fileNameMatch = contentDisposition.match(/filename="(.+)"/);
          if (fileNameMatch != null && fileNameMatch.length === 2) fileName = fileNameMatch[1];
        }
        fileDownload(response.data, fileName);
        setIsLoading(false);
      })
      .catch((error) => {})
      .finally(() => {
        setIsLoading(false);
      });
  }

  function exportPayoffSummaryReport(filters) {
    let reqFilters = { ...filters };

    if (reqFilters?.payoffDateRange == '') delete reqFilters.payoffDateRange;

    if (reqFilters?.accidentTypeName == '') delete reqFilters.accidentTypeName;

    if (reqFilters.portfolioName === null) delete reqFilters.portfolioName;

    if (reqFilters && reqFilters.lawFirm) {
      reqFilters = { ...reqFilters, LawFirmPublicId: reqFilters.lawFirm.publicId };
    }
    if (reqFilters.accidentTypeName) {
      reqFilters.AccidentTypeName = reqFilters.accidentTypeName;
      delete reqFilters.accidentTypeName;
    }
    if (reqFilters.portfolioName) {
      reqFilters.PortfolioPublicId = reqFilters.portfolioName;
      delete reqFilters.portfolioName;
    }
    if (reqFilters.accountManager) {
      reqFilters.AccountManagerPublicId = reqFilters.accountManager.publicId;
      delete reqFilters.accountManager;
    }
    if (reqFilters.broker) {
      reqFilters.BrokerPublicId = reqFilters.broker.publicId;
      delete reqFilters.broker;
    }
    if (reqFilters.state) {
      reqFilters.AccidentStateCode = reqFilters.state.stateCode;
      if (reqFilters?.AccidentStateCode == '') delete reqFilters.AccidentStateCode;

      delete reqFilters.state;
    }
    setIsLoading(true);

    // call api here
    VReportsStaticPoolAnalysisService.export(reqFilters, '', 'ASC', 1, null, 1)
      .then((response) => {
        const contentDisposition = response.headers['content-disposition'];
        let fileName = 'Data-Export.xlsx';
        if (contentDisposition) {
          const fileNameMatch = contentDisposition.match(/filename="(.+)"/);
          if (fileNameMatch != null && fileNameMatch.length === 2) fileName = fileNameMatch[1];
        }
        fileDownload(response.data, fileName);
        setIsLoading(false);
      })
      .catch((error) => {})
      .finally(() => {
        setIsLoading(false);
      });
  }

  function searchWithFilters(givenFilters) {
    var newFilters = { ...givenFilters };
    setFilters(newFilters);
    appContext.payoffStaticPoolAnalysisReportFilters = newFilters;
    localStorage.setItem('payoffStaticPoolAnalysisReportFilters', JSON.stringify(newFilters));

    update(newFilters);
  }

  return (
    <div className="container mt-10 mb-10" style={{ maxWidth: '95%' }}>
      <div className="row">
        <div className="col-md-12 bg-white box-shadow-border">
          <div className="p-10">
            <div className="mt-5 mb-10">
              <FilteringFields
                searchWithFilters={searchWithFilters}
                getAll={getAll}
              ></FilteringFields>
              <div>
                <h1 className="d-flex flex-column">Payoff Static Pool Analysis Report</h1>

                <div className="flex-column">
                  <button
                    className="btn btn-primary mt-5"
                    style={{
                      backgroundColor: 'var(--green)',
                      borderColor: 'var(--green)',
                      width: '216px',
                    }}
                    onClick={() => exportPayoffDetailsReport(filters)}
                  >
                    Export Payoff Details Report
                  </button>
                  <button
                    className="btn btn-primary mt-5"
                    style={{
                      backgroundColor: 'var(--green)',
                      borderColor: 'var(--green)',
                      width: '226px',
                      marginLeft: '10px'
                    }}
                    onClick={() => exportPayoffSummaryReport(filters)}
                  >
                    Export Payoff Summary Report
                  </button>


                  <div style={{ overflowX: 'auto' }}>
                    <Table id="payoffStatic" striped className="mt-10 ">
                      <thead style={{ background: 'black', text: 'white' }}>
                        <tr className="text-white">
                          <th rowSpan="2" className="firstCol">
                            Year
                          </th>
                          <th rowSpan="2"># of Payed Out Files</th>
                          <th rowSpan="2">WA Days Outstanding</th>
                          <th rowSpan="2">Cash Outlay Repaid</th>
                          <th rowSpan="2">Total Cash Collected</th>
                          <th rowSpan="2">NET Profit</th>
                          <th rowSpan="2">Present Value At Payoff</th>
                          <th rowSpan="2">Multiple on Cash Outlay</th>

                          <th
                            colSpan="1"
                            scope="colgroup"
                            align="center"
                            className="topth"
                            style={{ width: '80px' }}
                          >
                            APR
                          </th>

                          <th
                            colSpan="5"
                            scope="colgroup"
                            className="topth"
                            style={{ width: '750px' }}
                          >
                            Written Off
                          </th>
                          <th
                            colSpan="2"
                            scope="colgroup"
                            className="topth"
                            style={{ width: '250px' }}
                          >
                            Concessions
                          </th>
                          <th rowSpan="2">Concession % of Present Value</th>
                        </tr>
                        <tr className="text-white">
                          <th>Cumulative</th>
                          <th># of Files</th>
                          <th>Cash Outlay</th>
                          <th>% of Tot. Cash Outlay</th>
                          <th>Present Value</th>
                          <th className="wideCol">% of Present Value</th>
                          <th className="wideCol">Case Concession</th>
                          <th>Marketing</th>
                        </tr>
                      </thead>
                      <tbody>
                        {staticPoolAnalysisReport.map((row) => (
                          <>
                            {row?.year === 'All Years' && (
                              <tr style={{ background: 'white' }}>
                                <td />
                              </tr>
                            )}
                            <tr
                              className="top17 firstCol"
                              style={
                                row?.year === 'All Years'
                                  ? {
                                      border: '1px dashed black',
                                    }
                                  : { fontWeight: 'normal' }
                              }
                            >
                              <td
                                className="text-white firstCol"
                                style={{ background: 'black', fontWeight: 'bold' }}
                              >
                                {row?.year}
                              </td>

                              <td
                                style={
                                  row?.year === 'All Years'
                                    ? {
                                        fontWeight: 'bold',
                                        border: 2,
                                        borderColor: 'black',
                                      }
                                    : { fontWeight: 'normal' }
                                }
                              >
                                {row?.no_of_Paid_Out_Files}
                              </td>
                              <td
                                style={
                                  row?.year === 'All Years'
                                    ? {
                                        fontWeight: 'bold',
                                        border: 2,
                                        borderColor: 'black',
                                      }
                                    : { fontWeight: 'normal' }
                                }
                              >
                                {row?.wA_Days_Outstanding}
                              </td>
                              <td
                                style={
                                  row?.year === 'All Years'
                                    ? {
                                        fontWeight: 'bold',

                                        border: 2,
                                        borderColor: 'black',
                                      }
                                    : { fontWeight: 'normal' }
                                }
                              >
                                {typeof row?.cash_Outlay_Repaid === 'number' &&
                                  moneyFormatter.format(
                                    parseFloat(row?.cash_Outlay_Repaid).toFixed(2)
                                  )}
                              </td>
                              <td
                                style={
                                  row?.year === 'All Years'
                                    ? {
                                        fontWeight: 'bold',

                                        border: 2,
                                        borderColor: 'black',
                                      }
                                    : { fontWeight: 'normal' }
                                }
                              >
                                {typeof row?.total_Cash_Collected === 'number' &&
                                  moneyFormatter.format(
                                    parseFloat(row?.total_Cash_Collected).toFixed(2)
                                  )}
                              </td>
                              <td
                                style={
                                  row?.year === 'All Years'
                                    ? {
                                        fontWeight: 'bold',

                                        border: 2,
                                        borderColor: 'black',
                                      }
                                    : { fontWeight: 'normal' }
                                }
                              >
                                {typeof row?.net_Profit === 'number' &&
                                  moneyFormatter.format(parseFloat(row?.net_Profit).toFixed(2))}
                              </td>
                              <td
                                style={
                                  row?.year === 'All Years'
                                    ? {
                                        fontWeight: 'bold',

                                        border: 2,
                                        borderColor: 'black',
                                      }
                                    : { fontWeight: 'normal' }
                                }
                              >
                                {typeof row?.present_Value_At_Payoff === 'number' &&
                                  moneyFormatter.format(
                                    parseFloat(row?.present_Value_At_Payoff).toFixed(2)
                                  )}
                              </td>
                              <td
                                style={
                                  row?.year === 'All Years'
                                    ? {
                                        fontWeight: 'bold',

                                        border: 2,
                                        borderColor: 'black',
                                      }
                                    : { fontWeight: 'normal' }
                                }
                              >
                                {row?.multiple_on_Cash_Outlay + 'x'}
                              </td>
                              <td
                                style={
                                  row?.year === 'All Years'
                                    ? {
                                        fontWeight: 'bold',

                                        border: 2,
                                        borderColor: 'black',
                                      }
                                    : { fontWeight: 'normal' }
                                }
                              >
                                {row?.apR_Cumulative + '%'}
                              </td>
                              <td
                                style={
                                  row?.year === 'All Years'
                                    ? {
                                        fontWeight: 'bold',

                                        border: 2,
                                        borderColor: 'black',
                                      }
                                    : { fontWeight: 'normal' }
                                }
                              >
                                {row?.written_Off_No_of_Files}
                              </td>
                              <td
                                style={
                                  row?.year === 'All Years'
                                    ? {
                                        fontWeight: 'bold',

                                        border: 2,
                                        borderColor: 'black',
                                      }
                                    : { fontWeight: 'normal' }
                                }
                              >
                                {typeof row?.written_Off_Cash_Outlay === 'number' &&
                                  moneyFormatter.format(
                                    parseFloat(row?.written_Off_Cash_Outlay).toFixed(2)
                                  )}
                              </td>
                              <td
                                style={
                                  row?.year === 'All Years'
                                    ? {
                                        fontWeight: 'bold',

                                        border: 2,
                                        borderColor: 'black',
                                      }
                                    : { fontWeight: 'normal' }
                                }
                              >
                                {row?.written_Off_Perc_of_Total_Cash_Outlay + '%'}
                              </td>
                              <td
                                style={
                                  row?.year === 'All Years'
                                    ? {
                                        fontWeight: 'bold',

                                        border: 2,
                                        borderColor: 'black',
                                      }
                                    : { fontWeight: 'normal' }
                                }
                              >
                                {typeof row?.written_Off_Present_Value === 'number' &&
                                  moneyFormatter.format(
                                    parseFloat(row?.written_Off_Present_Value).toFixed(2)
                                  )}
                              </td>
                              <td
                                style={
                                  row?.year === 'All Years'
                                    ? {
                                        fontWeight: 'bold',

                                        border: 2,
                                        borderColor: 'black',
                                      }
                                    : { fontWeight: 'normal' }
                                }
                              >
                                {row?.written_Off_Perc_of_Present_Value + '%'}
                              </td>
                              <td
                                style={
                                  row?.year === 'All Years'
                                    ? {
                                        fontWeight: 'bold',

                                        border: 2,
                                        borderColor: 'black',
                                      }
                                    : { fontWeight: 'normal' }
                                }
                              >
                                {typeof row?.concessions_Case_Concession === 'number' &&
                                  moneyFormatter.format(
                                    parseFloat(row?.concessions_Case_Concession).toFixed(2)
                                  )}
                              </td>
                              <td
                                style={
                                  row?.year === 'All Years'
                                    ? {
                                        fontWeight: 'bold',

                                        border: 2,
                                        borderColor: 'black',
                                      }
                                    : { fontWeight: 'normal' }
                                }
                              >
                                {typeof row?.concessions_Marketing === 'number' &&
                                  moneyFormatter.format(
                                    parseFloat(row?.concessions_Marketing).toFixed(2)
                                  )}
                              </td>
                              <td
                                style={
                                  row?.year === 'All Years'
                                    ? {
                                        fontWeight: 'bold',

                                        border: 2,
                                        borderColor: 'black',
                                      }
                                    : { fontWeight: 'normal' }
                                }
                              >
                                {row?.concession_Perc_of_Present_Value + '%'}
                              </td>
                            </tr>
                            {row?.year === 'All Years' && (
                              <tr style={{ background: 'white' }}>
                                <td />
                              </tr>
                            )}
                          </>
                        ))}
                      </tbody>
                    </Table>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default PayoffStaticPoolAnalysis;
