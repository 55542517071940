import React, { useState, useEffect, useContext } from 'react';
import { useHistory, useParams } from 'react-router';
import { makeStyles, withStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import { Col } from 'reactstrap';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableFooter from '@material-ui/core/TableFooter';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import Pagination from '@material-ui/lab/Pagination';
import VReportsCaseStatusUpdateActivity from '@services/VReportsCaseStatusUpdateActivity';
import AppContext from '@contexts/AppContext';
import TableSortLabel from '@material-ui/core/TableSortLabel';
import ReactLoading from 'react-loading';
import CaseStatusUpdateActivityFilteringFields from './CaseStatusUpdateActivityFilteringFields';
import '../Styles.css';
import { Link } from 'react-router-dom';
import { FormattedAmount } from '@components/Utils/FormattedAmount';
import Moment from 'moment';
import fileDownload from 'js-file-download';
import { toast } from 'react-toastify';

const StyledTableCell = withStyles((theme) => ({
  head: {
    backgroundColor: '#efefef',
    color: theme.palette.common.black,
  },
  body: {
    fontSize: 14,
  },
}))(TableCell);

const useStyles2 = makeStyles({
  table: {
    minWidth: 500,
  },
});

export default function CaseStatusUpdateActivityGrid() {
  const appContext = useContext(AppContext);
  const classes = useStyles2();
  const [page, setPage] = React.useState(1);
  const [rowsPerPage, setRowsPerPage] = React.useState(15);
  const [filters, setFilters] = useState(appContext.caseStatusUpdateActivityFilters);
  const [orderBy, setOrderBy] = useState('applicationNo');
  const [orderDir, setOrderDir] = useState('ASC');
  const [rows, setRows] = useState([]);
  const { accountManagerId } = useParams();
  const history = useHistory();

  const [modal, setModal] = useState(false);
  const toggle = () => setModal(!modal);
  const [rowIndex, setRowIndex] = useState(undefined);
  const [givenRows, setGivenRows] = useState(undefined);
  const [isLoading, setIsLoading] = useState(false);
  const [pages, setPages] = useState(undefined);

  const [activeFieldForSorting, setActiveFieldForSorting] = useState();

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  useEffect(() => {
    if (
      (!filters || !filters.totalAdvancedAmountRange || filters.totalAdvancedAmountRange == '')
    ) {
      let tempFilters = {
        ...filters,
        totalAdvancedAmountRange: 'This month'
      };

      setFilters(tempFilters);
      appContext.caseStatusUpdateActivityFilters = tempFilters;
      localStorage.setItem('caseStatusUpdateActivityFilters', JSON.stringify(tempFilters));
      loadData(tempFilters);
    } else {
      loadData(filters);
    }
  }, [page, orderBy, orderDir, rowsPerPage]);

  const loadData = (filters) => {
    let reqFilters = filters;
    if (reqFilters.totalAdvancedAmountRange == '') {
      delete reqFilters.totalAdvancedAmountRange;
    }

    setIsLoading(true);

    VReportsCaseStatusUpdateActivity.search(reqFilters, orderBy, orderDir, page, null, rowsPerPage)
      .then((response) => {
        setRows(response.data.data);
        setPages(response.data.pages.slice(-1)[0]);
        setIsLoading(false);
      })
      .catch((err) => { })
      .finally(() => {
        setIsLoading(false);
      });
  };

  const getAll = () => {
    let tempFilters = {
      totalAdvancedAmountRange: 'This month',
      caseStatusRange: 'This month',
    };
    setFilters(tempFilters);
    appContext.caseStatusUpdateActivityFilters = tempFilters;
    localStorage.setItem('caseStatusUpdateActivityFilters', JSON.stringify(tempFilters));
    update(tempFilters);
  };

  const exportTo = (filters) => {
    let reqFilters = filters;
    if (reqFilters.dateRange == '') {
      delete reqFilters.dateRange;
    }

    setIsLoading(true);

    VReportsCaseStatusUpdateActivity.export(reqFilters, orderBy, orderDir, page, null, rowsPerPage)
      .then((response) => {
        const contentDisposition = response.headers['content-disposition'];
        let fileName = 'Data-Export.xlsx';
        if (contentDisposition) {
          const fileNameMatch = contentDisposition.match(/filename="(.+)"/);
          if (fileNameMatch != null && fileNameMatch.length === 2) fileName = fileNameMatch[1];
        }
        fileDownload(response.data, fileName);
        setIsLoading(false);
      })
      .catch((err) => { })
      .finally(() => {
        setIsLoading(false);
      });
  };

  const update = (filters) => {
    let reqFilters = filters;
    if (reqFilters.dateRange == '') {
      delete reqFilters.dateRange;
    }
    if (reqFilters?.accountManager) reqFilters.accountManagerPublicId = reqFilters.accountManager;
    delete reqFilters.accountManager;

    setIsLoading(true);

    VReportsCaseStatusUpdateActivity.search(reqFilters, orderBy, orderDir, page, null, rowsPerPage)
      .then((response) => {
        setRows(response.data.data);
        setPages(response.data.pages.slice(-1)[0]);
        setIsLoading(false);
      })
      .catch((err) => {
        clearGrid();
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  const clearGrid = (filters) => {
    setRows([]);
    setPages();
  };

  const searchWithFilters = (filters) => {
    var newFilters = { ...filters };
    setFilters(newFilters);
    appContext.caseStatusUpdateActivityFilters = newFilters;

    localStorage.setItem(
      'caseStatusUpdateActivityFilters',
      JSON.stringify(appContext.caseStatusUpdateActivityFilters)
    );

    if (page === 1) update(newFilters);
    else setPage(1);
  };

  const arrange = (field) => {
    if (orderBy === field) {
      if (orderDir === 'ASC') {
        setOrderDir('DESC');
      } else {
        setOrderDir('ASC');
      }
    } else {
      setOrderBy(field);
      setOrderDir('ASC');
    }

    setPage(1);
  };

  const handlePageSizeChange = (event) => {
    setRowsPerPage(event.target.value);
  };

  const exportAccountManagerDetails = () => {
    exportTo(filters);
  };

  return (
    <>
      <div className="d-flex mt-5 mb-10">
        <div className="mr-auto">
          <h1>Case Status Update Activity</h1>
        </div>
        <Link
          onClick={exportAccountManagerDetails}
          className="btn btn-primary export-btn "
          style={{
            backgroundColor: 'var(--green)',
            borderColor: 'var(--green)',
          }}
        >
          Export
        </Link>
      </div>
      <CaseStatusUpdateActivityFilteringFields
        searchWithFilters={searchWithFilters}
        getAll={getAll}
        clearGrid={clearGrid}
      ></CaseStatusUpdateActivityFilteringFields>
      <Col md="10" className="float-right">
        <TableContainer component={Paper}>
          <Table className={classes.table} aria-label="custom pagination table">
            <TableHead>
              <TableRow>
                <StyledTableCell>Id</StyledTableCell>

                <StyledTableCell
                  className="amd-acc-manger-clmn"
                  onClick={() => {
                    arrange('applicationNo');
                    setActiveFieldForSorting('applicationNo');
                  }}
                >
                  APP #
                  <TableSortLabel
                    active={activeFieldForSorting === 'applicationNo' ? true : false}
                    direction={
                      activeFieldForSorting === 'applicationNo'
                        ? orderDir.toLowerCase()
                        : 'asc'
                    }
                    onClick={() => arrange('applicationNo')}
                  ></TableSortLabel>
                </StyledTableCell>

                <StyledTableCell
                  className="amd-client-clmn"
                  onClick={() => {
                    arrange('clientName');
                    setActiveFieldForSorting('clientName');
                  }}
                >
                  CLIENT
                </StyledTableCell>

                <StyledTableCell
                  className="amd-status-clmn"
                  onClick={() => {
                    arrange('dateofBirth');
                    setActiveFieldForSorting('dateofBirth');
                  }}
                >
                  DATE OF BIRTH
                </StyledTableCell>

                <StyledTableCell
                  className="amd-appNo-clmn"
                  onClick={() => {
                    arrange('dateofLoss');
                    setActiveFieldForSorting('dateofLoss');
                  }}
                >
                  DATE OF LOSS
                </StyledTableCell>

                <StyledTableCell
                  className="amd-appNo-clmn"
                  onClick={() => {
                    arrange('lawFirmName');
                    setActiveFieldForSorting('lawFirmName');
                  }}
                >
                  LAW FIRM
                </StyledTableCell>

                <StyledTableCell
                  className="fcr-total-delivery-fees"
                  onClick={() => {
                    arrange('totalAdvancedAmount');
                    setActiveFieldForSorting('totalAdvancedAmount');
                  }}
                >
                  TOTAL ADVANCE AMT
                  <TableSortLabel
                    active={activeFieldForSorting === 'totalAdvancedAmount' ? true : false}
                    direction={
                      activeFieldForSorting === 'totalAdvancedAmount' ? orderDir.toLowerCase() : 'asc'
                    }
                    onClick={() => arrange('totalAdvancedAmount')}
                  ></TableSortLabel>
                </StyledTableCell>

                <StyledTableCell
                  className="amd-advancedDt-clmn"
                  onClick={() => {
                    arrange('caseStatus');
                    setActiveFieldForSorting('caseStatus');
                  }}
                >
                  CASE STATUS
                  <TableSortLabel
                    active={activeFieldForSorting === 'caseStatus' ? true : false}
                    direction={
                      activeFieldForSorting === 'caseStatus' ? orderDir.toLowerCase() : 'asc'
                    }
                    onClick={() => arrange('caseStatus')}
                  ></TableSortLabel>
                </StyledTableCell>


                <StyledTableCell
                  className="amd-advancedDt-clmn"
                  onClick={() => {
                    arrange('diaryFrame');
                    setActiveFieldForSorting('diaryFrame');
                  }}
                >
                  DIARY FRAME
                </StyledTableCell>

                <StyledTableCell
                  className="amd-advancedDt-clmn"
                  onClick={() => {
                    arrange('indexNumber');
                    setActiveFieldForSorting('indexNumber');
                  }}
                >
                  INDEX #
                </StyledTableCell>

                <StyledTableCell
                  className="amd-advancedDt-clmn"
                  onClick={() => {
                    arrange('timingOutlook');
                    setActiveFieldForSorting('timingOutlook');
                  }}
                >
                  TIMING OUTLOOK
                </StyledTableCell>

                <StyledTableCell
                  className="amd-advancedDt-clmn"
                  onClick={() => {
                    arrange('commentary');
                    setActiveFieldForSorting('commentary');
                  }}
                >
                  COMMENTARY
                </StyledTableCell>

                <StyledTableCell
                  className="amd-advancedDt-clmn"
                  onClick={() => {
                    arrange('submitterName');
                    setActiveFieldForSorting('submitterName');
                  }}
                >
                  SUBMITTER
                </StyledTableCell>

                <StyledTableCell
                  className="amd-advancedDt-clmn"
                  onClick={() => {
                    arrange('submittedDate');
                    setActiveFieldForSorting('submittedDate');
                  }}
                >
                  SUBMITTED DATE
                </StyledTableCell>
              </TableRow>
            </TableHead>

            {isLoading === false && (
              <TableBody>
                {rows &&
                  rows.map((row, index) => (
                    <TableRow key={row.applicationNo}>
                      <TableCell component="th" scope="row">{row.applicationNo}</TableCell>
                      <TableCell className="amd-acc-applicationNo-clmn">{row.applicationNo}</TableCell>
                      <TableCell className="amd-acc-manger-clmn">{row.clientName}</TableCell>
                      <TableCell className="amd-client-clmn">
                        {row?.dateofBirth &&
                          Moment(row?.dateofBirth?.split('T')[0]).format('MM-DD-YYYY')}
                        </TableCell>
                      <TableCell className="amd-status-clmn">
                        {row?.dateofLoss &&
                          Moment(row?.dateofLoss?.split('T')[0]).format('MM-DD-YYYY')}
                          </TableCell>
                      <TableCell className="amd-appNo-clmn">{row.lawFirmName}</TableCell>

                      <TableCell className="fcr-total-delivery-fees">
                        {row?.totalAdvancedAmount
                          ? FormattedAmount.format(parseFloat(row.totalAdvancedAmount).toFixed(2))
                          : '-'}
                      </TableCell>

                      <TableCell className="amd-appNo-clmn">{row.caseStatus}</TableCell>
                      <TableCell className="amd-appNo-clmn">{row.diaryFrame}</TableCell>
                      <TableCell className="amd-appNo-clmn">{row.indexNumber}</TableCell>
                      <TableCell className="amd-appNo-clmn">{row.timingOutlook}</TableCell>
                      <TableCell className="amd-appNo-clmn">{row.commentary}</TableCell>
                      <TableCell className="amd-advancedDt-clmn">
                        {row?.submitterName}
                      </TableCell>
                      <TableCell className="amd-advancedDt-clmn">
                        {row?.submittedDate &&
                          Moment(row?.submittedDate?.split('T')[0]).format('MM-DD-YYYY')}
                      </TableCell>
                    </TableRow>
                  ))}
              </TableBody>
            )}
            {isLoading && (
              <TableBody>
                <TableRow>
                  <td></td>
                  <td>
                    <ReactLoading type="bars" color="#7E8299" />
                  </td>
                  <td></td>
                </TableRow>
              </TableBody>
            )}

            <TableFooter></TableFooter>
          </Table>
        </TableContainer>
        <div className="float-left mt-5">
          <select onChange={handlePageSizeChange} className="form-control" defaultValue={15}>
            {[5, 10, 15, 25, 100].map((size) => (
              <option key={size} value={size}>
                {size}
              </option>
            ))}
          </select>
        </div>
        <div className="float-right mt-5">
          <Pagination
            count={pages}
            page={page}
            siblingCount={1}
            boundaryCount={1}
            variant="outlined"
            onChange={handleChangePage}
          />
        </div>
      </Col>
    </>
  );
}
