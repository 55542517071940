import React, { useEffect, useState, useContext, Fragment, useRef } from 'react';
import { Row, Col, Input, Label, Alert, Button, Form, FormGroup, Container } from 'reactstrap';
import { useParams, Link } from 'react-router-dom';
import { v4 } from 'uuid';
import { useHistory } from 'react-router';
import ReactLoading from 'react-loading';
import AppContext from '@contexts/AppContext';
import BrokerService from '@services/BrokerService';
import { useForm } from 'react-hook-form';
import { ErrorMessage } from '@hookform/error-message';
import StateSelect from '@components/StateSelect';
import { toast } from 'react-toastify';

const Broker = () => {
  const { id } = useParams();
  const [successMessage, setSuccessMessage] = useState('');
  const [errorMessage, setErrorMessage] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const appContext = useContext(AppContext);
  const { register, handleSubmit, watch, errors, formState } = useForm({ mode: 'onBlur' });
  const history = useHistory();
  const form = useRef();

  const [broker, setBroker] = useState({
    publicId: '',
    companyName: '',
    referralFee: '',
    buyOutComm: '',
    displayName: '',
    firstName: '',
    lastName: '',
    streetAddress: '',
    city: '',
    state: '',
    zipCode: '',
    workPhoneNo: '',
    tollFreePhoneNo: '',
    emailAddress: '',
    website: '',
    bankName: '',
    bankNo: '',
    bankRoutingNo: '',
    bankAccountNo: '',
    notes: '',
    isActive: true,
  });

  useEffect(() => {
    const makeMenuItemActive = () => {
      document.querySelector('#menuitem-contacts').click();
      document
        .querySelector('#kt_header_tab_7 > div > div > ul > li:nth-child(3)')
        .classList.add('menu-item-active');
    };

    makeMenuItemActive();
  }, []);

  useEffect(() => {}, []);

  useEffect(() => {
    const LoadBrokerDetailsIfIdPresent = () => {
      if (id) {
        setIsLoading(true);

        BrokerService.read(id)
          .then((response) => {
            if (response.data) {
              setBroker(response.data);
              setIsLoading(false);
            }
          })
          .catch((err) => {})
          .finally(() => {
            setIsLoading(false);
          });
      }
    };

    LoadBrokerDetailsIfIdPresent();
  }, []);

  const saveBroker = (data) => {
    setIsLoading(true);

    const br = { ...data };
    br.referralFee = br.referralFee ? parseFloat(br.referralFee) : undefined;
    br.buyOutComm = br.buyOutComm ? parseFloat(br.buyOutComm) : undefined;
    br.brokerId = undefined;
    br.displayName = undefined;
    setBroker(br);

    if (!id) {
      br.publicId = v4();
      setBroker(br);

      BrokerService.create(br)
        .then((response) => {
          appContext.toastMessage.message = 'Broker has been successfully created.';
          setIsLoading(false);
          history.push('/contacts/brokers');
        })
        .catch((err) => {})
        .finally(() => {
          setIsLoading(false);
        });
    } else {
      if (!(appContext?.userPermissions?.indexOf('Edit Brokers') > -1)) {
        appContext.toastMessage.message = 'You do not have permissions to perform this action.';
        toast.error(appContext.toastMessage.message, { autoClose: 3000 });
        appContext.toastMessage.message = null;
        return;
      }

      br.publicId = id;

      BrokerService.update(br)
        .then((response) => {
          appContext.toastMessage.message = 'Broker has been successfully saved.';
          setSuccessMessage('');
          setIsLoading(false);
          history.push('/contacts/brokers');
        })
        .catch((err) => {})
        .finally(() => {
          setIsLoading(false);
        });
    }
  };

  const hideForm = () => {
    form.current.style.display = 'none';
  };

  const handleCancelButton = () => {
    history.push('/contacts/brokers');
  };

  if (isLoading) {
    return (
      <div className="container mt-10 mb-10">
        <div className="row">
          <div className="col-md-12 mx-auto bg-white box-shadow-border">
            <div className="p-10">
              <div className="mt-5 mb-10">
                {errorMessage && (
                  <div className="alert alert-custom alert-danger mb-10" role="alert">
                    <div className="alert-icon">
                      <i className="flaticon-warning" />
                    </div>
                    <div className="alert-text"> {errorMessage} </div>
                  </div>
                )}

                <ReactLoading type="bars" color="#7E8299" />
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }

  return (
    <Fragment>
      <Container className="mt-10 mb-10">
        <Row>
          <Col md="12" className="mx-auto bg-white box-shadow-border">
            <div className="p-10">
              <div className="mt-5 mb-10">
                <h1>{!id ? 'New Broker' : 'Broker Details'}</h1>
                <Row>
                  <Col md="7 pt-5 pb-5">
                    {successMessage && <Alert color="success">{successMessage}</Alert>}
                    {successMessage && hideForm()}
                    {errorMessage && (
                      <Alert color="danger" className="mt-3">
                        {errorMessage}
                      </Alert>
                    )}
                  </Col>
                </Row>

                <Container className="formContainer p-0">
                  <form ref={form} onSubmit={handleSubmit(saveBroker)} noValidate>
                    <div className="row">
                      <div className="col-md-6">
                        <legend>Broker Information</legend>

                        <div className="row">
                          <div className="col-md-7">
                            <div className="form-group">
                              <label htmlFor="companyName">Company Name</label>
                              <input
                                type="text"
                                name="companyName"
                                className="form-control"
                                defaultValue={broker.companyName}
                                ref={register({
                                  required: 'Company name is required.',
                                  maxLength: {
                                    value: 100,
                                    message: 'Max length is 100 characters.',
                                  },
                                })}
                              />
                              {errors.companyName && (
                                <Alert color="danger" className="mt-5">
                                  <ErrorMessage errors={errors} name="companyName" />
                                </Alert>
                              )}
                            </div>
                          </div>
                          <div className="col-md-1">
                            <div className="form-group">
                              <input
                                type="checkbox"
                                name="isActive"
                                className="mr-2"
                                {...(!id && { disabled: true })}
                                defaultChecked={broker.isActive}
                                ref={register}
                              />
                              <label htmlFor="isActive">Active</label>
                            </div>
                          </div>
                        </div>

                        <div className="row">
                          <div className="col-md-4">
                            <div className="form-group">
                              <label htmlFor="referralFee">Referral Fee</label>
                              <input
                                type="text"
                                name="referralFee"
                                className="form-control"
                                defaultValue={broker.referralFee}
                                ref={register({
                                  required: 'Referral Fee is required.',
                                  // pattern: {
                                  //   value: /^\d{1,}(\.\d{0,2})?$/,
                                  //   message: 'Only numeric values allowed.',
                                  // },
                                })}
                              />
                              {errors.referralFee && (
                                <Alert color="danger" className="mt-5">
                                  <ErrorMessage errors={errors} name="referralFee" />
                                </Alert>
                              )}
                            </div>
                          </div>

                          <div className="col-md-4">
                            <div className="form-group">
                              <label htmlFor="buyOutComm">Buyout Commision</label>
                              <input
                                type="text"
                                name="buyOutComm"
                                className="form-control"
                                defaultValue={broker.buyoutComm}
                                ref={register({
                                  required: 'Buyout Commision is required.',
                                  pattern: {
                                    value: /^\d{1,}(\.\d{0,2})?$/,
                                    message: 'Only numeric values allowed.',
                                  },
                                })}
                              />
                              {errors.buyOutComm && (
                                <Alert color="danger" className="mt-5">
                                  <ErrorMessage errors={errors} name="buyOutComm" />
                                </Alert>
                              )}
                            </div>
                          </div>
                        </div>

                        <fieldset>
                          <legend>Contact Info</legend>

                          <div className="row">
                            <div className="col-md-4">
                              <div className="form-group">
                                <label htmlFor="firstName">First Name</label>
                                <input
                                  type="text"
                                  name="firstName"
                                  className="form-control"
                                  defaultValue={broker.firstName}
                                  ref={register({
                                    required: 'First Name is required.',
                                    maxLength: {
                                      value: 20,
                                      message: 'Max length is 20 characters.',
                                    },
                                  })}
                                />
                                {errors.firstName && (
                                  <Alert color="danger" className="mt-5">
                                    <ErrorMessage errors={errors} name="firstName" />
                                  </Alert>
                                )}
                              </div>
                            </div>

                            <div className="col-md-4">
                              <div className="form-group">
                                <label htmlFor="lastName">Last Name</label>
                                <input
                                  type="text"
                                  name="lastName"
                                  className="form-control"
                                  defaultValue={broker.lastName}
                                  ref={register({
                                    required: 'Last Name is required.',
                                    maxLength: {
                                      value: 30,
                                      message: 'Max length is 30 characters.',
                                    },
                                  })}
                                />
                                {errors.lastName && (
                                  <Alert color="danger" className="mt-5">
                                    <ErrorMessage errors={errors} name="lastName" />
                                  </Alert>
                                )}
                              </div>
                            </div>
                          </div>

                          <Row>
                            <Col md="8">
                              <div className="form-group">
                                <label htmlFor="streetAddress">Address</label>
                                <input
                                  type="text"
                                  name="streetAddress"
                                  className="form-control"
                                  defaultValue={broker.streetAddress}
                                  ref={register({
                                    maxLength: {
                                      value: 100,
                                      message: 'Max length is 100 characters.',
                                    },
                                  })}
                                />
                                {errors.streetAddress && (
                                  <Alert color="danger" className="mt-5">
                                    <ErrorMessage errors={errors} name="streetAddress" />
                                  </Alert>
                                )}
                              </div>
                            </Col>
                          </Row>

                          <Row>
                            <Col md="8">
                              <div className="form-group">
                                <label htmlFor="city">City</label>
                                <input
                                  type="text"
                                  name="city"
                                  className="form-control"
                                  defaultValue={broker.city}
                                  ref={register({
                                    maxLength: {
                                      value: 30,
                                      message: 'Max length is 30 characters.',
                                    },
                                  })}
                                />
                                {errors.city && (
                                  <Alert color="danger" className="mt-5">
                                    <ErrorMessage errors={errors} name="city" />
                                  </Alert>
                                )}
                              </div>
                            </Col>
                          </Row>

                          <div className="row">
                            <Col md="4">
                              <StateSelect
                                name="state"
                                label="State"
                                useRef={register({ required: 'State is required.' })}
                                defaultValue={broker.state}
                                setObject={setBroker}
                                givenObject={broker}
                                errors={errors}
                              />
                            </Col>

                            <Col md="4">
                              <div className="form-group">
                                <label htmlFor="zipCode">ZipCode</label>
                                <input
                                  type="text"
                                  name="zipCode"
                                  className="form-control"
                                  pattern="[0-9]*"
                                  defaultValue={broker.zipCode}
                                  ref={register({
                                    pattern: {
                                      value: /^\d{5}(?:[-\s]\d{4})?$/,
                                      message: 'The US Zip code provided is wrong.',
                                    },
                                    maxLength: {
                                      value: 20,
                                      message: 'Max length is 20 characters.',
                                    },
                                  })}
                                />
                                {errors.zipCode && (
                                  <Alert color="danger" className="mt-5">
                                    <ErrorMessage errors={errors} name="zipCode" />
                                  </Alert>
                                )}
                              </div>
                            </Col>
                          </div>

                          <div className="row">
                            <div className="col-md-4">
                              <div className="form-group">
                                <label htmlFor="workPhoneNo">Work Phone #</label>
                                <input
                                  type="text"
                                  name="workPhoneNo"
                                  className="form-control"
                                  defaultValue={broker.workPhoneNo}
                                  ref={register({
                                    maxLength: {
                                      value: 20,
                                      message: 'Max length is 20 characters.',
                                    },
                                  })}
                                />
                                {errors.workPhoneNo && (
                                  <Alert color="danger" className="mt-5">
                                    <ErrorMessage errors={errors} name="workPhoneNo" />
                                  </Alert>
                                )}
                              </div>
                            </div>

                            <div className="col-md-4">
                              <div className="form-group">
                                <label htmlFor="tollFreePhoneNo">Toll Free</label>
                                <input
                                  type="text"
                                  name="tollFreePhoneNo"
                                  className="form-control"
                                  defaultValue={broker.tollFreePhoneNo}
                                  ref={register({
                                    maxLength: {
                                      value: 20,
                                      message: 'Max length is 20 characters.',
                                    },
                                  })}
                                />
                              </div>
                              {errors.tollFreePhoneNo && (
                                <Alert color="danger" className="mt-5">
                                  <ErrorMessage errors={errors} name="tollFreePhoneNo" />
                                </Alert>
                              )}
                            </div>
                          </div>

                          <Row>
                            <Col md="8">
                              <div className="form-group">
                                <label htmlFor="emailAddress">Email</label>
                                <input
                                  type="text"
                                  name="emailAddress"
                                  className="form-control"
                                  defaultValue={broker.emailAddress}
                                  ref={register({
                                    required: 'Email is required.',
                                    maxLength: {
                                      value: 100,
                                      message: 'Max length is 100 characters.',
                                    },
                                    pattern: {
                                      value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
                                      message: 'Invalid email address provided.',
                                    },
                                  })}
                                />
                                {errors.emailAddress && (
                                  <Alert color="danger" className="mt-5">
                                    <ErrorMessage errors={errors} name="emailAddress" />
                                  </Alert>
                                )}
                              </div>
                            </Col>
                          </Row>

                          <Row>
                            <Col md="8">
                              <div className="form-group">
                                <label htmlFor="website">Website</label>
                                <input
                                  type="text"
                                  name="website"
                                  className="form-control"
                                  defaultValue={broker.webSite}
                                  ref={register({
                                    maxLength: {
                                      value: 100,
                                      message: 'Max length is 100 characters.',
                                    },
                                  })}
                                />
                                {errors.website && (
                                  <Alert color="danger" className="mt-5">
                                    <ErrorMessage errors={errors} name="website" />
                                  </Alert>
                                )}
                              </div>
                            </Col>
                          </Row>
                        </fieldset>
                      </div>

                      <div className="col-md-6">
                        <fieldset>
                          <legend>Bank Account Information</legend>

                          <Row>
                            <Col md="8">
                              <div className="form-group">
                                <label htmlFor="bankName">Bank Name</label>
                                <input
                                  type="text"
                                  name="bankName"
                                  className="form-control"
                                  defaultValue={broker.bankName}
                                  ref={register({
                                    required: 'Bank Name is required.',
                                    maxLength: {
                                      value: 30,
                                      message: 'Max length is 30 characters.',
                                    },
                                  })}
                                />
                                {errors.bankName && (
                                  <Alert color="danger" className="mt-5">
                                    <ErrorMessage errors={errors} name="bankName" />
                                  </Alert>
                                )}
                              </div>
                            </Col>
                          </Row>

                          <Row>
                            <Col md="4">
                              <div className="form-group">
                                <label htmlFor="bankNo">Bank # (3 digits)</label>
                                <input
                                  type="text"
                                  maxLength="5"
                                  name="bankNo"
                                  className="form-control"
                                  defaultValue={broker.bankNo}
                                  ref={register({
                                    required: 'Bank is required.',
                                    maxLength: { value: 5, message: 'Max length is 5 characters.' },
                                  })}
                                />
                                {errors.bankNo && (
                                  <Alert color="danger" className="mt-5">
                                    <ErrorMessage errors={errors} name="bankNo" />
                                  </Alert>
                                )}
                              </div>
                            </Col>

                            <Col md="4">
                              <div className="form-group">
                                <label htmlFor="bankRoutingNo">Bank Routing # (5 digits)</label>
                                <input
                                  type="text"
                                  maxLength="5"
                                  name="bankRoutingNo"
                                  className="form-control"
                                  defaultValue={broker.bankRoutingNo}
                                  ref={register({
                                    required: 'Bank Routing is required.',
                                    maxLength: { value: 5, message: 'Max length is 5 characters.' },
                                  })}
                                />
                                {errors.bankRoutingNo && (
                                  <Alert color="danger" className="mt-5">
                                    <ErrorMessage errors={errors} name="bankRoutingNo" />
                                  </Alert>
                                )}
                              </div>
                            </Col>
                          </Row>

                          <Row>
                            <Col md="8">
                              <div className="form-group">
                                <label htmlFor="bankAccountNo">Account #</label>
                                <input
                                  type="text"
                                  name="bankAccountNo"
                                  className="form-control"
                                  defaultValue={broker.bankAccountNo}
                                  ref={register({
                                    required: 'Account is required.',
                                    maxLength: {
                                      value: 20,
                                      message: 'Max length is 20 characters.',
                                    },
                                  })}
                                />
                                {errors.bankAccountNo && (
                                  <Alert color="danger" className="mt-5">
                                    <ErrorMessage errors={errors} name="bankAccountNo" />
                                  </Alert>
                                )}
                              </div>
                            </Col>
                          </Row>
                        </fieldset>

                        <fieldset>
                          <legend>Notes</legend>

                          <Row>
                            <Col md="8">
                              <div className="form-group">
                                <label htmlFor="notes">Notes</label>
                                <textarea
                                  name="notes"
                                  rows="8"
                                  className="form-control"
                                  defaultValue={broker.notes}
                                  ref={register({
                                    maxLength: {
                                      value: 1000,
                                      message: 'Max length is 1000 characters.',
                                    },
                                  })}
                                />
                              </div>
                              {errors.notes && (
                                <Alert color="danger" className="mt-5">
                                  <ErrorMessage errors={errors} name="notes" />
                                </Alert>
                              )}
                            </Col>
                          </Row>
                        </fieldset>
                      </div>
                    </div>

                    <button type="submit" className="btn btn-primary mr-5">
                      Update
                    </button>
                    <a href="##" onClick={handleCancelButton} className="btn btn-secondary">
                      Cancel
                    </a>
                  </form>
                </Container>
              </div>
            </div>
          </Col>
        </Row>
      </Container>
    </Fragment>
  );
};

export default Broker;
