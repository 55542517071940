import React, { useEffect, useContext, useRef, useState } from 'react';
import { Row, Col, Input, Label, Alert, Button, Form, FormGroup, Container } from 'reactstrap';

const CustomDateRange = (props) => {
  const { dateValue, setDateValue, fromDate, setFromDate, toDate, setToDate, labelText } = props;

  const [isDateRange, setIsDaterange] = useState(false);
  const [errorMessage, setErrorMessage] = useState();

  useEffect(() => {
    if (dateValue === 'Custom' || dateValue === null) {
      setIsDaterange(true);
    } else {
      setIsDaterange(false);
      setFromDate(null);
      setToDate(null);
    }
  }, [dateValue]);

  useEffect(() => {
    if (fromDate || toDate) {
      setDateValue('Custom');
    }
  }, [fromDate, toDate]);

  function onChangeDateFilter(e) {
    setDateValue(e.target.value);
  }

  function onChangeFromDate(e) {
    const selectedFromDate = e.target.value;
    setFromDate(selectedFromDate);

    if (selectedFromDate && toDate) {
      const from = new Date(selectedFromDate);
      const to = new Date(toDate);
      if (from <= to) {
        setErrorMessage(null);
      } else {
        setErrorMessage('The To date cannot be before From date');
      }
    } else {
      setErrorMessage('Dates are invalid');
    }
  }

  function onChangeToDate(e) {
    const selectedToDate = e.target.value;
    if (fromDate && selectedToDate) {
      const from = new Date(fromDate);
      const to = new Date(selectedToDate);
      if (from <= to) {
        setToDate(selectedToDate);
        setErrorMessage(null);
      } else {
        setErrorMessage('The To date cannot be before From date');
      }
    } else {
      setErrorMessage('Dates are invalid');
    }
  }

  return (
    <>
      <Row className="mt-5">
        <Col md="12">
          <label htmlFor="dateRange">{labelText ?? 'Select date range'}</label>
        </Col>
        <Col md="12">
          <select
            id="dateFilter"
            value={dateValue}
            onChange={onChangeDateFilter}
            className="form-control"
          >
            <option value="">Select date range</option>
            <option value="This month">This month</option>
            <option value="Today">Today</option>
            <option value="Yesterday">Yesterday</option>
            <option value="This week">This week</option>
            <option value="Last week">Last week</option>
            <option value="Last month">Last month</option>
            <option value="YTD">YTD</option>
            <option value="Custom">Custom</option>
          </select>
        </Col>
      </Row>

      {isDateRange == true && (
        <>
          <Row className="mt-5">
            <Col md="12">
              <label htmlFor="dateRange">From</label>
            </Col>
            <Col md="12">
              <input
                onChange={onChangeFromDate}
                type="date"
                id="fromDate"
                className="form-control"
                required="required"
                value={fromDate}
              />
            </Col>
          </Row>
          <Row className="mt-5">
            <Col md="12">
              <label htmlFor="dateRange">To</label>
            </Col>
            <Col md="12">
              <input
                onChange={onChangeToDate}
                type="date"
                id="toDate"
                className="form-control"
                required="required"
                value={toDate}
              />
            </Col>
            {errorMessage && (
              <Col md="12">
                <Alert color="danger" className="mt-5">
                  <span>{errorMessage}</span>
                </Alert>
              </Col>
            )}
          </Row>
        </>
      )}
    </>
  );
};

export default CustomDateRange;
