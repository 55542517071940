import React, { useEffect } from 'react';
import { Button, Modal, ModalHeader, ModalBody, ModalFooter, Alert } from 'reactstrap';
import { ErrorMessage } from '@hookform/error-message';
import { useForm } from 'react-hook-form';

const FileRenamePopup = (props) => {
  const {
    fileNameModal,
    togglefileName,
    handleRenameFileNameFormSubmit,
    setDocumentName,
    documentName
  } = props;
  const { register, handleSubmit, errors } = useForm({ mode: 'onBlur' });

  return (
    <div>
      <Modal isOpen={fileNameModal} toggle={togglefileName}>
        <ModalHeader>Rename File</ModalHeader>
        <ModalBody>
          <form method="POST" onSubmit={handleSubmit(handleRenameFileNameFormSubmit)} noValidate>
            <div className="form-group">
              <label>File Name</label>
              <input
                name="fileName"
                id="fileName"
                ref={register({ required: 'File Name is required.' })}
                required="required"
                style={{ resize: 'none' }}
                className="form-control"
                value = {documentName}
                onChange={(e) => setDocumentName(e.target.value)}
              />
              {errors.fileName && (
                <Alert color="danger" className="mt-5">
                  <ErrorMessage errors={errors} name="fileName" />
                </Alert>
              )}
            </div>
          </form>
        </ModalBody>
        <ModalFooter>
          <div className="form-group">
            <button
              type="submit"
              className="btn btn-primary"
              onClick={handleSubmit(handleRenameFileNameFormSubmit)}
            >
             Save
            </button>
            <button type="button" className="ml-5 btn btn-secondary" onClick={togglefileName}>
              Cancel
            </button>
          </div>
        </ModalFooter>
      </Modal>
    </div>
  );
};

export default FileRenamePopup;
