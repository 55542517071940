import React from 'react';
import { BrowserRouter as Router, Switch, Route, Redirect } from 'react-router-dom';
import { ToastContainer } from 'react-toastify';
import Menu from './components/Menu';
import Error404 from '@pages/Error404';
import Login from '@pages/Login';
import ForgotPassword from '@pages/ForgotPassword';
import ForgotPasswordSuccess from '@pages/ForgotPasswordSuccess';
import Dashboard from '@pages/Dashboard';
import Logout from '@pages/Logout';
import UnderwritingApplicationsPage from '@pages/Underwriting/Applications';
import UnderwritingApplicationsToBeReviewedPage from '@pages/Underwriting/ApplicationsToBeReviewed';
import UnderwritingApplicationsNewPage from '@pages/Underwriting/Applications/Application';
import PortfoliosPayoffLetterRequests from '@pages/Portfolios/PayoffLetterRequests';
import PortfolioPayoffLetterRequestNewPage from '@pages/Portfolios/PayoffLetterRequests/PayoffLetterRequest';
import PortfoliosConcessionDiary from '@pages/Portfolios/ConcessionDiary';
import PortfoliosCasesRequireReview from '@pages/Portfolios/CasesRequireReview';
import PortfoliosList from '@pages/Portfolios/PortfoliosList';
import AccountsActiveAdvanceReceivables from '@pages/Accounts/ActiveAdvanceReceivables';
import AccountsAccountManagerPipeline from '@pages/Accounts/AccountManagerPipeline';
import AccountsAccountFunding from '@pages/Accounts/AccountFundings';
import AccountsLawFirmContacts from '@pages/Accounts/LawFirmContacts';
import AccountingCasesToBeAdvanced from '@pages/Accounting/CasesToBeAdvanced';
import AccountingPayoffs from '@pages/Accounting/Payoffs';
import AccountingPayoffRequests from '@pages/Accounting/PayoffRequests';
import AccountingActiveAdvanceReceivables from '@pages/Accounting/ActiveAdvanceReceivables';
import ReportsAccountManagerDetails from '@pages/Reports/AccountManagerDetails';
import ReportsAccountManagerSummary from '@pages/Reports/AccountManagerSummary';
import ReportsAttorneyData from '@pages/Reports/AttorneyData';
import ReportsCommission from '@pages/Reports/Commision';
import ReportsCaseStatusAged from '@pages/Reports/CaseStatusAged';
import ReportsCaseStatus from '@pages/Reports/CaseStatus';
import ReportsConcessionDiary from '@pages/Reports/ConcessionDiary';
import ReportsFinancialCenter from '@pages/Reports/FinancialCenter';
import ReportsFinancialSummary from '@pages/Reports/FinancialSummary';
import ReportsLawFirmDetails from '@pages/Reports/LawFirmDetails';
import ReportsLawFirmSummary from '@pages/Reports/LawFirmSummary';
import ReportsPayoffDetails from '@pages/Reports/PayoffDetails';
import ReportsPayoffDiary from '@pages/Reports/PayoffDiary';
import ReportsPayoffStaticPoolAnalysis from '@pages/Reports/PayoffStaticPoolAnalysis';
import ReportsTypesOfAccidentsSummary from '@pages/Reports/TypesOfAccidentsSummary';
import ReportsPresentValue from '@pages/Reports/PresentValue';
import CaseStatusUpdateActivity from '@pages/Reports/CaseStatusUpdateActivity';
import ContactsLawFirms from '@pages/Contacts/LawFirms/LawFirms';
import ContactsAttorneys from '@pages/Contacts/Attorneys';
import ContactsBrokers from '@pages/Contacts/Brokers';
import ContactsFinancialStores from '@pages/Contacts/FinancialStores';
import SettingsUsers from '@pages/Settings/Users';
import SettingsUserRoles from '@pages/Settings/UserRoles';
import SettingsMiscSettings from '@pages/Settings/MiscSettings';
import PersonalInformation from '@pages/Profile/PersonalInformation';
import ChangePassword from '@pages/Profile/ChangePassword';
import SettingsUsersUser from '@pages/Settings/Users/User';
import UnderwritingApplicationsNewPagePlaintiff from '@pages/Underwriting/Applications/Application/Plaintiff';
import UnderwritingApplicationsAttorneyCaseStatus from '@pages/Underwriting/Applications/Application/AttorneyCaseStatus';

import UnderwritingNecessityFundingCaseStatusUpdates from '@pages/Underwriting/NecessityFundingCaseStatusUpdates';

import UnderwritingApplicationsNewPageAccident from '@pages/Underwriting/Applications/Application/Accident';
import UnderwritingApplicationsNewPageInjury from '@pages/Underwriting/Applications/Application/Injury';
import UnderwritingApplicationsNewPageCoverage from '@pages/Underwriting/Applications/Application/Coverage';
import UnderwritingApplicationsNewPageMisc from '@pages/Underwriting/Applications/Application/Misc';
import UnderwritingApplicationsNewPageNotes from '@pages/Underwriting/Applications/Application/Notes';
import UnderwritingApplicationsNewPageDocument from '@pages/Underwriting/Applications/Application/Documents/NewDocument';
import UnderwritingApplicationDocuments from '@pages/Underwriting/Applications/Application/Documents';
import UnderwritingApplicationAdvances from '@pages/Underwriting/Applications/Application/Advances';
import UnderwritingApplicationEditAdvance from '@pages/Underwriting/Applications/Application/Advances/EditAdvance';
import PortfolioList from '@pages/Portfolios/PortfoliosList';
import UnderwritingApplicationFinancialTerms from '@pages/Underwriting/Applications/Application/FinancialTerms';

import UnderwritingApplicationPayoffs from '@pages/Underwriting/Applications/Application/Payoffs';
import UnderwritingApplicationNewPayoffRequest from '@pages/Underwriting/Applications/Application/Payoffs/PayoffRequest/NewPayoffRequest';
import UnderwritingApplicationSimulatePayoff from '@pages/Underwriting/Applications/Application/Payoffs/PayoffsGrid/SimulatePayoff';
import UnderwritingApplicationNewConcessionRequest from '@pages/Underwriting/Applications/Application/Payoffs/ConcessionRequest/NewConcessionRequest';
import UnderwritingApplicationNewPayoff from '@pages/Underwriting/Applications/Application/Payoffs/PayoffsGrid/NewPayoff';

import UnderwritingApplicationNewPartialPayment from '@pages/Underwriting/Applications/Application/Payoffs/PartialPayments/NewPartialPayment';

import UnderwritingApplicationActivity from '@pages/Underwriting/Applications/Application/Activity';
import UnderwritingApplicationDisb from '@pages/Underwriting/Applications/Application/Disbs';
import UnderwritingApplicationIms from '@pages/Underwriting/Applications/Application/IMS';
import UnderwritingApplicationNewDisb from '@pages/Underwriting/Applications/Application/Disbs/NewDisbursement';
import UnderwritingApplicationAdvancesProduct from '@pages/Underwriting/Applications/Application/Advances/Product';
import UnderwritingApplicationAdvancesProductProcessingFeesTable from '@pages/Underwriting/Applications/Application/Advances/Product/ProcessingFeesTable';
// import PortfolioPayoffLetterRequestNewPageAttorney from '@pages/Portfolios/PayoffLetterRequests/PayoffLetterRequest/Attorney';
import ContactsLawFirm from '@pages/Contacts/LawFirms/LawFirm';
import ContactsAttorney from '@pages/Contacts/Attorneys/Attorney';
import ContactsBroker from '@pages/Contacts/Brokers/Broker';
import ContactsFinancialStore from '@pages/Contacts/FinancialStores/FinancialStore/FinancialStore';
import UnderwritingApplicationLiens from '@pages/Underwriting/Applications/Application/Liens';
import UnderwritingApplicationsNewLiensNewLien from '@pages/Underwriting/Applications/Application/Liens/NewLien';
import UnderwritingApplicationsNewNotesEditNote from '@pages/Underwriting/Applications/Application/Notes/EditNote';
import SettingsUserRolesEditUserRole from '@pages/Settings/UserRoles/EditUserRole';
import EditClient from '@pages/Underwriting/Clients/components/EditClient';
import UnderwritingClients from '@pages/Underwriting/Clients/Clients';
import Portfolio from '@pages/Portfolios/PortfoliosList/Portfolio';
import history from '@src/history';
import UserManagePermissions from '@pages/Settings/Users/ManagePermissions/UserManagePermission';
import './App.css';
import { PrivateRoute } from './components/PrivateRoute';
import jwt_decode from 'jwt-decode';
import { UserPermittedRoute } from './components/UserPermittedRoute/UserPermittedRoute';
import globalErrorHandler from '@components/GlobalErrorHandler';
import ResetPassword from '@pages/ResetPassword/ResetPassword';

const App = () => {
    var token = localStorage.getItem('lasuris-jwt');

    if (token) {
        var decoded = jwt_decode(token);
        if (decoded.exp < Date.now() / 1000) {
            history.push('/logout');
        }
    }

    return (
        <Router history={history}>
            <div>
                <ToastContainer />

                <Menu />

                <main>
                    <Switch>
                        {/*<Route exact path="/portfolios/payoff-letter-requests/new-portfolio">*/}
                        {/*  <PortfolioPayoffLetterRequestNewPage />*/}
                        {/*</Route>*/}

                        <Route
                            exact
                            path="/"
                            render={() => {
                                return localStorage.getItem('isUserAuthenticated') === 'true' ? (
                                    <Redirect to="/dashboard" />
                                ) : (
                                    <Redirect to="/login" />
                                );
                            }}
                        />

                        <Route exact path="/login" component={Login} />

                        <Route exact path="/resetpassword/:token" component={ResetPassword} />

                        <Route exact path="/forgotpassword" component={ForgotPassword} />

                        <Route exact path="/forgotpasswordsuccess" component={ForgotPasswordSuccess} />

                        <Route exact path="/attorneycasestatus/:attorneyPublicId/:lawfirmPublicId"
                            component={UnderwritingNecessityFundingCaseStatusUpdates} />

                        <PrivateRoute exact path="/logout" component={Logout} />

                        <PrivateRoute exact path="/dashboard" component={Dashboard} />

                        <PrivateRoute exact path="/profile/changepasssword" component={ChangePassword} />

                        <PrivateRoute exact path="/portfolios/new-portfolio" component={Portfolio} />

                        <PrivateRoute
                            exact
                            path="/underwriting/applications/:applicationId/payoffs/:payoffRequestId"
                            component={UnderwritingApplicationNewPayoffRequest}
                        />

                        <PrivateRoute
                            exact
                            path="/underwriting/applications/:applicationId/documents/new-document"
                            component={UnderwritingApplicationsNewPageDocument}
                        />

                        <PrivateRoute exact path="/portfolios/portfolios-list/:id" component={Portfolio} />

                        <PrivateRoute
                            exact
                            path="/underwriting/applications/:applicationId/attorney"
                            component={UnderwritingApplicationsNewPage}
                        />

                        <PrivateRoute
                            exact
                            path="/underwriting/applications/:applicationId/liens/new-lien/:lienId"
                            component={UnderwritingApplicationsNewLiensNewLien}
                        />

                        <PrivateRoute
                            exact
                            path="/underwriting/applications/:applicationId/notes/:noteId"
                            component={UnderwritingApplicationsNewNotesEditNote}
                        />

                        <PrivateRoute
                            exact
                            path="/portfolios/payoff-letter-requests/:applicationId/attorney"
                            component={PortfolioPayoffLetterRequestNewPage}
                        />

                        <PrivateRoute
                            exact
                            path="/settings/user-roles/new-user-role"
                            component={SettingsUserRolesEditUserRole}
                        />

                        {/* UserPermittedRoutes START*/}

                        {/* Accounting Pages  */}

                        <UserPermittedRoute
                            exact
                            path="/accounting/active-advance-receivables"
                            component={AccountingActiveAdvanceReceivables}
                            userRole={['Accounting']}
                            task={['View Cases to Be Advanced']}
                        />

                        <UserPermittedRoute
                            exact
                            path="/accounting/cases-to-be-advanced"
                            component={AccountingCasesToBeAdvanced}
                            userRole={['Accounting']}
                            task={['View Cases to Be Advanced']}
                        />

                        <UserPermittedRoute
                            exact
                            path="/accounting/payoff-requests"
                            component={AccountingPayoffRequests}
                            userRole={['Accounting']}
                            task={['View Payoff Requests']}
                        />

                        <UserPermittedRoute
                            exact
                            path="/accounting/payoffs"
                            component={AccountingPayoffs}
                            userRole={['Accounting']}
                            task={['View Payoffs']}
                        />

                        {/* Accounts Pages  */}

                        <UserPermittedRoute
                            exact
                            path="/accounts/account-fundings"
                            component={AccountsAccountFunding}
                            userRole={['Account Manager']}
                            task={['View Account Fundings']}
                        />

                        <UserPermittedRoute
                            exact
                            path="/accounts/account-manager-pipeline"
                            component={AccountsAccountManagerPipeline}
                            userRole={['Account Manager']}
                            task={['View Account Manager Pipeline']}
                        />

                        <UserPermittedRoute
                            exact
                            path="/accounts/active-advance-receivables"
                            component={AccountsActiveAdvanceReceivables}
                            userRole={['Account Manager']}
                            task={['View Active Advance Receivables']}
                        />

                        <UserPermittedRoute
                            exact
                            path="/accounts/active-advance-receivables/:lawFirm/:lawFirmPublicId"
                            component={AccountsActiveAdvanceReceivables}
                            userRole={['Account Manager']}
                            task={['View Active Advance Receivables']}
                        />

                        <UserPermittedRoute
                            exact
                            path="/accounts/law-firm-contacts"
                            component={AccountsLawFirmContacts}
                            userRole={['Account Manager']}
                            task={['View Law Firm Contacts']}
                        />

                        {/* Contacts Pages  */}

                        <UserPermittedRoute
                            exact
                            path="/contacts/attorneys/new-attorney"
                            component={ContactsAttorney}
                            task={['Add Attorneys']}
                        />

                        <UserPermittedRoute
                            exact
                            path="/contacts/brokers/new-broker"
                            component={ContactsBroker}
                            task={['Add Brokers']}
                        />

                        <UserPermittedRoute
                            exact
                            path="/contacts/financial-stores/new-financial-store"
                            component={ContactsFinancialStore}
                            task={['Add Financial Stores']}
                        />

                        <UserPermittedRoute
                            exact
                            path="/contacts/law-firms/new-law-firm"
                            component={ContactsLawFirm}
                            task={['Add Law Firms']}
                        />

                        <UserPermittedRoute
                            exact
                            path="/contacts/attorneys"
                            component={ContactsAttorneys}
                            task={['View Attorneys']}
                        />
                        <UserPermittedRoute
                            exact
                            path="/contacts/attorneys/:id"
                            component={ContactsAttorney}
                            task={['View Attorneys']}
                        />

                        <UserPermittedRoute
                            exact
                            path="/contacts/brokers"
                            component={ContactsBrokers}
                            task={['View Brokers']}
                        />

                        <UserPermittedRoute
                            exact
                            path="/contacts/brokers/:id"
                            component={ContactsBroker}
                            task={['View Brokers']}
                        />

                        <UserPermittedRoute
                            path="/contacts/financial-stores"
                            component={ContactsFinancialStores}
                            task={['View Financial Stores']}
                        />
                        <UserPermittedRoute
                            path="/contacts/financial-stores/:id"
                            component={ContactsFinancialStore}
                            task={['View Financial Stores']}
                        />

                        <UserPermittedRoute
                            exact
                            path="/contacts/law-firms"
                            component={ContactsLawFirms}
                            task={['View Law Firms']}
                        />
                        <UserPermittedRoute
                            exact
                            path="/contacts/law-firms/:id"
                            component={ContactsLawFirm}
                            task={['View Law Firms']}
                        />

                        {/* Misc Settings */}

                        <UserPermittedRoute
                            exact
                            path="/settings/misc-settings"
                            component={SettingsMiscSettings}
                            task={['View Misc. Settings']}
                        />

                        {/* Portfolios */}

                        <UserPermittedRoute
                            exact
                            path="/portfolios/payoff-letter-requests/new-portfolio"
                            component={PortfolioPayoffLetterRequestNewPage}
                            userRole={['Portfolio Manager']}
                            task={['Add Potfoilios']}
                        />

                        <UserPermittedRoute
                            exact
                            path="/portfolios/cases-require-review"
                            component={PortfoliosCasesRequireReview}
                            userRole={['Portfolio Manager']}
                            task={['View Cases Require Review']}
                        />

                        <UserPermittedRoute
                            exact
                            path="/portfolios/concession-diary"
                            component={PortfoliosConcessionDiary}
                            userRole={['Portfolio Manager']}
                            task={['View Concession Diary']}
                        />

                        <UserPermittedRoute
                            exact
                            path="/portfolios/payoff-letter-requests"
                            component={PortfoliosPayoffLetterRequests}
                            task={['View Payoff Letter Requests']}
                        />

                        <UserPermittedRoute
                            exact
                            path="/portfolios/portfolios-list"
                            component={PortfoliosList}
                            userRole={['Portfolio Manager']}
                            task={['View Potfoilios List']}
                        />

                        {/* Reports */}

                        <UserPermittedRoute
                            exact
                            path="/reports/account-manager-details"
                            component={ReportsAccountManagerDetails}
                            task={['Account Manager Details']}
                        />
                        <UserPermittedRoute
                            exact
                            path="/reports/account-manager-details/:accountManagerId"
                            component={ReportsAccountManagerDetails}
                            task={['Account Manager Details']}
                        />

                        <UserPermittedRoute
                            exact
                            path="/reports/account-manager-summary"
                            component={ReportsAccountManagerSummary}
                            task={['Account Manager Summary']}
                        />

                        <UserPermittedRoute
                            exact
                            path="/reports/commission-report"
                            component={ReportsCommission}
                            task={['Commission Report']}
                        />

                        <UserPermittedRoute
                            exact
                            path="/reports/attorney-data"
                            component={ReportsAttorneyData}
                            task={['Attorney Data']}
                        />

                        <UserPermittedRoute
                            exact
                            path="/reports/financial-center"
                            component={ReportsFinancialCenter}
                            task={['Financial Center']}
                        />

                        <UserPermittedRoute
                            exact
                            path="/reports/present-value"
                            component={ReportsPresentValue}
                            task={['Present Value']}
                        />

                        <UserPermittedRoute
                            exact
                            path="/reports/financial-summary"
                            component={ReportsFinancialSummary}
                            task={['Financial Summary']}
                        />

                        <UserPermittedRoute
                            exact
                            path="/reports/law-firm-details"
                            component={ReportsLawFirmDetails}
                            task={['Law Firm Details']}
                        />
                        <UserPermittedRoute
                            exact
                            path="/reports/law-firm-details/:lawFirmPublicId"
                            component={ReportsLawFirmDetails}
                            task={['Law Firm Details']}
                        />

                        <UserPermittedRoute
                            exact
                            path="/reports/law-firm-summary"
                            component={ReportsLawFirmSummary}
                            task={['Law Firm Summary']}
                        />

                        <UserPermittedRoute
                            exact
                            path="/reports/payoff-details"
                            component={ReportsPayoffDetails}
                            task={['Payoff Details']}
                        />

                        <UserPermittedRoute
                            exact
                            path="/reports/payoff-static-pool-analysis"
                            component={ReportsPayoffStaticPoolAnalysis}
                            task={['Payoff Static Pool Analysis']}
                        />

                        <UserPermittedRoute
                            exact
                            path="/reports/types-of-accidents-summary"
                            component={ReportsTypesOfAccidentsSummary}
                            task={['Types of Accidents Summary']}
                        />
                          <UserPermittedRoute
                            exact
                            path="/reports/case-status-update-activity"
                            component={CaseStatusUpdateActivity}
                            task={['Case Status Update Activity']}
                        />

                        {/* Underwriting  - General */}

                        <UserPermittedRoute
                            exact
                            path="/underwriting/applications/new-application"
                            component={UnderwritingApplicationsNewPage}
                            userRole={['Lead Underwriter', 'Underwriter']}
                            task={['Add a new Application']}
                        />

                        <UserPermittedRoute
                            exact
                            path="/underwriting/clients/new-client"
                            component={EditClient}
                            task={['Add Clients']}
                        />

                        <UserPermittedRoute
                            exact
                            path="/underwriting/clients/:id"
                            component={EditClient}
                            task={['Edit Clients']}
                        />

                        <UserPermittedRoute
                            exact
                            path="/underwriting/clients"
                            component={UnderwritingClients}
                            task={['View Clients']}
                        />

                        <UserPermittedRoute
                            exact
                            path="/underwriting/applications-to-be-reviewed"
                            component={UnderwritingApplicationsToBeReviewedPage}
                            userRole={['Lead Underwriter', 'Underwriter']}
                            task={['View Applications to be Reviewed']}
                        />

                        <UserPermittedRoute
                            exact
                            path="/underwriting/applications/:applicationId/advances/:advanceId/disbursement/new-disbursement"
                            component={UnderwritingApplicationNewDisb}
                            task={['Add Advance Disbursements']}
                        />

                        <UserPermittedRoute
                            exact
                            path="/underwriting/applications/:applicationId/advances/new-advance"
                            component={UnderwritingApplicationEditAdvance}
                            task={['Add Advances']}
                        />

                        <UserPermittedRoute
                            exact
                            path="/underwriting/applications/:applicationId/payoffs/concession-request/:concessionRequestId"
                            component={UnderwritingApplicationNewConcessionRequest}
                            task={['Add Concession Requests', 'View Concession Requests']}
                        />

                        <UserPermittedRoute
                            exact
                            path="/underwriting/applications/:applicationId/liens/new-lien"
                            component={UnderwritingApplicationsNewLiensNewLien}
                            task={['Add Liens']}
                        />

                        <UserPermittedRoute
                            exact
                            path="/underwriting/applications/:applicationId/notes/new-note"
                            component={UnderwritingApplicationsNewNotesEditNote}
                            task={['Add Notes']}
                        />

                        <UserPermittedRoute
                            exact
                            path="/underwriting/applications/:applicationId/payoffs/:payoffRequestId"
                            component={UnderwritingApplicationNewPayoffRequest}
                            task={['Add Payoff Requests', 'View Payoff Requests', 'Edit Payoff Requests']}
                        />

                        <UserPermittedRoute
                            exact
                            path="/underwriting/applications/:applicationId/payoffs/applicationPayoffs/simulatepayoff"
                            component={UnderwritingApplicationSimulatePayoff}
                            task={['Add Payoffs']}
                        />

                        <UserPermittedRoute
                            exact
                            path="/underwriting/applications/:applicationId/payoffs/applicationPayoffs/:payoffId"
                            component={UnderwritingApplicationNewPayoff}
                            task={['Add Payoffs']}
                        />

                        <UserPermittedRoute
                            exact
                            path="/underwriting/applications/:applicationId/payoffs/partialpayments/:payoffId"
                            component={UnderwritingApplicationNewPartialPayment}
                            task={['Add Payoffs']}
                        />

                        <UserPermittedRoute
                            exact
                            path="/underwriting/applications/:applicationId/payoffs/partialpayments/new-partialpayment"
                            component={UnderwritingApplicationNewPartialPayment}
                            task={['Add Payoffs']}
                        />

                        <UserPermittedRoute
                            exact
                            path="/underwriting/applications/:applicationId/payoffs/partialpayments/:payoffId"
                            component={UnderwritingApplicationNewPayoff}
                            task={['Add Payoffs']}
                        />

                        <UserPermittedRoute
                            exact
                            path="/underwriting/applications/:applicationId/accident"
                            component={UnderwritingApplicationsNewPageAccident}
                            task={['View Accident Info']}
                        />

                        <UserPermittedRoute
                            exact
                            path="/underwriting/applications/:applicationId/activity"
                            component={UnderwritingApplicationActivity}
                            task={['View Activity']}
                        />

                        <UserPermittedRoute
                            exact
                            path="/underwriting/applications/:applicationId/advances/:advanceId/disbursement"
                            component={UnderwritingApplicationDisb}
                            task={['View Advance Disbursements']}
                        />

                        <UserPermittedRoute
                            exact
                            path="/underwriting/applications/:applicationId/advances/:advanceId/disbursement/:disbursementId"
                            component={UnderwritingApplicationNewDisb}
                            task={['View Advance Disbursements']}
                        />

                        <UserPermittedRoute
                            exact
                            path="/underwriting/applications/:applicationId/advances/:advanceId/product"
                            component={UnderwritingApplicationAdvancesProduct}
                            task={['View Advance Financial Info']}
                        />

                        <UserPermittedRoute
                            exact
                            path="/underwriting/applications/:applicationId/advances/:advanceId/product/processing-fees-table"
                            component={UnderwritingApplicationAdvancesProductProcessingFeesTable}
                            task={['View Advance Financial Info']}
                        />

                        <UserPermittedRoute
                            exact
                            path="/underwriting/applications/:applicationId/advances"
                            component={UnderwritingApplicationAdvances}
                            task={['View Advances']}
                        />

                        <UserPermittedRoute
                            exact
                            path="/underwriting/applications/:applicationId/advances/:advanceId"
                            component={UnderwritingApplicationEditAdvance}
                            task={['View Advances']}
                        />

                        <UserPermittedRoute
                            exact
                            path="/underwriting/applications/new-application/attorney"
                            component={UnderwritingApplicationsNewPage}
                            task={['View an Application', 'View Attorney Info']}
                        />

                        <UserPermittedRoute
                            exact
                            path="/underwriting/applications"
                            component={UnderwritingApplicationsPage}
                            task={['View Applications List']}
                        />

                        <UserPermittedRoute
                            exact
                            path="/underwriting/applications/:applicationId/coverage"
                            component={UnderwritingApplicationsNewPageCoverage}
                            task={['View Coverage Info']}
                        />

                        <UserPermittedRoute
                            exact
                            path="/underwriting/applications/:applicationId/documents"
                            component={UnderwritingApplicationDocuments}
                            task={['View Documents']}
                        />

                        <UserPermittedRoute
                            exact
                            path="/underwriting/applications/:applicationId/financial-terms"
                            component={UnderwritingApplicationFinancialTerms}
                            task={['View Financial Terms']}
                        />

                        <UserPermittedRoute
                            exact
                            path="/underwriting/applications/:applicationId/ims"
                            component={UnderwritingApplicationIms}
                            task={['View IMS Info']}
                        />

                        <UserPermittedRoute
                            exact
                            path="/underwriting/applications/:applicationId/injury"
                            component={UnderwritingApplicationsNewPageInjury}
                            task={['View Injury Info']}
                        />

                        <UserPermittedRoute
                            exact
                            path="/underwriting/applications/:applicationId/liens"
                            component={UnderwritingApplicationLiens}
                            task={['View Liens']}
                        />

                        <UserPermittedRoute
                            exact
                            path="/underwriting/applications/:applicationId/misc"
                            component={UnderwritingApplicationsNewPageMisc}
                            task={['View Misc. Info']}
                        />

                        <UserPermittedRoute
                            exact
                            path="/underwriting/applications/:applicationId/notes"
                            component={UnderwritingApplicationsNewPageNotes}
                            task={['View Notes']}
                        />

                        <UserPermittedRoute
                            exact
                            path="/underwriting/applications/:applicationId/payoffs"
                            component={UnderwritingApplicationPayoffs}
                            task={['View Payoffs']}
                        />

                        <UserPermittedRoute
                            exact
                            path="/underwriting/applications/:applicationId/plaintiff"
                            component={UnderwritingApplicationsNewPagePlaintiff}
                            task={['View Plaintiff Info']}
                        />

                        <UserPermittedRoute
                            exact
                            path="/underwriting/applications/:applicationId/attorneycasestatus"
                            component={UnderwritingApplicationsAttorneyCaseStatus}
                            task={['View Plaintiff Info']} // TODO: Change permissions to match the task
                        />

                        {/* Users */}

                        <UserPermittedRoute
                            exact
                            path="/settings/users/new-user"
                            component={SettingsUsersUser}
                            task={['Add Users']}
                        />

                        <UserPermittedRoute
                            exact
                            path="/settings/user-roles/:id"
                            component={SettingsUserRolesEditUserRole}
                            task={['Edit  User Roles']}
                        />

                        <UserPermittedRoute
                            exact
                            path="/settings/users/:id"
                            component={SettingsUsersUser}
                            task={['Edit  Users']}
                        />

                        <UserPermittedRoute
                            exact
                            path="/profile"
                            component={PersonalInformation}
                            task={['Edit My Profile']}
                        />

                        <UserPermittedRoute
                            exact
                            path="/settings/user-manage-permissions/:id"
                            component={UserManagePermissions}
                            task={['Manage User Permissions']}
                        />

                        <UserPermittedRoute
                            exact
                            path="/settings/user-roles"
                            component={SettingsUserRoles}
                            task={['View User Roles']}
                        />

                        <UserPermittedRoute
                            exact
                            path="/settings/users"
                            component={SettingsUsers}
                            task={['View Users']}
                        />

                        {/* Underwriting user role pages  */}

                        {/* UserPermittedRoutes END */}

                        <Route path="*" component={Error404} />
                    </Switch>
                </main>
            </div>
        </Router>
    );
};

//export default globalErrorHandler(App);
export default App;
