import React, { useEffect } from 'react';
import Grid from './PresentValueGrid';
import { Row, Col } from 'reactstrap';

const PresentValue = () => {
  const makeMenuItemActive = () => {
    document.querySelector('#menuitem-reports').click();
    document.querySelector('#kt_header_menu > ul > li').classList.add('menu-item-active');
  };

  useEffect(() => makeMenuItemActive(), []);

  return (
    <div className="container mt-10 mb-10" style={{ maxWidth: '95%' }}>
      <div className="row">
        <div className="col-md-12 bg-white box-shadow-border col-xl-8 offset-xl-2">
          <div style={{ padding: '0.5rem' }}>
            <div className="d-flex mt-5 mb-10">
              <div className="mr-auto">
                <h1>Present Value Report</h1>
              </div>
            </div>
          </div>
          <Row>
            <Col md="12">
              <Grid />
            </Col>
          </Row>
        </div>
      </div>
    </div>
  );
};

export default PresentValue;
