import React from 'react';

let user = JSON.parse(localStorage.getItem('lasuris-user'));
let userPermissions = JSON.parse(localStorage.getItem('userPermissions'));
let currentApplication = JSON.parse(localStorage.getItem('currentApplication'));
let underwritingApplicationFilters = JSON.parse(
  localStorage.getItem('underwritingApplicationFilters')
);
let accountsActiveAdvanceReceivableFilters = JSON.parse(
  localStorage.getItem('accountsActiveAdvanceReceivableFilters')
);
let underwritingClientFilters = JSON.parse(localStorage.getItem('underwritingClientFilters'));
let underwritingApplicationToBeReviewedFilters = JSON.parse(
  localStorage.getItem('underwritingApplicationToBeReviewedFilters')
);
let lawFirmDetailFilters = JSON.parse(localStorage.getItem('lawFirmDetailFilters'));
let payoffLetterRequestsFilters = JSON.parse(localStorage.getItem('payoffLetterRequestsFilters'));
let concessionDiaryFilters = JSON.parse(localStorage.getItem('concessionDiaryFilters'));
let casesRequireReviewFilters = JSON.parse(localStorage.getItem('casesRequireReviewFilters'));
let payoffsFilters = JSON.parse(localStorage.getItem('payoffsFilters'));
let payoffRequestsFilters = JSON.parse(localStorage.getItem('payoffRequestsFilters'));
let casesToBeAdvancedFilters = JSON.parse(localStorage.getItem('casesToBeAdvancedFilters'));
let accountingCasesToBeAdvancedFilters = JSON.parse(
  localStorage.getItem('accountingCasesToBeAdvancedFilters')
);
let activeAdvanceReceivablesFilters = JSON.parse(
  localStorage.getItem('activeAdvanceReceivablesFilters')
);
let accountsActiveAdvanceReceivablesFilters = JSON.parse(
  localStorage.getItem('accountsActiveAdvanceReceivablesFilters')
);
let accountingActiveAdvanceReceivableFilters = JSON.parse(
  localStorage.getItem('accountingActiveAdvanceReceivableFilters')
);
let accountsLawFirmContactsFilters = JSON.parse(
  localStorage.getItem('accountsLawFirmContactsFilters')
);
let accountingPayoffsFilters = JSON.parse(localStorage.getItem('accountingPayoffsFilters'));
let accountsAccountFindingFilters = JSON.parse(
  localStorage.getItem('accountsAccountFindingFilters')
);
let accountsAccountManagerPipelineFilters = JSON.parse(
  localStorage.getItem('accountsAccountManagerPipelineFilters')
);
// REPORTS CONTEXTS
let lawFirmSummaryReportFilters = JSON.parse(localStorage.getItem('lawFirmSummaryReportFilters'));
let accountManagerDetailsReportFilters = JSON.parse(
  localStorage.getItem('accountManagerDetailsReportFilters')
);
let accountManagerSummaryReportFilters = JSON.parse(
  localStorage.getItem('accountManagerSummaryReportFilters')
);
let payoffStaticPoolAnalysisReportFilters = JSON.parse(
  localStorage.getItem('payoffStaticPoolAnalysisReportFilters')
);
let accidentSummaryReportFilters = JSON.parse(localStorage.getItem('accidentSummaryReportFilters'));
let attorneyDataReportFilters = JSON.parse(localStorage.getItem('attorneyDataReportFilters'));
let financialSummaryReportFilters = JSON.parse(
  localStorage.getItem('financialSummaryReportFilters')
);
let financialCenterReportFilters = JSON.parse(localStorage.getItem('financialCenterReportFilters'));

let presentValueReportFilters = JSON.parse(localStorage.getItem('presentValueReportFilters'));

let commissionReportFilters = JSON.parse(localStorage.getItem('commissionReportFilters'));
let caseStatusUpdateActivityFilters = JSON.parse(localStorage.getItem('caseStatusUpdateActivityFilters'));

let applicationCaseStatuses = [
  'Answer Received',
  'Appeal',
  'Arbitration (binding in NY)',
  'Bill of Particulars filed',
  'Closed Case',
  'Compliance Conference',
  'Default Motion Filed- No Answer',
  'Dismiss or SJ',
  "EBT's Held",
  "EBT's Scheduled",
  'Expert Witness',
  'ICO (infant compromise order)',
  'Inquest Hearing',
  'Judgement (For/against)',
  "Liability IME's held",
  'Mediation',
  'Motions',
  'NOI FILED',
  'PC Conference (Preliminary)',
  'Pre Trial Conference',
  'Remanded',
  'Stayed',
  'Summons & Complaint Filed/Index',
  'Trial',
];

const AppContext = React.createContext({
  isUserAuthenticated: false,
  user,
  userPermissions,
  applicationCaseStatuses,
  currentApplication: currentApplication ? currentApplication : {},
  toastMessage: { message: '', messageType: '' },
  underwritingApplicationFilters: underwritingApplicationFilters
    ? underwritingApplicationFilters
    : {},
  accountingActiveAdvanceReceivableFilters: accountingActiveAdvanceReceivableFilters
    ? accountingActiveAdvanceReceivableFilters
    : {},
  underwritingClientFilters: underwritingClientFilters ? underwritingClientFilters : {},
  underwritingApplicationToBeReviewedFilters: underwritingApplicationToBeReviewedFilters
    ? underwritingApplicationToBeReviewedFilters
    : {},
  lawFirmDetailFilters: lawFirmDetailFilters ? lawFirmDetailFilters : {},
  payoffLetterRequestsFilters: payoffLetterRequestsFilters ? payoffLetterRequestsFilters : {},
  concessionDiaryFilters: concessionDiaryFilters ? concessionDiaryFilters : {},
  casesRequireReviewFilters: casesRequireReviewFilters ? casesRequireReviewFilters : {},
  activeAdvanceReceivablesFilters: activeAdvanceReceivablesFilters
    ? activeAdvanceReceivablesFilters
    : {},
  accountsActiveAdvanceReceivablesFilters: accountsActiveAdvanceReceivablesFilters
    ? accountsActiveAdvanceReceivablesFilters
    : {},
  payoffsFilters: payoffsFilters ? payoffsFilters : {},
  payoffRequestsFilters: payoffRequestsFilters ? payoffRequestsFilters : {},
  casesToBeAdvancedFilters: casesToBeAdvancedFilters ? casesToBeAdvancedFilters : {},
  accountingCasesToBeAdvancedFilters: accountingCasesToBeAdvancedFilters
    ? accountingCasesToBeAdvancedFilters
    : {},
  accountsLawFirmContactsFilters: accountsLawFirmContactsFilters
    ? accountsLawFirmContactsFilters
    : {},
  accountingPayoffsFilters: accountingPayoffsFilters ? accountingPayoffsFilters : {},
  accountsAccountManagerPipelineFilters: accountsAccountManagerPipelineFilters
    ? accountsAccountManagerPipelineFilters
    : {},
  accountsAccountFindingFilters: accountsAccountFindingFilters ? accountsAccountFindingFilters : {},

  // REPORTS CONTEXTS
  lawFirmSummaryReportFilters: lawFirmSummaryReportFilters ? lawFirmSummaryReportFilters : {},
  accountManagerDetailsReportFilters: accountManagerDetailsReportFilters
    ? accountManagerDetailsReportFilters
    : {},
  accountManagerSummaryReportFilters: accountManagerSummaryReportFilters
    ? accountManagerSummaryReportFilters
    : {},
  payoffStaticPoolAnalysisReportFilters: payoffStaticPoolAnalysisReportFilters
    ? payoffStaticPoolAnalysisReportFilters
    : {},
  accidentSummaryReportFilters: accidentSummaryReportFilters ? accidentSummaryReportFilters : {},
  attorneyDataReportFilters: attorneyDataReportFilters ? attorneyDataReportFilters : {},
  financialSummaryReportFilters: financialSummaryReportFilters ? financialSummaryReportFilters : {},
  financialCenterReportFilters: financialCenterReportFilters ? financialCenterReportFilters : {},
  presentValueReportFilters: presentValueReportFilters ? presentValueReportFilters : {},
  commissionReportFilters: commissionReportFilters ? commissionReportFilters : {},
  caseStatusUpdateActivityFilters: caseStatusUpdateActivityFilters ? caseStatusUpdateActivityFilters : {},
});

export default AppContext;
