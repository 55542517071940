import React, { useContext, useEffect, useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import { useForm } from 'react-hook-form';
import GridService from '@services/GridService';
import './style.css';
import ApplicationsNewMenu from '../../../Menu/ApplicationsNewMenu';
import {
  Row,
  Col,
  Input,
  Label,
  Alert,
  Button,
  Form,
  FormGroup,
  Container,
  Table,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
} from 'reactstrap';

import { ErrorMessage } from '@hookform/error-message';
import AppContext from '@contexts/AppContext';
import ApplicationPayoffsGridService from '@services/ApplicationPayoffsGridService';
import ReactLoading from 'react-loading';
import ApplicationsService from '@services/ApplicationsService';

const NEW_PAYOFF_TEXT = 'new-payoff';
const user = JSON.parse(localStorage.getItem('lasuris-user'));

const NewPayoff = (props) => {
  const appContext = useContext(AppContext);
  const history = useHistory();
  const { applicationId, payoffId } = useParams();
  const [applicationPublicId, setApplicationPublicId] = useState();
  const [payoffData, setPayoffData] = useState({});
  const [requestData, setRequestData] = useState({});
  const [isLoading, setIsLoading] = useState(false);
  const [successMessage, setSuccessMessage] = useState('');
  const [isOpenConfirmationModal, setIsOpenConfirmationModal] = useState(false);

  const [client, setClient] = useState(
    appContext.currentApplication &&
      appContext.currentApplication &&
      Object.keys(appContext.currentApplication).length > 0
      ? appContext.currentApplication.client
      : ''
  );
  const {
    register,
    handleSubmit,
    watch,
    errors,
    formState,
    isSuccessfullySubmitted,
    setIsSuccessfullySubmitted,
  } = useForm({ mode: 'onBlur' });

  useEffect(() => {
    if (appContext.toastMessage.message)
      toast.success(appContext.toastMessage.message, { autoClose: 3000 });

    appContext.toastMessage.message = null;
    let item = document.querySelector('.payoffs');
    if (item) {
      item.classList.add('active');
    }

    ApplicationsService.search({ applicationNo: applicationId }, null, null, 1, {}, 1).then(
      (response) => {
        setApplicationPublicId(response.data.data[0].publicId);

        if (payoffId === NEW_PAYOFF_TEXT) {
          loadInitialData(response.data.data[0].publicId);
        }

        if (payoffId !== NEW_PAYOFF_TEXT) {
          // setIsLoading(true);
          ApplicationPayoffsGridService.read(payoffId)
            .then((res) => {
              setPayoffData(res.data);
              setIsLoading(false);
            })
            .catch((err) => {})
            .finally(() => {
              setIsLoading(false);
            });
        }
      }
    );
  }, []);

  useEffect(() => {
    if (requestData.amount === undefined) {
      return;
    }

    if (requestData.amount === 0) {
      setIsOpenConfirmationModal(!isOpenConfirmationModal);
      return;
    }

    setIsLoading(true);

    ApplicationPayoffsGridService.update(requestData)
      .then((res) => {
        appContext.toastMessage.message = 'Payoff request has been successfully submitted.';
        setSuccessMessage('');
        history.push('/underwriting/applications/' + applicationId + '/payoffs');
      })
      .catch((err) => {})
      .finally(() => setIsLoading(false));
  }, [requestData]);

  const handleFormSubmit = (data) => {
    const parsedAmount = parseFloat(data.amount);
    setRequestData({
      ...payoffData,
      amount: parsedAmount,
      payoffDate: new Date(data.payoffDate).toISOString(),
    });
    //setRequestData(payoffData);
  };

  const handleCalculateData = (data) => {
    var reqdata = {
      publicId: payoffData.publicId,
      createdByPublicId: user.publicId,
      applicationPublicId: applicationPublicId,
      payoffDate: payoffData.payoffDate,
      amount: payoffData.amount ? parseFloat(payoffData.amount) : 0,
      concessionAmount: payoffData.concessionAmount ? parseFloat(payoffData.concessionAmount) : 0,
      isFirstTimeLoad: false,
    };

    // setIsLoading(true);
    ApplicationPayoffsGridService.calculate(reqdata)
      .then((res) => {
        setPayoffData(res.data);
        setIsLoading(false);
      })
      .catch((err) => {})
      .finally(() => {
        //     setIsLoading(false);
      });
  };

  const handleCancel = () => {
    let path = applicationId ? applicationId : 'new-application';
    history.push('/underwriting/applications/' + path + '/payoffs');
  };

  const loadInitialData = (lastClickedAppPublicId) => {
    var reqData = {
      applicationPublicId: lastClickedAppPublicId,
      amount: 0,
      marketingExpense: 0,
      notes: '',
      createdByPublicId: user.publicId,
      concessionAmount: 0,
      payoffDate: payoffData.payoffDate ?? new Date().toISOString().split('T')[0],
      isFirstTimeLoad: true,
      applicationConcessionRequestAdvances: [],
    };

    ApplicationPayoffsGridService.calculate(reqData)
      .then((res) => {
        setPayoffData(res.data);
        payoffId = res.data.data.publicId;
        setIsLoading(false);
      })
      .catch((err) => {})
      .finally(() => {
        setIsLoading(false);
      });
  };

  const handleChange = (evt) => {
    const value = evt.target.value;
    setPayoffData({
      ...payoffData,
      [evt.target.name]: value,
    });
  };

  const moneyFormatter = new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: 'USD',
  });

  const parseDollarString = (val) => {
    return val ? moneyFormatter.format(parseFloat(val).toFixed(2)) : '-';
  };

  const toggleConfirmationModal = () => {
    setIsOpenConfirmationModal(!isOpenConfirmationModal);
  };

  const confirmApplcationStatusChange = () => {
    if (!(appContext?.userPermissions?.indexOf('Change Status') > -1)) {
      appContext.toastMessage.message = 'You do not have permissions to perform this action.';
      toast.error(appContext.toastMessage.message, { autoClose: 3000 });
      appContext.toastMessage.message = null;
      return;
    }

    //change status save
    let params = {
      applicationPublicId: applicationPublicId,
      userPublicId: appContext.user.publicId,
      applicationStatus: 'Written Off',
      note: 'Changed due to Payoff equals to 0',
    };

    let endpoint = '/Application/applicationstatus';

    GridService.create(endpoint, params)
      .then((resp) => {
        if (!resp.data) return;
        toggleConfirmationModal();
        appContext.toastMessage.message = 'Status changed successfully';
        toast.success(appContext.toastMessage.message, { autoClose: 3000 });

        setIsLoading(true);

        ApplicationPayoffsGridService.update(requestData)
          .then((res) => {
            appContext.toastMessage.message = 'Payoff request has been successfully submitted.';
            setSuccessMessage('');
            history.push('/underwriting/applications/' + applicationId + '/payoffs');
          })
          .catch((err) => {})
          .finally(() => setIsLoading(false));
      })
      .catch((err) => {})
      .finally(() => {
        setIsLoading(false);
      });
  };

  const cancelChangeApplicationStatusChange = () => {
    ApplicationPayoffsGridService.update(requestData)
      .then((res) => {
        setIsOpenConfirmationModal(!isOpenConfirmationModal);

        appContext.toastMessage.message = 'Payoff request has been successfully submitted.';
        setSuccessMessage('');
        history.push('/underwriting/applications/' + applicationId + '/payoffs');
      })
      .catch((err) => {})
      .finally(() => setIsLoading(false));
  };

  return (
    <>
      <Modal isOpen={isOpenConfirmationModal} toggle={toggleConfirmationModal}>
        <ModalHeader>Confirmation</ModalHeader>
        <ModalBody>
          <p>
            Amount received is equal to 0. Would you like to change the Application status to
            Written Off?
            <br />
          </p>
        </ModalBody>
        <ModalFooter>
          <Button color="primary" onClick={confirmApplcationStatusChange}>
            Yes
          </Button>
          <Button color="secondary" onClick={cancelChangeApplicationStatusChange}>
            No
          </Button>
        </ModalFooter>
      </Modal>
      <div className="container mt-10 mb-10">
        <div className="row">
          <div className="col-md-12 mx-auto bg-white box-shadow-border">
            <div className="p-10">
              <div className="mt-5 mb-10">
                <div>
                  <h1>
                    {applicationId != 'new-application'
                      ? (client ? `${client} - ` : 'Application ') + applicationId + ' - '
                      : undefined}
                    {payoffId === NEW_PAYOFF_TEXT ? 'Payoff ' : 'Payoff'}
                  </h1>

                  <ApplicationsNewMenu
                    applicationId={applicationId}
                    // menuItemIndex="2"
                  />
                  {isLoading ? (
                    <div>
                      <ReactLoading type="bars" color="#7E8299" />
                      Calculating...
                    </div>
                  ) : (
                    <form
                      onSubmit={handleSubmit(handleFormSubmit)}
                      noValidate
                      className="new-payoff-form"
                    >
                      <Row>
                        <Col sm="6" className="p-10 form-first-half-section">
                          <Row className="listing-rows mb-4">
                            <h6>Advanced Amount</h6>
                            <span>{parseDollarString(payoffData.advancedAmount)}</span>
                          </Row>
                          <Row className="listing-rows mb-4">
                            <h6>Total Cash Outlay</h6>
                            <span>{parseDollarString(payoffData.totalCashOutlay)}</span>
                          </Row>
                          <Row className="listing-rows mb-4">
                            <h6>Avg. Days Outstanding</h6>
                            <span>{payoffData.averageDaysOutstanding}</span>
                          </Row>
                          <Row className="listing-rows mb-4">
                            <h6>Gross Revenue</h6>
                            <span>{parseDollarString(payoffData.grossRevenue)}</span>
                          </Row>
                          <Row className="listing-rows mb-4">
                            <h6>Net Revenue</h6>
                            <span>{parseDollarString(payoffData.netRevenue)}</span>
                          </Row>
                          <hr />
                          <Row className="listing-rows mb-4">
                            <Col sm="6" className="p-0">
                              {' '}
                              <h6>Date of Payoff</h6>
                            </Col>
                            <Col sm="6" className="p-0">
                              {' '}
                              <input
                                type="date"
                                name="payoffDate"
                                id="payoffDate"
                                className="form-control date-of-payoff float-right"
                                onBlur={handleCalculateData}
                                ref={register({ required: 'Payoff Date is required' })}
                                defaultValue={
                                  applicationId == 'new-application'
                                    ? new Date().toISOString().split('T')[0]
                                    : payoffData?.payoffDate?.split('T')[0]
                                }
                                onChange={handleChange}
                                disabled={formState.isSubmitting || isSuccessfullySubmitted}
                              />
                            </Col>
                          </Row>
                          <Row className="listing-rows mb-4">
                            <h6>Total Payoff</h6>
                            <span>{parseDollarString(payoffData.totalPayoff)}</span>
                          </Row>
                          <Row className="listing-rows mb-4">
                            <h6>Concessions</h6>
                            <span>{parseDollarString(payoffData.concessionAmount)}</span>
                            {/* <Col sm="6" className="p-0">
                                                        <div className="input-group input-group-sm float-right amount">
                                                          <div className="input-group-prepend">
                                                            <span className="input-group-text">$</span>
                                                          </div>
                                                          <input
                                                            name="concessionAmount"
                                                            id="concessionAmount"
                                                            type="number"
                                                            onBlur={handleCalculateData}
                                                            ref={register({ required: 'Concessions is required.' })}
                                                            onChange={handleChange}
                                                            defaultValue={payoffData.concessionAmount}
                                                            className="form-control float-right number-input"
                                                            disabled={formState.isSubmitting || isSuccessfullySubmitted}
                                                            required="required"
                                                          />
                                                        </div>
                                                      </Col> */}
                          </Row>
                          <Row className="listing-rows mb-4">
                            <h6>Marketing Expense</h6>
                            <span>{parseDollarString(payoffData.marketingExpense)}</span>
                          </Row>

                          <Row className="listing-rows mb-4">
                            <Col sm="6" className="p-0">
                              <h6>Amount Received</h6>
                            </Col>
                            <Col sm="6" className="p-0">
                              <div className="input-group input-group-sm float-right amount">
                                <div className="input-group-prepend">
                                  <span className="input-group-text">$</span>
                                </div>
                                <input
                                  name="amount"
                                  id="amount"
                                  type="number"
                                  onBlur={handleCalculateData}
                                  ref={register({ required: 'Amount Received is required.' })}
                                  onChange={handleChange}
                                  defaultValue={payoffData.amount}
                                  className="form-control float-right number-input"
                                  readOnly={formState.isSubmitting || isSuccessfullySubmitted || payoffData.concessionAmount > 0}
                                  required="required"
                                />
                              </div>
                            </Col>
                          </Row>

                          <Row className="listing-rows mb-4">
                            <h6>Balance Owing</h6>
                            <span>{parseDollarString(payoffData.balanceOwning)}</span>
                          </Row>
                        </Col>
                      </Row>
                      <Row className="mb-3 mt-3">
                        <Col md="6" sm="12" className="form-first-half-section">
                          <h6>Notes</h6>
                          <textarea
                            name="notes"
                            id="notes"
                            rows="5"
                            onChange={handleChange}
                            defaultValue={payoffData.notes}
                            className="form-control notes-textarea"
                            ref={register()}
                            required="required"
                          />
                          {errors.notes && (
                            <Alert color="danger" className="mt-5">
                              <ErrorMessage errors={errors} name="notes" />
                            </Alert>
                          )}
                        </Col>
                      </Row>

                      <div className="row">
                        <div className="col-md-3">
                          <div className="form-group">
                            <button type="submit" className="btn btn-primary">
                              Save
                            </button>
                            <button
                              type="button"
                              className="ml-5 btn btn-secondary"
                              onClick={() => handleCancel()}
                              disabled={formState.isSubmitting || isSuccessfullySubmitted}
                            >
                              Cancel{' '}
                            </button>
                          </div>
                        </div>
                      </div>
                    </form>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default NewPayoff;
