import React, { useContext, useEffect, useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import {
  Row,
  Col,
  Input,
  Label,
  Alert,
  Button,
  Form,
  FormGroup,
  Container,
  ToastHeader,
} from 'reactstrap';
import { useForm } from 'react-hook-form';
import AttorneyService from '@services/AttorneyService';
import { ErrorMessage } from '@hookform/error-message';
import ApplicationFlagService from '@services/ApplicationFlagService';
import ReactLoading from 'react-loading';

import AppContext from '@contexts/AppContext';
import ApplicationsNewMenu from './Menu';
import Header from './Header';

import StateSelect from '@components/StateSelect/StateSelect';
import AttorneyAutoSuggest from '@components/AttorneyAutoSuggest/AttorneyAutoSuggestInsideApplication';
import ApplicationsService from '@services/ApplicationsService';
import { toast } from 'react-toastify';

const Application = () => {
  let appContext = useContext(AppContext);
  const history = useHistory();
  let { applicationId } = useParams();
  const [HasFirstButtonSubbmited, setHasFirstButtonSubbmited] = useState(true);
  const [attorney, setAttorney] = useState({
    displayName: '',
    attorneyPublicId: '',
  });
  const [isLoading, setIsLoading] = useState(false);
  const [disableTabs, setDisableTabs] = useState(false);
  const [selectedAttorney, setSelectedAttorney] = useState('');
  const [selectedAttorneyName, setSelectedAttorneyName] = useState('');
  const { register, handleSubmit, watch, errors, formState } = useForm({ mode: 'onBlur' });
  const [selectedAttorneyError, setSelectedAttorneyError] = useState(undefined);

  useEffect(() => {
    if (selectedAttorney != undefined && selectedAttorney != '') {
      AttorneyService.read(selectedAttorney)
        .then((response) => {
          setAttorney(response.data);
          setSelectedAttorneyName(response.data.displayName);
          setSelectedAttorneyError(undefined);
        })
        .catch((err) => {});
    }
  }, [selectedAttorney]);

  useEffect(() => {
    document.body.scrollTop = 0; // For Safari
    document.documentElement.scrollTop = 0; // For Chrome, Firefox, IE and Opera

    if (applicationId === 'undefined') applicationId = undefined;

    if (applicationId) {
      setIsLoading(true);
      ApplicationsService.search({ applicationNo: applicationId }, null, null, 1, {}, 1)
        .then((response) => {
          setIsLoading(false);
          setSelectedAttorney(response.data.data[0].attorneyPublicId);
          setSelectedAttorneyError(undefined);
        })
        .catch((err) => {});
    }

    ApplicationFlagService.searchExact(
      { applicationNo: applicationId, flagNameExact: 'Application Stopped' },
      null,
      null,
      1,
      null,
      1
    )
      .then((response) => {
        if (response?.data?.data[0]?.flagName === 'Application Stopped') {
          setDisableTabs(1);
        }
      })
      .catch((err) => {});

    ApplicationFlagService.searchExact(
      { applicationNo: applicationId, flagNameExact: 'Application Stopped2' },
      null,
      null,
      1,
      null,
      1
    )
      .then((response) => {
        if (response?.data?.data[0]?.flagName === 'Application Stopped2') {
          setDisableTabs(2);
        }
      })
      .catch((err) => {});

    ApplicationFlagService.searchExact(
      { applicationNo: applicationId, flagNameExact: 'Application Stopped3' },
      null,
      null,
      1,
      null,
      1
    )
      .then((response) => {
        if (response?.data?.data[0]?.flagName === 'Application Stopped3') {
          setDisableTabs(3);
        }
      })
      .catch((err) => {});
  }, []);

  const handleFormSubmitted = (e) => {
    e.preventDefault();

    setIsLoading(true);

    if (selectedAttorney === '') {
      setSelectedAttorneyError('Select an attorney.');
      setIsLoading(false);
      return;
    }

    if (applicationId) {
      if (!(appContext?.userPermissions?.indexOf('Edit Attorney Info') > -1)) {
        appContext.toastMessage.message = 'You do not have permissions to perform this action.';
        toast.error(appContext.toastMessage.message, { autoClose: 3000 });
        appContext.toastMessage.message = null;
        setIsLoading(false);
        return;
      }

      ApplicationsService.search({ applicationNo: applicationId }, null, null, 1, {}, 10).then(
        (response) => {
          var updatedApplication = { ...response.data.data[0] };
          updatedApplication.attorneyPublicId = selectedAttorney;

          ApplicationsService.update(updatedApplication)
            .then((response) => {
              appContext.currentApplication = response.data;
              appContext.newAppllicationStep = 1;
              setIsLoading(false);
              appContext.toastMessage.message = 'Your changes has been saved sucessfully.';
              history.push(
                `/underwriting/applications/${appContext.currentApplication.applicationNo}/plaintiff`
              );
            })
            .catch((err) => {});
        }
      );
    } else {
      if (!(appContext?.userPermissions?.indexOf('Add a new Application') > -1)) {
        appContext.toastMessage.message = 'You do not have permissions to perform this action.';
        toast.error(appContext.toastMessage.message, { autoClose: 3000 });
        appContext.toastMessage.message = null;
        setIsLoading(false);
        return;
      }

      let reqObject = {};
      reqObject.attorneyPublicId = selectedAttorney;
      reqObject.applicationStatus = 'Draft';

      ApplicationsService.create(reqObject)
        .then((response) => {
          appContext.currentApplication = response.data;
          appContext.newAppllicationStep = 1;

          if (!appContext.currentApplication) {
            appContext.toastMessage.message = 'Application No was not found!';
            return;
          }

          appContext.toastMessage.message = 'Your changes has been saved sucessfully.';
          setIsLoading(false);
          history.push(
            '/underwriting/applications/' +
              appContext.currentApplication.applicationNo +
              '/plaintiff'
          );
        })
        .catch((err) => {});
    }
  };

  const handleCancelButtonClicked = () => {
    history.push('/underwriting/applications');
  };

  const handleSaveAndExit = () => {
    setHasFirstButtonSubbmited(false);
    document.querySelector('button.btn.btn-primary.mr-5').click();
  };

  if (isLoading) {
    return (
      <Container className="mt-10 mb-10">
        <Row>
          <Col md="12" className="mx-auto bg-white box-shadow-border">
            <div className="p-10 mt-5 mb-10">
              {disableTabs != null && (
                <Header applicationId={applicationId} handleSaveAndExit={handleSaveAndExit} />
              )}

              {disableTabs != null && (
                <ApplicationsNewMenu
                  disableTabs={disableTabs}
                  applicationId={applicationId}
                  menuItemIndex="0"
                />
              )}

              <Container className="p-0">
                <Row>
                  <Col md="8">
                    <Row>
                      <Col md="4">
                        <ReactLoading type="bars" color="#7E8299" />
                      </Col>
                    </Row>
                  </Col>
                </Row>
              </Container>
            </div>
          </Col>
        </Row>
      </Container>
    );
  }

  return (
    <Container className="mt-10 mb-10">
      <Row>
        <Col md="12" className="mx-auto bg-white box-shadow-border">
          <div className="p-10 mt-5 mb-10">
            {disableTabs != null && (
              <Header applicationId={applicationId} handleSaveAndExit={handleSaveAndExit} />
            )}

            {disableTabs != null && (
              <ApplicationsNewMenu
                disableTabs={disableTabs}
                applicationId={applicationId}
                menuItemIndex="0"
              />
            )}

            <Form onSubmit={handleFormSubmitted} method="POST">
              <Container className="p-0">
                <Row>
                  <Col md="8">
                    <Row>
                      <Col md="4">
                        <FormGroup>
                          <Label htmlFor="attorneyName">Attorney&apos;s Name</Label>
                          <AttorneyAutoSuggest
                            selectedAttorney={setSelectedAttorney}
                            selectedAttorneyName={() => {}}
                            defaultAttorney={selectedAttorneyName}
                          />
                          {selectedAttorneyError && (
                            <Alert color="danger" className="mt-5">
                              <span>Select an attorney</span>
                            </Alert>
                          )}
                        </FormGroup>
                      </Col>
                      <Col md="4">
                        <FormGroup>
                          <Label htmlFor="lawFirm">Law Firm</Label>
                          <Input
                            type="text"
                            name="lawFirm"
                            className="form-control"
                            required="required"
                            value={attorney?.lawFirm?.lawFirmName}
                            disabled
                          />
                        </FormGroup>
                      </Col>
                    </Row>
                    <Row>
                      <Col md="3">
                        <FormGroup>
                          <Label htmlFor="phoneNumber">Phone Number</Label>
                          <Input
                            type="tel"
                            name="phoneNumber"
                            className="form-control"
                            required="required"
                            value={attorney?.workingPhoneNo}
                            disabled
                          />
                        </FormGroup>
                      </Col>
                      <Col md="1">
                        <FormGroup>
                          <Label htmlFor="phoneNumber">Ext</Label>
                          <Input
                            type="tel"
                            name="phoneExt"
                            className="form-control"
                            required="required"
                            value={attorney?.phoneExt}
                            disabled
                          />
                        </FormGroup>
                      </Col>
                      <Col md="4">
                        <FormGroup>
                          <Label htmlFor="faxNumber">Fax Number</Label>
                          <Input type="tel" name="faxNumber" className="form-control" disabled />
                        </FormGroup>
                      </Col>
                    </Row>
                    <Row>
                      <Col md="8">
                        <FormGroup>
                          <Label htmlFor="email">Attorney email</Label>
                          <Input
                            type="email"
                            name="email"
                            className="form-control"
                            required="required"
                            value={attorney?.emailAddress}
                            disabled
                          />
                        </FormGroup>
                      </Col>
                    </Row>
                    <Row>
                      <Col md="8">
                        <FormGroup>
                          <Label htmlFor="email">Paralegal email</Label>
                          <Input
                            type="email"
                            name="email"
                            className="form-control"
                            required="required"
                            value={attorney?.paralegalEmailAddress}
                            disabled
                          />
                        </FormGroup>
                      </Col>
                    </Row>
                    <Row>
                      <Col md="4">
                        <FormGroup>
                          <Label htmlFor="address">Address</Label>
                          <Input
                            type="text"
                            id="address"
                            className="form-control"
                            required="required"
                            value={attorney?.lawFirm?.streetAddress}
                            disabled
                          />
                        </FormGroup>
                      </Col>
                      <Col md="4">
                        <FormGroup>
                          <Label htmlFor="city">City</Label>
                          <Input
                            type="text"
                            id="city"
                            className="form-control"
                            required="required"
                            value={attorney?.lawFirm?.city}
                            disabled
                          />
                        </FormGroup>
                      </Col>
                    </Row>
                    <Row>
                      <Col md="4">
                        <StateSelect
                          name="stateCode"
                          label="State"
                          useRef={register({ required: 'State is required.' })}
                          defaultValue={attorney?.lawFirm?.stateCode}
                          setObject={setAttorney}
                          disabled="disabled"
                          givenObject={attorney}
                          errors={errors}
                        />
                      </Col>
                      <Col md="4">
                        <FormGroup>
                          <Label htmlFor="zip">Zip Code</Label>
                          <Input
                            type="text"
                            id="zip"
                            className="form-control"
                            required="required"
                            pattern="[0-9]{5}"
                            maxLength="5"
                            value={attorney?.lawFirm?.zipCode}
                            disabled
                          />
                        </FormGroup>
                      </Col>
                    </Row>
                    <Row>
                      <Col md="8">
                        <Button type="submit" color="primary" className="btn btn-primary mr-5">
                          Save
                        </Button>
                        <Button
                          color="secondary"
                          onClick={handleCancelButtonClicked}
                          className="btn btn-secondary"
                        >
                          Cancel
                        </Button>
                      </Col>
                    </Row>
                  </Col>
                </Row>
              </Container>
            </Form>
          </div>
        </Col>
      </Row>
    </Container>
  );
};

export default Application;
