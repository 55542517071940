import React, { useContext, useEffect } from 'react';
import { Link, useHistory } from 'react-router-dom';
import { toast } from 'react-toastify';
import { Row, Col } from 'reactstrap';
import BrokersGrid from './BrokersGrid';
import AppContext from '@contexts/AppContext';

const Brokers = () => {
  const appContext = useContext(AppContext);
  const history = useHistory();

  const makeMenuItemActive = () => {
    document.querySelector('#menuitem-contacts').click();
    document
      .querySelector('#kt_header_tab_7 > div > div > ul > li:nth-child(3)')
      .classList.add('menu-item-active');
  };

  useEffect(() => {
    makeMenuItemActive();
    if (appContext.toastMessage.message) {
      toast.success(appContext.toastMessage.message, { autoClose: 3000 });
      appContext.toastMessage.message = null;
    }
  }, []);

  return (
    <div className="container mt-10 mb-10">
      <div className="row">
        <div className="col-md-12 bg-white box-shadow-border">
          <div className="p-10">
            <div className="d-flex mt-5 mb-10">
              <div className="mr-auto">
                <h1>Brokers</h1>
              </div>

              <Link
                to="/contacts/brokers/new-broker"
                className="btn btn-primary"
                style={{
                  backgroundColor: 'var(--green)',
                  borderColor: 'var(--green)',
                }}
              >
                New Broker
              </Link>
            </div>

            <Row>
              <Col md="12">
                <BrokersGrid />
              </Col>
            </Row>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Brokers;
