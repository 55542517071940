import React, { useContext, useEffect, useState, useRef } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import Header from '../../Header';
import AppContext from '@contexts/AppContext';
import {
  Row,
  Col,
  Input,
  Label,
  Alert,
  Button,
  Form,
  FormGroup,
  Container,
  Table,
} from 'reactstrap';
import { useForm } from 'react-hook-form';
import { ErrorMessage } from '@hookform/error-message';
import ApplicationAdvanceService from '@services/ApplicationAdvanceService';
import ApplicationsService from '@services/ApplicationsService';
import ApplicationFlagService from '@services/ApplicationFlagService';
import FinancialStoreService from '@services/FinancialStoreService';
import ApplicationCoverageService from '@services/ApplicationCoverageService';
import MiscSettingsService from '@services/MiscSettingsService';
import ApplicationsNewMenu from '../../Menu';
import ReactLoading from 'react-loading';
import PortfolioService from '@services/PortfolioService';
import PortfolioAutoSuggest from '@components/PortfoltioAutoSuggest/PortfolioAutoSuggest';

const EditAdvance = (props) => {
  const appContext = useContext(AppContext);
  const history = useHistory();
  const { applicationId, advanceId } = useParams();
  const refferingPartyRef = useRef();

  const [applicationPublicId, setApplicationPublicId] = useState(null);
  const [disableTabs, setDisableTabs] = useState(false);
  const [selectedAdvanceOption, setSelectedAdvanceOption] = useState('');
  const [selectedFundingType, setSelectedFundingType] = useState('');

  const [advanceStatusOptions, setAdvanceStatusOptions] = useState([]);

  const [hideOptions, setHideOptions] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [isAdvanceApproved, setIsAdvanceApproved] = useState(false);
  const [advanceRecorded, setAdvanceRecorded] = useState(false);
  const [advanceAmountRecorded, setAdvanceAmountRecorded] = useState(null);
  const [selectedFinancialStore, setSelectedFinancialStore] = useState(false);
  const [selectedFinancialStoreObject, setSelectedFinancialStoreObject] = useState(null);
  const [noFinancialStoresFound, setNoFinancialStoresFound] = useState(false);
  const {
    register,
    handleSubmit,
    watch,
    errors,
    formState,
    isSuccessfullySubmitted,
    setIsSuccessfullySubmitted,
  } = useForm({ mode: 'onBlur' });
  const [overnightCheckAdvance, setOvernightCheckAdvance] = useState({});
  const [bankWireAdvance, setBankWireAdvance] = useState({});
  const [financialStoreAdvance, setFinancialStoreAdvance] = useState(null);
  const [financialStoreDeliveryFee, setFinancialStoreDeliveryFee] = useState(null);

  const [overnightCheckAdvanceNfpFee, setOvernightCheckAdvanceNfpFee] = useState(null);
  const [overnightCheckDeliveryFee, setOvernightCheckDeliveryFee] = useState(null);

  const [financialStoreNfpFee, setFinancialStoreNfpFee] = useState(null);
  const [bankWireNfpFee, setBankWireNfpFee] = useState(null);
  const [bankWireDeliveryFee, setBankWireAdvanceDeliveryFee] = useState(null);

  const [loading, setLoading] = useState(true);
  const [isEditMode, setIsEditMode] = useState(false);
  const [showForm, setShowForm] = useState(true);
  const clientZipCode = useRef(null);
  const [storesByZipCode, setStoresByZipCode] = useState();
  const [miscSettings, setMiscSettings] = useState(null);

  const [selectedPortfolio, setSelectedPortfolio] = useState(
    'B0862ED4-E66F-4104-84CF-2E134DF88CA7'
  );
  const [selectedPortfolioName, setSelectedPortfolioName] = useState('Draft');
  const [firstSelectedPortfolioRun, setFirstSelectedPortfolioRun] = useState(false);
  const [selectedPortfolioError, setSelectedPortfolioError] = useState(undefined);

  const [brokerRefFee, setBrokerRefFee] = useState(null);
  const [defaultValueBrokerRefFee, setDefaultValueBrokerRefFee] = useState(null);

  useEffect(() => {
    if (selectedPortfolio != '') {
      PortfolioService.read(selectedPortfolio)
        .then((response) => {
          setSelectedPortfolioName(response?.data?.portfolioName);
          setSelectedPortfolioError(undefined);
          setFirstSelectedPortfolioRun(true);
        })
        .catch((err) => {})
        .finally(() => {
          setIsLoading(false);
        });
    }
  }, [selectedPortfolio]);

  const overnightCheckFormSubmitted = (data) => {
    if (!(appContext?.userPermissions?.indexOf('Edit Advances') > -1)) {
      appContext.toastMessage.message = 'You do not have permissions to perform this action.';
      toast.error(appContext.toastMessage.message, { autoClose: 3000 });
      appContext.toastMessage.message = null;
      return;
    }

    setIsLoading(true);

    if (selectedPortfolio === '') {
      setSelectedPortfolioError(true);
      return;
    }

    var updatedOvernightCheckAdvance = { ...overnightCheckAdvance };

    updatedOvernightCheckAdvance.applicationPublicId = applicationPublicId;
    updatedOvernightCheckAdvance.createdByPublicId = appContext.user.publicId;
    updatedOvernightCheckAdvance.advanceNo = data.overnightCheckAdvanceNumber;
    updatedOvernightCheckAdvance.advanceDate = new Date(data.overnightCheckDate);
    updatedOvernightCheckAdvance.checkNo = data.overnightCheckCheckNumber;
    updatedOvernightCheckAdvance.deliveryFee = parseFloat(data.overnightCheckDeliveryFee);
    updatedOvernightCheckAdvance.amount = parseFloat(data.overnightCheckAdvanceAmount);
    updatedOvernightCheckAdvance.nfpFee = parseFloat(data.overnightCheckNfpFee);
    updatedOvernightCheckAdvance.fundingType = 'Overnight Check';
    updatedOvernightCheckAdvance.details = 'Check #' + data.overnightCheckAdvanceNumber;
    updatedOvernightCheckAdvance.referringPartyFee = brokerRefFee ? parseFloat(brokerRefFee) : 0;
    updatedOvernightCheckAdvance.portfolioPublicId = selectedPortfolio;

    if (advanceId) {
      updatedOvernightCheckAdvance.publicId = advanceId;
      updatedOvernightCheckAdvance.createdByDisplayName = undefined;
      updatedOvernightCheckAdvance.advanceDate = data.overnightCheckDate.split('T')[0];

      ApplicationAdvanceService.update(updatedOvernightCheckAdvance)
        .then((response) => {
          setAdvanceAmountRecorded(updatedOvernightCheckAdvance.amount);
          appContext.toastMessage.message = 'Data sucessfully saved.';
          history.push('../advances');
        })
        .catch((err) => {})
        .finally(() => {
          setIsLoading(false);
        });
    } else {
      ApplicationAdvanceService.create(updatedOvernightCheckAdvance)
        .then((response) => {
          setAdvanceAmountRecorded(updatedOvernightCheckAdvance.amount);
          appContext.toastMessage.message = 'Data sucessfully saved.';
          history.push('../advances');
        })
        .catch((err) => {})
        .finally(() => {
          setIsLoading(false);
        });
    }
  };

  const bankWireFormSubmitted = (data) => {
    setIsLoading(true);

    if (!(appContext?.userPermissions?.indexOf('Edit Advances') > -1)) {
      appContext.toastMessage.message = 'You do not have permissions to perform this action.';
      toast.error(appContext.toastMessage.message, { autoClose: 3000 });
      appContext.toastMessage.message = null;
      return;
    }

    if (selectedPortfolio === '') {
      setSelectedPortfolioError(true);
      return;
    }

    var updatedBankWireAdvance = { ...bankWireAdvance };

    updatedBankWireAdvance.applicationPublicId = applicationPublicId;
    updatedBankWireAdvance.createdByPublicId = appContext.user.publicId;
    updatedBankWireAdvance.advanceNo = data.bankWireAdvanceNumber;
    updatedBankWireAdvance.advanceDate = new Date(data.bankWireDate);
    updatedBankWireAdvance.bankWireConfirmationNo = data.bankWireConfirmationNo;
    updatedBankWireAdvance.deliveryFee = parseFloat(data.bankWireDeliveryFee);
    updatedBankWireAdvance.amount = parseFloat(data.bankWireAdvanceAmount);
    updatedBankWireAdvance.nfpFee = parseFloat(data.bankWireNfpFee);
    updatedBankWireAdvance.fundingType = 'Bank Wire';
    updatedBankWireAdvance.details = 'Payment Confirmation: ' + data.bankWireConfirmationNo;
    updatedBankWireAdvance.referringPartyFee = brokerRefFee ? parseFloat(brokerRefFee) : 0;
    updatedBankWireAdvance.portfolioPublicId = selectedPortfolio;

    if (advanceId) {
      updatedBankWireAdvance.publicId = advanceId;
      updatedBankWireAdvance.createdByDisplayName = undefined;
      updatedBankWireAdvance.advanceDate = data.bankWireDate.split('T')[0];

      ApplicationAdvanceService.update(updatedBankWireAdvance)
        .then((response) => {
          setAdvanceAmountRecorded(updatedBankWireAdvance.amount);
          appContext.toastMessage.message = 'Data sucessfully saved.';
          history.push('../advances');
        })
        .catch((err) => {})
        .finally(() => {
          setIsLoading(false);
        });
    } else {
      ApplicationAdvanceService.create(updatedBankWireAdvance)
        .then((response) => {
          setAdvanceAmountRecorded(updatedBankWireAdvance.amount);
          appContext.toastMessage.message = 'Data sucessfully saved.';
          history.push('../advances');
        })
        .catch((err) => {})
        .finally(() => {
          setIsLoading(false);
        });
    }
  };

  const financialStoreFormSubmitted = (data) => {
    setIsLoading(true);

    if (!(appContext?.userPermissions?.indexOf('Edit Advances') > -1)) {
      appContext.toastMessage.message = 'You do not have permissions to perform this action.';
      toast.error(appContext.toastMessage.message, { autoClose: 3000 });
      appContext.toastMessage.message = null;
      return;
    }

    if (selectedPortfolio === '') {
      setSelectedPortfolioError(true);
      return;
    }

    var updatedFinancialStoreAdvance = { ...financialStoreAdvance };

    updatedFinancialStoreAdvance.applicationPublicId = applicationPublicId;
    updatedFinancialStoreAdvance.createdByPublicId = appContext.user.publicId;
    updatedFinancialStoreAdvance.financialStorePublicId = selectedFinancialStoreObject?.publicId;
    updatedFinancialStoreAdvance.advanceNo = data.financialStoreAdvanceNumber;
    updatedFinancialStoreAdvance.advanceDate = new Date(data.financialStoreAdvanceDate);
    updatedFinancialStoreAdvance.deliveryFee = parseFloat(data.financialStoreAdvanceDeliveryFee);

    updatedFinancialStoreAdvance.financialStoreConfirmationNo = data.financialStoreConfirmationNo;

    // updatedFinancialStoreAdvance.payomaticSecurityCode = data.clients4DigitsSecurityCode;
    // updatedFinancialStoreAdvance.payomaticAccountNo = data.financialStoreAccountNumber;
    updatedFinancialStoreAdvance.amount = parseFloat(data.financialStoreAdvanceAmount);
    updatedFinancialStoreAdvance.nfpFee = parseFloat(data.financialStoreNfpFee);
    updatedFinancialStoreAdvance.fundingType = 'Financial Store';
    updatedFinancialStoreAdvance.details = 'Financial Store';
    updatedFinancialStoreAdvance.referringPartyFee = brokerRefFee ? parseFloat(brokerRefFee) : 0;
    updatedFinancialStoreAdvance.portfolioPublicId = selectedPortfolio;

    if (advanceId) {
      updatedFinancialStoreAdvance.publicId = advanceId;
      updatedFinancialStoreAdvance.createdByDisplayName = undefined;

      ApplicationAdvanceService.update(updatedFinancialStoreAdvance)
        .then((response) => {
          setAdvanceAmountRecorded(updatedFinancialStoreAdvance.amount);
          appContext.toastMessage.message = 'Data sucessfully saved.';
          history.push('../advances');
        })
        .catch((err) => {})
        .finally(() => {
          setIsLoading(false);
        });
    } else {
      ApplicationAdvanceService.create(updatedFinancialStoreAdvance)
        .then((response) => {
          setAdvanceAmountRecorded(updatedFinancialStoreAdvance.amount);
          appContext.toastMessage.message = 'Data sucessfully saved.';
          history.push('../advances');
        })
        .catch((err) => {})
        .finally(() => {
          setIsLoading(false);
        });
    }
  };

  useEffect(() => {
    if (selectedAdvanceOption === 'bankWire') setSelectedFundingType('Bank Wire');
    if (selectedAdvanceOption === 'overnightCheck') setSelectedFundingType('Overnight Check');
    if (selectedAdvanceOption === 'financialStore') setSelectedFundingType('Financial Store');
  }, [selectedAdvanceOption]);

  useEffect(() => {
    setIsLoading(true);

    // TODO: check why 2 api calls
    ApplicationsService.search({ applicationNo: applicationId }, null, null, 1, {}, 10)
      .then((response) => {
        setApplicationPublicId(response.data.data[0].publicId);

        // Get Next Advance No
        ApplicationsService.nextadvanceno(response.data.data[0].publicId).then((response) => {
          if (!response.data) return;
          setFinancialStoreAdvance((prevState) => ({ ...prevState, advanceNo: response.data }));
          setOvernightCheckAdvance((prevState) => ({ ...prevState, advanceNo: response.data }));
          setBankWireAdvance((prevState) => ({ ...prevState, advanceNo: response.data }));
        });
      })
      .catch((err) => {})
      .finally(() => {
        setIsLoading(false);
      });

    if (advanceId) {
      setIsLoading(true);

      ApplicationAdvanceService.read(advanceId)
        .then((response) => {
          setHideOptions(true);

          if (response?.data?.portfolioPublicId != '00000000-0000-0000-0000-000000000000') {
            PortfolioService.read(response?.data?.portfolioPublicId)
              .then((response2) => {
                setSelectedPortfolio(response2?.data?.publicId);
                setSelectedPortfolioName(response2?.data?.portfolioName);
              })
              .catch((err) => {})
              .finally(() => {
                setIsLoading(false);
              });
          }

          //Get Advance Status types
          ApplicationAdvanceService.advancestatuses().then((response) => {
            let accidentTypesList = [];
            if (!response.data) return;
            if (response.data.length > 0) {
              setAdvanceStatusOptions(response.data);
            }
          });

          setDefaultValueBrokerRefFee(response?.data?.referringPartyFee);

          if (response?.data?.approvedAmount) {
            setIsAdvanceApproved(true);
          }
          if (response?.data?.fundingType === 'Bank Wire') {
            setBankWireAdvance(response.data);
            setSelectedAdvanceOption('bankWire');
          }
          if (response?.data?.fundingType === 'Overnight Check') {
            setOvernightCheckAdvance(response.data);
            setSelectedAdvanceOption('overnightCheck');
          }
          if (response?.data?.fundingType === 'Financial Store') {
            setFinancialStoreAdvance(response.data);
            setSelectedAdvanceOption('financialStore');
            setSelectedFinancialStore(true);

            // FinancialStoreService.read(response.data.financialStorePublicId)
            //   .then((response2) => {
            //     var financialStoreObject = {};
            //     financialStoreObject.publicId = response2.data.publicId;
            //     financialStoreObject.companyName = response2.data.companyName;
            //     financialStoreObject.streetAddress = response2.data.streetAddress;
            //     financialStoreObject.zipCode = response2.data.zipCode;
            //     financialStoreObject.state = response2.data.state;
            //     financialStoreObject.distance = response2.data.distance;
            //     financialStoreObject.cashProcessingFee = response2.data.cashProcessingFee;
            //     setSelectedFinancialStoreObject(financialStoreObject);
            //   })
            //   .catch((err) => {})
            //   .finally(() => {
            //     setIsLoading(false);
            //   });
          }
        })
        .catch((err) => {})
        .finally(() => {
          setIsLoading(false);
        });
    } else {
      //Get Advance Status types
      ApplicationAdvanceService.advancestatuses().then((response) => {
        let accidentTypesList = [];
        if (!response.data) return;
        if (response.data.length > 0) {
          setAdvanceStatusOptions(response.data);

          setBankWireAdvance({ ...bankWireAdvance, advanceStatus: 'Draft' });
          setOvernightCheckAdvance({ ...overnightCheckAdvance, advanceStatus: 'Draft' });
          setFinancialStoreAdvance({ ...financialStoreAdvance, advanceStatus: 'Draft' });
        }
      });
    }

    setIsLoading(true);

    // business rule here - we need misc settings to
    MiscSettingsService.search({}, 1, {})
      .then((response) => {
        setMiscSettings(response.data.data[0]);
      })
      .catch((err) => {})
      .finally(() => {
        setIsLoading(false);
      });
  }, []);

  const handleFindStores = () => {
    setIsLoading(true);

    setNoFinancialStoresFound(false);

    FinancialStoreService.search(
      { getbyzipcode: { zipCode: clientZipCode.current.value, radius: 10 } },
      '',
      '',
      1,
      null,
      10
    )
      .then((response) => {
        setStoresByZipCode(response.data.data);
        if (response.data.data.length === 0) setNoFinancialStoresFound(true);
      })
      .catch((err) => {})
      .finally(() => {
        setIsLoading(false);
      });
  };

  // const overnightCheckAdvanceDeliveryFeeDefaultValue = () => {
  //   if (overnightCheckAdvance.deliveryFee != null) {
  //     return overnightCheckAdvance.deliveryFee;
  //   }

  //   return miscSettings.overnightCheckFee;
  // };

  const bankWireAdvanceDeliveryFeeDefaultValue = () => {
    if (bankWireAdvance.deliveryFee != null) {
      return bankWireAdvance.deliveryFee;
    }

    return miscSettings.bankWireFee;
  };

  // const calculateDeliveryFee = () => {
  //   var deliveryFee = null;

  //   // For now do change process
  //   if(deliveryFee == null) return;

  //   if (
  //     !isNaN(selectedFinancialStoreObject?.cashProcessingFee) &&
  //     !isNaN(financialStoreAdvance?.amount)
  //   ) {
  //     // calculate delivery fee
  //     deliveryFee =
  //       parseFloat(selectedFinancialStoreObject?.cashProcessingFee) *
  //       parseFloat(financialStoreAdvance?.amount);
  //   } else {
  //     deliveryFee = 0;
  //   }

  //   setFinancialStoreDeliveryFee(deliveryFee);
  // };

  const onBlureCalculateNfpFee = (e) => {
    const { name, value } = e.target;

    if (advanceId || !value || value == null) return;

    //Send to calculate the NFP Fee
    ApplicationAdvanceService.calculateAdvanceFees({
      applicationPublicId: applicationPublicId,
      advanceAmount: parseFloat(value),
      fundingType: selectedFundingType,
      financialStorePublicId: selectedFinancialStoreObject?.publicId,
    })
      .then((response) => {
        if (name === 'financialStoreAdvanceAmount') {
          setFinancialStoreAdvance((financialStoreAdvance) => ({
            ...financialStoreAdvance,
            nfpFee: response.data.nfpFee,
            deliveryFee: response.data.deliveryFee,
          }));
          setFinancialStoreNfpFee(response.data.nfpFee);
          setFinancialStoreDeliveryFee(response.data.deliveryFee);
        }
        if (name === 'overnightCheckAdvanceAmount') {
          setOvernightCheckAdvance((overnightCheckAdvance) => ({
            ...overnightCheckAdvance,
            nfpFee: response.data.nfpFee,
            deliveryFee: response.data.deliveryFee,
          }));
          setOvernightCheckAdvanceNfpFee(response.data.nfpFee);
          setOvernightCheckDeliveryFee(response.data.deliveryFee);
        }
        if (name === 'bankWireAdvanceAmount') {
          bankWireAdvance.nfpFee = response.data.nfpFee;

          setBankWireAdvance((bankWireAdvance) => ({
            ...bankWireAdvance,
            nfpFee: response.data.nfpFee,
            deliveryFee: response.data.deliveryFee,
          }));
          setBankWireNfpFee(response.data.nfpFee);
          setBankWireAdvanceDeliveryFee(response.data.deliveryFee);
        }
      })
      .catch((err) => {})
      .finally(() => {
        setIsLoading(false);
      });
  };

  // useEffect(() => {
  //   calculateDeliveryFee();
  // }, [financialStoreAdvance]);

  // useEffect(() => {
  //   calculateDeliveryFee();
  // }, [selectedFinancialStoreObject]);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    let updatedFinancialStoreAdvance = { ...financialStoreAdvance };
    updatedFinancialStoreAdvance[name] = value;
    setFinancialStoreAdvance(updatedFinancialStoreAdvance);
  };

  const handlePropertiesInputChange = (property) => {
    return (e) => {
      this.setState({
        [property]: e.target.value,
      });
    };
  };

  if (isLoading) {
    return (
      <Container className="mt-10 mb-10">
        <Row>
          <Col md="12" className="mx-auto bg-white box-shadow-border">
            <div className="p-10 mt-5 mb-10">
              {disableTabs != null && (
                <Header applicationId={applicationId} handleSaveAndExit={() => {}} />
              )}

              {disableTabs != null && (
                <ApplicationsNewMenu
                  disableTabs={disableTabs}
                  applicationId={applicationId}
                  menuItemIndex="8"
                />
              )}

              <Container className="p-0">
                <Row>
                  <Col md="8">
                    <Row>
                      <Col md="4">
                        <ReactLoading type="bars" color="#7E8299" />
                      </Col>
                    </Row>
                  </Col>
                </Row>
              </Container>
            </div>
          </Col>
        </Row>
      </Container>
    );
  }

  const moneyFormatter = new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: 'USD',
  });

  const parseDollarString = (val) => {
    return val ? moneyFormatter.format(parseFloat(val).toFixed(2)) : '-';
  };

  return (
    <div className="container mt-10 mb-10">
      <div className="row">
        <div className="col-md-12 mx-auto bg-white box-shadow-border">
          <div className="p-10">
            <div className="mt-5 mb-10">
              <div>
                <Header applicationId={applicationId} handleSaveAndExit={() => {}} />
                <h1>{advanceId ? 'Edit Advance' : 'New Advance'}</h1>
                <ApplicationsNewMenu applicationId={applicationId} menuItemIndex="8" />

                {showForm && (
                  <Row>
                    <Col md="8">
                      {hideOptions === false && (
                        <Row className="mb-10">
                          <Col md="3">
                            <FormGroup check>
                              <Label check>
                                <Input
                                  type="radio"
                                  name="radio1"
                                  value="financialStore"
                                  checked={
                                    selectedAdvanceOption === 'financialStore'
                                      ? 'checked'
                                      : undefined
                                  }
                                  onClick={(e) => {
                                    setSelectedAdvanceOption(e.target.value);
                                  }}
                                />
                                Financial Store
                              </Label>
                            </FormGroup>
                          </Col>
                          <Col md="3">
                            <FormGroup check>
                              <Label check>
                                <Input
                                  type="radio"
                                  name="radio1"
                                  value="overnightCheck"
                                  checked={
                                    selectedAdvanceOption === 'overnightCheck'
                                      ? 'checked'
                                      : undefined
                                  }
                                  onClick={(e) => {
                                    setSelectedAdvanceOption(e.target.value);
                                  }}
                                />
                                Overnight Check
                              </Label>
                            </FormGroup>
                          </Col>
                          <Col md="3">
                            <FormGroup check>
                              <Label check>
                                <Input
                                  type="radio"
                                  name="radio1"
                                  value="bankWire"
                                  checked={
                                    selectedAdvanceOption === 'bankWire' ? 'checked' : undefined
                                  }
                                  onClick={(e) => {
                                    setSelectedAdvanceOption(e.target.value);
                                  }}
                                />
                                Bank Wire
                              </Label>
                            </FormGroup>
                          </Col>
                        </Row>
                      )}
                      {selectedAdvanceOption === '' && (
                        <Row className="mt-15">
                          <Col md="2">
                            <FormGroup>
                              <Button
                                onClick={() => {
                                  history.push('.');
                                }}
                              >
                                Cancel
                              </Button>
                            </FormGroup>
                          </Col>
                        </Row>
                      )}

                      {selectedAdvanceOption === 'financialStore' && (
                        <>
                          {/* {selectedFinancialStore === false && (
                            <>
                              <Row>
                                <Col md="3">
                                  <FormGroup>
                                    <Label>Client's Zip Code</Label>
                                    <Input name="clientZipCode" innerRef={clientZipCode} />
                                  </FormGroup>
                                </Col>
                                <Col md="2">
                                  <FormGroup>
                                    <Label>&nbsp; &nbsp; &nbsp; &nbsp; </Label>
                                    <Button
                                      style={{
                                        backgroundColor: 'var(--green)',
                                        color: 'white',
                                        borderColor: 'var(--green)',
                                      }}
                                      onClick={handleFindStores}
                                    >
                                      Find stores
                                    </Button>
                                  </FormGroup>
                                </Col>
                              </Row>

                              <Row>
                                <Col md="8">
                                  <Table>
                                    <thead>
                                      <tr>
                                        <th>Store Name</th>
                                        <th>Address</th>
                                        <th>Distance</th>
                                        <th>Action</th>
                                      </tr>
                                    </thead>

                                    <tbody>
                                      {noFinancialStoresFound && (
                                        <tr>
                                          <td>
                                            <span className="mb-5">No Financial Stores found.</span>
                                          </td>
                                        </tr>
                                      )}
                                      {storesByZipCode?.map((row, index) => (
                                        <tr>
                                          <td style={{ display: 'none' }}>{row?.publicId}</td>
                                          <td>
                                            {row?.companyName} # {row?.storeNumber}
                                          </td>
                                          <td>
                                            {row?.streetAddress}, {row?.zipCode}, {row?.state}
                                          </td>
                                          <td>{parseFloat(row?.distance)}</td>
                                          <td>
                                            <Button
                                              className="btn-sm"
                                              onClick={(e) => {
                                                setSelectedFinancialStoreObject(row);
                                                setSelectedFinancialStore(true);
                                                setFinancialStoreDeliveryFee(null);
                                              }}
                                            >
                                              Select
                                            </Button>
                                          </td>
                                        </tr>
                                      ))}
                                    </tbody>
                                  </Table>
                                </Col>
                              </Row>
                            </>
                          )} */}

                          {/* {selectedFinancialStore === true && ( */}
                          <form onSubmit={handleSubmit(financialStoreFormSubmitted)} noValidate>
                            <div className="mt-5">
                              {/* <p>
                                  <strong>Selected Financial Store:</strong>{' '}
                                  {selectedFinancialStoreObject?.companyName},{' '}
                                  {selectedFinancialStoreObject?.streetAddress},{' '}
                                  {selectedFinancialStoreObject?.zipCode},{' '}
                                  {selectedFinancialStoreObject?.state}
                                  &nbsp;{' '}
                                  <a
                                    className="btn btn-primary"
                                    onClick={() => {
                                      setSelectedFinancialStore(false);
                                    }}
                                  >
                                    Change
                                  </a>
                                </p>
                                <hr /> */}
                              {/* <Row>
                                  <Col md="12">
                                    <Row>
                                      <Col md="3">
                                        <FormGroup>
                                          <Label for="clients4DigitsSecurityCode">
                                            Client's 4 digits Security
                                          </Label>
                                          <Input
                                            name="clients4DigitsSecurityCode"
                                            defaultValue={
                                              financialStoreAdvance?.payomaticSecurityCode
                                            }
                                            innerRef={register({ required: 'Value is required.' })}
                                            id="clients4DigitsSecurityCode"
                                          ></Input>
                                          {errors.clients4DigitsSecurityCode && (
                                            <Alert color="danger" className="mt-5">
                                              <ErrorMessage
                                                errors={errors}
                                                name="clients4DigitsSecurityCode"
                                              />
                                            </Alert>
                                          )}
                                        </FormGroup>
                                      </Col>
                                      <Col md="3">
                                        <Label for="financialStoreAccountNumber">Account #</Label>
                                        <Input
                                          defaultValue={financialStoreAdvance?.payomaticAccountNo}
                                          name="financialStoreAccountNumber"
                                          innerRef={register({ required: 'Value is required.' })}
                                          id="financialStoreAccountNumber"
                                        />
                                        {errors.financialStoreAccountNumber && (
                                          <Alert color="danger" className="mt-5">
                                            <ErrorMessage
                                              errors={errors}
                                              name="financialStoreAccountNumber"
                                            />
                                          </Alert>
                                        )}
                                      </Col>
                                    </Row>
                                  </Col>
                                </Row> */}
                              <Row>
                                <Col md="3">
                                  <FormGroup>
                                    <Label for="financialStoreAdvanceNumber">Advance #</Label>
                                    <Input
                                      defaultValue={financialStoreAdvance?.advanceNo}
                                      name="financialStoreAdvanceNumber"
                                      innerRef={register({
                                        required: 'Advance number is required.',
                                      })}
                                      id="financialStoreAdvanceNumber"
                                    />
                                    {errors.financialStoreAdvanceNumber && (
                                      <Alert color="danger" className="mt-5">
                                        <ErrorMessage
                                          errors={errors}
                                          name="financialStoreAdvanceNumber"
                                        />
                                      </Alert>
                                    )}
                                  </FormGroup>

                                  <FormGroup>
                                    <Label for="financialStoreAdvanceStatus">Advance Status</Label>
                                    <select
                                      className="form-control"
                                      value={financialStoreAdvance?.advanceStatus}
                                      name="financialStoreAdvanceStatus"
                                      id="financialStoreAdvanceStatus"
                                      onChange={(event) => {
                                        setFinancialStoreAdvance({
                                          ...financialStoreAdvance,
                                          advanceStatus: event.target.value,
                                        });
                                      }}
                                    >
                                      <option value="">Select Advance Status</option>
                                      {advanceStatusOptions.map((status, index) => {
                                        return (
                                          <option key={index} value={status}>
                                            {status}
                                          </option>
                                        );
                                      })}
                                    </select>
                                  </FormGroup>

                                  <FormGroup className="mt-5">
                                    <label htmlFor="portfolio">Portfolio</label>

                                    {selectedPortfolio === '' && selectedPortfolioName === '' ? (
                                      <PortfolioAutoSuggest
                                        selectedPortfolio={setSelectedPortfolio}
                                        defaultPortfolio={''}
                                        isAdvance={true}
                                      />
                                    ) : selectedPortfolio != '' && selectedPortfolioName != '' ? (
                                      <PortfolioAutoSuggest
                                        selectedPortfolio={setSelectedPortfolio}
                                        defaultPortfolio={selectedPortfolioName}
                                        isAdvance={true}
                                      />
                                    ) : undefined}

                                    {!selectedPortfolioName && (
                                      <Alert color="danger" className="mt-5">
                                        <span>Select a Portfolio</span>
                                      </Alert>
                                    )}
                                  </FormGroup>

                                  <FormGroup className="mt-5">
                                    <Label for="financialStoreAdvanceDate">Date</Label>
                                    <Input
                                      defaultValue={
                                        financialStoreAdvance?.advanceDate?.split('T')[0]
                                      }
                                      type="date"
                                      innerRef={register({ required: 'Date is required.' })}
                                      name="financialStoreAdvanceDate"
                                      id="financialStoreAdvanceDate"
                                    />
                                    {errors.financialStoreAdvanceDate && (
                                      <Alert color="danger" className="mt-5">
                                        <ErrorMessage
                                          errors={errors}
                                          name="financialStoreAdvanceDate"
                                        />
                                      </Alert>
                                    )}
                                  </FormGroup>

                                  <FormGroup className="mt-5">
                                    <Label for="financialStoreConfirmationNo">Confirmation #</Label>
                                    <Input
                                      defaultValue={
                                        financialStoreAdvance?.financialStoreConfirmationNo
                                      }
                                      innerRef={register({
                                        required: 'Confirmation number is required.',
                                      })}
                                      name="financialStoreConfirmationNo"
                                      id="financialStoreConfirmationNo"
                                    />
                                    {errors.financialStoreConfirmationNo && (
                                      <Alert color="danger" className="mt-5">
                                        <ErrorMessage
                                          errors={errors}
                                          name="financialStoreConfirmationNo"
                                        />
                                      </Alert>
                                    )}
                                  </FormGroup>
                                </Col>
                              </Row>

                              <Row>
                                <Col md="3">
                                  <FormGroup>
                                    <Label for="financialStoreAdvanceAmount">
                                      <strong>Advance Amount</strong>
                                    </Label>
                                    <div className="input-group input-group-sm amount">
                                      <div className="input-group-prepend">
                                        <span className="input-group-text">$</span>
                                      </div>
                                      <input
                                        name="financialStoreAdvanceAmount"
                                        id="financialStoreAdvanceAmount"
                                        type="number"
                                        ref={register({
                                          required: 'Advance amount is required.',
                                        })}
                                        onChange={handleInputChange}
                                        onBlur={onBlureCalculateNfpFee}
                                        defaultValue={financialStoreAdvance?.amount}
                                        className="form-control float-right number-input"
                                        // disabled={
                                        //   formState.isSubmitting ||
                                        //   isSuccessfullySubmitted ||
                                        //   isAdvanceApproved
                                        // }
                                        disabled={formState.isSubmitting || isSuccessfullySubmitted}
                                        required="required"
                                      />
                                      {errors.financialStoreAdvanceAmount && (
                                        <Alert color="danger" className="mt-5">
                                          <ErrorMessage
                                            errors={errors}
                                            name="financialStoreAdvanceAmount"
                                          />
                                        </Alert>
                                      )}
                                    </div>
                                  </FormGroup>
                                </Col>
                                <Col md="3">
                                  <FormGroup>
                                    <Label for="financialStoreAdvanceDeliveryFee">
                                      <strong>Delivery Fee</strong>
                                    </Label>
                                    <div className="input-group input-group-sm amount">
                                      <div className="input-group-prepend">
                                        <span className="input-group-text">$</span>
                                      </div>
                                      <input
                                        name="financialStoreAdvanceDeliveryFee"
                                        id="financialStoreAdvanceDeliveryFee"
                                        type="number"
                                        ref={register({
                                          required: 'Delivery fee is required.',
                                        })}
                                        onChange={(e) =>
                                          setFinancialStoreDeliveryFee(e.target.value)
                                        }
                                        defaultValue={financialStoreAdvance?.deliveryFee}
                                        value={financialStoreDeliveryFee}
                                        // onChange={handleInputChange}
                                        // defaultValue={financialStoreDeliveryFee}
                                        // value={financialStoreDeliveryFee}
                                        className="form-control float-right number-input"
                                        // disabled={
                                        //   formState.isSubmitting ||
                                        //   isSuccessfullySubmitted ||
                                        //   isAdvanceApproved
                                        // }
                                        disabled={formState.isSubmitting || isSuccessfullySubmitted}
                                        required="required"
                                      />
                                      {errors.financialStoreAdvanceDeliveryFee && (
                                        <Alert color="danger" className="mt-5">
                                          <ErrorMessage
                                            errors={errors}
                                            name="financialStoreAdvanceDeliveryFee"
                                          />
                                        </Alert>
                                      )}
                                    </div>
                                  </FormGroup>
                                </Col>
                                <Col md="3">
                                  <FormGroup>
                                    <Label for="financialStoreNfpFee">
                                      <strong>NFP Fees</strong>
                                    </Label>
                                    <div className="input-group input-group-sm amount">
                                      <div className="input-group-prepend">
                                        <span className="input-group-text">$</span>
                                      </div>
                                      <input
                                        name="financialStoreNfpFee"
                                        id="financialStoreNfpFee"
                                        type="number"
                                        ref={register({
                                          required: 'Delivery fee is required.',
                                        })}
                                        onChange={(e) => setFinancialStoreNfpFee(e.target.value)}
                                        defaultValue={financialStoreAdvance?.nfpFee}
                                        value={financialStoreNfpFee}
                                        // value={financialStoreNfpFee}
                                        className="form-control float-right number-input"
                                        // disabled={
                                        //   formState.isSubmitting ||
                                        //   isSuccessfullySubmitted ||
                                        //   isAdvanceApproved
                                        // }
                                        disabled={formState.isSubmitting || isSuccessfullySubmitted}
                                        required="required"
                                      />
                                      {errors.financialStoreNfpFee && (
                                        <Alert color="danger" className="mt-5">
                                          <ErrorMessage
                                            errors={errors}
                                            name="financialStoreNfpFee"
                                          />
                                        </Alert>
                                      )}
                                    </div>
                                  </FormGroup>
                                </Col>

                                <Col md="3">
                                  <FormGroup>
                                    <Label for="brokerRefFee">
                                      <strong>Referring Party Fee</strong>
                                    </Label>
                                    <div className="input-group input-group-sm amount">
                                      <div className="input-group-prepend">
                                        <span className="input-group-text">$</span>
                                      </div>
                                      <input
                                        type="number"
                                        name="brokerRefFee"
                                        ref={refferingPartyRef}
                                        className="form-control float-right number-input"
                                        step="0.0001"
                                        onChange={(e) => {
                                          setBrokerRefFee(e.target.value);
                                        }}
                                        value={brokerRefFee}
                                        defaultValue={defaultValueBrokerRefFee}
                                        disabled={formState.isSubmitting || isSuccessfullySubmitted}
                                      />
                                      {errors.brokerRefFee && (
                                        <Alert color="danger" className="mt-5">
                                          <ErrorMessage errors={errors} name="brokerRefFee" />
                                        </Alert>
                                      )}
                                    </div>
                                  </FormGroup>
                                </Col>
                              </Row>

                              <Row>
                                <Col md="3">
                                  <FormGroup className="mt-5">
                                    <Label>
                                      <strong>Approved Amount</strong> :{' '}
                                      {parseDollarString(financialStoreAdvance?.approvedAmount)}
                                    </Label>
                                  </FormGroup>
                                </Col>
                              </Row>

                              <Row>
                                <Col md="3">
                                  <Button color="primary" type="submit">
                                    Save
                                  </Button>{' '}
                                  &nbsp;
                                  <Button
                                    onClick={(e) => {
                                      if (advanceId) {
                                        history.push('../advances');
                                      } else {
                                        setSelectedAdvanceOption('');
                                      }
                                    }}
                                  >
                                    Cancel
                                  </Button>{' '}
                                </Col>
                              </Row>
                            </div>
                          </form>
                          {/* )} */}
                        </>
                      )}

                      {selectedAdvanceOption === 'overnightCheck' && (
                        <>
                          <form onSubmit={handleSubmit(overnightCheckFormSubmitted)} noValidate>
                            <Row>
                              <Col md="3">
                                <FormGroup>
                                  <Label for="overnightCheckAdvanceNumber">Advance #</Label>
                                  <Input
                                    defaultValue={overnightCheckAdvance?.advanceNo}
                                    name="overnightCheckAdvanceNumber"
                                    id="overnightCheckAdvanceNumber"
                                    innerRef={register({ required: 'Advance number is required.' })}
                                  />
                                  {errors.overnightCheckAdvanceNumber && (
                                    <Alert color="danger" className="mt-5">
                                      <ErrorMessage
                                        errors={errors}
                                        name="overnightCheckAdvanceNumber"
                                      />
                                    </Alert>
                                  )}
                                </FormGroup>

                                <FormGroup>
                                  <Label for="overnightCheckAdvanceStatus">Advance Status</Label>
                                  <select
                                    className="form-control"
                                    value={overnightCheckAdvance?.advanceStatus}
                                    name="overnightCheckAdvanceStatus"
                                    id="overnightCheckAdvanceStatus"
                                    onChange={(event) => {
                                      setOvernightCheckAdvance({
                                        ...overnightCheckAdvance,
                                        advanceStatus: event.target.value,
                                      });
                                    }}
                                  >
                                    <option value="">Select Advance Status</option>
                                    {advanceStatusOptions.map((status, index) => {
                                      return (
                                        <option key={index} value={status}>
                                          {status}
                                        </option>
                                      );
                                    })}
                                  </select>
                                </FormGroup>

                                <FormGroup className="mt-5">
                                  <label htmlFor="portfolio">Portfolio</label>

                                  {selectedPortfolio === '' && selectedPortfolioName === '' ? (
                                    <PortfolioAutoSuggest
                                      selectedPortfolio={setSelectedPortfolio}
                                      defaultPortfolio={''}
                                      isAdvance={true}
                                    />
                                  ) : selectedPortfolio != '' && selectedPortfolioName != '' ? (
                                    <PortfolioAutoSuggest
                                      selectedPortfolio={setSelectedPortfolio}
                                      defaultPortfolio={selectedPortfolioName}
                                      isAdvance={true}
                                    />
                                  ) : undefined}

                                  {!selectedPortfolioName && (
                                    <Alert color="danger" className="mt-5">
                                      <span>Select a Portfolio</span>
                                    </Alert>
                                  )}
                                </FormGroup>
                                <FormGroup className="mt-5">
                                  <Label for="overnightCheckDate">Date</Label>
                                  <Input
                                    defaultValue={overnightCheckAdvance?.advanceDate?.split('T')[0]}
                                    type="date"
                                    innerRef={register({ required: 'Check date is required.' })}
                                    name="overnightCheckDate"
                                    id="overnightCheckDate"
                                  />
                                  {errors.overnightCheckDate && (
                                    <Alert color="danger" className="mt-5">
                                      <ErrorMessage errors={errors} name="overnightCheckDate" />
                                    </Alert>
                                  )}
                                </FormGroup>
                                <FormGroup className="mt-5">
                                  <Label for="overnightCheckCheckNumber">Check #</Label>
                                  <Input
                                    defaultValue={overnightCheckAdvance?.checkNo}
                                    name="overnightCheckCheckNumber"
                                    innerRef={register({ required: 'Check number is required.' })}
                                    id="checkNumber"
                                  />
                                  {errors.overnightCheckCheckNumber && (
                                    <Alert color="danger" className="mt-5">
                                      <ErrorMessage
                                        errors={errors}
                                        name="overnightCheckCheckNumber"
                                      />
                                    </Alert>
                                  )}
                                </FormGroup>
                              </Col>
                            </Row>

                            <Row>
                              <Col md="3">
                                <FormGroup className="mt-5">
                                  <Label for="overnightCheckAdvanceAmount">
                                    <strong>Advance Amount</strong>
                                  </Label>
                                  <div className="input-group input-group-sm amount">
                                    <div className="input-group-prepend">
                                      <span className="input-group-text">$</span>
                                    </div>
                                    <input
                                      name="overnightCheckAdvanceAmount"
                                      id="overnightCheckAdvanceAmount"
                                      type="number"
                                      ref={register({
                                        required: 'Advance amount is required.',
                                      })}
                                      onChange={handleInputChange}
                                      onBlur={onBlureCalculateNfpFee}
                                      defaultValue={overnightCheckAdvance?.amount}
                                      className="form-control float-right number-input"
                                      // disabled={
                                      //   formState.isSubmitting ||
                                      //   isSuccessfullySubmitted ||
                                      //   isAdvanceApproved
                                      // }
                                      disabled={formState.isSubmitting || isSuccessfullySubmitted}
                                      required="required"
                                    />
                                    {errors.overnightCheckAdvanceAmount && (
                                      <Alert color="danger" className="mt-5">
                                        <ErrorMessage
                                          errors={errors}
                                          name="overnightCheckAdvanceAmount"
                                        />
                                      </Alert>
                                    )}
                                  </div>
                                </FormGroup>
                              </Col>
                              <Col md="3">
                                <FormGroup className="mt-5">
                                  <Label for="overnightCheckDeliveryFee">
                                    <strong>Delivery Fee</strong>
                                  </Label>
                                  <div className="input-group input-group-sm amount">
                                    <div className="input-group-prepend">
                                      <span className="input-group-text">$</span>
                                    </div>
                                    <input
                                      name="overnightCheckDeliveryFee"
                                      id="overnightCheckDeliveryFee"
                                      type="number"
                                      ref={register({
                                        required: 'Delivery fee is required.',
                                      })}
                                      onChange={(e) => setOvernightCheckDeliveryFee(e.target.value)}
                                      defaultValue={overnightCheckAdvance?.deliveryFee}
                                      value={overnightCheckDeliveryFee}
                                      className="form-control float-right number-input"
                                      // disabled={
                                      //   formState.isSubmitting ||
                                      //   isSuccessfullySubmitted ||
                                      //   isAdvanceApproved
                                      // }
                                      disabled={formState.isSubmitting || isSuccessfullySubmitted}
                                      required="required"
                                    />
                                    {errors.overnightCheckDeliveryFee && (
                                      <Alert color="danger" className="mt-5">
                                        <ErrorMessage
                                          errors={errors}
                                          name="overnightCheckDeliveryFee"
                                        />
                                      </Alert>
                                    )}
                                  </div>
                                </FormGroup>
                              </Col>
                              <Col md="3">
                                <FormGroup className="mt-5">
                                  <Label for="overnightCheckNfpFee">
                                    <strong>NFP Fees</strong>
                                  </Label>
                                  <div className="input-group input-group-sm amount">
                                    <div className="input-group-prepend">
                                      <span className="input-group-text">$</span>
                                    </div>
                                    <input
                                      name="overnightCheckNfpFee"
                                      id="overnightCheckNfpFee"
                                      type="number"
                                      ref={register({
                                        required: 'NFP Fees amount is required.',
                                      })}
                                      onChange={(e) =>
                                        setOvernightCheckAdvanceNfpFee(e.target.value)
                                      }
                                      defaultValue={overnightCheckAdvance?.nfpFee}
                                      value={overnightCheckAdvanceNfpFee}
                                      className="form-control float-right number-input"
                                      // disabled={
                                      //   formState.isSubmitting ||
                                      //   isSuccessfullySubmitted ||
                                      //   isAdvanceApproved
                                      // }
                                      disabled={formState.isSubmitting || isSuccessfullySubmitted}
                                      required="required"
                                    />
                                    {errors.overnightCheckNfpFee && (
                                      <Alert color="danger" className="mt-5">
                                        <ErrorMessage errors={errors} name="overnightCheckNfpFee" />
                                      </Alert>
                                    )}
                                  </div>
                                </FormGroup>
                              </Col>

                              <Col md="3">
                                <FormGroup className="mt-5">
                                  <Label for="financialStoreNfpFee">
                                    <strong>Referring Party Fee</strong>
                                  </Label>
                                  <div className="input-group input-group-sm amount">
                                    <div className="input-group-prepend">
                                      <span className="input-group-text">$</span>
                                    </div>
                                    <input
                                      type="number"
                                      name="brokerRefFee"
                                      ref={refferingPartyRef}
                                      className="form-control float-right number-input"
                                      step="0.0001"
                                      onChange={(e) => {
                                        setBrokerRefFee(e.target.value);
                                      }}
                                      value={brokerRefFee}
                                      disabled={formState.isSubmitting || isSuccessfullySubmitted}
                                      defaultValue={defaultValueBrokerRefFee}
                                    />
                                    {errors.brokerRefFee && (
                                      <Alert color="danger" className="mt-5">
                                        <ErrorMessage errors={errors} name="brokerRefFee" />
                                      </Alert>
                                    )}
                                  </div>
                                </FormGroup>
                              </Col>
                            </Row>

                            <Row>
                              <Col md="3">
                                <FormGroup className="mt-5">
                                  <Label>
                                    <strong>Approved Amount</strong> :{' '}
                                    {parseDollarString(overnightCheckAdvance?.approvedAmount)}
                                  </Label>
                                </FormGroup>
                              </Col>
                            </Row>

                            <Row>
                              <Col md="3">
                                <Button color="primary" type="submit">
                                  Save
                                </Button>{' '}
                                &nbsp;
                                <Button
                                  onClick={(e) => {
                                    if (advanceId) {
                                      history.push('../advances');
                                    } else {
                                      setSelectedAdvanceOption('');
                                    }
                                  }}
                                >
                                  Cancel
                                </Button>
                              </Col>
                            </Row>
                          </form>
                        </>
                      )}

                      {selectedAdvanceOption === 'bankWire' && (
                        <>
                          <form onSubmit={handleSubmit(bankWireFormSubmitted)} noValidate>
                            <Row>
                              <Col md="3">
                                <FormGroup>
                                  <Label for="bankWireAdvanceNumber">Advance #</Label>
                                  <Input
                                    defaultValue={bankWireAdvance?.advanceNo}
                                    name="bankWireAdvanceNumber"
                                    innerRef={register({ required: 'Advance number is required.' })}
                                    id="bankWireAdvanceNumber"
                                  />
                                  {errors.bankWireAdvanceNumber && (
                                    <Alert color="danger" className="mt-5">
                                      <ErrorMessage errors={errors} name="bankWireAdvanceNumber" />
                                    </Alert>
                                  )}
                                </FormGroup>

                                <FormGroup>
                                  <Label for="bankWireAdvanceStatus">Advance Status</Label>
                                  <select
                                    className="form-control"
                                    value={bankWireAdvance?.advanceStatus}
                                    name="bankWireAdvanceStatus"
                                    id="bankWireAdvanceStatus"
                                    onChange={(event) => {
                                      setBankWireAdvance({
                                        ...bankWireAdvance,
                                        advanceStatus: event.target.value,
                                      });
                                    }}
                                  >
                                    <option value="">Select Advance Status</option>
                                    {advanceStatusOptions.map((status, index) => {
                                      return (
                                        <option key={index} value={status}>
                                          {status}
                                        </option>
                                      );
                                    })}
                                  </select>
                                </FormGroup>

                                <FormGroup className="mt-5">
                                  <label htmlFor="portfolio">Portfolio</label>

                                  {selectedPortfolio === '' && selectedPortfolioName === '' ? (
                                    <PortfolioAutoSuggest
                                      selectedPortfolio={setSelectedPortfolio}
                                      defaultPortfolio={''}
                                      isAdvance={true}
                                    />
                                  ) : selectedPortfolio != '' && selectedPortfolioName != '' ? (
                                    <PortfolioAutoSuggest
                                      selectedPortfolio={setSelectedPortfolio}
                                      defaultPortfolio={selectedPortfolioName}
                                      isAdvance={true}
                                    />
                                  ) : undefined}

                                  {(!selectedPortfolioName || !selectedPortfolio) && (
                                    <Alert color="danger" className="mt-5">
                                      <span>Select a Portfolio</span>
                                    </Alert>
                                  )}
                                </FormGroup>
                                <FormGroup className="mt-5">
                                  <Label for="bankWireDate">Date</Label>
                                  <Input
                                    defaultValue={bankWireAdvance?.advanceDate?.split('T')[0]}
                                    type="date"
                                    name="bankWireDate"
                                    innerRef={register({ required: 'Date is required.' })}
                                    id="bankWireDate"
                                  />
                                  {errors.bankWireDate && (
                                    <Alert color="danger" className="mt-5">
                                      <ErrorMessage errors={errors} name="bankWireDate" />
                                    </Alert>
                                  )}
                                </FormGroup>
                                <FormGroup className="mt-5">
                                  <Label for="bankWireConfirmationNo">Confirmation #</Label>
                                  <Input
                                    defaultValue={bankWireAdvance?.bankWireConfirmationNo}
                                    innerRef={register({
                                      required: 'Confirmation number is required.',
                                    })}
                                    name="bankWireConfirmationNo"
                                    id="bankWireConfirmationNo"
                                  />
                                  {errors.bankWireConfirmationNo && (
                                    <Alert color="danger" className="mt-5">
                                      <ErrorMessage errors={errors} name="bankWireConfirmationNo" />
                                    </Alert>
                                  )}
                                </FormGroup>
                              </Col>
                            </Row>

                            <Row>
                              <Col md="3">
                                <FormGroup className="mt-5">
                                  <Label for="bankWireAdvanceAmount">
                                    <strong>Advance Amount</strong>
                                  </Label>

                                  <div className="input-group input-group-sm amount">
                                    <div className="input-group-prepend">
                                      <span className="input-group-text">$</span>
                                    </div>
                                    <input
                                      name="bankWireAdvanceAmount"
                                      id="bankWireAdvanceAmount"
                                      type="number"
                                      ref={register({
                                        required: 'Advance amount is required.',
                                      })}
                                      onBlur={onBlureCalculateNfpFee}
                                      onChange={handleInputChange}
                                      defaultValue={bankWireAdvance?.amount}
                                      className="form-control float-right number-input"
                                      // disabled={
                                      //   formState.isSubmitting ||
                                      //   isSuccessfullySubmitted ||
                                      //   isAdvanceApproved
                                      // }
                                      disabled={formState.isSubmitting || isSuccessfullySubmitted}
                                      required="required"
                                    />
                                    {errors.bankWireAdvanceAmount && (
                                      <Alert color="danger" className="mt-5">
                                        <ErrorMessage
                                          errors={errors}
                                          name="bankWireAdvanceAmount"
                                        />
                                      </Alert>
                                    )}
                                  </div>
                                </FormGroup>
                              </Col>
                              <Col md="3">
                                <FormGroup className="mt-5">
                                  <Label for="bankWireDeliveryFee">
                                    <strong>Delivery Fee</strong>
                                  </Label>
                                  <div className="input-group input-group-sm amount">
                                    <div className="input-group-prepend">
                                      <span className="input-group-text">$</span>
                                    </div>
                                    <input
                                      name="bankWireDeliveryFee"
                                      id="bankWireDeliveryFee"
                                      type="number"
                                      ref={register({
                                        required: 'Delivery fee is required.',
                                      })}
                                      onChange={(e) =>
                                        setBankWireAdvanceDeliveryFee(e.target.value)
                                      }
                                      defaultValue={bankWireAdvance?.deliveryFee}
                                      value={bankWireDeliveryFee}
                                      className="form-control float-right number-input"
                                      // disabled={
                                      //   formState.isSubmitting ||
                                      //   isSuccessfullySubmitted ||
                                      //   isAdvanceApproved
                                      // }
                                      disabled={formState.isSubmitting || isSuccessfullySubmitted}
                                      required="required"
                                    />
                                    {errors.bankWireDeliveryFee && (
                                      <Alert color="danger" className="mt-5">
                                        <ErrorMessage errors={errors} name="bankWireDeliveryFee" />
                                      </Alert>
                                    )}
                                  </div>
                                </FormGroup>
                              </Col>
                              <Col md="3">
                                <FormGroup className="mt-5">
                                  <Label for="bankWireNfpFee">
                                    <strong>NFP Fees</strong>
                                  </Label>
                                  <div className="input-group input-group-sm amount">
                                    <div className="input-group-prepend">
                                      <span className="input-group-text">$</span>
                                    </div>
                                    <input
                                      name="bankWireNfpFee"
                                      id="bankWireNfpFee"
                                      type="number"
                                      ref={register({
                                        required: 'NFP fee is required.',
                                      })}
                                      onChange={(e) => setBankWireNfpFee(e.target.value)}
                                      defaultValue={bankWireAdvance?.nfpFee}
                                      value={bankWireNfpFee}
                                      className="form-control float-right number-input"
                                      // disabled={
                                      //   formState.isSubmitting ||
                                      //   isSuccessfullySubmitted ||
                                      //   isAdvanceApproved
                                      // }
                                      disabled={formState.isSubmitting || isSuccessfullySubmitted}
                                      required="required"
                                    />
                                    {errors.bankWireNfpFee && (
                                      <Alert color="danger" className="mt-5">
                                        <ErrorMessage errors={errors} name="bankWireNfpFee" />
                                      </Alert>
                                    )}
                                  </div>
                                </FormGroup>
                              </Col>
                              <Col md="3">
                                <FormGroup className="mt-5">
                                  <Label for="financialStoreNfpFee">
                                    <strong>Referring Party Fee</strong>
                                  </Label>
                                  <div className="input-group input-group-sm amount">
                                    <div className="input-group-prepend">
                                      <span className="input-group-text">$</span>
                                    </div>
                                    <input
                                      type="number"
                                      name="brokerRefFee"
                                      ref={refferingPartyRef}
                                      className="form-control float-right number-input"
                                      step="0.0001"
                                      onChange={(e) => {
                                        setBrokerRefFee(e.target.value);
                                      }}
                                      value={brokerRefFee}
                                      disabled={formState.isSubmitting || isSuccessfullySubmitted}
                                      defaultValue={defaultValueBrokerRefFee}
                                    />
                                    {errors.brokerRefFee && (
                                      <Alert color="danger" className="mt-5">
                                        <ErrorMessage errors={errors} name="brokerRefFee" />
                                      </Alert>
                                    )}
                                  </div>
                                </FormGroup>
                              </Col>
                            </Row>

                            <Row>
                              <Col md="3">
                                <FormGroup className="mt-5">
                                  <Label>
                                    <strong>Approved Amount</strong> :{' '}
                                    {parseDollarString(bankWireAdvance?.approvedAmount)}
                                  </Label>
                                </FormGroup>
                              </Col>
                            </Row>

                            <Row>
                              <Col md="3">
                                <Button color="primary" type="submit">
                                  Save
                                </Button>{' '}
                                &nbsp;
                                <Button
                                  onClick={(e) => {
                                    if (advanceId) {
                                      history.push('../advances');
                                    } else {
                                      setSelectedAdvanceOption('');
                                    }
                                  }}
                                >
                                  Cancel
                                </Button>
                              </Col>
                            </Row>
                          </form>
                        </>
                      )}
                    </Col>
                  </Row>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default EditAdvance;
