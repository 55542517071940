import React, { useEffect, useContext, useRef, useState } from 'react';
import { Row, Col, Input, Label, Alert, Button, Form, FormGroup, Container } from 'reactstrap';
import AppContext from '@contexts/AppContext';
import MultiSelect from "react-multi-select-component";
import PortfolioService from '@services/PortfolioService';
import GridService from '@services/GridService';

import './Styles.css';

const FilteringFields = (props) => {
  let dateFilter = useRef();
  let applicationNoFilter = useRef();         

  let accidentTypeFilter = useRef();
  let paymentConfirmation = useRef();

  const clearFilters = () => {
    setDateValue("This month");
    dateFilter.current.value = "This month";
    appContext.accidentSummaryReportFilters = {};
    setDaterange(false);
    setFromDate(null);
    setToDate(null);
    setStatusValue([]);
    setCaseStatus([]);
    setPortfolio([]);

    props.getAll();
  };
  const [caseStatus, setCaseStatus] = useState([]);
  const [statusValue, setStatusValue] = useState([]);
  const [caseStatusOptions, setCaseStatusOptions] = useState([]);
  const [portfolio, setPortfolio] = useState([]);
  const [portfolioOptions, setPortfolioOptions] = useState([])
  const [dateRange, setDaterange] = useState(false);
  const [dateValue, setDateValue] = useState("This month");
  const [fromDate, setFromDate] = useState(null);
  const [toDate, setToDate] = useState(null);
  const [attorney, setAttorney] = useState({
    displayName: '',
    attorneyPublicId: '',
  });
  const [appStatusOptions, setAppStatusOptions] = useState([]);
  function changeFunc() {
    const dateFilter = document.getElementById("dateFilter");
    const selectedDate = dateFilter.options[dateFilter.selectedIndex].value;
    if (selectedDate === "Custom") {
      setDaterange(true);
      setDateValue(null);
    }
    else {
      setDaterange(false);
      setDateValue(selectedDate);
      setFromDate(null);
      setToDate(null);
    }
  }
  function changeFromDate() {
    const fromDate = document.getElementById("fromDate");
    const selectedFromDate = fromDate.value;
    setFromDate(selectedFromDate);
  }
  function changeToDate() {
    const toDate = document.getElementById("toDate");
    const selectedToDate = toDate.value;
    if (fromDate < selectedToDate) {
      setToDate(selectedToDate);
    }
    else {
      if (fromDate != null) {
      }
      else {
        alert("Select from date first");
      }
    }
  }

  function selectChanage () {
    const accidentType = document.getElementById('accidentTypeFilter');
    const selectedAccidentType = accidentType.options[accidentType.selectedIndex].value;
    localStorage.setItem('accidentType',selectedAccidentType);
  }


  function selectDateRange() {
    if (dateValue != null) {
      return dateValue;
    }
    else {
      if (fromDate != null && toDate != null) {
        let dateRange = {
          fromDate: new Date(fromDate).toISOString(),
          toDate: new Date(toDate).toISOString()
        };
        return dateRange;
      }
      else {
        return '';
      }
    }
  }
  const appContext = useContext(AppContext);
  const accidentSummaryReportFilters = appContext.accidentSummaryReportFilters;
  useEffect(() => {
    const isFiltersEmpty = Object.keys(accidentSummaryReportFilters).length == 0;

        let tempStatusOptions = [];
        tempStatusOptions = [
            {
                "label": "All Applications",
                "value": "All Applications"
            },
            {
                "label": "Active Advance Recievable Only",
                "value": "Active Advance Recievable Only"
            }
        ]
          setAppStatusOptions(tempStatusOptions);


    //Get case status options
    GridService.read('/Application/applicationcasestatuses').then((resp) => {
      if (!resp.data) return;
      if (resp.data.length > 0) {
        let tempCaseStatusOptions = [];
        resp.data.map((item) => {
          tempCaseStatusOptions.push({
            "label": item,
            "value": item
          })
        });
        setCaseStatusOptions(tempCaseStatusOptions);
      }
    });

     //Get portfolio list
     PortfolioService.search({}, "", "", 1, null, 50).then((response) => {
        let portfolioList = [];
        if (!response.data) return;
        if (response.data.data.length > 0) {
          response.data.data.map((item) => {
            portfolioList.push({
              label: item.portfolioName,
              value: item.publicId
            });
          });
          if (!isFiltersEmpty) {
            if (accidentSummaryReportFilters.portfolioName) {
              if (portfolioList.length > 0) {
                let tempportfolioName = [];
                accidentSummaryReportFilters.portfolioName.map((item) => {
                  const portfolio = portfolioList.find((portfolio) => portfolio.value === item);
                  tempportfolioName.push({
                    "label": portfolio.label,
                    "value": item
                  });
                });
                setPortfolio(tempportfolioName);
              }
            }
          }
        }
        setPortfolioOptions(portfolioList);
      });
    //Retaining data by checking with appcontext
    if (!isFiltersEmpty) {
      // applicationNoFilter.current.value = accidentSummaryReportFilters.applicationNo ? accidentSummaryReportFilters.applicationNo : ''
      // accidentTypeFilter.current.value = accidentSummaryReportFilters.accidentTypeName ? accidentSummaryReportFilters.accidentTypeName : '';
      // paymentConfirmation.current.value = accidentSummaryReportFilters.paymentConfinrmationNo ? accidentSummaryReportFilters.paymentConfinrmationNo : ''
      if (accidentSummaryReportFilters.dateRange) {
        if (typeof (accidentSummaryReportFilters.dateRange) == "string") {
          dateFilter.current.value = accidentSummaryReportFilters.dateRange;
          setDateValue(accidentSummaryReportFilters.dateRange);
          setDaterange(false);
          setFromDate(null);
          setToDate(null);
        }
        else {
          dateFilter.current.value = "Custom";
          setDaterange(true);
          setDateValue("This month");
          setFromDate(accidentSummaryReportFilters.dateRange.fromDate.split('T')[0]);
          setToDate(accidentSummaryReportFilters.dateRange.toDate.split('T')[0]);
        }
      }
      if (accidentSummaryReportFilters.applicationStatus) {
        let tempAppStatus = [];
        accidentSummaryReportFilters.applicationStatus.map((item) => {
          tempAppStatus.push({
            "label": item,
            "value": item
          });
        });
        setStatusValue(tempAppStatus);
      }
      if (accidentSummaryReportFilters.caseStatus) {
        let tempcaseStatus = [];
        accidentSummaryReportFilters.caseStatus.map((item) => {
          tempcaseStatus.push({
            "label": item,
            "value": item
          });
        });
        setCaseStatus(tempcaseStatus);
      }
      if (accidentSummaryReportFilters.portfolioName) {
        let tempportfolioName = [];
        accidentSummaryReportFilters.portfolioName.map((item) => {
          tempportfolioName.push({
            "label": item,
            "value": item
          });
        });
        setPortfolio(tempportfolioName);
      }
    }
  }, [])




  const search = () => {
    const tempStatusValue = [];
    const tempCaseStatus = [];
    const tempPortfolio = [];

    statusValue.map((status) => {
      tempStatusValue.push(status.value);
    });

    caseStatus.map((status) => {
      tempCaseStatus.push(status.value);
    });

    portfolio.map((port) => {
      tempPortfolio.push(port.value);
    });

    let filters = {
      'dateRange': selectDateRange(),
      'PortfolioPublicId ': tempPortfolio.length > 0 ? tempPortfolio : null
    };

    if (filters.dateRange === '') delete filters.dateRange;

    props.searchWithFilters(filters);
  }

  return (
    <>
      <Col md="3" className="float-left typesOfAccidentSummary" style={{ backgroundColor: '#e9e9e9' }}>
        <Row className="mt-5">
          <Col md="6">
            <h2>Filters</h2>
          </Col>
        </Row>

        <Row className="mt-5">
          <Col md="12">
            <label htmlFor="dateRange">Advance Date Range</label>
          </Col>
          <Col md="12">
            <select id="dateFilter" onChange={changeFunc} className="form-control" ref={dateFilter}>
              <option value="This month">This month</option>
              <option value="Today">Today</option>
              <option value="Yesterday">Yesterday</option>
              <option value="This week">This week</option>
              <option value="Last week">Last week</option>
              <option value="Last month">Last month</option>
              <option value="YTD">YTD</option>
              <option value="Custom">Custom</option>
            </select>
          </Col>
        </Row>
        {dateRange == true &&
          <>
            <Row className="mt-5">
              <Col md="12">
                <label htmlFor="dateRange">From</label>
              </Col>
              <Col md="12">
                <input
                  onChange={changeFromDate}
                  type="date"
                  id="fromDate"
                  className="form-control"
                  required="required"
                  defaultValue={fromDate}
                />
              </Col>
            </Row>
            <Row className="mt-5">
              <Col md="12">
                <label htmlFor="dateRange">To</label>
              </Col>
              <Col md="12">
                <input
                  onChange={changeToDate}
                  type="date"
                  id="toDate"
                  className="form-control"
                  required="required"
                  defaultValue={toDate}
                />
              </Col>
            </Row>
          </>
        }

        <Row className="mt-5">
          <Col md="12">
            <label htmlFor="status">Portfolio</label>
          </Col>
          <Col md="12">
            <div>
              <MultiSelect
                value={portfolio}
                onChange={setPortfolio}
                labelledBy={"Portfolio"}
                options={portfolioOptions}
              />
            </div>
          </Col>
        </Row>


        <Row className="mt-5">
          <Col md="12">
            <label htmlFor="accidentTypeFilter">Application Status</label>
          </Col>
          <Col md="12">
            <select id="accidentTypeFilter"
            onChange={selectChanage}
                className="form-control">
                    {appStatusOptions.map((option) => (
                        <option 
                            value={option.value}>
                            {option.label}
                        </option>
                    ))}
            </select>
          </Col>
        </Row>

        <Row className="mt-5 mb-5">
          <Col md="6" className="pr-0">
            <span className="a-link">
              <a className="btn clear-filter-btn" onClick={clearFilters}>
                Clear filters
              </a>
            </span>
          </Col>
          <Col md="6">
            <span className="a-link float-right">
              <a className="btn btn-primary search-btn" onClick={search}>
                <i class="fas fa-search"></i>
                Search
              </a>
            </span>
          </Col>
        </Row>
      </Col>
    </>
  );
};

export default FilteringFields;

