/* eslint-disable jsx-a11y/label-has-associated-control */
import React, { useContext, useEffect, useState, useRef } from 'react';
import { Link, NavLink, useHistory } from 'react-router-dom';
import { Row, Col, Input, Label, Alert, Button, Form, FormGroup, Container } from 'reactstrap';
import AppContext from '@contexts/AppContext';
import ProcessingFeeRatesTable from './ProcessingFeeRatesTable';
import MultiplierPercentagesTable from './MultiplierPercentagesTable';
import MiscSettingsService from '@services/MiscSettingsService';
import { useForm } from 'react-hook-form';
import { toast } from 'react-toastify';
import { ErrorMessage } from '@hookform/error-message';
import ReactLoading from 'react-loading';

const MiscSettings = () => {
  const [tabNumber, setTabNumber] = React.useState(1);
  const history = useHistory();
  const [isLoading, setIsLoading] = useState(false);
  const { register, handleSubmit, watch, errors, formState } = useForm({ mode: 'onBlur' });
  const appContext = useContext(AppContext);

  const [miscSettings, setMiscSettings] = useState({
    overnightCheckFee: '',
    publicId: '',
  });

  const makeMenuItemActive = () => {
    document.querySelector('#menuitem-settings').click();
    document
      .querySelector('#kt_header_tab_8 > div > div > ul > li:nth-child(3)')
      .classList.add('menu-item-active');
    document.querySelector('#applications-new-menu > li:nth-child(1) > a').click();
  };

  useEffect(() => {
    makeMenuItemActive();

    setIsLoading(true);

    MiscSettingsService.search({}, 1, {})
      .then((response) => {
        setMiscSettings(response?.data?.data[0]);
      })
      .catch((err) => {})
      .finally(() => {
        setIsLoading(false);
      });
  }, []);

  const saveMiscSetting = (data) => {
    if (!(appContext?.userPermissions?.indexOf('Edit Misc. Settings') > -1)) {
      appContext.toastMessage.message = 'You do not have permissions to perform this action.';
      toast.error(appContext.toastMessage.message, { autoClose: 3000 });
      appContext.toastMessage.message = null;
      return;
    }

    setIsLoading(true);

    var reqData = {
      publicId: miscSettings.publicId,
      startApplicationIdFrom: miscSettings.startApplicationIdFrom,
      overnightCheckFee: miscSettings.overnightCheckFee,
      accruedPercentage: miscSettings.accruedPercentage,
      bankWireFee: miscSettings.bankWireFee,
      isActive: miscSettings.isActive,
    };

    MiscSettingsService.update(reqData)
      .then((response) => {
        toast('Other Settings have been successfully updated.', { type: 'success' });
      })
      .catch((err) => {})
      .finally(() => {
        setIsLoading(false);
      });
  };

  if (isLoading) {
    return (
      <div className="container mt-10 mb-10">
        <div className="row">
          <div className="col-md-12 mx-auto bg-white">
            <div className="p-10">
              <div className="mt-5 mb-10">
                <ReactLoading type="bars" color="#7E8299" />
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }

  return (
    <div className="container mt-10 mb-10">
      <div className="row">
        <div className="col-md-12 bg-white">
          <div className="p-10">
            <div className="d-flex mt-5 mb-10">
              <div className="mr-auto">
                <h1>Settings</h1>
              </div>
            </div>

            <ul id="applications-new-menu" className="nav nav-tabs nav-tabs-line mb-5">
              <li className="nav-item">
                <Link
                  onClick={() => {
                    setTabNumber(1);
                  }}
                  className="nav-link"
                >
                  Processing Fee Rates
                </Link>
              </li>
              <li className="nav-item">
                <Link
                  onClick={() => {
                    setTabNumber(2);
                  }}
                  className="nav-link"
                >
                  Multiplier Percentages
                </Link>
              </li>
              <li className="nav-item">
                <Link
                  onClick={() => {
                    setTabNumber(3);
                  }}
                  className="nav-link"
                >
                  Other Settings
                </Link>
              </li>
            </ul>

            {tabNumber == 1 && (
              <div className="p-0">
                <ProcessingFeeRatesTable />
              </div>
            )}

            {tabNumber == 2 && (
              <div>
                <MultiplierPercentagesTable percentage={miscSettings} />
              </div>
            )}

            {tabNumber == 3 && (
              <div>
                <Row>
                  <Col md="12">
                    <h2 className="mt-3 mb-5">Other Settings</h2>
                  </Col>
                </Row>

                <form onSubmit={handleSubmit(saveMiscSetting)}>
                  <Row>
                    <div className="col-md-2 mb-3">
                      <FormGroup>
                        <Label for="overnight-check-fee">Overnight Check Fee</Label>
                        <input
                          id="overnight-check-fee"
                          name="overnight-check-fee"
                          type="number"
                          className="form-control"
                          style={{ width: '150px' }}
                          ref={register({ required: 'Overnight Check Fee is required.' })}
                          defaultValue={miscSettings.overnightCheckFee}
                          onChange={(e) => {
                            setMiscSettings({
                              ...miscSettings,
                              overnightCheckFee: e.target.valueAsNumber,
                            });
                          }}
                        />
                        {errors['overnight-check-fee'] && (
                          <Alert color="danger" className="mt-5">
                            <ErrorMessage errors={errors} name="overnight-check-fee" />
                          </Alert>
                        )}
                      </FormGroup>
                    </div>

                    <div className="col-md-2 mb-3">
                      <FormGroup>
                        <Label for="bankWireFee">Bank Wire Fee</Label>
                        <input
                          type="number"
                          id="bankWireFee"
                          name="bankWireFee"
                          ref={register({ required: 'Bank Wire Fee is required.' })}
                          onChange={(e) => {
                            setMiscSettings({
                              ...miscSettings,
                              bankWireFee: e.target.valueAsNumber,
                            });
                          }}
                          style={{ width: '150px' }}
                          className="form-control"
                          defaultValue={miscSettings.bankWireFee}
                        />
                        {errors['bankWireFee'] && (
                          <Alert color="danger" className="mt-5">
                            <ErrorMessage errors={errors} name="bankWireFee" />
                          </Alert>
                        )}
                      </FormGroup>
                    </div>
                  </Row>

                  <Row>
                    <div className="col-md-2 mb-3">
                      <FormGroup>
                        <Label for="nextApplicationId">Next Application ID</Label>
                        <input
                          type="number"
                          id="nextApplicationId"
                          name="nextApplicationId"
                          ref={register({ required: 'Next Application ID is required.' })}
                          onChange={(e) => {
                            setMiscSettings({
                              ...miscSettings,
                              startApplicationIdFrom: e.target.valueAsNumber,
                            });
                          }}
                          style={{ width: '150px' }}
                          className="form-control"
                          defaultValue={miscSettings.startApplicationIdFrom}
                        />
                        {errors['nextApplicationId'] && (
                          <Alert color="danger" className="mt-5">
                            <ErrorMessage errors={errors} name="nextApplicationId" />
                          </Alert>
                        )}
                      </FormGroup>
                    </div>

                    <div className="col-md-2 mb-3">
                      <FormGroup>
                        <Label for="accruedPercentage">Accrued Percentage</Label>
                        <input
                          type="number"
                          id="accruedPercentage"
                          name="accruedPercentage"
                          ref={register({ required: 'Accrued Percentage is required.' })}
                          onChange={(e) => {
                            setMiscSettings({
                              ...miscSettings,
                              accruedPercentage: e.target.valueAsNumber,
                            });
                          }}
                          style={{ width: '150px' }}
                          max="1.00"
                          className="form-control"
                          defaultValue={miscSettings.accruedPercentage}
                        />
                        {errors['accruedPercentage'] && (
                          <Alert color="danger" className="mt-5">
                            <ErrorMessage errors={errors} name="accruedPercentage" />
                          </Alert>
                        )}
                      </FormGroup>
                    </div>
                  </Row>

                  <Row>
                    <Col md="12">
                      <button className="btn btn-primary mt-2">
                        Save{' '}
                        {/*loadingSave && <span class="spinner-border spinner-border-sm ml-2" role="status"></span>*/}
                      </button>
                    </Col>
                  </Row>
                </form>
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default MiscSettings;
