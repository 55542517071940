import React, { useEffect, useContext, useRef, useState } from 'react';
import { Row, Col, Input, Label, Alert, Button, Form, FormGroup, Container } from 'reactstrap';
import { useHistory, Link, useParams } from 'react-router-dom';
import AppContext from '@contexts/AppContext';
import MultiSelect from 'react-multi-select-component';
import AttorneyAutoSuggest from '@components/AttorneyAutoSuggest/AttorneyAutoSuggest';
import LawFirmAutoSuggest from '@components/LawFirmAutoSuggest/LawFirmAutoSuggest';
import ClientsAutoSuggest from '@components/ClientsAutoSuggest/ClientsAutoSuggest';
import PortfolioService from '@services/PortfolioService';
import GridService from '@services/GridService';
import AccidentTypeService from '@services/AccidentTypeService';
import DateRange from '@components/Filtering/DateRange/DateRange';
import ApplicationAdvanceService from '@services/ApplicationAdvanceService';
import './Styles.css';

const GlobalFilteringFields = (props) => {
  const {
    appContextFilter,
    searchWithFilters,
    getAll,
    defaultApplicationStatus,
    defaultAdvanceStatus,
  } = props;

  let clientFilter = useRef();
  let applicationNoFilter = useRef();
  let advanceNoFilter = useRef();
  let applicationStatusFilter = useRef();
  let advancestatusFilter = useRef();
  let caseStatusFilter = useRef();
  let attorneyFilter = useRef();
  let lawFirmFilter = useRef();
  let accidentTypeFilter = useRef();
  let paymentConfirmation = useRef();

  const appContext = useContext(AppContext);
  const { lawFirm, lawFirmPublicId } = useParams();
  const [caseStatus, setCaseStatus] = useState([]);
  const [applicationStatusValue, setApplicationStatusValue] = useState([]);
  const [advanceStatusValue, setAdvanceStatusValue] = useState([]);
  const [caseStatusOptions, setCaseStatusOptions] = useState([]);
  const [accidentTypes, setAccidentTypes] = useState([]);
  const [accidentTypesOptions, setAccidentTypesOptions] = useState([]);
  const [portfolio, setPortfolio] = useState([]);
  const [portfolioOptions, setPortfolioOptions] = useState([]);
  const [dateRange, setDaterange] = useState(false);
  const [dateValue, setDateValue] = useState('Custom');
  const [fromDate, setFromDate] = useState(null);
  const [toDate, setToDate] = useState(null);
  const [selectedAttorney, setSelectedAttorney] = useState({});
  const [selectedAttorneyError, setSelectedAttorneyError] = useState(undefined);
  const [selectedLawFirm, setSelectedLawFirm] = useState({});
  const [selectedClient, setSelectedClient] = useState({});
  const [appStatusOptions, setAppStatusOptions] = useState([]);
  const [advanceStatusOptions, setAdvanceStatusOptions] = useState([]);

  const defaultFromDate = () => {
    let today = new Date();
    let year = today.getFullYear();

    let fromDate = new Date(2000, 0, 1).toISOString().split('T')[0];
    return fromDate;
  };

  const defaultToDate = () => {
    let today = new Date();
    let month = today.getMonth();

    var lastDayOfMonth = new Date();
    lastDayOfMonth.setMonth(month + 1);
    lastDayOfMonth.setDate(1);
    lastDayOfMonth.setHours(-5);

    let toDate = lastDayOfMonth.toISOString().split('T')[0];
    return toDate;
  };

  const clearFilters = () => {
    setDateValue('Custom');
    appContext.accountsActiveAdvanceReceivablesFilters = {};

    setDaterange(false);
    setFromDate(defaultFromDate());
    setToDate(defaultToDate());
    setSelectedClient({});
    applicationNoFilter.current.value = '';
    advanceNoFilter.current.value = '';

    setApplicationStatusValue([]);
    defaultApplicationStatus.map((stat) => {
      let newItem = {
        label: stat,
        value: stat,
      };
      setApplicationStatusValue((statusValue) => [newItem]);
    });

    setAdvanceStatusValue([]);
    defaultAdvanceStatus.map((stat) => {
      let newItem = {
        label: stat,
        value: stat,
      };
      setAdvanceStatusValue((statusValue) => [newItem]);
    });

    setCaseStatus([]);
    setAccidentTypes([]);
    setPortfolio([]);
    setSelectedAttorney({});
    setSelectedLawFirm({});
    accidentTypeFilter.current.value = '';
    paymentConfirmation.current.value = '';

    let dateRange = {
      fromDate: new Date(defaultFromDate()).toISOString(),
      toDate: new Date(defaultToDate()).toISOString(),
    };

    getAll(dateRange);
  };

  function selectDateRange() {
    if (dateValue !== 'Custom') {
      return dateValue;
    } else {
      if (fromDate != null && toDate != null) {
        let dateRange = {
          fromDate: new Date(fromDate).toISOString(),
          toDate: new Date(toDate).toISOString(),
        };
        return dateRange;
      } else {
        return '';
      }
    }
  }

  // const appContextFilter =appContext.accountsActiveAdvanceReceivablesFilters;

  useEffect(() => {
    setFromDate(defaultFromDate());
    setToDate(defaultToDate());
    setDaterange(null);

    const isFiltersEmpty = Object.keys(appContextFilter).length == 0;

    //Get application status options
    GridService.read('/Application/applicationstatuses').then((resp) => {
      if (!resp.data) return;
      if (resp.data.length > 0) {
        let tempStatusOptions = [];
        resp.data.map((item) => {
          tempStatusOptions.push({
            label: item,
            value: item,
          });
          setAppStatusOptions(tempStatusOptions);
        });
        if (
          props.defaultApplicationStatus &&
          props.defaultApplicationStatus !== '' &&
          props.defaultApplicationStatus.length > 0
        ) {
          setApplicationStatusValue([]);
          props.defaultApplicationStatus.map((stat) => {
            let newItem = {
              label: stat,
              value: stat,
            };
            setApplicationStatusValue((statusValue) => [...statusValue, newItem]);
          });
        }
      }
    });

    // Get Advance Statuses
    ApplicationAdvanceService.advancestatuses().then((response) => {
      if (!response.data) return;
      if (response.data.length > 0) {
        let tempStatusOptions = [];
        response.data.map((item) => {
          tempStatusOptions.push({
            label: item,
            value: item,
          });
          setAdvanceStatusOptions(tempStatusOptions);
        });
        if (
          props.defaultAdvanceStatus &&
          props.defaultAdvanceStatus !== '' &&
          props.defaultAdvanceStatus.length > 0
        ) {
                setAdvanceStatusValue([]);
                props.defaultAdvanceStatus.map((stat) => {
                    let newItem = {
                        label: stat,
                        value: stat,
                    };
                    setAdvanceStatusValue((statusValue) => [...statusValue, newItem]);
                });
            }
        }
    });

    //Get case status options

    let tempCaseStatusOptions = [];

    appContext.applicationCaseStatuses.map((item) => {
      tempCaseStatusOptions.push({
        label: item,
        value: item,
      });
    });

    setCaseStatusOptions((prevData) => ({ ...prevData, tempCaseStatusOptions }));

    //Get portfolio list
    PortfolioService.search({}, '', '', 1, null, 50).then((response) => {
      let portfolioList = [];
      if (!response.data) return;
      if (response.data.data.length > 0) {
        response.data.data.map((item) => {
          portfolioList.push({
            label: item.portfolioName,
            value: item.publicId,
          });
        });
        if (!isFiltersEmpty) {
          if (appContextFilter.portfolioName) {
            if (portfolioList.length > 0) {
              let tempportfolioName = [];
              appContextFilter.portfolioName.map((item) => {
                const portfolio = portfolioList.find((portfolio) => portfolio.value === item);
                tempportfolioName.push({
                  label: portfolio.label,
                  value: item,
                });
              });
              setPortfolio(tempportfolioName);
            }
          }
        }
      }
      setPortfolioOptions(portfolioList);
    });
    //Get Accident types
    AccidentTypeService.search2({}, '', '', 1, null, 100).then((response) => {
      let accidentTypesList = [];
      if (!response.data) return;
      if (response.data.data.length > 0) {
        setAccidentTypesOptions(response.data.data);
      }
      if (appContextFilter?.accidentTypeName) {
        accidentTypeFilter.current.value = appContextFilter.accidentTypeName
          ? appContextFilter.accidentTypeName
          : '';
      }
    });

    //Retaining data by checking with appcontext
    if (!isFiltersEmpty) {
      applicationNoFilter.current.value = appContextFilter.applicationNo
        ? appContextFilter.applicationNo
        : '';
      advanceNoFilter.current.value = appContextFilter.advanceNo ? appContextFilter.advanceNo : '';
      accidentTypeFilter.current.value = appContextFilter.accidentTypeName
        ? appContextFilter.accidentTypeName
        : '';
      paymentConfirmation.current.value = appContextFilter.paymentConfinrmationNo
        ? appContextFilter.paymentConfinrmationNo
        : '';
      if (appContextFilter.dateRange) {
        if (typeof appContextFilter.dateRange == 'string') {
          setDateValue(appContextFilter.dateRange);
          setDaterange(false);
          setFromDate(null);
          setToDate(null);
        } else {
          setDaterange(true);
          setDateValue(null);
          setFromDate(appContextFilter.dateRange.fromDate.split('T')[0]);
          setToDate(appContextFilter.dateRange.toDate.split('T')[0]);
        }
      }
      if (appContextFilter.applicationStatus) {
        let tempAppStatus = [];
        appContextFilter.applicationStatus.map((item) => {
          tempAppStatus.push({
            label: item,
            value: item,
          });
        });
        setApplicationStatusValue(tempAppStatus);
      }
      if (appContextFilter.advanceStatus) {
        let tempAdvanceStatus = [];
        appContextFilter.advanceStatus.map((item) => {
            tempAdvanceStatus.push({
            label: item,
            value: item,
          });
        });
        setAdvanceStatusValue(tempAdvanceStatus);
      }
      if (appContextFilter.caseStatus) {
        let tempcaseStatus = [];
        appContextFilter.caseStatus.map((item) => {
          tempcaseStatus.push({
            label: item,
            value: item,
          });
        });
        setCaseStatus(tempcaseStatus);
      }
      if (appContextFilter.portfolioName) {
        let tempportfolioName = [];
        appContextFilter.portfolioName.map((item) => {
          tempportfolioName.push({
            label: item,
            value: item,
          });
        });
        setPortfolio(tempportfolioName);
      }

      if (appContextFilter.client) {
        setSelectedClient(appContextFilter.client);
      }
      if (appContextFilter.attorney) {
        setSelectedAttorney(appContextFilter.attorney);
      }
      if (appContextFilter.lawFirm) {
        setSelectedLawFirm(appContextFilter.lawFirm);
      }
    }
  }, []);

  const search = () => {
    const tempAppStatusValue = [];
    const tempAdvanceStatusValue = [];
    const tempCaseStatus = [];
    const tempPortfolio = [];

    applicationStatusValue.map((status) => {
      tempAppStatusValue.push(status.value);
    });
    advanceStatusValue.map((status) => {
      tempAdvanceStatusValue.push(status.value);
    });
    caseStatus.map((status) => {
      tempCaseStatus.push(status.value);
    });
    portfolio.map((port) => {
      tempPortfolio.push(port.value);
    });
    let filters = {
      dateRange: selectDateRange(),
      client: selectedClient,
      applicationNo: applicationNoFilter.current.value,
      advanceNo: advanceNoFilter.current.value,
      applicationStatus: tempAppStatusValue.length > 0 ? tempAppStatusValue : null,
      advanceStatus: tempAdvanceStatusValue.length > 0 ? tempAdvanceStatusValue : null,
      caseStatus: tempCaseStatus.length > 0 ? tempCaseStatus : null,
      attorney: selectedAttorney,
      lawFirm: selectedLawFirm,
      accidentTypeName: accidentTypeFilter.current.value,
      paymentConfinrmationNo: paymentConfirmation?.current?.value,
      portfolioName: tempPortfolio.length > 0 ? tempPortfolio : null,
    };

    if (filters.dateRange === '') delete filters.dateRange;

    searchWithFilters(filters);
  };

  return (
    <>
      <Col md="2" className="float-left" style={{ backgroundColor: '#e9e9e9' }}>
        <Row className="mt-5">
          <Col md="6">
            <h2>Filters</h2>
          </Col>
        </Row>
        {/* Test */}

        <DateRange
          setDateValue={setDateValue}
          dateValue={dateValue}
          setDateValue={setDateValue}
          fromDate={fromDate}
          setFromDate={setFromDate}
          toDate={toDate}
          setToDate={setToDate}
        />

        <Row className="mt-5">
          <Col md="12">
            <label htmlFor="client">Client</label>
          </Col>
          <Col md="12">
            <ClientsAutoSuggest
              ref={clientFilter}
              selectedClient={(value) => {
                setSelectedClient(value);
              }}
              selectedClientName={(value) => {}}
              defaultClient={selectedClient}
            />
          </Col>
        </Row>

        <Row className="mt-5">
          <Col md="12">
            <label htmlFor="status">Application #</label>
          </Col>
          <Col md="12">
            <input
              type="search"
              name="status"
              ref={applicationNoFilter}
              className="form-control"
            ></input>
          </Col>
        </Row>

        <Row className="mt-5">
          <Col md="12">
            <label htmlFor="status">Advance #</label>
          </Col>
          <Col md="12">
            <input
              type="search"
              name="status"
              ref={advanceNoFilter}
              className="form-control"
            ></input>
          </Col>
        </Row>

        <Row className="mt-5">
          <Col md="12">
            <label htmlFor="status">Application Status</label>
          </Col>
          <Col md="12">
            <div ref={applicationStatusFilter}>
              <MultiSelect
                value={applicationStatusValue}
                onChange={setApplicationStatusValue}
                labelledBy={'Application Status'}
                options={appStatusOptions}
              />
            </div>
          </Col>
        </Row>

        <Row className="mt-5">
          <Col md="12">
            <label htmlFor="status">Advance Status</label>
          </Col>
          <Col md="12">
            <div ref={advancestatusFilter}>
              <MultiSelect
                value={advanceStatusValue}
                onChange={setAdvanceStatusValue}
                labelledBy={'Advance Status'}
                options={advanceStatusOptions}
              />
            </div>
          </Col>
        </Row>

        <Row className="mt-5">
          <Col md="12">
            <label htmlFor="status">Case Status</label>
          </Col>
          <Col md="12">
            <div ref={caseStatusFilter}>
              <MultiSelect
                value={caseStatus}
                onChange={setCaseStatus}
                labelledBy={'Case Status'}
                options={caseStatusOptions}
              />
            </div>
          </Col>
        </Row>

        <Row className="mt-5">
          <Col md="12">
            <label htmlFor="status">Portfolio</label>
          </Col>
          <Col md="12">
            <div>
              <MultiSelect
                value={portfolio}
                onChange={setPortfolio}
                labelledBy={'Portfolio'}
                options={portfolioOptions}
              />
            </div>
          </Col>
        </Row>

        <Row className="mt-5">
          <Col md="12">
            <label htmlFor="attorney">Attorney</label>
          </Col>
          <Col md="12">
            <AttorneyAutoSuggest
              ref={attorneyFilter}
              selectedAttorney={(value) => {
                setSelectedAttorney(value);
              }}
              selectedAttorneyName={() => {}}
              defaultAttorney={selectedAttorney}
            />
            {selectedAttorneyError && (
              <Alert color="danger" className="mt-5">
                <span>Select an attorney</span>
              </Alert>
            )}
          </Col>
        </Row>

        <Row className="mt-5">
          <Col md="12">
            <label htmlFor="lawFirm">Law Firm</label>
          </Col>
          <Col md="12">
            <LawFirmAutoSuggest
              ref={lawFirmFilter}
              selectedLawFirm={(value) => {
                setSelectedLawFirm(value);
              }}
              selectedLawFirmName={() => {}}
              defaultLawFirm={selectedLawFirm}
            />
          </Col>
        </Row>

        <Row className="mt-5">
          <Col md="12">
            <label htmlFor="status">Type of Accident</label>
          </Col>
          <Col md="12">
            <select className="form-control" ref={accidentTypeFilter}>
              <option value="">Select Accident Type</option>
              {accidentTypesOptions.map((item) => {
                return <option value={item.accidentTypeName}>{item.accidentTypeName}</option>;
              })}
            </select>
          </Col>
        </Row>

        <Row className="mt-5 mb-5">
          <Col md="12">
            <label htmlFor="status">Payment Confirmation #</label>
          </Col>
          <Col md="12">
            <input
              type="text"
              ref={paymentConfirmation}
              name="status"
              className="form-control"
            ></input>
          </Col>
        </Row>

        <Row className="mt-5 mb-5">
          <Col md="7">
            <span className="a-link">
              <a
                className="btn"
                style={{ color: '#000000', background: 'white' }}
                onClick={clearFilters}
              >
                Clear filters
              </a>
            </span>
          </Col>
          <Col md="4">
            <span className="row">
              <a className="btn btn-primary row" style={{ color: '#fff' }} onClick={search}>
                <i className="fas fa-search"></i>
                Search
              </a>
            </span>
          </Col>
        </Row>
      </Col>
    </>
  );
};

export default GlobalFilteringFields;
