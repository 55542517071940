import React from 'react';
import Autosuggest from 'react-autosuggest';
import LawFirmService from '../../services/LawFirmService';

class LawFirmAutoSuggest extends React.Component {

    constructor(props) {
        super(props);
        this.props = props;
        this.state = {
            value: '',
            suggestions: [],
        };
    }

    getSuggestionValue = ((suggestion) => {
        this.props.selectedLawFirm(suggestion);
        this.props.selectedLawFirmName(suggestion.lawFirmName);
        return suggestion.lawFirmName;
    });

    renderSuggestion = ((suggestion) => {
        return (
            <span>{suggestion.lawFirmName}</span>
        );
    });

    onChange = (event, { newValue, method }) => {
        this.setState({
            value: newValue
        });
        if (!newValue || newValue == "") {
            this.props.selectedLawFirmName('');
            this.props.selectedLawFirm({});
        }
    };

    onSuggestionsFetchRequested = ({ value }) => {

        LawFirmService.autoSuggest(value).then((response) => {
            this.setState({ suggestions: response.data.data });
        });

    };

    onSuggestionsClearRequested = () => {
        this.setState({
            suggestions: []
        });
    };

    componentDidMount = () => {
        this.setState({ suggestions: [], value: this.props.defaultLawFirm?.lawFirmName ? this.props.defaultLawFirm.lawFirmName : '' });
    };

    componentDidUpdate(prevProps, prevState) {
        if (prevProps.defaultLawFirm != this.props.defaultLawFirm) {
            this.setState({
                value: this.props.defaultLawFirm?.lawFirmName ? this.props.defaultLawFirm.lawFirmName : ''
            })
        }
    }

    render() {
        const { value, suggestions } = this.state;
        const inputProps = {
            placeholder: "Search Law Firm...",
            value,
            onChange: this.onChange
        };


        return (
            <Autosuggest
                suggestions={suggestions}
                onSuggestionsFetchRequested={this.onSuggestionsFetchRequested}
                onSuggestionsClearRequested={this.onSuggestionsClearRequested}
                getSuggestionValue={this.getSuggestionValue}
                renderSuggestion={this.renderSuggestion}
                inputProps={inputProps}
            />
        );
    }
}

export default LawFirmAutoSuggest;