import React, { useState, useEffect, useContext } from 'react';
import { useHistory, useParams } from 'react-router';
import { makeStyles, useTheme, withStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import { Row, Col } from 'reactstrap';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableFooter from '@material-ui/core/TableFooter';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import Pagination from '@material-ui/lab/Pagination';
import EditIcon from '@material-ui/icons/Edit';
import VApplicationsListDataService from '@services/VApplicationsListDataService';
import AppContext from '@contexts/AppContext';
import TableSortLabel from '@material-ui/core/TableSortLabel';
import ReactLoading from 'react-loading';
import Moment from 'moment';
import GlobalFilteringFields from '@components/Filtering/GlobalFilteringFields';

const StyledTableCell = withStyles((theme) => ({
  head: {
    backgroundColor: '#efefef',
    color: theme.palette.common.black,
  },
  body: {
    fontSize: 14,
  },
}))(TableCell);

const useStyles2 = makeStyles({
  table: {
    minWidth: 500,
  },
});

const moneyFormatter = new Intl.NumberFormat('en-US', {
  style: 'currency',
  currency: 'USD',
});

const DEFAULT_APP_STATUS = [];
const DEFAULT_ADV_STATUS = ['Active Advance Receivable'];

export default function CustomPaginationActionsTable() {
  const appContext = useContext(AppContext);
  const classes = useStyles2();
  const { lawFirm, lawFirmPublicId } = useParams();
  const [page, setPage] = React.useState(1);
  const [rowsPerPage, setRowsPerPage] = React.useState(15);
  const [filters, setFilters] = useState(appContext.accountsActiveAdvanceReceivablesFilters);
  const [orderBy, setOrderBy] = useState('');
  const [orderDir, setOrderDir] = useState('ASC');
  const [rows, setRows] = useState([]);
  const [modal, setModal] = useState(false);
  const [rowIndex, setRowIndex] = useState(undefined);
  const [givenRows, setGivenRows] = useState(undefined);
  const [isLoading, setIsLoading] = useState(false);
  const [pages, setPages] = useState(undefined);

  const [activeFieldForSorting, setActiveFieldForSorting] = useState();

  const toggle = () => setModal(!modal);
  const history = useHistory();

  useEffect(() => {
    let tempFilters = null;
    if (!filters.dateRange || filters.dateRange == '') {
      tempFilters = {
        ...filters,
        dateRange: 'This month',
        advanceStatus: DEFAULT_ADV_STATUS,
      };
    }

    if (lawFirm && lawFirmPublicId) {
      tempFilters = {
        ...tempFilters,
        lawFirm: { lawFirmName: lawFirm, publicId: lawFirmPublicId },
      };
      setFilters(tempFilters);
      appContext.accountsActiveAdvanceReceivablesFilters = tempFilters;
      localStorage.setItem('accountsActiveAdvanceReceivablesFilters', JSON.stringify(tempFilters));
      loadData(tempFilters);
    } else {
      if (tempFilters !== null) {
        setFilters(tempFilters);
        appContext.accountsActiveAdvanceReceivablesFilters = tempFilters;
        localStorage.setItem(
          'accountsActiveAdvanceReceivablesFilters',
          JSON.stringify(tempFilters)
        );
        loadData(tempFilters);
      } else {
        loadData(filters);
      }
    }
  }, [page, orderBy, orderDir, rowsPerPage]);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const loadData = (filters) => {
    setFilters(filters);

    console.log(appContext);

    filters.AccountManagerPublicId = appContext['user'].publicId;
    let reqFilters = filters;
    if (reqFilters && reqFilters.client) {
      reqFilters = { ...reqFilters, ClientPublicId: reqFilters.client.publicId };
    }
    if (reqFilters && reqFilters.attorney) {
      reqFilters = { ...reqFilters, AttorneyPublicId: reqFilters.attorney.publicId };
    }
    if (reqFilters && reqFilters.lawFirm) {
      reqFilters = { ...reqFilters, LawFirmPublicId: reqFilters.lawFirm.publicId };
    }
    if (reqFilters.applicationNo) reqFilters.ApplicationNo = reqFilters.applicationNo;
    if (reqFilters.advanceNo) reqFilters.AdvanceNo = reqFilters.advanceNo;
    if (reqFilters.caseStatus) reqFilters.ApplicationCaseStatus = reqFilters.caseStatus;
    if (reqFilters.portfolioName) reqFilters.PortfolioPublicId = reqFilters.portfolioName;
    if (reqFilters.accidentTypeName) reqFilters.AccidentTypeName = reqFilters.accidentTypeName;
    if (reqFilters.applicationStatus) reqFilters.ApplicationStatus = reqFilters.applicationStatus;
    if (reqFilters.advanceStatus) reqFilters.AdvanceStatus = reqFilters.advanceStatus;
    if (reqFilters.paymentConfinrmationNo)
      reqFilters.PaymentConfinrmationNo = reqFilters.paymentConfinrmationNo;

    delete reqFilters.applicationNo;
    delete reqFilters.advanceNo;
    delete reqFilters.client;
    delete reqFilters.caseStatus;
    delete reqFilters.lawFirm;
    delete reqFilters.attorney;
    delete reqFilters.portfolioName;
    delete reqFilters.accidentTypeName;
    delete reqFilters.applicationStatus;
    delete reqFilters.advanceStatus;
    delete reqFilters.paymentConfinrmationNo;

    setIsLoading(true);

    VApplicationsListDataService.search(reqFilters, orderBy, orderDir, page, null, rowsPerPage)
      .then((response) => {
        setRows(response.data.data);
        setPages(response.data.pages.slice(-1)[0]);
        setIsLoading(false);
      })
      .catch((err) => {})
      .finally(() => {
        setIsLoading(false);
      });
  };

  const getAll = () => {
    let tempFilters = {
      dateRange: 'This month',
      AdvanceStatus: ['Active Advance Receivable'],
    };

    setFilters(tempFilters);
    appContext.accountsActiveAdvanceReceivablesFilters = tempFilters;
    localStorage.setItem('accountsActiveAdvanceReceivablesFilters', JSON.stringify(tempFilters));

    searchWithFilters(tempFilters);
  };

  const update = (filters) => {
    filters.AccountManagerPublicId = appContext['user'].publicId;
    let reqFilters = filters;
    if (reqFilters && reqFilters.client) {
      reqFilters = { ...reqFilters, ClientPublicId: reqFilters.client.publicId };
    }
    if (reqFilters && reqFilters.attorney) {
      reqFilters = { ...reqFilters, AttorneyPublicId: reqFilters.attorney.publicId };
    }
    if (reqFilters && reqFilters.lawFirm) {
      reqFilters = { ...reqFilters, LawFirmPublicId: reqFilters.lawFirm.publicId };
    }
    if (reqFilters.applicationNo) reqFilters.ApplicationNo = reqFilters.applicationNo;
    if (reqFilters.advanceNo) reqFilters.AdvanceNo = reqFilters.advanceNo;
    if (reqFilters.caseStatus) reqFilters.ApplicationCaseStatus = reqFilters.caseStatus;
    if (reqFilters.portfolioName) reqFilters.PortfolioPublicId = reqFilters.portfolioName;
    if (reqFilters.accidentTypeName) reqFilters.AccidentTypeName = reqFilters.accidentTypeName;
    if (reqFilters.applicationStatus) reqFilters.ApplicationStatus = reqFilters.applicationStatus;
    if (reqFilters.advanceStatus) reqFilters.AdvanceStatus = reqFilters.advanceStatus;
    if (reqFilters.paymentConfinrmationNo)
      reqFilters.PaymentConfinrmationNo = reqFilters.paymentConfinrmationNo;

    delete reqFilters.applicationNo;
    delete reqFilters.advanceNo;
    delete reqFilters.client;
    delete reqFilters.caseStatus;
    delete reqFilters.lawFirm;
    delete reqFilters.attorney;
    delete reqFilters.portfolioName;
    delete reqFilters.accidentTypeName;
    delete reqFilters.applicationStatus;
    delete reqFilters.advanceStatus;
    delete reqFilters.paymentConfinrmationNo;

    setIsLoading(true);

    VApplicationsListDataService.search(reqFilters, orderBy, orderDir, page, null, rowsPerPage)
      .then((response) => {
        setRows(response.data.data);
        setPages(response.data.pages.slice(-1)[0]);
        setIsLoading(false);
      })
      .catch((err) => {})
      .finally(() => {
        setIsLoading(false);
      });
  };

  const searchWithFilters = (filters) => {
    var newFilters = { ...filters };
    setFilters(newFilters);
    appContext.accountsActiveAdvanceReceivablesFilters = newFilters;
    localStorage.setItem('accountsActiveAdvanceReceivablesFilters', JSON.stringify(newFilters));
    if (page === 1) update(newFilters);
    else setPage(1);
  };

  const arrange = (field) => {
    if (orderBy === field) {
      if (orderDir === 'ASC') {
        setOrderDir('DESC');
      } else {
        setOrderDir('ASC');
      }
    } else {
      setOrderBy(field);
      setOrderDir('ASC');
    }

    setPage(1);
  };

  const handlePageSizeChange = (event) => {
    setRowsPerPage(event.target.value);
  };

  return (
    <>
      <GlobalFilteringFields
        appContextFilter={filters}
        searchWithFilters={searchWithFilters}
        getAll={getAll}
        defaultApplicationStatus={DEFAULT_APP_STATUS}
        defaultAdvanceStatus={DEFAULT_ADV_STATUS}
      ></GlobalFilteringFields>

      <Col md="10" className="float-right">
        <TableContainer component={Paper}>
          <Table className={classes.table} aria-label="custom pagination table">
            <TableHead>
              <TableRow>
                <StyledTableCell>Id</StyledTableCell>

                <StyledTableCell style={{ width: '15%' }}>CLIENT</StyledTableCell>

                <StyledTableCell style={{ width: '8%' }}>APP STATUS</StyledTableCell>
                <StyledTableCell style={{ width: '8%' }}>ADV STATUS</StyledTableCell>

                <StyledTableCell
                  style={{ width: '8%' }}
                  onClick={() => {
                    arrange('applicationNo');
                    setActiveFieldForSorting('applicationNo');
                  }}
                >
                  APP #
                  <TableSortLabel
                    active={activeFieldForSorting === 'applicationNo' ? true : false}
                    direction={
                      activeFieldForSorting === 'applicationNo' ? orderDir.toLowerCase() : 'asc'
                    }
                    onClick={() => arrange('applicationNo')}
                  ></TableSortLabel>
                </StyledTableCell>

                <StyledTableCell
                  style={{ width: '8%' }}
                  onClick={() => {
                    arrange('advanceNo');
                    setActiveFieldForSorting('advanceNo');
                  }}
                >
                  ADV #
                  <TableSortLabel
                    active={activeFieldForSorting === 'advanceNo' ? true : false}
                    direction={
                      activeFieldForSorting === 'advanceNo' ? orderDir.toLowerCase() : 'asc'
                    }
                    onClick={() => arrange('advanceNo')}
                  ></TableSortLabel>
                </StyledTableCell>

                <StyledTableCell style={{ width: '10%' }}>TYPE OF ACCIDENT</StyledTableCell>

                <StyledTableCell
                  style={{ width: '10%' }}
                  onClick={() => {
                    arrange('applicationDate');
                    setActiveFieldForSorting('applicationDate');
                  }}
                >
                  ADV. DATE
                  <TableSortLabel
                    active={activeFieldForSorting === 'applicationDate' ? true : false}
                    direction={
                      activeFieldForSorting === 'applicationDate' ? orderDir.toLowerCase() : 'asc'
                    }
                    onClick={() => arrange('applicationDate')}
                  ></TableSortLabel>
                </StyledTableCell>

                <StyledTableCell style={{ width: '12%' }}>ADVANCED AMOUNT</StyledTableCell>

                <StyledTableCell style={{ width: '10%' }}>ATTORNEY</StyledTableCell>

                {/* <StyledTableCell style={{ width: '15%' }}>LAST NOTE</StyledTableCell> */}
                <StyledTableCell>ACTIONS</StyledTableCell>
              </TableRow>
            </TableHead>

            {isLoading === false && (
              <TableBody>
                {rows &&
                  rows.map((row, index) => (
                    <TableRow key={row.name}>
                      <TableCell component="th" scope="row">
                        {row.id}
                      </TableCell>
                      <TableCell style={{ width: '15%' }}>{row?.client}</TableCell>
                      <TableCell style={{ width: '8%' }}>{row?.applicationStatus}</TableCell>
                      <TableCell style={{ width: '8%' }}>{row?.advanceStatus}</TableCell>
                      <TableCell style={{ width: '8%' }}>{row?.applicationNo}</TableCell>
                      <TableCell style={{ width: '8%' }}>{row?.advanceNo}</TableCell>
                      <TableCell style={{ width: '10%' }}>{row?.accidentTypeName}</TableCell>
                      <TableCell style={{ width: '10%' }}>
                        {row?.applicationDate &&
                          Moment(row.applicationDate.split('T')[0]).format('MM-DD-YYYY')}
                      </TableCell>
                      <TableCell style={{ width: '12%' }}>
                        {row?.advancedAmount != null &&
                          moneyFormatter.format(parseFloat(row?.advancedAmount).toFixed(2))}
                      </TableCell>
                      <TableCell style={{ width: '10%' }}>{row?.attorney}</TableCell>
                      {/* <TableCell style={{ width: '15%' }}>{row?.lastNote}</TableCell> */}
                      <TableCell className="p-2">
                        <a
                          className="btn float-right"
                          onClick={() => {
                            history.push(
                              '/underwriting/applications/' + row?.applicationNo + '/attorney'
                            );
                          }}
                        >
                          <EditIcon />
                        </a>
                      </TableCell>
                    </TableRow>
                  ))}
              </TableBody>
            )}
            {isLoading && (
              <TableBody>
                <TableRow>
                  <td></td>
                  <td>
                    <ReactLoading type="bars" color="#7E8299" />
                  </td>
                  <td></td>
                </TableRow>
              </TableBody>
            )}

            <TableFooter></TableFooter>
          </Table>
        </TableContainer>
        <div className="float-left mt-5">
          <select onChange={handlePageSizeChange} className="form-control" defaultValue={15}>
            {[5, 10, 15, 25, 100].map((size) => (
              <option key={size} value={size}>
                {size}
              </option>
            ))}
          </select>
        </div>
        <div className="float-right mt-5">
          <Pagination
            count={pages}
            page={page}
            siblingCount={1}
            boundaryCount={1}
            variant="outlined"
            onChange={handleChangePage}
          />
        </div>
      </Col>
    </>
  );
}
