import { useContext, useEffect } from 'react';
import { useHistory } from 'react-router-dom';

import AppContext from '@contexts/AppContext';

const Logout = () => {
  const appContext = useContext(AppContext);
  const history = useHistory();

  useEffect(()=>{
    if (localStorage.getItem('isUserAuthenticated') === 'true') {
      appContext.toastMessage.message = 'You have logged out sucessfully.';
      appContext.userPermissions = [];
      localStorage.removeItem('lasuris-user');
      localStorage.removeItem('lasuris-jwt');
      localStorage.removeItem('userPermissions');
      localStorage.setItem('isUserAuthenticated', false);
      history.push('/login');
    }
  
    history.push('/login');
  });

  return null;

};

export default Logout;
