import React, { useContext, useEffect, useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { useForm } from 'react-hook-form';
import ApplicationsNewMenu from '../../Menu';
import ApplicationNoteService from '@services/ApplicationNoteService';
import { Row, Col, Input, Label, Alert, Button, Form, FormGroup, Container } from 'reactstrap';
import { ErrorMessage } from '@hookform/error-message';
import ApplicationsService from '@services/ApplicationsService';
import { toast } from 'react-toastify';
import AppContext from '@contexts/AppContext';
import Header from '../../Header';
import ReactLoading from 'react-loading';


const EditNote = (props) => {

  const user = JSON.parse(localStorage.getItem('lasuris-user'));
  const appContext = useContext(AppContext);
  const history = useHistory();
  const [isLoading, setIsLoading] = useState(false);
  const [disableTabs, setDisableTabs] = useState(false);
  const { applicationId } = useParams();
  const [note, setNote] = useState({});
  const { register, handleSubmit, watch, errors, formState } = useForm({ mode: 'onBlur' });

  const handleFormSubmit = (data) => {

    if (!(appContext?.userPermissions?.indexOf('Edit Notes') > -1)) {
      appContext.toastMessage.message = 'You do not have permissions to perform this action.';
      toast.error(appContext.toastMessage.message, { autoClose: 3000 });
      appContext.toastMessage.message = null;
      return;
    }

    // update note
    if (props.match.params.noteId != 'new-note') {

      var updatedNote = { ...note };
      updatedNote.note = data.note;

      setIsLoading(true);

      ApplicationNoteService.update(updatedNote).then((response) => {
        appContext.toastMessage.message = 'Note has been successfully updated.';
        history.push(
          '/underwriting/applications/' + applicationId + '/notes'
        );
      }).catch((err) => {
        
      }).finally(() => {
        setIsLoading(false);
      });

    } else {

      setIsLoading(true);

      // create new note
      ApplicationsService.search({ 'applicationNo': applicationId }, null, null, 1, {}, 10).then((response) => {

        var updatedNote = { ...note };
        updatedNote = data;
        updatedNote.applicationPublicId = response.data.data[0].publicId;
        updatedNote.CreatedByPublicId = user.publicId;

        ApplicationNoteService.create(updatedNote).then((response) => {

          appContext.toastMessage.message = 'Note has been sucessfully saved.';
          history.push('/underwriting/applications/' + applicationId + '/notes');
        }).catch((err) => {
          
        }).finally(() => {
          setIsLoading(false);
        });

      });
    }
  };

  useEffect(() => {

    if (props.match.params.noteId != 'new-note') {

      setIsLoading(true);

      ApplicationNoteService.read(props.match.params.noteId).then((response) => {
        setNote(response.data);
      }).catch((err) => {
        
      }).finally(() => {
        setIsLoading(false);
      });

    }
  }, []);

  const handleCancel = () => {
    let path = applicationId ? applicationId : 'new-application';
    history.push('/underwriting/applications/' + path + '/notes');
  };

  if (isLoading) {
    return (
      <Container className="mt-10 mb-10">
        <Row>
          <Col md="12" className="mx-auto bg-white box-shadow-border">
            <div className="p-10 mt-5 mb-10">

              {(disableTabs != null) &&
                <Header applicationId={applicationId} handleSaveAndExit={() => { }} />
              }

              {(disableTabs != null) &&
                <ApplicationsNewMenu disableTabs={disableTabs} applicationId={applicationId} menuItemIndex="7" />
              }

              <Container className="p-0">
                <Row>
                  <Col md="8">
                    <Row>
                      <Col md="4">
                        <ReactLoading type="bars" color="#7E8299" />
                      </Col>
                    </Row>
                  </Col>
                </Row>
              </Container>

            </div>
          </Col>
        </Row>
      </Container>
    )
  }

  return (
    <div className="container mt-10 mb-10">
      <div className="row">
        <div className="col-md-12 mx-auto bg-white box-shadow-border">
          <div className="p-10">
            <div className="mt-5 mb-10">
              <div>

                <h1>{(applicationId != 'new-application') ?
                  'Application ' + applicationId + ' - ' : undefined}
                  {!props.match.params.noteId ? 'New Note' : 'Note Details'}</h1>

                <ApplicationsNewMenu applicationId={applicationId} menuItemIndex="7" />

                <form onSubmit={handleSubmit(handleFormSubmit)} noValidate>
                  <div className="row">
                    <div className="col-md-3">
                      <div className="form-group">
                        <label htmlFor="date">Note</label>
                        <textarea
                          name="note"
                          id="note"
                          rows="12"
                          ref={register({ required: 'A value is required.' })}
                          defaultValue={note?.note}
                          style={{ width: '600px' }}
                          className="form-control"
                          required="required"
                        />
                        {errors.note && (
                          <Alert color="danger" className="mt-5">
                            <ErrorMessage errors={errors} name="note" />
                          </Alert>
                        )}
                      </div>

                      <div className="form-group">
                        <button type="submit" className="btn btn-primary">
                          Save
                        </button>
                        <button
                          type="button"
                          className="ml-5 btn btn-secondary"
                          onClick={() => handleCancel()}
                        >
                          Cancel
                        </button>
                      </div>
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default EditNote;
