import React, { useState, useEffect, useContext, useRef } from 'react';
import { useHistory, withRouter, useParams } from 'react-router-dom';

import { makeStyles, withStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableFooter from '@material-ui/core/TableFooter';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import Pagination from '@material-ui/lab/Pagination';
import GridService from '@services/GridService';
import AppContext from '@contexts/AppContext';
import TableSortLabel from '@material-ui/core/TableSortLabel';
import ReactLoading from 'react-loading';

const StyledTableCell = withStyles((theme) => ({
  head: {
    backgroundColor: '#efefef',
    color: theme.palette.common.black,
  },
  body: {
    fontSize: 14,
  },
}))(TableCell);

const useStyles2 = makeStyles({
  table: {
    minWidth: 500,
  },
});

const ActivityGrid = () => {
  const appContext = useContext(AppContext);
  const { applicationId } = useParams();
  const history = useHistory();
  const mountedRef = useRef();
  const classes = useStyles2();
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(15);
  const [filters, setFilters] = useState({});
  const [orderBy, setOrderBy] = useState('');
  const [orderDir, setOrderDir] = useState('ASC');
  const [rows, setRows] = useState([]);
  const [rowIndex, setRowIndex] = useState(undefined);
  const [givenRows, setGivenRows] = useState(undefined);
  const [isLoading, setIsLoading] = useState(false);
  const [pages, setPages] = useState(undefined);
  const [activeFieldForSorting, setActiveFieldForSorting] = useState();

  useEffect(() => {
    if (!mountedRef.current) return;
    fetchData();
  }, [orderBy, orderDir, rowsPerPage]);

  useEffect(() => {
    if (!mountedRef.current) return;
    fetchData();
  }, [page]);

  useEffect(() => {
    if (!applicationId || applicationId == 'undefined') {
      appContext.toastMessage.message = 'Application No was not found!';
      history.push('/underwriting/applications/new-application/');
      return;
    }

    mountedRef.current = true;
    setPage(1);
  }, []);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const fetchData = () => {
    var newFilters = { ...filters };
    newFilters['applicationPublicId'] = appContext.currentApplication.publicId;
    setFilters(newFilters);
    let endpoint = `/ApplicationActivity/`;
    setIsLoading(true);
    GridService.search(endpoint, newFilters, orderBy, orderDir, 1, null, rowsPerPage)
      .then((response) => {
        if (!response.data) return;
        setIsLoading(false);
        setRows(response.data.data);
        setPages(response.data.pages.slice(-1)[0]);
      })
      .catch((err) => {})
      .finally(() => {
        setIsLoading(false);
      });
  };

  const arrange = (field) => {
    if (orderBy === field) {
      if (orderDir === 'ASC') {
        setOrderDir('DESC');
      } else {
        setOrderDir('ASC');
      }
    } else {
      setOrderBy(field);
      setOrderDir('ASC');
    }

    setPage(1);
  };

  const handlePageSizeChange = (event) => {
    setRowsPerPage(event.target.value);
  };
  const searchColumn = (event, columnName) => {
    var newFilters = filters;
    newFilters[columnName] = event.target.value;
    setFilters(newFilters);
    fetchData();
  };
  return (
    <div>
      <TableContainer component={Paper}>
        <Table className={classes.table} aria-label="custom pagination table">
          <TableHead>
            <TableRow>
              <StyledTableCell>Id</StyledTableCell>

              <StyledTableCell
                onClick={() => {
                  arrange('activityDate');
                  setActiveFieldForSorting('activityDate');
                }}
              >
                DATE
                <TableSortLabel
                  active={activeFieldForSorting === 'activityDate' ? true : false}
                  direction={
                    activeFieldForSorting === 'activityDate' ? orderDir.toLowerCase() : 'asc'
                  }
                  onClick={() => arrange('activityDate')}
                ></TableSortLabel>
              </StyledTableCell>

              <StyledTableCell
                onClick={() => {
                  arrange('activityType');
                  setActiveFieldForSorting('activityType');
                }}
              >
                TYPE
                <TableSortLabel
                  active={activeFieldForSorting === 'activityType' ? true : false}
                  direction={
                    activeFieldForSorting === 'activityType' ? orderDir.toLowerCase() : 'asc'
                  }
                  onClick={() => arrange('activityType')}
                ></TableSortLabel>
              </StyledTableCell>

              <StyledTableCell
                onClick={() => {
                  arrange('description');
                  setActiveFieldForSorting('description');
                }}
              >
                DESCRIPTION
                <TableSortLabel
                  active={activeFieldForSorting === 'description' ? true : false}
                  direction={
                    activeFieldForSorting === 'description' ? orderDir.toLowerCase() : 'asc'
                  }
                  onClick={() => arrange('description')}
                ></TableSortLabel>
              </StyledTableCell>
              <StyledTableCell
                onClick={() => {
                  arrange('createdByOriginalName');
                  setActiveFieldForSorting('createdByOriginalName');
                }}
              >
                CREATED BY
                <TableSortLabel
                  active={activeFieldForSorting === 'createdByOriginalName' ? true : false}
                  direction={
                    activeFieldForSorting === 'createdByOriginalName'
                      ? orderDir.toLowerCase()
                      : 'asc'
                  }
                  onClick={() => arrange('createdByOriginalName')}
                ></TableSortLabel>
              </StyledTableCell>
            </TableRow>
            <TableRow>
              <StyledTableCell>
                <input
                  type="search"
                  className="form-control"
                  placeholder="Search..."
                  onChange={(e) => searchColumn(e, 'id')}
                />
              </StyledTableCell>
              <StyledTableCell>
                <input
                  type="search"
                  className="form-control"
                  placeholder="Search..."
                  onChange={(e) => searchColumn(e, 'activityDate')}
                />
              </StyledTableCell>
              <StyledTableCell>
                <input
                  type="search"
                  className="form-control"
                  placeholder="Search..."
                  onChange={(e) => searchColumn(e, 'activityType')}
                />
              </StyledTableCell>
              <StyledTableCell>
                <input
                  type="search"
                  className="form-control"
                  placeholder="Search..."
                  onChange={(e) => searchColumn(e, 'description')}
                />
              </StyledTableCell>
              <StyledTableCell>
                <input
                  type="search"
                  className="form-control"
                  placeholder="Search..."
                  onChange={(e) => searchColumn(e, 'createdByOriginalName')}
                />
              </StyledTableCell>
            </TableRow>
          </TableHead>
          {isLoading === false && (
            <TableBody>
              {rows &&
                rows.map((row, index) => (
                  <TableRow key={row.name}>
                    <TableCell component="th" scope="row">
                      {row.id}
                    </TableCell>
                    <TableCell>{row.activityDate.split('T')[0]}</TableCell>
                    <TableCell>{row.activityType}</TableCell>
                    <TableCell>{row.description}</TableCell>
                    <TableCell>{row.createdByOriginalName}</TableCell>
                  </TableRow>
                ))}
            </TableBody>
          )}
          {isLoading && (
            <TableBody>
              <TableRow>
                <td></td>
                <td>
                  <ReactLoading type="bars" color="#7E8299" />
                </td>
                <td></td>
              </TableRow>
            </TableBody>
          )}

          <TableFooter></TableFooter>
        </Table>
      </TableContainer>
      <div className="float-left mt-5">
        <select onChange={handlePageSizeChange} className="form-control" defaultValue={15}>
          {[5, 10, 15, 25, 100].map((size) => (
            <option key={size} value={size}>
              {size}
            </option>
          ))}
        </select>
      </div>
      <div className="float-right mt-5">
        <Pagination
          count={pages}
          page={page}
          siblingCount={1}
          boundaryCount={1}
          variant="outlined"
          onChange={handleChangePage}
        />
      </div>
    </div>
  );
};

export default withRouter(ActivityGrid);
