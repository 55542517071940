import React, { useEffect, useState } from 'react';
import UsStateService from '@services/UsStateService';

const StateSelect = (props) => {

  const [options, setOptions] = useState();

  useEffect(() => {
    UsStateService.search({}, 1, {}).then((result) => {

      const defaultValue = <option value=''>Please select a State</option>;
      const statesOptionItems = result?.data?.data?.map((state) => (
        <option key={state.stateName} value={state.stateCode}>{state.stateName}</option>
      ));

      setOptions([defaultValue, statesOptionItems]);
    });
  }, [])

  return (
    <div className="form-group">
      <select
        id={props.id !== undefined ? props.id : 'state'}
        name={props.name !== undefined ? props.name : 'state'}
        className="form-control"
        ref={props.useRef !== undefined ? props.useRef : undefined}
        required="required"
        disabled={props.disabled !== undefined? props.disabled: undefined}
        value={
          props.defaultValue !== undefined ? props.defaultValue : undefined
        }
        onChange={(event) => {
          if (event) {
            props.setObject({
              ...props.givenObject,
              [props.name]: event.target.value        
            });
          } 
        }}
      >
        {options}
      </select>      
    </div>
  );
};

export default StateSelect;
