import { useParams, Link } from 'react-router-dom';
import React, { useState, useEffect, useContext } from 'react';
import { makeStyles, withStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import { Col } from 'reactstrap';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableFooter from '@material-ui/core/TableFooter';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import AppContext from '@contexts/AppContext';
import ReactLoading from 'react-loading';
import './Styles.css';
import LawFirmService from '@services/LawFirmService';

const StyledTableCell = withStyles((theme) => ({
  head: {
    backgroundColor: '#efefef',
    color: theme.palette.common.black,
  },
  body: {
    fontSize: 14,
  },
}))(TableCell);

const useStyles2 = makeStyles({
  table: {
    minWidth: 500,
  },
});

export default function LawFirmsAdvancesTable() {
  const appContext = useContext(AppContext);
  const { id } = useParams();
  const classes = useStyles2();
  const [page, setPage] = React.useState(1);
  const [rowsPerPage, setRowsPerPage] = React.useState(99999);
  const [rows, setRows] = useState([]);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  useEffect(() => {
    loadData();
  }, []);

  const loadData = () => {

    setIsLoading(true);

    if (id) {
      LawFirmService.readAdvances(id)
        .then((response) => {
          setRows(response.data);
          setIsLoading(false);
        }).catch((err) => {
        }).finally(() => {
          setIsLoading(false);
        });
    }
  };

  const [modal, setModal] = useState(false);
  const toggle = () => setModal(!modal);
  const [isLoading, setIsLoading] = useState(false);
  const [pages, setPages] = useState(undefined);


  return (
    <>
      <Col md="8" className="float-left lfsr-table-container" >
        <br />
        <TableContainer component={Paper}>
          <Table className={classes.table} aria-label="custom pagination table">
            <TableHead>
              <TableRow>
                <StyledTableCell
                  className="num-cases"
                >

                </StyledTableCell>
                <StyledTableCell
                  className="law-firm"
                >
                  Advance Status
                </StyledTableCell>

                <StyledTableCell
                  className="total-advanced-amt"
                >
                  Total Advanced Amount
                </StyledTableCell>

              </TableRow>
            </TableHead>
            {isLoading === false && (
              <TableBody>
                {rows &&
                  rows.map((row, index) => (
                    <TableRow key={row.id}>
                      <TableCell className="num-cases"></TableCell>
                      <TableCell className="law-firm">{row?.status ? row.status : "-"}</TableCell>
                      <TableCell className="total-advanced-amt">{row?.count ? row.count : "-"}</TableCell>
                    </TableRow>
                  ))}
              </TableBody>
            )}
            {isLoading && (
              <TableBody>
                <TableRow>
                  <td></td>
                  <td>
                    <ReactLoading type="bars" color="#7E8299" />
                  </td>
                  <td></td>
                </TableRow>
              </TableBody>
            )}

            <TableFooter></TableFooter>
          </Table>
        </TableContainer>
      </Col>
    </>
  );
}
