import axios from 'axios';
import { auth, resError } from '@services/Helpers/ServiceInterceptor';

const api = axios.create({ baseURL: process.env.REACT_APP_API_URL });

api.interceptors.request.use((config) => auth(config));
api.interceptors.response.use(
  (response) => {
    return response;
  },
  (error) => resError(error)
);

class AuthService {
  constructor() {
    if (!AuthService.instance) {
      AuthService.instance = this;
    }
    return AuthService.instance;
  }

  login = async (email, password) => {
    const response = await api.post('/users/login', { email, password });

    if (response.data) {
      localStorage.setItem('lasuris-user', JSON.stringify(response.data.user));
      localStorage.setItem('lasuris-jwt', response.data.jwtToken);
      return response.data;
    } else {
      return response;
    }
  };

  loginAttorney = async (email, password, attorneyPublicId) => {
    const response = await api.post('/users/attorneylogin', { email, password, attorneyPublicId });

    if (response.data) {
      localStorage.setItem('lasuris-user', JSON.stringify(response.data.user));
      localStorage.setItem('lasuris-jwt', response.data.jwtToken);
      return response.data;
    } else {
      return response;
    }
  };


  logout = () => {
    localStorage.removeItem('lasuris-user');
    localStorage.removeItem('lasuris-jwt');
  };

  forgotPassword = async (email) => {
    const response = await api.post('/users/forgot-password', { email });

    if (response.data) {
      return response.data;
    } else {
      return response;
    }
  };

  resetPassword = async (token, password) => {
    const response = await api.post('/users/reset-password', { token, password });

    if (response.data) {
      return response.data;
    } else {
      return response;
    }
  };
}

const instance = new AuthService();
Object.freeze(instance);

export default instance;
