import React, { useContext, useEffect, useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import { useForm } from 'react-hook-form';
import { Row, Col, Alert, Container } from 'reactstrap';

import ApplicationsNewMenu from '../Menu';
import AppContext from '@contexts/AppContext';
import Header from '../Header';
import StateSelect from '@components/StateSelect';
import { ErrorMessage } from '@hookform/error-message';
import AccidentTypeService from '@services/AccidentTypeService';
import CollisionTypeService from '@services/CollisionTypeService';
import AccidentImpactService from '@services/AccidentImpactService';
import ApplicationAccidentInfoService from '@services/ApplicationAccidentInfoService';
import ApplicationFlagService from '@services/ApplicationFlagService';
import ReactLoading from 'react-loading';

const Accident = (props) => {
  const appContext = useContext(AppContext);
  const history = useHistory();
  const [disableTabs, setDisableTabs] = useState(false);
  const applicationId = props.match.params.applicationId;
  const [isLoading, setIsLoading] = useState(false);
  const [HasFirstButtonSubbmited, setHasFirstButtonSubbmited] = useState(true);
  const { register, handleSubmit, errors } = useForm({ mode: 'onBlur' });
  const [accident, setAccident] = useState({});
  const [isAccidentNew, setIsAccidentNew] = useState(true);

  let application = appContext.currentApplication;

  const handleFormSubmitted = (data) => {
    setIsLoading(true);

    const newAccident = { ...accident };
    newAccident.applicationPublicId = application.publicId;
    newAccident.accidentStateCode = data.accidentStateCode;
    newAccident.stateCode = undefined;
    newAccident.accidentTypePublicId = data.accidentTypePublicId;
    newAccident.accidentDate = new Date(data.dateOfAccident);
    newAccident.description = data.description;
    newAccident.didPoliceArrived = data.didPoliceArrived === 'true' ? true : false;
    newAccident.plaintiffWas = data.plaintiffWas;
    newAccident.collisionTypePublicId = data.collisionTypePublicId;
    newAccident.accidentImpactPublicId = data.accidentImpactPublicId;
    newAccident.numberofPeopleinPlaintiffCar = parseInt(data.numberofPeopleinPlaintiffCar);
    newAccident.numberofPeopleinOtherCar = parseInt(data.numberofPeopleinOtherCar);
    newAccident.propertyDamages = data.propertyDamages;
    newAccident.policeReportNo = data.policeReportNo;
    newAccident.wasAnyoneIntoxicated = data.wasAnyoneIntoxicated;

    if (isAccidentNew === true) {
      if (!(appContext?.userPermissions?.indexOf('Edit Accident Info') > -1)) {
        appContext.toastMessage.message = 'You do not have permissions to perform this action.';
        toast.error(appContext.toastMessage.message, { autoClose: 3000 });
        setIsLoading(false);
        appContext.toastMessage.message = null;
        return;
      }

      ApplicationAccidentInfoService.create(newAccident)
        .then(() => {
          appContext.toastMessage.message = 'Your changes has been saved sucessfully.';

          if (HasFirstButtonSubbmited) {
            appContext.newAppllicationStep += 1;
            setIsLoading(false);
            history.push('/underwriting/applications/' + applicationId + '/injury');
          } else {
            appContext.newAppllicationStep = 1;
            setHasFirstButtonSubbmited(true);
            setIsLoading(false);
            history.push('/underwriting/applications');
          }
        })
        .catch((err) => {})
        .finally(() => {
          setIsLoading(false);
        });
    } else {
      if (!(appContext?.userPermissions?.indexOf('Edit Accident Info') > -1)) {
        appContext.toastMessage.message = 'You do not have permissions to perform this action.';
        toast.error(appContext.toastMessage.message, { autoClose: 3000 });
        appContext.toastMessage.message = null;
        setIsLoading(false);
        return;
      }

      ApplicationAccidentInfoService.update(newAccident)
        .then(() => {
          appContext.toastMessage.message = 'Your changes has been saved sucessfully.';

          if (HasFirstButtonSubbmited) {
            appContext.newAppllicationStep += 1;
            setIsLoading(false);
            history.push('/underwriting/applications/' + applicationId + '/injury');
          } else {
            appContext.newAppllicationStep = 1;
            setHasFirstButtonSubbmited(true);
            setIsLoading(false);
            history.push('/underwriting/applications');
          }
        })
        .catch((err) => {})
        .finally(() => {
          setIsLoading(false);
        });
    }
  };

  const handleSaveAndExit = () => {
    setHasFirstButtonSubbmited(false);
    document.querySelector('button.btn.btn-primary.mr-5').click();
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    let accidentUpdated = { ...accident };
    accidentUpdated[name] = value;
    setAccident(accidentUpdated);
  };

  const handleCancelButtonClicked = () => {
    appContext.newAppllicationStep = 1;
    history.push('/underwriting/applications');
  };

  const [numberOfPeopleInPlaintiffCarOptions, setNumberOfPeopleInPlaintiffCarOptions] = useState();
  const [numberOfPeopleInOtherCarOptions, setNumberOfPeopleInOtherCar] = useState();
  const [accidentTypes, setAccidentTypes] = useState();
  const [collisionTypes, setCollisionTypes] = useState();
  const [accidentImpacts, setAccidentImpacts] = useState();

  const numberOfPeopleInPlaintiffCar = () => {
    const defaultValue = (
      <option value="0" selected>
        None
      </option>
    );
    const values = [...Array(8).keys()]
      .map((i) => i + 1)
      .map((item) => (
        <option key={item} value={item}>
          {item}
        </option>
      ));

    setNumberOfPeopleInPlaintiffCarOptions([defaultValue, values]);
    setNumberOfPeopleInOtherCar([defaultValue, values]);
  };

  const getAccidentTypes = () => {
    AccidentTypeService.search({ accidentTypeName: '' }, 1)
      .then((response) => {
        let accidentTypes = response.data.data.map((item) => (
          <option
            key={item.publicId}
            value={item.publicId}
            {...(item.accidentTypeName === 'Other' ?? 'selected')}
          >
            {item.accidentTypeName}
          </option>
        ));
        let defaultValue = <option value="">Please select a value</option>;
        setAccidentTypes([defaultValue, accidentTypes]);
      })
      .catch((err) => {})
      .finally(() => {
        setIsLoading(false);
      });
  };

  const getCollisionTypes = () => {
    CollisionTypeService.search({ collisionTypeName: '' }, 1)
      .then((response) => {
        let collisionTypes = response.data.data.map((item) => (
          <option
            key={item.publicId}
            value={item.publicId}
            selected={item.collisionTypeName === 'Other' ?? item.publicId}
          >
            {item.collisionTypeName}
          </option>
        ));
        let defaultValue = <option value="">Please select a value</option>;
        setCollisionTypes([defaultValue, collisionTypes]);
      })
      .catch((err) => {})
      .finally(() => {
        setIsLoading(false);
      });
  };

  const getAccidentImpacts = () => {
    AccidentImpactService.search({ accidentImpactName: '' }, 1)
      .then((response) => {
        let accidentImpacts = response.data.data.map((item) => (
          <option
            key={item.publicId}
            value={item.publicId}
            selected={item.accidentImpactName === 'Other' ?? item.publicId}
          >
            {item.accidentImpactName}
          </option>
        ));
        let defaultValue = <option value="">Please select a value</option>;
        //let defaultValue = <option value="17422953-32cf-982a-2951-633f2f091f0d">Other</option>;
        setAccidentImpacts([defaultValue, accidentImpacts]);
      })
      .catch((err) => {})
      .finally(() => {
        setIsLoading(false);
      });
  };

  useEffect(() => {
    document.body.scrollTop = 0; // For Safari
    document.documentElement.scrollTop = 0; // For Chrome, Firefox, IE and Opera

    if (appContext.toastMessage.message && appContext.toastMessage.type === 'error') {
      toast.error(appContext.toastMessage.message, { autoClose: 3000 });
      appContext.toastMessage.message = null;
      appContext.toastMessage.type = null;
    } else {
      toast.success(appContext.toastMessage.message, { autoClose: 3000 });
      appContext.toastMessage.message = null;
    }

    if (!applicationId || applicationId == 'undefined') {
      appContext.toastMessage.message = 'Application No was not found!';
      history.push('/underwriting/applications/new-application/');
      return;
    }

    if (applicationId) {
      setIsLoading(true);

      ApplicationAccidentInfoService.search({ applicationNo: applicationId }, 1, {})
        .then((response) => {
          if (!response.data || response.data.length === 0) return;
          setAccident(response.data.data[0]);
          setIsLoading(false);
          if (response.data.data.length > 0) setIsAccidentNew(false);
        })
        .catch((err) => {})
        .finally(() => {
          setIsLoading(false);
        });
    }

    numberOfPeopleInPlaintiffCar();
    getAccidentTypes();
    getCollisionTypes();
    getAccidentImpacts();

    ApplicationFlagService.searchExact(
      { applicationNo: applicationId, flagNameExact: 'Application Stopped' },
      null,
      null,
      1,
      null,
      1
    )
      .then((response) => {
        if (response?.data?.data[0]?.flagName === 'Application Stopped') {
          setDisableTabs(1);
          setIsLoading(false);
        }
      })
      .catch((err) => {})
      .finally(() => {
        setIsLoading(false);
      });

    ApplicationFlagService.searchExact(
      { applicationNo: applicationId, flagNameExact: 'Application Stopped2' },
      null,
      null,
      1,
      null,
      1
    )
      .then((response) => {
        if (response?.data?.data[0]?.flagName === 'Application Stopped2') {
          setDisableTabs(2);
          setIsLoading(false);
        }
      })
      .catch((err) => {})
      .finally(() => {
        setIsLoading(false);
      });

    ApplicationFlagService.searchExact(
      { applicationNo: applicationId, flagNameExact: 'Application Stopped3' },
      null,
      null,
      1,
      null,
      1
    )
      .then((response) => {
        if (response?.data?.data[0]?.flagName === 'Application Stopped3') {
          setDisableTabs(3);
          setIsLoading(false);
        }
      })
      .catch((err) => {})
      .finally(() => {
        setIsLoading(false);
      });
  }, []);

  if (isLoading) {
    return (
      <Container className="mt-10 mb-10">
        <Row>
          <Col md="12" className="mx-auto bg-white box-shadow-border">
            <div className="p-10 mt-5 mb-10">
              {disableTabs != null && (
                <Header applicationId={applicationId} handleSaveAndExit={handleSaveAndExit} />
              )}

              {disableTabs != null && (
                <ApplicationsNewMenu
                  disableTabs={disableTabs}
                  applicationId={applicationId}
                  menuItemIndex="2 "
                />
              )}

              <Container className="p-0">
                <Row>
                  <Col md="8">
                    <Row>
                      <Col md="4">
                        <ReactLoading type="bars" color="#7E8299" />
                      </Col>
                    </Row>
                  </Col>
                </Row>
              </Container>
            </div>
          </Col>
        </Row>
      </Container>
    );
  }

  return (
    <div className="container mt-10 mb-10">
      <div className="row">
        <div className="col-md-12 mx-auto bg-white box-shadow-border">
          <div className="p-10">
            <div className="mt-5 mb-10">
              <div>
                {disableTabs != null && (
                  <Header
                    disableTabs={disableTabs}
                    applicationId={applicationId}
                    handleSaveAndExit={handleSaveAndExit}
                  />
                )}

                {disableTabs != null && (
                  <ApplicationsNewMenu
                    disableTabs={disableTabs}
                    applicationId={applicationId}
                    menuItemIndex="2"
                  />
                )}

                <form onSubmit={handleSubmit(handleFormSubmitted)} method="POST" noValidate>
                  <Container className="p-0">
                    <Row>
                      <Col md="8">
                        <Row>
                          <Col md="4">
                            <div className="form-group">
                              <StateSelect
                                name="accidentStateCode"
                                label="State in which accident happened"
                                useRef={register({ required: 'State is required.' })}
                                setObject={setAccident}
                                givenObject={accident}
                                defaultValue={accident?.accidentStateCode}
                                errors={errors}
                              />
                            </div>
                          </Col>
                          <Col md="4">
                            <div className="form-group">
                              <label htmlFor="accidentTypePublicId">Type of Accident</label>
                              <select
                                name="accidentTypePublicId"
                                className="form-control"
                                required="required"
                                onChange={handleInputChange}
                                value={accident?.accidentTypePublicId}
                                ref={register({ required: 'Type of Accident is required.' })}
                              >
                                {accidentTypes}
                              </select>
                              {errors.accidentTypePublicId && (
                                <Alert color="danger" className="mt-5">
                                  <ErrorMessage errors={errors} name="accidentTypePublicId" />
                                </Alert>
                              )}
                            </div>
                          </Col>
                        </Row>

                        <Row>
                          <Col md="4">
                            <div className="form-group">
                              <label htmlFor="dateOfAccident">Date of Accident</label>
                              <input
                                type="date"
                                name="dateOfAccident"
                                ref={register({ required: 'Date of Accident is required.' })}
                                className="form-control"
                                defaultValue={accident?.accidentDate?.split('T')[0]}
                                required="required"
                              />
                              {errors.dateOfAccident && (
                                <Alert color="danger" className="mt-5">
                                  <ErrorMessage errors={errors} name="dateOfAccident" />
                                </Alert>
                              )}
                            </div>
                          </Col>
                        </Row>

                        <Row>
                          <Col md="8">
                            <div className="form-group">
                              <label htmlFor="description">Description of Accident</label>
                              <textarea
                                type="text"
                                name="description"
                                ref={register({ required: 'Description of Accident is required.' })}
                                rows="8"
                                defaultValue={accident?.description}
                                className="form-control"
                                required="required"
                              />
                              {errors.description && (
                                <Alert color="danger" className="mt-5">
                                  <ErrorMessage errors={errors} name="description" />
                                </Alert>
                              )}
                            </div>
                          </Col>
                        </Row>

                        <Row>
                          <Col md="4">
                            <div className="form-group">
                              <label>Did police arrive at the scene?</label>
                              <br />
                              <input
                                type="radio"
                                name="didPoliceArrived"
                                ref={register({ required: 'A value is required.' })}
                                value={true}
                                required="required"
                                defaultChecked={accident?.didPoliceArrived ? 'checked' : undefined}
                                onClick={() => {
                                  setAccident({ ...accident, didPoliceArrived: true });
                                }}
                              />
                              <label htmlFor="policeArrivedYes" className="ml-2 mr-2">
                                Yes
                              </label>
                              <input
                                type="radio"
                                name="didPoliceArrived"
                                ref={register({ required: 'A value is required.' })}
                                value={false}
                                required="required"
                                defaultChecked={
                                  !accident?.didPoliceArrived &&
                                  accident?.didPoliceArrived != undefined
                                    ? 'checked'
                                    : undefined
                                }
                                onClick={() => {
                                  setAccident({ ...accident, didPoliceArrived: false });
                                }}
                              />
                              <label htmlFor="policeArrivedNo" className="ml-2 mr-2">
                                No
                              </label>
                              {errors.policeArrived && (
                                <Alert color="danger" className="mt-5">
                                  <ErrorMessage errors={errors} name="policeArrived" />
                                </Alert>
                              )}
                            </div>
                          </Col>
                          <Col md="4">
                            {accident?.didPoliceArrived && (
                              <div className="form-group">
                                <label htmlFor="policeReportNo">Police Report #</label>
                                <input
                                  type="text"
                                  name="policeReportNo"
                                  ref={register()}
                                  defaultValue={accident?.policeReportNo}
                                  //required="required"
                                  className="form-control"
                                />
                                {errors.policeReportNo && (
                                  <Alert color="danger" className="mt-5">
                                    <ErrorMessage errors={errors} name="policeReportNo" />
                                  </Alert>
                                )}
                              </div>
                            )}
                          </Col>
                        </Row>

                        <Row>
                          <Col md="4">
                            <div className="form-group">
                              <label htmlFor="plaintiffWas">Plaintiff Was</label>
                              <select
                                name="plaintiffWas"
                                ref={register({ required: 'A value is required.' })}
                                onChange={handleInputChange}
                                value={accident?.plaintiffWas}
                                className="form-control"
                                required="required"
                              >
                                <option value="">Please select a value</option>
                                <option value="driver">Driver</option>
                                <option value="passengerFrontSeat">Passenger Front Seat</option>
                                <option value="passengerBackSeat">Passenger Back Seat</option>
                                <option value="pedestrianBicyclist">Pedestrian/Bicyclist</option>
                                <option value="motorcycleDriver">Motorcycle Driver</option>
                                <option value="motorcyclePassenger">Motorcycle Passenger</option>
                                <option value="taxiPassenger">Taxi Passenger</option>
                                <option value="busTransitPassenger">Bus/Transit Passenger</option>
                                <option value="other">Other</option>
                              </select>
                              {errors.plaintiffWas && (
                                <Alert color="danger" className="mt-5">
                                  <ErrorMessage errors={errors} name="plaintiffWas" />
                                </Alert>
                              )}
                            </div>
                          </Col>
                        </Row>

                        <Row>
                          <Col md="4">
                            <div className="form-group">
                              <label htmlFor="collisionTypePublicId">Type of Collision</label>
                              <select
                                name="collisionTypePublicId"
                                ref={register()}
                                //ref={register({ required: 'Type of collision is required.' })}
                                onChange={handleInputChange}
                                value={accident?.collisionTypePublicId}
                                className="form-control"
                                // required="required"
                              >
                                {collisionTypes}
                              </select>
                              {errors.collisionTypePublicId && (
                                <Alert color="danger" className="mt-5">
                                  <ErrorMessage errors={errors} name="collisionTypePublicId" />
                                </Alert>
                              )}
                            </div>
                          </Col>
                          <Col md="4">
                            <div className="form-group">
                              <label htmlFor="accidentImpactPublicId">Accident Impact</label>
                              <select
                                name="accidentImpactPublicId"
                                ref={register()}
                                //ref={register({ required: 'Accident impact is required.' })}
                                onChange={handleInputChange}
                                value={accident?.accidentImpactPublicId}
                                className="form-control"
                                // required="required"
                              >
                                {accidentImpacts}
                              </select>
                              {errors.accidentImpactPublicId && (
                                <Alert color="danger" className="mt-5">
                                  <ErrorMessage errors={errors} name="accidentImpactPublicId" />
                                </Alert>
                              )}
                            </div>
                          </Col>
                        </Row>

                        <Row>
                          <Col md="4">
                            <div className="form-group">
                              <label htmlFor="numberofPeopleinPlaintiffCar">
                                How many people where in PLAINTIFF&apos;s car (including you)?
                              </label>
                              <select
                                name="numberofPeopleinPlaintiffCar"
                                ref={register({ required: 'A value is required.' })}
                                onChange={handleInputChange}
                                value={parseInt(accident?.numberofPeopleinPlaintiffCar)}
                                className="form-control"
                                required="required"
                              >
                                {numberOfPeopleInPlaintiffCarOptions}
                              </select>
                              {errors.numberofPeopleinPlaintiffCar && (
                                <Alert color="danger" className="mt-5">
                                  <ErrorMessage
                                    errors={errors}
                                    name="numberofPeopleinPlaintiffCar"
                                  />
                                </Alert>
                              )}
                            </div>
                          </Col>
                          <Col md="4">
                            <div className="form-group">
                              <label htmlFor="numberofPeopleinOtherCar">
                                How many people where in the other car (including the driver)?
                              </label>
                              <select
                                name="numberofPeopleinOtherCar"
                                ref={register({ required: 'A value is required.' })}
                                onChange={handleInputChange}
                                value={parseInt(accident?.numberofPeopleinOtherCar)}
                                className="form-control"
                                required="required"
                              >
                                {numberOfPeopleInOtherCarOptions}
                              </select>
                              {errors.numberofPeopleinOtherCar && (
                                <Alert color="danger" className="mt-5">
                                  <ErrorMessage errors={errors} name="numberofPeopleinOtherCar" />
                                </Alert>
                              )}
                            </div>
                          </Col>
                        </Row>

                        <Row>
                          <Col md="4">
                            <div className="form-group">
                              <label>Property Damages</label>
                              <select
                                name="propertyDamages"
                                ref={register({ required: 'A value is required.' })}
                                className="form-control"
                                onChange={handleInputChange}
                                value={accident?.propertyDamages}
                                required="required"
                              >
                                <option value="">Please select a value</option>
                                <option value="yes">Yes</option>
                                <option value="no">No</option>
                                <option value="notYetDetermined" selected>
                                  Not Yet Determined
                                </option>
                                <option value="notApplicable">Not Applicable</option>
                              </select>
                              {errors.propertyDamages && (
                                <Alert color="danger" className="mt-5">
                                  <ErrorMessage errors={errors} name="propertyDamages" />
                                </Alert>
                              )}
                            </div>
                          </Col>
                          <Col md="4">
                            <div className="form-group">
                              <label htmlFor="wasAnyoneIntoxicated">Was anyone intoxicated?</label>
                              <select
                                name="wasAnyoneIntoxicated"
                                ref={register({ required: 'A value is required.' })}
                                className="form-control"
                                onChange={handleInputChange}
                                value={accident?.wasAnyoneIntoxicated}
                                required="required"
                              >
                                <option value="">Please select a value</option>
                                <option value="No one">No one</option>
                                <option value="DEFENDANT">DEFENDANT</option>
                                <option value="Plaintiff">Plaintiff</option>
                                <option value="Driver">Driver</option>
                                <option value="Not Known" selected>
                                  Not Known
                                </option>
                              </select>
                              {errors.wasAnyoneIntoxicated && (
                                <Alert color="danger" className="mt-5">
                                  <ErrorMessage errors={errors} name="wasAnyoneIntoxicated" />
                                </Alert>
                              )}
                            </div>
                          </Col>
                        </Row>

                        <Row>
                          <Col md="4">
                            <button type="submit" className="btn btn-primary mr-5">
                              Save
                            </button>
                            <button
                              type="button"
                              onClick={handleCancelButtonClicked}
                              className="btn btn-secondary"
                            >
                              Cancel
                            </button>
                          </Col>
                        </Row>
                      </Col>
                    </Row>
                  </Container>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Accident;
