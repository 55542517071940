import React, { useEffect, useContext, useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { Row, Col, Input, Label, Alert, Button, Form, Container, Table } from 'reactstrap';
import { toast } from 'react-toastify';
import AppContext from '@contexts/AppContext';
import ApplicationsNewMenu from '../Menu/ApplicationsNewMenu';
import Header from '../Header';
import FormGroup from '@material-ui/core/FormGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import GridService from '@services/GridService';
import ApplicationsService from '@services/ApplicationsService';
import ReactLoading from 'react-loading';

const IMS = () => {

  const appContext = useContext(AppContext);
  const history = useHistory();
  const [disableTabs, setDisableTabs] = useState(false);
  const { applicationId } = useParams();
  const [isLoading, setIsLoading] = useState(false);
  const [applicationPublicId, setApplicationPublicId] = useState();
  
  const [ims, setIms] = useState({
    docReqsImssearch: false,
    docReqsPacersearch: false,
    docReqsNoPacersearch: false,
    lawFirmReqAttorneySignedCaseDeta: false,
    lawFirmReqPacersearch: false,
    lawFirmReqPhotoCopiesofReqDocumentation: false,
    lawFirmSecAttorneySignedAgreement: false,
    imsregImsentered: false,
    imsregImstoBeEntered: false,
    plaintiffSecPlaintiffSignedAgreement: false,
    plaintiffSecPacersearch: false,
  });

  const fetchData = () => {

    setIsLoading(true);

    let filters = {
      // "applicationPublicId": appContext.currentApplication.publicId,
      "applicationNo": applicationId
    }
    let endpoint = 'ApplicationImsdetail/';
    GridService.search(endpoint, filters, "", "", 1, null, 10).then((response) => {
      if (response.data.data.length > 0)
        setIms(response.data.data[0]);
    }).catch((err) => {
      
    }).finally(() => {
      setIsLoading(false);
    });
  }

  useEffect(() => {
   
    let item = document.querySelector('.ims');
    if (item) {
      item.classList.add('active');
    }

    fetchData();

    ApplicationsService.search({ applicationNo: applicationId }, null, null, 1, {}, 1).then((response) => {
      setApplicationPublicId(response.data.data[0].publicId);
    }).catch((err) => {
      
    }).finally(() => {
      setIsLoading(false);
    });;
  
  }, []);

  useEffect(() => {
    if (appContext.toastMessage.message)
      toast.success(appContext.toastMessage.message, { autoClose: 3000 });
    appContext.toastMessage.message = null;
  }, [appContext.toastMessage.message, history]);

  const handleFormSubmit = (e) => {

    e.preventDefault();

    if(!(appContext?.userPermissions?.indexOf('Edit IMS Info') > -1)){
      appContext.toastMessage.message = 'You do not have permissions to perform this action.';
      toast.error(appContext.toastMessage.message, { autoClose: 3000 });
      appContext.toastMessage.message = null;
      return;
    }
    
    let params = {
      applicationPublicId: applicationPublicId,
      docReqsImssearch: ims.docReqsImssearch,
      docReqsPacersearch: ims.docReqsPacersearch,
      docReqsNoPacersearch: ims.docReqsNoPacersearch,
      lawFirmReqAttorneySignedCaseDeta: ims.lawFirmReqAttorneySignedCaseDeta,
      lawFirmReqPacersearch: ims.lawFirmReqPacersearch,
      lawFirmReqPhotoCopiesofReqDocumentation: ims.lawFirmReqPhotoCopiesofReqDocumentation,
      lawFirmSecAttorneySignedAgreement: ims.lawFirmSecAttorneySignedAgreement,
      imsregImsentered: ims.imsregImsentered,
      imsregImstoBeEntered: ims.imsregImstoBeEntered,
      plaintiffSecPlaintiffSignedAgreement: ims.plaintiffSecPlaintiffSignedAgreement,
      plaintiffSecPacersearch: ims.plaintiffSecPacersearch,
      publicId: ims.publicId != undefined ? ims.publicId : null
    };

    let endpoint = 'ApplicationImsdetail';
    
    GridService.create(endpoint, params).then((resp) => {
      appContext.toastMessage.message = 'IMS has been successfully updated.';
      //Navigate to other tab
      history.push(
        '/underwriting/applications/' + applicationId + '/activity'
      );
    }).catch((err) => {
      
    }).finally(() => {
      setIsLoading(false);
    });;
    
  }

  const handleCancelButton = () => {
    history.push('/underwriting/applications');
  };

  const change = (keyVal) => {
    let tempIms = { ...ims };
    tempIms[keyVal] = tempIms[keyVal] != undefined ? !tempIms[keyVal] : true;
    setIms(tempIms);
  }

  const str = '- Police Report <br /> - Relevant Medical records ( relating injury to accident ) <br /> - Confirmation of Insurance Carrier and Coverage Amount <br /> - Copy of complaint ( first page w/ caption & case #)'

  if (isLoading) {
    return (
      <Container className="mt-10 mb-10">
        <Row>
          <Col md="12" className="mx-auto bg-white box-shadow-border">
            <div className="p-10 mt-5 mb-10">

              {(disableTabs != null) &&
                <Header applicationId={applicationId} handleSaveAndExit={() => { }} />
              }

              {(disableTabs != null) &&
                <ApplicationsNewMenu disableTabs={disableTabs} applicationId={applicationId} menuItemIndex="12" />
              }

              <Container className="p-0">
                <Row>
                  <Col md="8">
                    <Row>
                      <Col md="4">
                        <ReactLoading type="bars" color="#7E8299" />
                      </Col>
                    </Row>
                  </Col>
                </Row>
              </Container>

            </div>
          </Col>
        </Row>
      </Container>
    )
  }

  return (

    <div className="container mt-10 mb-10">
      <div className="row">
        <div className="col-md-12 bg-white">
          <div className="p-10">
            <div className="mt-5 mb-10">

            <Header applicationId={applicationId} handleSaveAndExit={() => { }} />

            <ApplicationsNewMenu applicationId={applicationId} menuItemIndex="12" />

              <form method="POST" onSubmit={handleFormSubmit} noValidate>
                <Row>
                  <Col md="12">
                    <div className="container mt-10 mb-10">
                      <div className="row">
                        <div className="col-md-12 mx-auto bg-white">
                          <div className="p-10">
                            <div className="mt-5 mb-10">

                              <div className="row" >
                                <div className="col">
                                  <div className="form-group">
                                    <div className="container">
                                      <div className="row">
                                        <div className="col-md-6">
                                          <div className="col-md-12" >
                                            <div >
                                              <label style={{ fontWeight: "bold", marginTop: '10px' }} htmlFor="user-permissions">Documentation Requirements</label>

                                              <FormGroup style={{ border: "1px solid", paddingLeft: "10px" }}>
                                                <FormControlLabel
                                                  control={
                                                    <Checkbox
                                                      checked={Object.keys(ims).length === 0 ? false : ims.docReqsImssearch}
                                                      onChange={() => { change('docReqsImssearch') }}
                                                      name={"IMS Search"}
                                                      color="primary"
                                                    />
                                                  }
                                                  label={"IMS Search"}
                                                />
                                                <FormControlLabel
                                                  control={
                                                    <Checkbox
                                                      checked={Object.keys(ims).length === 0 ? false : ims.docReqsPacersearch}
                                                      onChange={() => { change('docReqsPacersearch') }}
                                                      name={"PACER Search"}
                                                      color="primary"
                                                    />
                                                  }
                                                  label={"PACER Search"}
                                                />
                                                <FormControlLabel
                                                  control={
                                                    <Checkbox
                                                      checked={Object.keys(ims).length === 0 ? false : ims.docReqsNoPacersearch}
                                                      onChange={() => { change('docReqsNoPacersearch') }}
                                                      name={"No PACER Search/No SSN#"}
                                                      color="primary"
                                                    />
                                                  }
                                                  label={"No PACER Search/No SSN#"}
                                                />
                                              </FormGroup>
                                            </div>
                                            <div >
                                              <label style={{ fontWeight: "bold", marginTop: '10px' }} htmlFor="user-permissions">Law Firm Requirements</label>
                                              <FormGroup style={{ border: "1px solid", paddingLeft: "10px" }} >
                                                <FormControlLabel
                                                  control={
                                                    <Checkbox
                                                      checked={Object.keys(ims).length === 0 ? false : ims.lawFirmReqAttorneySignedCaseDeta}
                                                      onChange={() => { change('lawFirmReqAttorneySignedCaseDeta') }}
                                                      name={"Attorney Signed Case Details"}
                                                      color="primary"
                                                    />
                                                  }
                                                  label={"Attorney Signed Case Details"}
                                                />
                                                <FormControlLabel
                                                  control={
                                                    <Checkbox
                                                      checked={Object.keys(ims).length === 0 ? false : ims.lawFirmReqPhotoCopiesofReqDocumentation}
                                                      onChange={() => { change('lawFirmReqPhotoCopiesofReqDocumentation') }}
                                                      name={"Photocopies of required Documents"}
                                                      color="primary"
                                                    />
                                                  }
                                                  label={"Photocopies of required Documents"}
                                                />
                                                <div>
                                                  <p dangerouslySetInnerHTML={{ __html: str }}>
                                                  </p>
                                                </div>
                                              </FormGroup>
                                            </div>
                                          </div>
                                        </div>
                                        <div className="col-md-6">
                                          <div className="col-md-12">
                                            <div >
                                              <label style={{ fontWeight: "bold", marginTop: '10px' }} htmlFor="user-permissions">IMS Registration</label>
                                              <FormGroup style={{ border: "1px solid", paddingLeft: "10px" }} >
                                                <FormControlLabel
                                                  control={
                                                    <Checkbox
                                                      checked={Object.keys(ims).length === 0 ? false : ims.imsregImsentered}
                                                      onChange={() => { change('imsregImsentered') }}
                                                      name={"IMS Entered"}
                                                      color="primary"
                                                    />
                                                  }
                                                  label={"IMS Entered"}
                                                />
                                                <FormControlLabel
                                                  control={
                                                    <Checkbox
                                                      checked={Object.keys(ims).length === 0 ? false : ims.imsregImstoBeEntered}
                                                      onChange={() => { change('imsregImstoBeEntered') }}
                                                      name={"IMS to be Entered"}
                                                      color="primary"
                                                    />
                                                  }
                                                  label={"IMS to be Entered"}
                                                />
                                              </FormGroup>
                                            </div>
                                            <div >
                                              <label style={{ fontWeight: "bold", marginTop: '10px' }} htmlFor="user-permissions">Plaintiff Security</label>
                                              <FormGroup style={{ border: "1px solid", paddingLeft: "10px" }} >
                                                <FormControlLabel
                                                  control={
                                                    <Checkbox
                                                      checked={Object.keys(ims).length === 0 ? false : ims.plaintiffSecPlaintiffSignedAgreement}
                                                      onChange={() => { change('plaintiffSecPlaintiffSignedAgreement') }}
                                                      name={"Plaintiff Signed Agreement at Pay-O-Matic"}
                                                      color="primary"
                                                    />
                                                  }
                                                  label={"Plaintiff Signed Agreement at Pay-O-Matic"}
                                                />
                                              </FormGroup>
                                            </div>
                                          </div>
                                          <div className="col-md-12">
                                            <div >
                                              <label style={{ fontWeight: "bold", marginTop: '10px' }} htmlFor="user-permissions">Law Firm Security</label>
                                              <FormGroup style={{ border: "1px solid", paddingLeft: "10px" }} >
                                                <FormControlLabel
                                                  control={
                                                    <Checkbox
                                                      checked={Object.keys(ims).length === 0 ? false : ims.lawFirmSecAttorneySignedAgreement}
                                                      onChange={() => { change('lawFirmSecAttorneySignedAgreement') }}
                                                      name={"Attorney Signed Agreement"}
                                                      color="primary"
                                                    />
                                                  }
                                                  label={"Attorney Signed Agreement"}
                                                />
                                              </FormGroup>
                                            </div>
                                          </div>
                                        </div>
                                        
                                      </div>

                                      <div className="row" className="mt-5">
                                          <div className="col-md-12">
                                            <button type="submit" className="btn btn-primary mr-5">
                                              Save
                                            </button>
                                            <a
                                              href="##"
                                              onClick={handleCancelButton}
                                              className="btn btn-secondary"
                                            >
                                              Cancel
                                            </a>
                                          </div>
                                          </div>
                                    </div>
                                  </div>
                                </div>
                              </div>

                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </Col>
                </Row>
              </form>

            </div>

          </div>
        </div>
      </div>
    </div>
  );
};

export default IMS;
