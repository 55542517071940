import React, { useState, useEffect, useContext } from 'react';
import { useHistory, useParams } from 'react-router';
import { makeStyles, withStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import { Col } from 'reactstrap';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableFooter from '@material-ui/core/TableFooter';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import Pagination from '@material-ui/lab/Pagination';
import VReportsAccountManagerDetail from '@services/VReportsAccountManagerDetail';
import AppContext from '@contexts/AppContext';
import TableSortLabel from '@material-ui/core/TableSortLabel';
import ReactLoading from 'react-loading';
import AccountManagerDetailsFilteringFields from './AccountManagerDetailsFilteringFields';
import '../Styles.css';
import { Link } from 'react-router-dom';
import { FormattedAmount } from '@components/Utils/FormattedAmount';
import Moment from 'moment';
import fileDownload from 'js-file-download';
import { toast } from 'react-toastify';

const StyledTableCell = withStyles((theme) => ({
  head: {
    backgroundColor: '#efefef',
    color: theme.palette.common.black,
  },
  body: {
    fontSize: 14,
  },
}))(TableCell);

const useStyles2 = makeStyles({
  table: {
    minWidth: 500,
  },
});

export default function AccountManagerDetailsGrid() {
  const appContext = useContext(AppContext);
  const classes = useStyles2();
  const [page, setPage] = React.useState(1);
  const [rowsPerPage, setRowsPerPage] = React.useState(15);
  const [filters, setFilters] = useState(appContext.accountManagerDetailsReportFilters);
  const [orderBy, setOrderBy] = useState('AccounntManagerName');
  const [orderDir, setOrderDir] = useState('ASC');
  const [rows, setRows] = useState([]);
  const { accountManagerId } = useParams();
  const history = useHistory();

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  useEffect(() => {
    if (!filters.dateRange || filters.dateRange == '') {
      let tempFilters = {
        ...filters,
        dateRange: 'This month',
      };

      setFilters(tempFilters);
      appContext.accountManagerDetailsReportFilters = tempFilters;
      localStorage.setItem('accountManagerDetailsReportFilters', JSON.stringify(tempFilters));
      loadData(tempFilters);
    } else {
      loadData(filters);
    }
  }, [page, orderBy, orderDir, rowsPerPage]);

  const loadData = (filters) => {
    let reqFilters = filters;
    if (reqFilters.dateRange == '') {
      delete reqFilters.dateRange;
    }

    const userDetails = appContext['user'];
    if (
      userDetails?.roles &&
      userDetails.roles.length == 1 &&
      userDetails.roles[0]?.roleName == 'Account Manager'
    ) {
      reqFilters.AccountManagerPublicId = userDetails.publicId;
    } else if (accountManagerId) {
      reqFilters.AccountManagerPublicId = accountManagerId;
      if (appContext.accountManagerSummaryReportFilters.dateRange) {
        reqFilters.dateRange = appContext.accountManagerSummaryReportFilters.dateRange;
      }
    } else if (reqFilters?.accountManager?.publicId)
      reqFilters.AccountManagerPublicId = reqFilters.accountManager.publicId;

    delete reqFilters.accountManager;
    setIsLoading(true);

    VReportsAccountManagerDetail.search(reqFilters, orderBy, orderDir, page, null, rowsPerPage)
      .then((response) => {
        setRows(response.data.data);
        setPages(response.data.pages.slice(-1)[0]);
        setIsLoading(false);
      })
      .catch((err) => {})
      .finally(() => {
        setIsLoading(false);
      });
  };

  const getAll = () => {
    let tempFilters = {
      dateRange: 'This month',
    };
    setFilters(tempFilters);
    appContext.accountManagerDetailsReportFilters = tempFilters;
    localStorage.setItem('accountManagerDetailsReportFilters', JSON.stringify(tempFilters));
    update(tempFilters);
  };

  const exportTo = (filters) => {
    let reqFilters = filters;
    if (reqFilters.dateRange == '') {
      delete reqFilters.dateRange;
    }
    if (reqFilters?.accountManager?.publicId)
      reqFilters.AccountManagerPublicId = reqFilters.accountManager.publicId;
    delete reqFilters.accountManager;

    setIsLoading(true);

    VReportsAccountManagerDetail.export(reqFilters, orderBy, orderDir, page, null, rowsPerPage)
      .then((response) => {
        const contentDisposition = response.headers['content-disposition'];
        let fileName = 'Data-Export.xlsx';
        if (contentDisposition) {
          const fileNameMatch = contentDisposition.match(/filename="(.+)"/);
          if (fileNameMatch != null && fileNameMatch.length === 2) fileName = fileNameMatch[1];
        }
        fileDownload(response.data, fileName);
        setIsLoading(false);
      })
      .catch((err) => {})
      .finally(() => {
        setIsLoading(false);
      });
  };

  const update = (filters) => {
    let reqFilters = filters;
    if (reqFilters.dateRange == '') {
      delete reqFilters.dateRange;
    }
    if (reqFilters?.accountManager) reqFilters.AccountManagerPublicId = reqFilters.accountManager;
    delete reqFilters.accountManager;

    setIsLoading(true);

    VReportsAccountManagerDetail.search(reqFilters, orderBy, orderDir, page, null, rowsPerPage)
      .then((response) => {
        setRows(response.data.data);
        setPages(response.data.pages.slice(-1)[0]);
        setIsLoading(false);
      })
      .catch((err) => {})
      .finally(() => {
        setIsLoading(false);
      });
  };

  const searchWithFilters = (filters) => {
    var newFilters = { ...filters };
    setFilters(newFilters);
    appContext.accountManagerDetailsReportFilters = newFilters;
    appContext.accountManagerSummaryReportFilters.dateRange = newFilters.dateRange;

    localStorage.setItem(
      'accountManagerDetailsReportFilters',
      JSON.stringify(appContext.accountManagerDetailsReportFilters)
    );
    localStorage.setItem(
      'accountManagerSummaryReportFilters',
      JSON.stringify(appContext.accountManagerSummaryReportFilters)
    );

    if (page === 1) update(newFilters);
    else setPage(1);
  };

  const [modal, setModal] = useState(false);
  const toggle = () => setModal(!modal);
  const [rowIndex, setRowIndex] = useState(undefined);
  const [givenRows, setGivenRows] = useState(undefined);
  const [isLoading, setIsLoading] = useState(false);
  const [pages, setPages] = useState(undefined);

  const [activeFieldForSorting, setActiveFieldForSorting] = useState();

  const arrange = (field) => {
    if (orderBy === field) {
      if (orderDir === 'ASC') {
        setOrderDir('DESC');
      } else {
        setOrderDir('ASC');
      }
    } else {
      setOrderBy(field);
      setOrderDir('ASC');
    }

    setPage(1);
  };

  const handlePageSizeChange = (event) => {
    setRowsPerPage(event.target.value);
  };

  const exportAccountManagerDetails = () => {
    exportTo(filters);
  };

  return (
    <>
      <div className="d-flex mt-5 mb-10">
        <div className="mr-auto">
          <h1>Account Manager Details Report</h1>
        </div>
        <Link
          onClick={exportAccountManagerDetails}
          className="btn btn-primary export-btn "
          style={{
            backgroundColor: 'var(--green)',
            borderColor: 'var(--green)',
          }}
        >
          Export
        </Link>
      </div>
      <AccountManagerDetailsFilteringFields
        searchWithFilters={searchWithFilters}
        getAll={getAll}
      ></AccountManagerDetailsFilteringFields>
      <Col md="10" className="float-right">
        <TableContainer component={Paper}>
          <Table className={classes.table} aria-label="custom pagination table">
            <TableHead>
              <TableRow>
                <StyledTableCell>Id</StyledTableCell>

                <StyledTableCell
                  className="amd-acc-manger-clmn"
                  onClick={() => {
                    arrange('accountManagerName');
                    setActiveFieldForSorting('accountManagerName');
                  }}
                >
                  ACC. MANAGER
                  <TableSortLabel
                    active={activeFieldForSorting === 'accountManagerName' ? true : false}
                    direction={
                      activeFieldForSorting === 'accountManagerName'
                        ? orderDir.toLowerCase()
                        : 'asc'
                    }
                    onClick={() => arrange('accountManagerName')}
                  ></TableSortLabel>
                </StyledTableCell>

                <StyledTableCell
                  className="amd-client-clmn"
                  onClick={() => {
                    arrange('clientName');
                    setActiveFieldForSorting('clientName');
                  }}
                >
                  CLIENT
                </StyledTableCell>

                <StyledTableCell
                  className="amd-attorney-clmn"
                  onClick={() => {
                    arrange('attorney');
                    setActiveFieldForSorting('attorney');
                  }}
                >
                  ATTORNEY
                </StyledTableCell>

                              <StyledTableCell
                                  className="amd-status-clmn"
                                  onClick={() => {
                                      arrange('applicationStatus');
                                      setActiveFieldForSorting('applicationStatus');
                                  }}
                              >
                                  APP STATUS
                </StyledTableCell>

                              <StyledTableCell
                                  className="amd-appNo-clmn"
                                  onClick={() => {
                                      arrange('applicationNo');
                                      setActiveFieldForSorting('applicationNo');
                                  }}
                              >
                                  APP NO
                </StyledTableCell>


                              <StyledTableCell
                  className="amd-status-clmn"
                  onClick={() => {
                    arrange('advanceStatus');
                      setActiveFieldForSorting('advanceStatus');
                  }}
                >
                  ADV. STATUS
                </StyledTableCell>

                <StyledTableCell
                  className="amd-appNo-clmn"
                  onClick={() => {
                    arrange('advanceNo');
                      setActiveFieldForSorting('advanceNo');
                  }}
                >
                  ADV NO
                </StyledTableCell>

                <StyledTableCell
                  className="amd-advancedDt-clmn"
                  onClick={() => {
                    arrange('advanceDate');
                    setActiveFieldForSorting('advanceDate');
                  }}
                >
                  ADVANCE DATE
                  <TableSortLabel
                    active={activeFieldForSorting === 'advanceDate' ? true : false}
                    direction={
                      activeFieldForSorting === 'advanceDate' ? orderDir.toLowerCase() : 'asc'
                    }
                    onClick={() => arrange('advanceDate')}
                  ></TableSortLabel>
                </StyledTableCell>

                <StyledTableCell
                  className="amd-payoffdt-clmn"
                  onClick={() => {
                    arrange('payoffDate');
                    setActiveFieldForSorting('payoffDate');
                  }}
                >
                  PAYOFF DATE
                </StyledTableCell>

                <StyledTableCell
                  className="amd-othrdisbs-clmn"
                  onClick={() => {
                    arrange('otherDisbursements');
                    setActiveFieldForSorting('otherDisbursements');
                  }}
                >
                  OTHER DISBS.
                </StyledTableCell>

                <StyledTableCell
                  className="amd-ctc-clmn"
                  onClick={() => {
                    arrange('cashtoClient');
                    setActiveFieldForSorting('cashtoClient');
                  }}
                >
                  CASH TO CLIENT
                </StyledTableCell>

                <StyledTableCell
                  className="amd-comAdv-clmn"
                  onClick={() => {
                    arrange('commissionableAdvances');
                    setActiveFieldForSorting('commissionableAdvances');
                  }}
                >
                  COMM. ADVANCES
                </StyledTableCell>

                <StyledTableCell
                  className="amd-npffee-clmn"
                  onClick={() => {
                    arrange('nfpfees');
                    setActiveFieldForSorting('nfpfees');
                  }}
                >
                  NFP FEE
                </StyledTableCell>

                <StyledTableCell
                  className="amd-portfolio-clmn"
                  onClick={() => {
                    arrange('portfolioCode');
                    setActiveFieldForSorting('portfolioCode');
                  }}
                >
                  PORTFOLIO
                </StyledTableCell>

                <StyledTableCell
                  className="amd-paymntconf-clmn"
                  onClick={() => {
                    arrange('paymentConfinrmationNo');
                    setActiveFieldForSorting('paymentConfinrmationNo');
                  }}
                >
                  PAYMENT CONF.
                </StyledTableCell>
              </TableRow>
            </TableHead>
            {isLoading === false && (
              <TableBody>
                {rows &&
                  rows.map((row, index) => (
                    <TableRow key={row.name}>
                      <TableCell component="th" scope="row">
                        {row.id}
                      </TableCell>
                      <TableCell className="amd-acc-manger-clmn">
                        {row.accountManagerName}
                      </TableCell>
                      <TableCell className="amd-client-clmn">{row.clientName}</TableCell>
                      <TableCell className="amd-attorney-clmn">{row.attorney}</TableCell>
                      <TableCell className="amd-status-clmn">{row.applicationStatus}</TableCell>
                      <TableCell className="amd-appNo-clmn">{row.applicationNo}</TableCell>
                          <TableCell className="amd-status-clmn">{row.advanceStatus}</TableCell>
                          <TableCell className="amd-appNo-clmn">{row.advanceNo}</TableCell>
                      <TableCell className="amd-advancedDt-clmn">
                        {row?.advanceDate &&
                          Moment(row?.advanceDate?.split('T')[0]).format('MM-DD-YYYY')}
                      </TableCell>
                      <TableCell className="amd-payoffdt-clmn">
                        {row?.payoffDate &&
                          Moment(row?.payoffDate?.split('T')[0]).format('MM-DD-YYYY')}
                      </TableCell>
                      <TableCell className="amd-othrdisbs-clmn">
                        {row?.otherDisbursements
                          ? FormattedAmount.format(parseFloat(row.otherDisbursements).toFixed(2))
                          : '-'}
                      </TableCell>
                      <TableCell className="amd-ctc-clmn">
                        {row?.cashtoClient
                          ? FormattedAmount.format(parseFloat(row.cashtoClient).toFixed(2))
                          : '-'}
                      </TableCell>
                      <TableCell className="amd-comAdv-clmn">
                        {row?.commissionableAdvances
                          ? FormattedAmount.format(
                              parseFloat(row.commissionableAdvances).toFixed(2)
                            )
                          : '-'}
                      </TableCell>
                      <TableCell className="amd-npffee-clmn">
                        {row?.nfpfees
                          ? FormattedAmount.format(parseFloat(row.nfpfees).toFixed(2))
                          : '-'}
                      </TableCell>
                      <TableCell className="amd-portfolio-clmn">{row.portfolioCode}</TableCell>
                      <TableCell className="amd-paymntconf-clmn">
                        {row.paymentConfinrmationNo}
                      </TableCell>
                    </TableRow>
                  ))}
              </TableBody>
            )}
            {isLoading && (
              <TableBody>
                <TableRow>
                  <td></td>
                  <td>
                    <ReactLoading type="bars" color="#7E8299" />
                  </td>
                  <td></td>
                </TableRow>
              </TableBody>
            )}

            <TableFooter></TableFooter>
          </Table>
        </TableContainer>
        <div className="float-left mt-5">
          <select onChange={handlePageSizeChange} className="form-control" defaultValue={15}>
            {[5, 10, 15, 25, 100].map((size) => (
              <option key={size} value={size}>
                {size}
              </option>
            ))}
          </select>
        </div>
        <div className="float-right mt-5">
          <Pagination
            count={pages}
            page={page}
            siblingCount={1}
            boundaryCount={1}
            variant="outlined"
            onChange={handleChangePage}
          />
        </div>
      </Col>
    </>
  );
}
