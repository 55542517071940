import React, { useEffect, useState, useContext, Fragment, useRef } from 'react';
import { Row, Col, Input, Label, Alert, Button, Form, FormGroup, Container } from 'reactstrap';
import { useParams, Link } from 'react-router-dom';
import { useHistory, useLocation } from 'react-router';
import ReactLoading from 'react-loading';
import AppContext from '@contexts/AppContext';
import { useForm } from 'react-hook-form';
import UserService from '@services/UserService';
import SystemRolesService from '@services/SystemRolesService';
import { ErrorMessage } from '@hookform/error-message';
import MultiSelect from 'react-multi-select-component';
import { toast } from 'react-toastify';
import './style.css';

const User = () => {
  const { id } = useParams();
  const [successMessage, setSuccessMessage] = useState('');
  const [errorMessage, setErrorMessage] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const appContext = useContext(AppContext);
  const { register, handleSubmit, watch, errors, formState, getValues } = useForm({
    mode: 'onBlur',
  });
  const history = useHistory();
  const location = useLocation();

  const [user, setUser] = useState({
    publicId: '',
    firstName: '',
    lastName: '',
    password: '',
    userRole: '',
    workingPhoneNo: '',
    workingPhoneExt: '',
    mobilePhoneNo: '',
    emailAddress: '',
    approvalLimit: '',
    frontEndAccountManagerFeePerc: '',
    backEndAccountManagerFeePerc: '',
    notes: '',
    isActive: true,
  });

  const [userBackup, setUserBackup] = useState({
    publicId: '',
    firstName: '',
    lastName: '',
    password: '',
    userRole: '',
    workingPhoneNo: '',
    workingPhoneExt: '',
    mobilePhoneNo: '',
    emailAddress: '',
    approvalLimit: '',
    frontEndAccountManagerFeePerc: '',
    backEndAccountManagerFeePerc: '',
    notes: '',
    isActive: true
  });

  const [userRole, setUserRole] = useState([]);
  const [userRoles, setUserRoles] = useState([]);

  useEffect(() => {
    onSetUserRole();
  }, [userRole]);

  const [errorEmailExists, setErrorEmailExists] = useState(false);

  const makeMenuItemActive = () => {
    document.querySelector('#menuitem-settings').click();
    document
      .querySelector('#kt_header_tab_8 > div > div > ul > li:nth-child(1)')
      .classList.add('menu-item-active');
  };

  useEffect(() => makeMenuItemActive(), []);

  useEffect(() => {
    if (user.emailAddress != '') {
      // check if email exists

      UserService.searchEmail(
        { existingpublicid: user.publicId, emailExact: user.emailAddress },
        null,
        null,
        1,
        null,
        1
      ).then((response) => {
        if (response.status === 200 && response.data.data.length > 0) {
          setErrorEmailExists(true);
        } else {
          setErrorEmailExists(false);
        }
      });
    }
  }, [user.emailAddress]);

  useEffect(() => {
    const LoadUserDetailsIfIdPresent = () => {
      if (id) {
        setIsLoading(true);

        UserService.read(id)
          .then((response) => {
              setUser(response.data);
              setUserBackup(response.data);
              updateUserRole(response.data);
              setIsLoading(false);
          })
          .catch((err) => {})
          .finally(() => {
            setIsLoading(false);
          });
      }
    };

    if (location.state?.user) {
      setIsLoading(true);
      setUser(location.state?.user);
      setUserBackup(location.state?.user);
      updateUserRole(location.state?.user);
      setIsLoading(false);
    } else {
      LoadUserDetailsIfIdPresent();
    }
  }, []);

  useEffect(() => {
    let pageNumber = 1;
    const perPage = 9999;

    const fetchUserRoles = (__prevData = []) => {
      SystemRolesService.search2('', pageNumber, perPage, 'RoleName', 'ASC', {
        permissions: 'No',
      })
        .then((response) => {
          if (!response.data) return;

          const userRolesFetched = response.data.data.map((item) => {
            let newItem = {
              label: item.roleName,
              value: item.roleName,
              ...item,
              rolePermisssions: item.systemRolePermissions,
            };
            return newItem;
          });

          setUserRoles([...__prevData, ...userRolesFetched]);
        })
        .catch((err) => {})
        .finally(() => {});
    };

    fetchUserRoles();
  }, []);

  const updateUserRole = (user) => {
    if (user.roles) {
      user.roles.map((item) => {
        let newItem = {
          ...item,
          label: item.roleName,
          value: item.roleName,
        };

        setUserRole((setUserRole) => [...setUserRole, newItem]);
      });
    } else {
      setUserRole([]);
    }
  };

  const onSetUserRole = () => {
    const duser = { ...user };

    duser.roles = userRole.map((val) => {
      let rolepermissions = [];

      const histRole = userBackup.roles?.filter((role) => role.publicId == val.publicId)[0];
      if (histRole) {
        rolepermissions = histRole.rolePermisssions.map((srp) => {
          return {
            publicId: srp.publicId,
            permittedTaskPublicId: srp.permittedTaskPublicId,
            userRolePublicId: duser.publicId ? duser.publicId : null,
            taskName: srp.taskName,
            taskGroup: srp.taskGroup,
          };
        });
      } else {
        rolepermissions = val.rolePermisssions.map((srp) => {
          return {
            publicId: srp.publicId,
            userRolePublicId: duser.publicId ? duser.publicId : null,
            permittedTaskPublicId: srp.permittedTaskPublicId,
            taskName: srp.taskName,
            taskGroup: srp.taskGroup,
          };
        });
      }

      return {
        publicId: val.publicId,
        userPublicId: id ? id : null,
        systemRolePublicId: val.publicId,
        roleName: val.roleName,
        rolePermisssions: rolepermissions,
      };
    });

    setUser(duser);
  };

  const handleFormSubmitted = (_user) => {
    setIsLoading(true);

    const duser = { ...user, ..._user };

    duser.approvalLimit = duser.approvalLimit ? parseFloat(duser.approvalLimit) : undefined;
    duser.frontEndAccountManagerFeePerc = duser.frontEndAccountManagerFeePerc
      ? +duser.frontEndAccountManagerFeePerc
      : undefined;
    duser.backEndAccountManagerFeePerc = duser.backEndAccountManagerFeePerc
      ? +duser.backEndAccountManagerFeePerc
      : undefined;
    if (!duser.publicId) {
      delete duser['publicId'];

      UserService.create(duser)
        .then((response) => {
          appContext.toastMessage.message = 'User has been created successfully.';
          setSuccessMessage('');
          setIsLoading(false);
          history.push('/settings/users');
        })
        .catch((err) => {})
        .finally(() => {
          setIsLoading(false);
        });
    } else {
      UserService.update(duser)
        .then((response) => {
          appContext.toastMessage.message = 'User has been updated successfully.';
          setSuccessMessage('');
          setIsLoading(false);
          history.push('/settings/users');
        })
        .catch((err) => {})
        .finally(() => {
          setIsLoading(false);
        });
    }
  };

  const handleCancelButton = () => {
    history.push('/settings/users');
  };

  const form = useRef();

  const hideForm = () => {
    form.current.style.display = 'none';
  };

  const managePermissions = () => {
    if (userRole.length === 0) {
      alert('No roles selected for the user. Please assign a role to manage permissions.');
      return;
    } else {
      const currentFormValues = getValues();
      const _user = { ...user, ...currentFormValues };

      let userId = id;
      if (id == undefined) {
        userId = 'new-user';
      }
      history.push(`/settings/user-manage-permissions/` + userId, { user: _user });
    }
  };

  if (isLoading) {
    return (
      <div className="container mt-10 mb-10">
        <div className="row">
          <div className="col-md-12 mx-auto bg-white box-shadow-border">
            <div className="p-10">
              <div className="mt-5 mb-10">
                <ReactLoading type="bars" color="#7E8299" />
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }

  return (
    <Fragment>
      <Container className="mt-10 mb-10">
        <Row>
          <Col md="12" className="mx-auto bg-white box-shadow-border">
            <div className="p-10">
              <div className="mt-5 mb-10">
                <h1>{!id ? 'New User' : 'User Details'}</h1>

                <Row>
                  <Col md="7 pt-5 pb-5">
                    {successMessage && <Alert color="success">{successMessage}</Alert>}
                    {successMessage && hideForm()}
                    {errorMessage && (
                      <Alert color="danger" className="mt-3">
                        {errorMessage}
                      </Alert>
                    )}
                  </Col>
                </Row>

                <Container className="formContainer p-0">
                  <form ref={form} onSubmit={handleSubmit(handleFormSubmitted)} noValidate>
                    <div className="row">
                      <div className="col-md-2">
                        <div className="form-group">
                          <label htmlFor="firstName">First Name</label>
                          <input
                            type="text"
                            name="firstName"
                            className="form-control"
                            required="required"
                            style={{ width: '150px' }}
                            defaultValue={user.firstName}
                            ref={register({
                              required: 'First Name is required',
                              maxLength: { value: 20, message: 'Max length is 20 characters' },
                            })}
                          />
                          {errors.firstName && (
                            <Alert color="danger" className="mt-3">
                              <ErrorMessage errors={errors} name="firstName" />
                            </Alert>
                          )}
                        </div>
                      </div>

                      <div className="col-md-2">
                        <div className="form-group">
                          <label htmlFor="lastName">Last Name</label>
                          <input
                            type="text"
                            name="lastName"
                            className="form-control"
                            required="required"
                            style={{ width: '200px' }}
                            defaultValue={user.lastName}
                            ref={register({
                              required: 'Last Name is required',
                              maxLength: { value: 20, message: 'Max length is 20 characters' },
                            })}
                          />
                          {errors.lastName && (
                            <Alert color="danger" className="mt-3">
                              <ErrorMessage errors={errors} name="lastName" />
                            </Alert>
                          )}
                        </div>
                      </div>

                      <div className="col-md-2">
                        <div className="form-group">
                          <input
                            type="checkbox"
                            name="isActive"
                            {...(!id && { disabled: true })}
                            className="mr-2"
                            defaultChecked={user.isActive}
                            ref={register}
                          />
                          <label htmlFor="isActive">Active</label>
                          {errors.isActive && (
                            <Alert color="danger" className="mt-3">
                              <ErrorMessage errors={errors} name="isActive" />
                            </Alert>
                          )}
                        </div>
                      </div>
                    </div>

                    <Row>
                      <Col md="3">
                        <FormGroup>
                          <label htmlFor="password">Password</label>
                          {!id && (
                            <input
                              type="password"
                              name="password"
                              className="form-control"
                              required="required"
                              style={{ width: '200px' }}
                              defaultValue={user.password}
                              ref={register({
                                required: 'Password is required',
                                pattern: {
                                  value: /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[\W\_])[A-Za-z\d\W\_]{8,}$/,
                                  message:
                                    'Password must be at least 8 characters and include at least: 1 lowercase letter, 1 uppercase letter, 1 number, 1 special character.',
                                },
                                maxLength: { value: 200, message: 'Max length is 200 characters' },
                              })}
                            />
                          )}
                          {id && (
                            <input
                              type="password"
                              name="password"
                              className="form-control"
                              required="required"
                              style={{ width: '200px' }}
                              defaultValue={user.password}
                              ref={register({
                                pattern: {
                                  value: /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[\W\_])[A-Za-z\d\W\_]{8,}$/,
                                  message:
                                    'Password must be at least 8 characters and include at least: 1 lowercase letter, 1 uppercase letter, 1 number, 1 special character.',
                                },
                                maxLength: { value: 200, message: 'Max length is 200 characters' },
                              })}
                            />
                          )}
                          {errors.password && (
                            <Alert color="danger" className="mt-3">
                              <ErrorMessage errors={errors} name="password" />
                            </Alert>
                          )}
                        </FormGroup>
                      </Col>
                    </Row>

                    <Row>
                      <Col md="3">
                        <FormGroup>
                          <label htmlFor="userRole">User Role</label>

                          <MultiSelect
                            options={userRoles}
                            labelledBy={'Select'}
                            value={userRole}
                            onChange={setUserRole}
                          />
                        </FormGroup>
                      </Col>

                      <div
                        className="manageLinkColor btn"
                        onClick={() => {
                          managePermissions();
                        }}
                      >
                        Manage Permissions
                      </div>
                    </Row>

                    <Row>
                      <Col md="3">
                        <FormGroup>
                          <label htmlFor="workingPhoneNo">Working Phone #</label>
                          <input
                            type="text"
                            name="workingPhoneNo"
                            className="form-control"
                            style={{ width: '200px' }}
                            defaultValue={user.workingPhoneNo}
                            ref={register({
                              required: { value: true, message: 'Working Phone No is required' },
                              maxLength: { value: 20, maxLength: 'Max length is 20 characters.' },
                            })}
                          />
                          {errors.workingPhoneNo && (
                            <Alert color="danger" className="mt-3">
                              <ErrorMessage errors={errors} name="workingPhoneNo" />
                            </Alert>
                          )}
                        </FormGroup>
                      </Col>

                      <Col md="3">
                        <FormGroup>
                          <label htmlFor="WorkingPhoneExt">Ext</label>
                          <input
                            type="text"
                            name="workingPhoneExt"
                            className="form-control"
                            style={{ width: '60px' }}
                            defaultValue={user.workingPhoneExt}
                            ref={register({
                              maxLength: { value: 5, maxLength: 'Max length is 5 characters.' },
                            })}
                          />
                          {errors.WorkingPhoneExt && (
                            <Alert color="danger" className="mt-3">
                              <ErrorMessage errors={errors} name="workingPhoneExt" />
                            </Alert>
                          )}
                        </FormGroup>
                      </Col>
                    </Row>

                    <Row>
                      <Col md="3">
                        <div className="form-group">
                          <label htmlFor="mobilePhoneNo">Mobile #</label>
                          <input
                            type="text"
                            name="mobilePhoneNo"
                            className="form-control"
                            style={{ width: '200px' }}
                            defaultValue={user.mobilePhoneNo}
                            ref={register({
                              maxLength: { value: 20, maxLength: 'Max length is 20 characters.' },
                            })}
                          />
                          {errors.MobilePhoneNo && (
                            <Alert color="danger" className="mt-3">
                              <ErrorMessage errors={errors} name="MobilePhoneNo" />
                            </Alert>
                          )}
                        </div>
                      </Col>
                    </Row>

                    <Row>
                      <Col md="3">
                        <div className="form-group">
                          <label htmlFor="emailAddress">Email Address</label>
                          <input
                            type="email"
                            name="emailAddress"
                            className="form-control"
                            style={{ width: '450px' }}
                            onKeyUp={(e) => setUser({ ...user, emailAddress: e.target.value })}
                            defaultValue={user.emailAddress}
                            ref={register({
                              required: 'Email is required.',
                              maxLength: { value: 100, message: 'Max length is 100 characters.' },
                              pattern: {
                                value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
                                message: 'Invalid email address provided.',
                              },
                            })}
                          />
                          {errors.emailAddress && (
                            <Alert color="danger" className="mt-3">
                              <ErrorMessage errors={errors} name="emailAddress" />
                            </Alert>
                          )}
                          {errorEmailExists == true && (
                            <Alert color="danger" className="mt-3">
                              Email allready exists.
                            </Alert>
                          )}
                        </div>
                      </Col>
                    </Row>

                    <div className="form-group">
                      <label htmlFor="approvalLimit">Approval Limit</label>
                      <input
                        type="text"
                        name="approvalLimit"
                        className="form-control"
                        style={{ width: '150px' }}
                        defaultValue={user.approvalLimit}
                        ref={register({
                          value: /^\d{1,}(\.\d{0,2})?$/,
                          message: 'Only numeric values allowed.',
                        })}
                      />
                      {errors.approvalLimit && (
                        <Alert color="danger" className="mt-3">
                          <ErrorMessage errors={errors} name="approvalLimit" />
                        </Alert>
                      )}
                    </div>

                    <Row>
                      <Col md="3">
                        <div className="form-group">
                          <label htmlFor="frontEndAccountManagerFeePerc">
                            Front End Account Manager Fee %
                          </label>
                          <input
                            type="text"
                            name="frontEndAccountManagerFeePerc"
                            className="form-control"
                            style={{ width: '100px' }}
                            defaultValue={user.frontEndAccountManagerFeePerc}
                            ref={register({
                              value: /^\d{1,}(\.\d{0,2})?$/,
                              message: 'Only numeric values allowed.',
                            })}
                          />
                          {errors.frontEndAccountManagerFeePerc && (
                            <Alert color="danger" className="mt-3">
                              <ErrorMessage errors={errors} name="frontEndAccountManagerFeePerc" />
                            </Alert>
                          )}
                        </div>
                      </Col>
                    </Row>

                    <Row>
                      <Col md="3">
                        <div className="form-group">
                          <label htmlFor="backEndAccountManagerFeePerc">
                            Back End Account Manager Fee %
                          </label>
                          <input
                            type="text"
                            name="backEndAccountManagerFeePerc"
                            className="form-control"
                            style={{ width: '100px' }}
                            defaultValue={user.backEndAccountManagerFeePerc}
                            ref={register({
                              value: /^\d{1,}(\.\d{0,2})?$/,
                              message: 'Only numeric values allowed.',
                            })}
                          />
                          {errors.backEndAccountManagerFeePerc && (
                            <Alert color="danger" className="mt-3">
                              <ErrorMessage errors={errors} name="backEndAccountManagerFeePerc" />
                            </Alert>
                          )}
                        </div>
                      </Col>
                    </Row>

                    <div className="form-group">
                      <label htmlFor="notes">Notes</label>
                      <textarea
                        name="notes"
                        rows="8"
                        className="form-control"
                        style={{ width: '450px' }}
                        defaultValue={user.notes}
                        ref={register}
                      />
                      {errors['Notes'] && (
                        <Alert color="danger" className="mt-3">
                          {errors['Notes']}
                        </Alert>
                      )}
                    </div>

                    <button type="submit" className="btn btn-primary mr-5">
                      Save
                    </button>
                    <a href="##" onClick={handleCancelButton} className="btn btn-secondary">
                      Cancel
                    </a>
                  </form>
                </Container>
              </div>
            </div>
          </Col>
        </Row>
      </Container>
    </Fragment>
  );
};

export default User;
