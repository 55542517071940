import React from 'react';
import Autosuggest from 'react-autosuggest';
import ClientService from '../../services/ClientService';

class ClientsAutoSuggest extends React.Component {
  constructor(props) {
    super(props);
    this.props = props;
    this.state = {
      value: '',
      suggestions: [],
    };
  }

  getSuggestionValue = (suggestion) => {
    this.props.selectedClient(suggestion);
    this.props.selectedClientName(suggestion.displayName);
    return suggestion.displayName;
  };

  renderSuggestion = (suggestion) => {
    return <span>{suggestion.displayName}</span>;
  };

  onChange = (event, { newValue, method }) => {
    this.setState({
      value: newValue,
    });
    if (!newValue || newValue == "") {
      this.props.selectedClientName('');
      this.props.selectedClient({});
    }
  };

  onSuggestionsFetchRequested = ({ value }) => {

    ClientService.autoSuggest(value).then((response) => {
      this.setState({ suggestions: response.data.data });
    });

  };

  onSuggestionsClearRequested = () => {
    this.setState({
      suggestions: [],
    });
  };

  componentDidMount = () => {
    this.setState({ suggestions: [], value: this.props.defaultClient?.displayName ? this.props.defaultClient.displayName : '' });
  };
  componentDidUpdate(prevProps, prevState) {
    if (prevProps.defaultClient != this.props.defaultClient) {
      this.setState({
        value: this.props.defaultClient?.displayName ? this.props.defaultClient.displayName : ''
      })
    }
  }

  render() {
    const { value, suggestions } = this.state;
    const inputProps = {
      placeholder: 'Search Clients...',
      value,
      onChange: this.onChange,
    };

    return (
      <Autosuggest
        suggestions={suggestions}
        onSuggestionsFetchRequested={this.onSuggestionsFetchRequested}
        onSuggestionsClearRequested={this.onSuggestionsClearRequested}
        getSuggestionValue={this.getSuggestionValue}
        renderSuggestion={this.renderSuggestion}
        inputProps={inputProps}
      />
    );
  }
}

export default ClientsAutoSuggest;
