import React, { useEffect, useContext, useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { Row, Col, Modal, ModalHeader, ModalBody, ModalFooter, Button } from 'reactstrap';
import { Link } from 'react-router-dom';
import { toast } from 'react-toastify';
import AppContext from '@contexts/AppContext';
import ApplicationsNewMenu from '../Menu/ApplicationsNewMenu';
import Header from '../Header';

import Grid from './Grid';
import NewDisbursement from './NewDisbursement/NewDisbursement';

const Disbursement = (props) => {
    const appContext = useContext(AppContext);
    const history = useHistory();
    const { applicationId, advanceId } = useParams();
    let application = appContext.currentApplication;
    const [modal, setModal] = useState(null);
    const [edit, setEdit] = useState(null);
    const [editPublicId, setEditPublicId] = useState(null);

    useEffect(() => {
        if (!applicationId) {
            appContext.toastMessage.message = 'Application No was not found!';
            history.push('/underwriting/applications/new-application/');
            return;
        }
        let item = document.querySelector('.disbs');
        if (item) {
            item.classList.add('active');
        }
    }, []);

    useEffect(() => {
        if (appContext.toastMessage.message)
            toast.success(appContext.toastMessage.message, { autoClose: 3000 });
        appContext.toastMessage.message = null;
    }, [appContext.toastMessage.message, history]);

    const handleNewItem = () => {
        let path = applicationId ? applicationId : 'new-application';
        history.push(`/underwriting/applications/${path}/advances/${advanceId}/disbursement/new-disbursement`);
    };
    const newDisbursement = () => {
        setEdit(false);
        toggleModal();
    }
    const editDisbursement = (publicId) => {
        setEdit(true);
        setEditPublicId(publicId);
        toggleModal();
    }
    const toggleModal = () => { setModal(!modal); }

    return (
        <div className="container mt-10 mb-10">
            <div className="row">
                <div className="col-md-12 bg-white box-shadow-border">
                    <div className="p-10">
                        <div className="mt-5 mb-10">
                            <Modal isOpen={modal} toggle={toggleModal}>
                                <ModalBody>
                                    <NewDisbursement editPublicId={editPublicId} edit={edit} advanceId={props.advanceId} toggle={toggleModal} />
                                </ModalBody>
                            </Modal>
                            <Header applicationId={applicationId} />
                            <ApplicationsNewMenu applicationId={applicationId} menuItemIndex={8} />
                            <div className="row"
                                style={{
                                    justifyContent: 'space-between',
                                    width: '15%',
                                    marginLeft: '0px',
                                    paddingBottom: '3px',
                                    marginBottom: "10px",
                                    cursor: "pointer"
                                }}
                            >
                                <p
                                    onClick={() => {
                                        history.push(`/underwriting/applications/${applicationId}/advances/${advanceId}/product`);
                                    }}>
                                    Financials
                                            </p>
                                <p
                                    style={{
                                        borderBottom: '1px solid #3699FF',
                                        color: "#3699FF"
                                    }}
                                    onClick={() => {
                                    }}>Disbursement</p>
                            </div>
                            <div className="row">
                                <Col md="4" className="row">

                                </Col>
                                <Col md="8" className="float-right"><Link
                                    onClick={handleNewItem}
                                    className="btn btn-primary float-right"
                                    style={{
                                        backgroundColor: 'var(--green)',
                                        borderColor: 'var(--green)',
                                    }}
                                >
                                    New Disbursement
              </Link></Col>

                            </div>

                        </div>
                        <Row>
                            <Col md="12">
                                <Grid advanceId={props.advanceId} edit={editDisbursement} />
                            </Col>
                        </Row>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Disbursement;

