import axios from 'axios';
import { auth, resError } from '@services/Helpers/ServiceInterceptor';

const api = axios.create({
    baseURL: process.env.REACT_APP_API_URL + '/VReportsStaticPoolAnalysis/',
});

api.interceptors.request.use((config) => auth(config));
api.interceptors.response.use(
    (response) => {
        return response;
    },
    (error) => resError(error)
);

class VReportsStaticPoolAnalysisService {

    constructor() {
        if (!VReportsStaticPoolAnalysisService.instance) {
            VReportsStaticPoolAnalysisService.instance = this;
        }
        return VReportsStaticPoolAnalysisService.instance;
    }

    search = (filters, orderBy, orderDir, p, fields, perPage) => {
        return api.post('/search', { filters: Object.keys(filters).length > 0 ? filters : null, orderBy, orderDir, page: p, fields: undefined, perPage: parseInt(perPage) });
    };

    export = (filters, orderBy, orderDir, p, fields, perPage) => {
        return api.post('/export', { filters: Object.keys(filters).length > 0 ? filters : {}, orderBy, orderDir, page: p, fields: undefined, perPage: parseInt(perPage) }, {
            responseType: 'blob',
        });
    }
}

const instance = new VReportsStaticPoolAnalysisService();
Object.freeze(instance);

export default instance;