import React, { useRef, useState } from 'react';
import { Link, useHistory } from 'react-router-dom';
import lasurisLogo from '@images/lasuris_logo.png';
import './ForgotPassword.css';
import { toast } from 'react-toastify';
import AuthService from '@services/AuthService';
import { useForm } from 'react-hook-form';
import { Alert } from 'reactstrap';
import { ErrorMessage } from '@hookform/error-message';

const ForgotPassword = () => {
  const history = useHistory();
  const [errorMessage, setErrorMessage] = useState('');
  const [email, setEmail] = useState('');
  const [ifResetSentSuccessfully, setIfResetSentSuccessfully] = useState(false);

  const { register, handleSubmit, watch, errors, setError, formState } = useForm({
    mode: 'onBlur',
  });

  const buttonSubmit = (e) => {
    //e.preventDefault();
    AuthService.forgotPassword(e.email)
      .then((res) => {
        setIfResetSentSuccessfully(true);
        // appContext.toastMessage.message = 'You have reset your password successfully.';
        // toast.info(appContext.toastMessage.message, { autoClose: 3000 });
        // history.push('/login');
      })
      .catch((err) => {
        // toast.success(appContext.toastMessage.message, { autoClose: 3000 });
        // setError('email', 'Wrong email.');
      });
    // .finally(() => history.push('/forgotpasswordsuccess'));
  };

  return (
    <div className="d-flex flex-column flex-root flex-center">
      <div className="login login-1 d-flex flex-column flex-lg-row flex-column-fluid bg-white login-forgot-on mt-20 box-shadow-border">
        <div className="login-content flex-row-fluid d-flex flex-column justify-content-center position-relative overflow-hidden p-7 mx-auto">
          <div className="login-form login-forgot bg-white d-flex flex-center">
            {ifResetSentSuccessfully ? (
              <div className="mb-8">
                <div className="d-flex flex-center mb-5">
                  <h1>Password reset request is sent successfully</h1>
                </div>
                <p>
                  If the supplied user exists then an email with instructions on how to reset your
                  password has been sent to your mailbox. Please check there.
                </p>
                <Link
                  to="/login"
                  className="btn btn-primary font-weight-bolder font-size-h6 px-8 py-4 my-3 mr-3"
                >
                  Go to Login
                </Link>
              </div>
            ) : (
              <form
                onSubmit={handleSubmit(buttonSubmit)}
                className="form fv-plugins-bootstrap fv-plugins-framework"
                id="kt_login_forgot_form"
              >
                <div className="mb-8">
                  <div className="d-flex flex-center mb-5">
                    <Link to="/login">
                      <img src={lasurisLogo} alt="logo" />
                    </Link>
                  </div>
                  <h3 className="font-weight-bolder text-dark font-size-h4 font-size-h1-lg d-flex flex-center">
                    Forgotten Password ?
                  </h3>
                  <p className="text-muted font-weight-bold font-size-h4 d-flex flex-center">
                    Enter your email to reset your password
                  </p>
                </div>
                <div className="form-group fv-plugins-icon-container">
                  <input
                    ref={register({ required: 'A value is required.' })}
                    defaultValue={email}
                    className="form-control form-control-solid h-auto py-7 px-6 rounded-lg font-size-h6 d-flex flex-center"
                    type="email"
                    placeholder="Email"
                    name="email"
                    autoComplete="off"
                    required="required"
                  />
                  {errors.email && (
                    <Alert color="danger" className="mt-5">
                      <ErrorMessage errors={errors} name="note" />
                    </Alert>
                  )}
                </div>
                <div className="form-group d-flex flex-wrap pb-lg-0 d-flex flex-center">
                  <input
                    type="submit"
                    id="kt_login_forgot_submit"
                    className="btn btn-primary font-weight-bolder font-size-h6 px-8 py-4 my-3 mr-4"
                    value="Submit"
                  />
                  <input
                    type="button"
                    id="kt_login_forgot_submit"
                    onClick={(_) => history.push('/login')}
                    className="btn btn-secondary font-weight-bolder font-size-h6 px-8 py-4 my-3 mr-4"
                    value="Cancel"
                  />
                </div>
              </form>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default ForgotPassword;
