import React from 'react';
import Autosuggest from 'react-autosuggest';
import UserService from '../../services/UserService';

class AccountManagersAutoSuggest extends React.Component {
  constructor(props) {
    super(props);
    this.props = props;
    this.state = {
      value: '',
      suggestions: [],
    };
  }

  getSuggestionValue = (suggestion) => {
    this.props.selectedAccountManager(suggestion);
    this.props.selectedAccountManagerName(suggestion.displayName);
    return suggestion.displayName;
  };

  renderSuggestion = (suggestion) => {
    return <span>{suggestion.displayName}</span>;
  };

  onChange = (event, { newValue, method }) => {
    this.setState({
      value: newValue,
    });
    if (!newValue || newValue == '') {
      this.props.selectedAccountManagerName('');
      this.props.selectedAccountManager({});
    }
  };

  onSuggestionsFetchRequested = ({ value }) => {
    UserService.searchAccountManagers(value).then((response) => {
      this.setState({ suggestions: response.data.data });
    });
  };

  onSuggestionsClearRequested = () => {
    this.setState({
      suggestions: [],
    });
  };

  componentDidMount = () => {
    this.setState({
      suggestions: [],
      value: this.props.defaultAccountManager?.displayName
        ? this.props.defaultAccountManager.displayName
        : '',
    });
  };

  componentDidUpdate(prevProps, prevState) {
    if (prevProps.defaultAccountManager != this.props.defaultAccountManager) {
      this.setState({
        value: this.props.defaultAccountManager?.displayName
          ? this.props.defaultAccountManager.displayName
          : '',
      });
    }
  }

  render() {
    const { value, suggestions } = this.state;
    const inputProps = {
      placeholder: 'Search Account Manager...',
      value,
      onChange: this.onChange,
      disabled: this.props.makeDisabled ? true : false,
    };

    return (
      <Autosuggest
        suggestions={suggestions}
        onSuggestionsFetchRequested={this.onSuggestionsFetchRequested}
        onSuggestionsClearRequested={this.onSuggestionsClearRequested}
        getSuggestionValue={this.getSuggestionValue}
        renderSuggestion={this.renderSuggestion}
        inputProps={inputProps}
      />
    );
  }
}

export default AccountManagersAutoSuggest;
