/* eslint-disable jsx-a11y/label-has-associated-control */
import React, { useEffect, useState, useContext, Fragment, useRef } from 'react';
import { Row, Col, Input, Label, Alert, Button, Form, FormGroup, Container } from 'reactstrap';
import { useParams, Link } from 'react-router-dom';
import { v4 } from 'uuid';
import { useHistory } from 'react-router';
import ReactLoading from 'react-loading';
import AppContext from '@contexts/AppContext';
import { ErrorMessage } from '@hookform/error-message';
import FinancialStoreService from '@services/FinancialStoreService';
import { useForm } from 'react-hook-form';
import StateSelect from '@components/StateSelect';
import { toast } from 'react-toastify';

const NewFinancialStore = () => {
  let isChanged = false;
  const { id } = useParams();
  const [successMessage, setSuccessMessage] = useState('');
  const [errorMessage, setErrorMessage] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const appContext = useContext(AppContext);
  const { register, handleSubmit, watch, errors, formState } = useForm({ mode: 'onBlur' });
  const history = useHistory();
  const form = useRef();

  const [financialStore, setFinancialStore] = useState({
    publicId: '',
    companyName: '',
    cashProcessingFee: '',
    firstName: '',
    lastName: '',
    streetAddress: '',
    city: '',
    state: '',
    zipCode: '',
    emailAddress: '',
    webSite: '',
    bankName: '',
    bankStreetAddress: '',
    bankCity: '',
    bankState: '',
    bankZipCode: '',
    bankNo: '',
    bankRoutingNo: '',
    bankAccountNo: '',
    notes: '',
    isActive: true,
  });

  const makeMenuItemActive = () => {
    document.querySelector('#menuitem-contacts').click();
    document
      .querySelector('#kt_header_tab_7 > div > div > ul > li:nth-child(4)')
      .classList.add('menu-item-active');
  };

  useEffect(() => makeMenuItemActive(), []);

  useEffect(() => {
    const LoadFinancialStoreDetailsIfIdPresent = () => {
      if (id) {
        setIsLoading(true);
        FinancialStoreService.read(id).then(
          (response) => {
            if (response.data) {
              setFinancialStore(response.data);
              setIsLoading(false);
            }
          })
          .catch((err) => {
            
          })
          .finally(() => {
            setIsLoading(false);
          });

      }
    };

    LoadFinancialStoreDetailsIfIdPresent();
  }, []);

  const saveFinancialStore = (financialStore) => {
    
    setIsLoading(true);

    const fs = { ...financialStore };

    if (fs.cashProcessingFee != undefined) {
      fs.cashProcessingFee = parseFloat(fs.cashProcessingFee);
    }

    setFinancialStore(fs);

    if (!id) {
      fs.publicId = v4();
      setFinancialStore(fs);
      FinancialStoreService.create(fs)
        .then((response) => {
          appContext.toastMessage.message = 'Financial store has been successfully created';
          setIsLoading(false);
          history.push('/contacts/financial-stores');
        })
        .catch((err) => {
          
        })
        .finally(() => {
          setIsLoading(false);
        });
        
    } else {

      if(!(appContext?.userPermissions?.indexOf('Edit Financial Stores') > -1)){
        appContext.toastMessage.message = 'You do not have permissions to perform this action.';
        toast.error(appContext.toastMessage.message, { autoClose: 3000 });
        appContext.toastMessage.message = null;
        return;
      }

      fs.publicId = id;
      
      FinancialStoreService.update(fs)
        .then((response) => {
          appContext.toastMessage.message = 'Financial store has been successfully saved.';
          setSuccessMessage('');
          setIsLoading(false);
          history.push('/contacts/financial-stores');
        })
        .catch((err) => {
          
        })
        .finally(() => {
          setIsLoading(false);
        });
        
    }
  };

  const changeActive = (e) => {
    isChanged = true;
    var fs = { ...financialStore };
    fs.isActive = e.target.checked;
    setFinancialStore(fs);
  };

  const changeNewCashEntered = (e) => {
    isChanged = true;
    var fs = { ...financialStore };
    fs.newCashEntered = e.target.checked;
    setFinancialStore(fs);
  };

  const changeCashFundingConfirmation = (e) => {
    isChanged = true;
    var fs = { ...financialStore };
    fs.cashFundingConfirmation = e.target.checked;
    setFinancialStore(fs);
  };

  const hideForm = () => {
    form.current.style.display = 'none';
  };

  const handleCancelButton = () => {
    history.push('/contacts/financial-stores');
  };

  if (isLoading) {
    return (
      <div className="container mt-10 mb-10">
        <div className="row">
          <div className="col-md-12 mx-auto bg-white box-shadow-border">
            <div className="p-10">
              <div className="mt-5 mb-10">
                {errorMessage && (
                  <div className="alert alert-custom alert-danger mb-10" role="alert">
                    <div className="alert-icon">
                      <i className="flaticon-warning" />
                    </div>
                    <div className="alert-text"> {errorMessage} </div>
                  </div>
                )}

                <ReactLoading type="bars" color="#7E8299" />
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }

  return (
    <Fragment>
      <Container className="mt-10 mb-10">
        <Row>
          <Col md="12" className="mx-auto bg-white box-shadow-border">
            <div className="p-10">
              <div className="mt-5 mb-10">
                <h1>{!id ? 'New Financial Store' : 'Financial Store Details'}</h1>
                <Row>
                  <Col md="7 pt-5 pb-5">
                    {successMessage && <Alert color="success">{successMessage}</Alert>}
                    {successMessage && hideForm()}
                    {errorMessage && (
                      <Alert color="danger" className="mt-3">
                        {errorMessage}
                      </Alert>
                    )}
                  </Col>
                </Row>

                <form ref={form} onSubmit={handleSubmit(saveFinancialStore)} noValidate>
                  <div className="row">
                    <div className="col-md-6">
                      <legend>Financial Store Information</legend>

                      <div className="row">
                        <div className="col-md-7">
                          <div className="form-group">
                            <label htmlFor="companyName">Company Name</label>
                            <input
                              type="text"
                              name="companyName"
                              className="form-control"
                              defaultValue={financialStore.companyName}
                              ref={register({
                                required: 'Company name is required.',
                                maxLength: { value: 100, message: 'Max length is 100 characters.' },
                              })}
                            />
                            {errors.companyName && (
                              <Alert color="danger" className="mt-5">
                                <ErrorMessage errors={errors} name="companyName" />
                              </Alert>
                            )}
                          </div>
                        </div>
                        <div className="col-md-1">
                          <div className="form-group">
                            <input
                              type="checkbox"
                              name="isActive"
                              ref={register}
                              {...(!id && { disabled: true })}
                              defaultChecked={financialStore.isActive}
                              onClick={changeActive}
                              className="mr-2"
                            />
                            <label htmlFor="isActive">Active</label>
                            {errors && errors['IsActive'] && (
                              <Alert color="danger" className="mt-3">
                                {errors['IsActive']}
                              </Alert>
                            )}
                          </div>
                        </div>
                      </div>

                      <div className="row">
                        <div className="col-md-4">
                          <div className="form-group">
                            <label htmlFor="cashProcessingFee">Cash Processing Fee</label>
                            <input
                              type="text"
                              name="cashProcessingFee"
                              className="form-control"
                              defaultValue={financialStore.cashProcessingFee}
                              ref={register({
                                required: 'Cash Processing Fee is required.',
                                pattern: {
                                  value: /^\d{1,}(\.\d{0,2})?$/,
                                  message: 'Only numeric values allowed.',
                                },
                              })}
                            />
                            {errors.cashProcessingFee && (
                              <Alert color="danger" className="mt-5">
                                <ErrorMessage errors={errors} name="cashProcessingFee" />
                              </Alert>
                            )}
                          </div>
                        </div>
                      </div>

                      <div className="row">
                        <div className="col-md-4">
                          <div className="form-group">
                            <input
                              type="checkbox"
                              name="newCashEntered"
                              defaultChecked={financialStore.newCashEntered}
                              onClick={changeNewCashEntered}
                              ref={register}
                            />
                            {errors && errors['NewCashEntered'] && (
                              <Alert color="danger" className="mt-3">
                                {errors['NewCashEntered']}
                              </Alert>
                            )}
                            <label htmlFor="newCashEntered">&nbsp;New Cash Entered</label>
                          </div>
                        </div>

                        <div className="col-md-5">
                          <div className="form-group">
                            <input
                              type="checkbox"
                              name="cashFundingConfirmation"
                              defaultChecked={financialStore.cashFundingConfirmation}
                              onClick={changeCashFundingConfirmation}
                              ref={register}
                            />
                            {errors && errors['CashFundingConfirmation'] && (
                              <Alert color="danger" className="mt-3">
                                {errors['CashFundingConfirmation']}
                              </Alert>
                            )}
                            <label htmlFor="cashFundingConfirmation">
                              &nbsp;Cash Funding Confirmation
                            </label>
                          </div>
                        </div>
                      </div>

                      <fieldset className="mt-6">
                        <legend>Contact Info</legend>

                        <Row>
                          <Col md="8">
                            <div className="form-group">
                              <label htmlFor="streetAddress">Address</label>
                              <input
                                type="text"
                                name="streetAddress"
                                className="form-control"
                                defaultValue={financialStore.streetAddress}
                                ref={register({
                                  maxLength: {
                                    value: 100,
                                    message: 'Max length is 100 characters.',
                                  },
                                })}
                              />
                              {errors.streetAddress && (
                                <Alert color="danger" className="mt-5">
                                  <ErrorMessage errors={errors} name="streetAddress" />
                                </Alert>
                              )}
                            </div>
                          </Col>
                        </Row>

                        <Row>
                          <Col md="8">
                            <div className="form-group">
                              <label htmlFor="city">City</label>
                              <input
                                type="text"
                                name="city"
                                className="form-control"
                                defaultValue={financialStore.city}
                                ref={register({
                                  maxLength: { value: 30, message: 'Max length is 30 characters.' },
                                })}
                              />
                              {errors.city && (
                                <Alert color="danger" className="mt-5">
                                  <ErrorMessage errors={errors} name="city" />
                                </Alert>
                              )}
                            </div>
                          </Col>
                        </Row>

                        <div className="row">
                          <div className="col-md-4">
                            <StateSelect
                              name="state"
                              label="State"
                              id="state"
                              useRef={register({ required: 'State is required.' })}
                              defaultValue={financialStore.state}
                              setObject={setFinancialStore}
                              givenObject={financialStore}
                              errors={errors}
                            />
                          </div>
                          <div className="col-md-4">
                            <div className="form-group">
                              <label htmlFor="zipCode">Zip Code</label>
                              <input
                                type="text"
                                name="zipCode"
                                className="form-control"
                                pattern="[0-9]*"
                                defaultValue={financialStore.zipCode}
                                ref={register({
                                  pattern: {
                                    value: /^\d{5}(?:[-\s]\d{4})?$/,
                                    message: 'The US Zip code provided is wrong.',
                                  },
                                  maxLength: { value: 20, message: 'Max length is 20 characters.' },
                                })}
                              />
                              {errors.zipCode && (
                                <Alert color="danger" className="mt-5">
                                  <ErrorMessage errors={errors} name="zipCode" />
                                </Alert>
                              )}
                            </div>
                          </div>
                        </div>

                        <div className="row">
                          <div className="col-md-4">
                            <div className="form-group">
                              <label htmlFor="workPhoneNo">Work Phone #</label>
                              <input
                                type="text"
                                name="workPhoneNo"
                                className="form-control"
                                defaultValue={financialStore.workPhoneNo}
                                ref={register({
                                  maxLength: { value: 20, message: 'Max length is 20 characters.' },
                                })}
                              />
                              {errors.workPhoneNo && (
                                <Alert color="danger" className="mt-5">
                                  <ErrorMessage errors={errors} name="workPhoneNo" />
                                </Alert>
                              )}
                            </div>
                          </div>

                          <div className="col-md-4">
                            <div className="form-group">
                              <label htmlFor="tollFreePhoneNo">Toll Free</label>
                              <input
                                type="text"
                                name="tollFreePhoneNo"
                                className="form-control"
                                defaultValue={financialStore.tollFreePhoneNo}
                                ref={register({
                                  maxLength: { value: 20, message: 'Max length is 20 characters.' },
                                })}
                              />
                            </div>
                            {errors.tollFreePhoneNo && (
                              <Alert color="danger" className="mt-5">
                                <ErrorMessage errors={errors} name="tollFreePhoneNo" />
                              </Alert>
                            )}
                          </div>
                        </div>

                        <Row>
                          <Col md="8">
                            <div className="form-group">
                              <label htmlFor="emailAddress">Email</label>
                              <input
                                type="text"
                                name="emailAddress"
                                className="form-control"
                                defaultValue={financialStore.emailAddress}
                                ref={register({
                                  required: 'Email is required.',
                                  maxLength: {
                                    value: 100,
                                    message: 'Max length is 100 characters.',
                                  },
                                  pattern: {
                                    value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
                                    message: 'Invalid email address provided.',
                                  },
                                })}
                              />
                              {errors.emailAddress && (
                                <Alert color="danger" className="mt-5">
                                  <ErrorMessage errors={errors} name="emailAddress" />
                                </Alert>
                              )}
                            </div>
                          </Col>
                        </Row>

                        <Row>
                          <Col md="8">
                            <div className="form-group">
                              <label htmlFor="webSite">Website</label>
                              <input
                                type="text"
                                name="webSite"
                                className="form-control"
                                defaultValue={financialStore.webSite}
                                ref={register({
                                  maxLength: {
                                    value: 100,
                                    message: 'Max length is 100 characters.',
                                  },
                                })}
                              />
                              {errors.webSite && (
                                <Alert color="danger" className="mt-5">
                                  <ErrorMessage errors={errors} name="webSite" />
                                </Alert>
                              )}
                            </div>
                          </Col>
                        </Row>
                      </fieldset>
                    </div>

                    <div className="col-md-6">
                      <fieldset>
                        <legend>Bank Account Information</legend>

                        <Row>
                          <Col md="8">
                            <div className="form-group">
                              <label htmlFor="bankName">Bank Name</label>
                              <input
                                type="text"
                                name="bankName"
                                className="form-control"
                                defaultValue={financialStore.bankName}
                                ref={register({
                                  maxLength: { value: 30, message: 'Max length is 30 characters.' },
                                })}
                              />
                              {errors.bankName && (
                                <Alert color="danger" className="mt-5">
                                  <ErrorMessage errors={errors} name="bankName" />
                                </Alert>
                              )}
                            </div>
                          </Col>
                        </Row>

                        <Row>
                          <Col md="8">
                            <div className="form-group">
                              <label htmlFor="bankStreetAddress">Address</label>
                              <input
                                type="text"
                                name="bankStreetAddress"
                                className="form-control"
                                defaultValue={financialStore.bankStreetAddress}
                                ref={register({
                                  maxLength: {
                                    value: 100,
                                    message: 'Max length is 100 characters.',
                                  },
                                })}
                              />
                              {errors.bankStreetAddress && (
                                <Alert color="danger" className="mt-5">
                                  <ErrorMessage errors={errors} name="bankStreetAddress" />
                                </Alert>
                              )}
                            </div>
                          </Col>
                        </Row>

                        <Row>
                          <Col md="8">
                            <div className="form-group">
                              <label htmlFor="bankCity">City</label>
                              <input
                                type="text"
                                name="bankCity"
                                className="form-control"
                                defaultValue={financialStore.bankCity}
                                ref={register({
                                  maxLength: { value: 30, message: 'Max length is 30 characters.' },
                                })}
                              />
                              {errors.bankCity && (
                                <Alert color="danger" className="mt-5">
                                  <ErrorMessage errors={errors} name="bankCity" />
                                </Alert>
                              )}
                            </div>
                          </Col>
                        </Row>

                        <div className="row">
                          <div className="col-md-4">
                            <StateSelect
                              name="bankState"
                              label="State"
                              id="state"
                              useRef={register({ required: 'State is required.' })}
                              defaultValue={financialStore.bankState}
                              setObject={setFinancialStore}
                              givenObject={financialStore}
                              errors={errors}
                            />
                          </div>

                          <div className="col-md-4">
                            <div className="form-group">
                              <label htmlFor="bankZipCode">Zip Code</label>
                              <input
                                type="text"
                                name="bankZipCode"
                                className="form-control"
                                pattern="[0-9]*"
                                defaultValue={financialStore.bankZipCode}
                                ref={register({
                                  pattern: {
                                    value: /^\d{5}(?:[-\s]\d{4})?$/,
                                    message: 'The US Zip code provided is wrong.',
                                  },
                                  maxLength: { value: 20, message: 'Max length is 20 characters.' },
                                })}
                              />
                              {errors.bankZipCode && (
                                <Alert color="danger" className="mt-5">
                                  <ErrorMessage errors={errors} name="bankZipCode" />
                                </Alert>
                              )}
                            </div>
                          </div>
                        </div>

                        <Row>
                          <Col md="4">
                            <div className="form-group">
                              <label htmlFor="bankNo">Bank # (3 digits)</label>
                              <input
                                type="text"
                                maxLength="10"
                                name="bankNo"
                                className="form-control"
                                defaultValue={financialStore.bankNo}
                                ref={register({
                                  maxLength: { value: 10, message: 'Max length is 10 characters.' },
                                })}
                              />
                              {errors.bankNo && (
                                <Alert color="danger" className="mt-5">
                                  <ErrorMessage errors={errors} name="bankNo" />
                                </Alert>
                              )}
                            </div>
                          </Col>

                          <Col md="4">
                            <div className="form-group">
                              <label htmlFor="bankRoutingNo">Bank Routing # (3 digits)</label>
                              <input
                                type="text"
                                maxLength="5"
                                name="bankRoutingNo"
                                className="form-control"
                                defaultValue={financialStore.bankRoutingNo}
                                ref={register({
                                  maxLength: { value: 10, message: 'Max length is 10 characters.' },
                                })}
                              />
                              {errors.bankRoutingNo && (
                                <Alert color="danger" className="mt-5">
                                  <ErrorMessage errors={errors} name="bankRoutingNo" />
                                </Alert>
                              )}
                            </div>
                          </Col>
                        </Row>

                        <Row>
                          <Col md="8">
                            <div className="form-group">
                              <label htmlFor="bankAccountNo">Account #</label>
                              <input
                                type="text"
                                name="bankAccountNo"
                                className="form-control"
                                defaultValue={financialStore.bankAccountNo}
                                ref={register({
                                  maxLength: { value: 20, message: 'Max length is 20 characters.' },
                                })}
                              />
                              {errors.bankAccountNo && (
                                <Alert color="danger" className="mt-5">
                                  <ErrorMessage errors={errors} name="bankAccountNo" />
                                </Alert>
                              )}
                            </div>
                          </Col>
                        </Row>
                      </fieldset>

                      <fieldset>
                        <Row>
                          <Col md="8">
                            <legend>Notes</legend>
                            <div className="form-group">
                              <label htmlFor="notes">Notes</label>
                              <textarea
                                name="notes"
                                rows="8"
                                className="form-control"
                                defaultValue={financialStore.notes}
                                ref={register({
                                  maxLength: {
                                    value: 1000,
                                    message: 'Max length is 1000 characters.',
                                  },
                                })}
                              />
                            </div>
                            {errors.notes && (
                              <Alert color="danger" className="mt-5">
                                <ErrorMessage errors={errors} name="notes" />
                              </Alert>
                            )}
                          </Col>
                        </Row>
                      </fieldset>
                    </div>
                  </div>

                  <button type="submit" className="btn btn-primary mr-5">
                    Save
                  </button>
                  <a href="##" onClick={handleCancelButton} className="btn btn-secondary">
                    Cancel
                  </a>
                </form>
              </div>
            </div>
          </Col>
        </Row>
      </Container>
    </Fragment>
  );
};

export default NewFinancialStore;