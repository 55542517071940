import React, { useContext, useEffect, useState } from 'react';
import { Link, useHistory, useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import ApplicationsMenu from '../Menu';
import AppContext from '@contexts/AppContext';
import { Col, Row } from 'reactstrap';
import PayoffRequestGrid from './PayoffRequest/PayoffRequestGrid';
import ConcessionReqestGrid from './ConcessionRequest/ConcessionReqestGrid';
import { Box } from '@material-ui/core';
import PayoffsGrid from './PayoffsGrid/PayoffsGrid';
import PartialPaymentsGrid from './PartialPayments/PartialPaymentsGrid';

import Header from '../Header';
import ApplicationsService from '@services/ApplicationsService';
import ApplicationPayoffsGridService from '@services/ApplicationPayoffsGridService';

const Payoffs = (props) => {
  const { params } = props.match;
  const application = { id: params.id };
  const { applicationId } = useParams();
  const appContext = useContext(AppContext);
  const history = useHistory();
  const [isPayoffNewEnabled, setIsPayoffNewEnabled] = useState(false);

  const handleSaveAndExit = () => {
    appContext.toastMessage.message = 'Your changes has been saved sucessfully.';
    history.push('/underwriting/applications');
  };

  const wrapperSetIsPayoffNewEnabled = (obj) => setIsPayoffNewEnabled(obj);

  useEffect(() => {
    let item = document.querySelector('.payoffs');
    if (item) {
      item.classList.add('active');
    }

    if (!applicationId || applicationId == 'undefined') {
      appContext.toastMessage.message = 'Application No was not found!';
      history.push('/underwriting/applications/new-application/');
      return;
    }

    if (appContext.toastMessage.message)
      toast.success(appContext.toastMessage.message, { autoClose: 3000 });
    appContext.toastMessage.message = null;
  }, []);

  return (
    <div className="container mt-10 mb-10">
      <div className="row">
        <div className="col-md-12 mx-auto bg-white box-shadow-border">
          <div className="p-10">
            <div className="mt-5 mb-10">
              <Header applicationId={applicationId} />
              <ApplicationsMenu applicationId={applicationId} />

              <Row className="mb-5">
                <div className="col-md-6">
                  <h3>Payoff Requests</h3>
                </div>
                <div className="col-md-6">
                  {isPayoffNewEnabled && (
                    <a
                      className="btn btn-primary mr-1 btn-sm  float-right"
                      onClick={() =>
                        history.push(
                          `/underwriting/applications/${applicationId}/payoffs/new-payoff-request`
                        )
                      }
                    >
                      New Payoff Request
                    </a>
                  )}
                </div>
              </Row>
              <Row>
                <Col applicationId={applicationId} md="12">
                  <PayoffRequestGrid />
                </Col>
              </Row>

              <hr />

              <Box m={5}></Box>
              <Row className="mb-5">
                <div className="col-md-6">
                  <h3>Concession Requests</h3>
                </div>
                <div className="col-md-6">
                  {isPayoffNewEnabled && (
                    <a
                      className="btn btn-primary mr-1 btn-sm  float-right"
                      onClick={() =>
                        history.push(
                          `/underwriting/applications/${applicationId}/payoffs/concession-request/new-concession-request`
                        )
                      }
                    >
                      New Concession Request
                    </a>
                  )}
                </div>
              </Row>
              <Row>
                <Col applicationId={applicationId} md="12">
                  <ConcessionReqestGrid />
                </Col>
              </Row>
              <hr />
              <PayoffsGrid
                isPayoffNewEnabled={isPayoffNewEnabled}
                wrapperSetIsPayoffNewEnabled={wrapperSetIsPayoffNewEnabled}
              />
              <hr />
              <PartialPaymentsGrid
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Payoffs;
