import React, { useEffect, useContext, useRef, useState } from 'react';
import { Row, Col, Alert } from 'reactstrap';
import AppContext from '@contexts/AppContext';
import MultiSelect from 'react-multi-select-component';
import PortfolioService from '@services/PortfolioService';
import GridService from '@services/GridService';
import DateRange from '@components/Filtering/DateRange/DateRange';
import AttorneyAutoSuggest from '@components/AttorneyAutoSuggest/AttorneyAutoSuggest';
import LawFirmAutoSuggest from '@components/LawFirmAutoSuggest/LawFirmAutoSuggest';
import './Styles.css';

const PresentValueFilteringFields = (props) => {
  let dateFilter = useRef();
  let statusFilter = useRef();
  const [statusValue, setStatusValue] = useState([]);
  const [portfolio, setPortfolio] = useState([]);
  const [portfolioOptions, setPortfolioOptions] = useState([]);
  const [dateRange, setDaterange] = useState(false);
  const [dateValue, setDateValue] = useState('Custom');
  const [fromDate, setFromDate] = useState(null);
  const [toDate, setToDate] = useState(null);
  const [presentValueDate, setPresentValueDate] = useState();
  const [asofDate, setAsOfDate] = useState();
  
  const [appStatusOptions, setAppStatusOptions] = useState([]);
  const appContext = useContext(AppContext);
  const presentValueReportFilters = appContext.presentValueReportFilters;

  const [selectedAttorney, setSelectedAttorney] = useState({});
  const [selectedAttorneyError, setSelectedAttorneyError] = useState(undefined);
  const [selectedLawFirm, setSelectedLawFirm] = useState({});

  
  let attorneyFilter = useRef();
  let lawFirmFilter = useRef();

  function defaultFromDate() {
    let fromDate = new Date(2000, 0, 1).toISOString().split('T')[0];
    return fromDate;
  }

  function defaultToDate() {
    let today = new Date();
    let month = today.getMonth();

    var lastDayOfMonth = new Date();
    lastDayOfMonth.setMonth(month + 1);
    lastDayOfMonth.setDate(1);
    lastDayOfMonth.setHours(-5);

    let toDate = lastDayOfMonth.toISOString().split('T')[0];
    return toDate;
  }

  function clearFilters() {
    setDateValue('Custom');
    appContext.presentValueReportFilters = {};
    setDaterange(false);
    setFromDate(defaultFromDate());
    setToDate(defaultToDate());
    setStatusValue([]);
    setPortfolio([]);
    setSelectedAttorney({});
    setSelectedLawFirm({});

    let dateRange = {
      fromDate: new Date(defaultFromDate()).toISOString(),
      toDate: new Date(defaultToDate()).toISOString(),
    };

    var today = new Date();
    var lastDayOfMonth = new Date(today.getFullYear(), today.getMonth() + 1, 0);
    var date = lastDayOfMonth.toISOString().substr(0, 10);
    setPresentValueDate(date);
    setAsOfDate(date);

    props.getAll(dateRange);
  }

  function changePresentValueDate() {
    const presentValDate = document.getElementById('presentValueDate').value;
    setPresentValueDate(presentValDate);
  }

  function changeAsOfDate() {
    const asOfDate = document.getElementById('asOfDate').value;
    setAsOfDate(asOfDate);
  }


  function selectDateRange() {
    if (dateValue !== 'Custom') {
      return dateValue;
    } else {
      if (fromDate != null && toDate != null) {
        let dateRange = {
          fromDate: new Date(fromDate).toISOString(),
          toDate: new Date(toDate).toISOString(),
        };
        return dateRange;
      } else {
        return '';
      }
    }
  }

  function search() {
    const tempStatusValue = [];
    const tempPortfolio = [];
    statusValue.map((status) => {
      tempStatusValue.push(status.value);
    });

    portfolio.map((port) => {
      tempPortfolio.push(port.value);
    });

    let filters = {
      dateRange: selectDateRange(),
      presentValueDate: new Date(presentValueDate).toISOString(),
      portfolioName: tempPortfolio.length > 0 ? tempPortfolio : null,
      attorneyPublicId: selectedAttorney.publicId,
      lawFirmPublicId: selectedLawFirm.publicId,
      asofDate: new Date(asofDate).toISOString(),
     };

    if (filters.dateRange === '') delete filters.dateRange;

    props.searchWithFilters(filters);
  }

  useEffect(() => {
    setFromDate(defaultFromDate());
    setToDate(defaultToDate());
    setDaterange(null);

    var today = new Date();
    var lastDayOfMonth = new Date(today.getFullYear(), today.getMonth() + 1, 0);
    var date = lastDayOfMonth.toISOString().substr(0, 10);
    setPresentValueDate(date);
    setAsOfDate(date);

    let isFiltersEmpty = true;

    if (presentValueReportFilters)
      isFiltersEmpty = Object.keys(presentValueReportFilters).length == 0;

    //Get application status options
    GridService.read('/Application/applicationstatuses').then((resp) => {
      if (!resp.data) return;
      if (resp.data.length > 0) {
        let tempStatusOptions = [];
        resp.data.map((item) => {
          tempStatusOptions.push({
            label: item,
            value: item,
          });
          setAppStatusOptions(tempStatusOptions);
        });
        if (
          props.defaultApplicationStatus &&
          props.defaultApplicationStatus !== '' &&
          props.defaultApplicationStatus.length > 0
        ) {
          setStatusValue([]);
          props.defaultApplicationStatus.map((stat) => {
            let newItem = {
              label: stat,
              value: stat,
            };
            setStatusValue((statusValue) => [...statusValue, newItem]);
          });
        }
      }
    });

    let portfolioList = [];

    //Get portfolio list
    PortfolioService.search({}, '', '', 1, null, 50).then((response) => {
      if (!response.data) return;
      if (response.data.data.length > 0) {
        response.data.data.map((item) => {
          portfolioList.push({
            label: item.portfolioName,
            value: item.publicId,
          });
        });
        if (!isFiltersEmpty) {
          if (presentValueReportFilters.portfolioName) {
            if (portfolioList.length > 0) {
              let tempportfolioName = [];
              presentValueReportFilters.portfolioName.map((item) => {
                const portfolio = portfolioList.find((portfolio) => portfolio.value === item);
                tempportfolioName.push({
                  label: portfolio.label,
                  value: item,
                });
              });
              setPortfolio(tempportfolioName);
            }
          }
        }
      }
      setPortfolioOptions(portfolioList);
    });

    //Retaining data by checking with appcontext
    if (!isFiltersEmpty) {
      if (presentValueReportFilters.dateRange) {
        if (typeof presentValueReportFilters.dateRange == 'string') {
          setDateValue(presentValueReportFilters.dateRange);
          setDaterange(false);
          setFromDate(null);
          setToDate(null);
        } else {
          setDaterange(true);
          setDateValue(null);
          setFromDate(presentValueReportFilters.dateRange.fromDate.split('T')[0]);
          setToDate(presentValueReportFilters.dateRange.toDate.split('T')[0]);
        }
      }

      // if (presentValueReportFilters.presentValueDate) {
      //   setPresentValueDate(presentValueReportFilters.presentValueDate.split('T')[0]);
      // }
    }
  }, []);

  useEffect(() => {
    if (presentValueReportFilters && presentValueReportFilters.PortfolioPublicId) {
      if (portfolioOptions.length === 0) return;

      let tempportfolioName = [];
      presentValueReportFilters.PortfolioPublicId.map((item) => {
        const portfolio = portfolioOptions.find((portfolio) => portfolio.value === item);
        tempportfolioName.push({
          label: portfolio.label,
          value: item,
        });
      });
      setPortfolio(tempportfolioName);
    }
  }, [portfolioOptions]);

  return (
    <>
      <Col md="3" className="float-left fin-summary-filtering-section">
        <Row className="mt-5">
          <Col md="6">
            <h2>Filters</h2>
          </Col>
        </Row>

        <DateRange
          setDateValue={setDateValue}
          dateValue={dateValue}
          setDateValue={setDateValue}
          fromDate={fromDate}
          setFromDate={setFromDate}
          toDate={toDate}
          setToDate={setToDate}
          labelText="Advanced Date Range"
        />

        <Row className="mt-5">
          <Col md="12">
            <label htmlFor="status">Present Value</label>
          </Col>
          <Col md="12">
            <div>
              <input
                onChange={changePresentValueDate}
                type="date"
                id="presentValueDate"
                className="form-control"
                required="required"
                value={presentValueDate}
              />
            </div>
          </Col>
        </Row>

        <Row className="mt-5">
          <Col md="12">
            <label htmlFor="status">Payoff Date</label>
          </Col>
          <Col md="12">
            <div>
              <input
                onChange={changeAsOfDate}
                type="date"
                id="asOfDate"
                className="form-control"
                required="required"
                value={asofDate}
              />
            </div>
          </Col>
        </Row>

        <Row className="mt-5">
          <Col md="12">
            <label htmlFor="status">Portfolio</label>
          </Col>
          <Col md="12">
            <div>
              <MultiSelect
                value={portfolio}
                onChange={setPortfolio}
                labelledBy={'Portfolio'}
                options={portfolioOptions}
              />
            </div>
          </Col>
        </Row>

        <Row className="mt-5">
          <Col md="12">
            <label htmlFor="attorney">Attorney</label>
          </Col>
          <Col md="12">
            <AttorneyAutoSuggest
              ref={attorneyFilter}
              selectedAttorney={(value) => {
                setSelectedAttorney(value);
              }}
              selectedAttorneyName={() => {}}
              defaultAttorney={selectedAttorney}
            />
            {selectedAttorneyError && (
              <Alert color="danger" className="mt-5">
                <span>Select an attorney</span>
              </Alert>
            )}
          </Col>
        </Row>

        <Row className="mt-5">
          <Col md="12">
            <label htmlFor="lawFirm">Law Firm</label>
          </Col>
          <Col md="12">
            <LawFirmAutoSuggest
              ref={lawFirmFilter}
              selectedLawFirm={(value) => {
                setSelectedLawFirm(value);
              }}
              selectedLawFirmName={() => {}}
              defaultLawFirm={selectedLawFirm}
            />
          </Col>
        </Row>

        <Row className="mt-5 mb-5">
          <Col md="6" className="pr-0">
            <span className="a-link">
              <a className="btn clear-filter-button" onClick={clearFilters}>
                Clear filters
              </a>
            </span>
          </Col>
          <Col md="6">
            <span className="a-link float-right">
              <a className="btn btn-primary search-btn" onClick={search}>
                <i className="fas fa-search"></i>
                Search
              </a>
            </span>
          </Col>
        </Row>
      </Col>
    </>
  );
};

export default PresentValueFilteringFields;
