import React from 'react';

const Header = (props) => {
  return (
    <div className="mb-5">
      <div className="row">
        <div className="col-md-6">
          <h1 style={{ lineHeight: 'unset' }}>
            {(props.applicationId !== undefined) 
            ? 'Payoff Letter Request ' 
            // +  props.applicationId 
            :'New Payoff Letter Request'}</h1>
        </div>
        <div className="col-md-6">
          <div className="float-right">
            <span className="mr-5" style={{ color: 'var(--red)' }}>
              Draft
            </span>
            {/*
            <button
              type="button"
              className="btn btn-primary float-right"
              onClick={props.handleSaveAndExit}
            >
              Save & Exit
            </button>
*/}

          </div>
        </div>
      </div>
    </div>
  );
};

export default Header;
