import React, { useState, useEffect, useContext } from 'react';
import { useHistory, useParams } from 'react-router';
import { makeStyles, withStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import { Col } from 'reactstrap';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableFooter from '@material-ui/core/TableFooter';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import Pagination from '@material-ui/lab/Pagination';
import VReportsCommission from '@services/VReportsCommission';
import AppContext from '@contexts/AppContext';
import TableSortLabel from '@material-ui/core/TableSortLabel';
import ReactLoading from 'react-loading';
import CommissionFilteringFields from './CommissionFilteringFields';
import '../Styles.css';
import { Link } from 'react-router-dom';
import { FormattedAmount } from '@components/Utils/FormattedAmount';
import Moment from 'moment';
import fileDownload from 'js-file-download';
import { toast } from 'react-toastify';

const StyledTableCell = withStyles((theme) => ({
  head: {
    backgroundColor: '#efefef',
    color: theme.palette.common.black,
  },
  body: {
    fontSize: 14,
  },
}))(TableCell);

const useStyles2 = makeStyles({
  table: {
    minWidth: 500,
  },
});

export default function CommissionGrid() {
  const appContext = useContext(AppContext);
  const classes = useStyles2();
  const [page, setPage] = React.useState(1);
  const [rowsPerPage, setRowsPerPage] = React.useState(15);
  const [filters, setFilters] = useState(appContext.commissionReportFilters);
  const [orderBy, setOrderBy] = useState('AccountManagerName');
  const [orderDir, setOrderDir] = useState('ASC');
  const [rows, setRows] = useState([]);
  const { accountManagerId } = useParams();
  const history = useHistory();

  const [modal, setModal] = useState(false);
  const toggle = () => setModal(!modal);
  const [rowIndex, setRowIndex] = useState(undefined);
  const [givenRows, setGivenRows] = useState(undefined);
  const [isLoading, setIsLoading] = useState(false);
  const [pages, setPages] = useState(undefined);

  const [activeFieldForSorting, setActiveFieldForSorting] = useState();

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  useEffect(() => {
    if (
      (!filters || !filters.advanceDateRange || filters.advanceDateRange == '') &&
      (!filters || !filters.payoffDateRange || filters.payoffDateRange == '')
    ) {
      let tempFilters = {
        ...filters,
        advanceDateRange: 'This month',
        payoffDateRange: 'This month',
      };

      setFilters(tempFilters);
      appContext.commissionReportFilters = tempFilters;
      localStorage.setItem('commissionReportFilters', JSON.stringify(tempFilters));
      loadData(tempFilters);
    } else {
      loadData(filters);
    }
  }, [page, orderBy, orderDir, rowsPerPage]);

  const loadData = (filters) => {
    let reqFilters = filters;
    if (reqFilters.advanceDateRange == '') {
      delete reqFilters.advanceDateRange;
    }

    if (reqFilters.payoffDateRange == '') {
      delete reqFilters.payoffDateRange;
    }

    const userDetails = appContext['user'];
    if (
      userDetails?.roles &&
      userDetails.roles.length == 1 &&
      userDetails.roles[0]?.roleName == 'Account Manager'
    ) {
      reqFilters.accountManagerPublicId = userDetails.publicId;
    } else if (accountManagerId) {
      reqFilters.accountManagerPublicId = accountManagerId;

      if (appContext.commissionReportFilters.advanceDateRange) {
        reqFilters.advanceDateRange = appContext.commissionReportFilters.advanceDateRange;
      }

      if (appContext.commissionReportFilters.payoffDateRange) {
        reqFilters.payoffDateRange = appContext.commissionReportFilters.payoffDateRange;
      }
    } else if (reqFilters?.accountManager?.publicId)
      reqFilters.accountManagerPublicId = reqFilters.accountManager.publicId;
    else if (reqFilters?.accountManager)
      reqFilters.accountManagerPublicId = reqFilters.accountManager;

    delete reqFilters.accountManager;

    if (reqFilters.payoffDateRange === '') delete reqFilters.payoffDateRange;

    if (
      !reqFilters.accountManagerPublicId ||
      reqFilters.accountManagerPublicId === null ||
      reqFilters.accountManagerPublicId === {}
    ) {
      clearGrid();
      return;
    }

    setIsLoading(true);

    VReportsCommission.search(reqFilters, orderBy, orderDir, page, null, rowsPerPage)
      .then((response) => {
        setRows(response.data.data);
        setPages(response.data.pages.slice(-1)[0]);
        setIsLoading(false);
      })
      .catch((err) => {})
      .finally(() => {
        setIsLoading(false);
      });
  };

  const getAll = () => {
    let tempFilters = {
      advanceDateRange: 'This month',
      payoffDateRange: 'This month',
    };
    setFilters(tempFilters);
    appContext.commissionReportFilters = tempFilters;
    localStorage.setItem('commissionReportFilters', JSON.stringify(tempFilters));
    update(tempFilters);
  };

  const exportTo = (filters) => {
    let reqFilters = filters;
    if (reqFilters.dateRange == '') {
      delete reqFilters.dateRange;
    }
    if (reqFilters?.accountManager?.publicId)
      reqFilters.accountManagerPublicId = reqFilters.accountManager.publicId;
    else if (reqFilters?.accountManager)
      reqFilters.accountManagerPublicId = reqFilters.accountManager;

    delete reqFilters.accountManager;

    if (reqFilters.payoffDateRange === '') delete reqFilters.payoffDateRange;

    if (!reqFilters.accountManagerPublicId) return;

    setIsLoading(true);

    VReportsCommission.export(reqFilters, orderBy, orderDir, page, null, rowsPerPage)
      .then((response) => {
        const contentDisposition = response.headers['content-disposition'];
        let fileName = 'Data-Export.xlsx';
        if (contentDisposition) {
          const fileNameMatch = contentDisposition.match(/filename="(.+)"/);
          if (fileNameMatch != null && fileNameMatch.length === 2) fileName = fileNameMatch[1];
        }
        fileDownload(response.data, fileName);
        setIsLoading(false);
      })
      .catch((err) => {})
      .finally(() => {
        setIsLoading(false);
      });
  };

  const update = (filters) => {
    let reqFilters = filters;
    if (reqFilters.dateRange == '') {
      delete reqFilters.dateRange;
    }
    if (reqFilters?.accountManager) reqFilters.accountManagerPublicId = reqFilters.accountManager;
    delete reqFilters.accountManager;

    if (reqFilters.payoffDateRange === '') delete reqFilters.payoffDateRange;

    // if (!reqFilters.accountManagerPublicId) return;

    setIsLoading(true);

    VReportsCommission.search(reqFilters, orderBy, orderDir, page, null, rowsPerPage)
      .then((response) => {
        setRows(response.data.data);
        setPages(response.data.pages.slice(-1)[0]);
        setIsLoading(false);
      })
      .catch((err) => {
        clearGrid();
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  const clearGrid = (filters) => {
    setRows([]);
    setPages();
  };

  const searchWithFilters = (filters) => {
    var newFilters = { ...filters };
    setFilters(newFilters);
    appContext.commissionReportFilters = newFilters;
    appContext.accountManagerSummaryReportFilters.dateRange = newFilters.dateRange;

    localStorage.setItem(
      'commissionReportFilters',
      JSON.stringify(appContext.commissionReportFilters)
    );
    localStorage.setItem(
      'accountManagerSummaryReportFilters',
      JSON.stringify(appContext.accountManagerSummaryReportFilters)
    );

    if (page === 1) update(newFilters);
    else setPage(1);
  };

  const arrange = (field) => {
    if (orderBy === field) {
      if (orderDir === 'ASC') {
        setOrderDir('DESC');
      } else {
        setOrderDir('ASC');
      }
    } else {
      setOrderBy(field);
      setOrderDir('ASC');
    }

    setPage(1);
  };

  const handlePageSizeChange = (event) => {
    setRowsPerPage(event.target.value);
  };

  const exportAccountManagerDetails = () => {
    exportTo(filters);
  };

  return (
    <>
      <div className="d-flex mt-5 mb-10">
        <div className="mr-auto">
          <h1>Commission Report</h1>
        </div>
        <Link
          onClick={exportAccountManagerDetails}
          className="btn btn-primary export-btn "
          style={{
            backgroundColor: 'var(--green)',
            borderColor: 'var(--green)',
          }}
        >
          Export
        </Link>
      </div>
      <CommissionFilteringFields
        searchWithFilters={searchWithFilters}
        getAll={getAll}
        clearGrid={clearGrid}
      ></CommissionFilteringFields>
      <Col md="10" className="float-right">
        <TableContainer component={Paper}>
          <Table className={classes.table} aria-label="custom pagination table">
            <TableHead>
              <TableRow>
                <StyledTableCell>Id</StyledTableCell>

                <StyledTableCell
                  className="amd-acc-manger-clmn"
                  onClick={() => {
                    arrange('accountManagerName');
                    setActiveFieldForSorting('accountManagerName');
                  }}
                >
                  ACC. MANAGER
                  <TableSortLabel
                    active={activeFieldForSorting === 'accountManagerName' ? true : false}
                    direction={
                      activeFieldForSorting === 'accountManagerName'
                        ? orderDir.toLowerCase()
                        : 'asc'
                    }
                    onClick={() => arrange('accountManagerName')}
                  ></TableSortLabel>
                </StyledTableCell>

                <StyledTableCell
                  className="amd-client-clmn"
                  onClick={() => {
                    arrange('clientName');
                    setActiveFieldForSorting('clientName');
                  }}
                >
                  CLIENT
                </StyledTableCell>

                <StyledTableCell
                  className="amd-status-clmn"
                  onClick={() => {
                    arrange('applicationStatus');
                    setActiveFieldForSorting('applicationStatus');
                  }}
                >
                  STATUS
                </StyledTableCell>

                <StyledTableCell
                  className="amd-appNo-clmn"
                  onClick={() => {
                    arrange('applicationNo');
                    setActiveFieldForSorting('applicationNo');
                  }}
                >
                  APP #
                </StyledTableCell>

                <StyledTableCell
                  className="amd-appNo-clmn"
                  onClick={() => {
                    arrange('applicationNo');
                    setActiveFieldForSorting('applicationNo');
                  }}
                >
                  ADV #
                </StyledTableCell>

                <StyledTableCell
                  className="amd-advancedDt-clmn"
                  onClick={() => {
                    arrange('advanceDate');
                    setActiveFieldForSorting('advanceDate');
                  }}
                >
                  ADVANCE DATE
                  <TableSortLabel
                    active={activeFieldForSorting === 'advanceDate' ? true : false}
                    direction={
                      activeFieldForSorting === 'advanceDate' ? orderDir.toLowerCase() : 'asc'
                    }
                    onClick={() => arrange('advanceDate')}
                  ></TableSortLabel>
                </StyledTableCell>

                <StyledTableCell
                  className="amd-advancedDt-clmn"
                  onClick={() => {
                    arrange('payoffDate');
                    setActiveFieldForSorting('payoffDate');
                  }}
                >
                  PAYOFF DATE
                  <TableSortLabel
                    active={activeFieldForSorting === 'payoffDate' ? true : false}
                    direction={
                      activeFieldForSorting === 'payoffDate' ? orderDir.toLowerCase() : 'asc'
                    }
                    onClick={() => arrange('payoffDate')}
                  ></TableSortLabel>
                </StyledTableCell>

                <StyledTableCell
                  className="amd-ctc-clmn"
                  onClick={() => {
                    arrange('cashtoClient');
                    setActiveFieldForSorting('cashtoClient');
                  }}
                >
                  CASH TO CLIENT
                </StyledTableCell>

                <StyledTableCell
                  className="amd-ctc-clmn"
                  onClick={() => {
                    arrange('surgicalAmount');
                    setActiveFieldForSorting('surgicalAmount');
                  }}
                >
                  SURGICAL AMT
                </StyledTableCell>

                <StyledTableCell
                  className="amd-ctc-clmn"
                  onClick={() => {
                    arrange('buyoutAmount');
                    setActiveFieldForSorting('buyoutAmount');
                  }}
                >
                  BUYOUT AMT
                </StyledTableCell>

                <StyledTableCell
                  className="amd-ctc-clmn"
                  onClick={() => {
                    arrange('commissionAmount');
                    setActiveFieldForSorting('commissionAmount');
                  }}
                >
                  COMM AMT
                </StyledTableCell>

                <StyledTableCell
                  className="amd-portfolio-clmn"
                  onClick={() => {
                    arrange('portfolioCode');
                    setActiveFieldForSorting('portfolioCode');
                  }}
                >
                  PORTFOLIO
                </StyledTableCell>

                <StyledTableCell
                  className="amd-attorney-clmn"
                  onClick={() => {
                    arrange('attorney');
                    setActiveFieldForSorting('attorney');
                  }}
                >
                  ATTORNEY
                </StyledTableCell>
              </TableRow>
            </TableHead>
            {isLoading === false && (
              <TableBody>
                {rows &&
                  rows.map((row, index) => (
                    <TableRow key={row.name}>
                      <TableCell component="th" scope="row">
                        {row.id}
                      </TableCell>
                      <TableCell className="amd-acc-manger-clmn">
                        {row.accountManagerName}
                      </TableCell>
                      <TableCell className="amd-client-clmn">{row.clientName}</TableCell>
                      <TableCell className="amd-status-clmn">{row.applicationStatus}</TableCell>
                      <TableCell className="amd-appNo-clmn">{row.applicationNo}</TableCell>
                      <TableCell className="amd-appNo-clmn">{row.advanceNo}</TableCell>
                      <TableCell className="amd-advancedDt-clmn">
                        {row?.advanceDate &&
                          Moment(row?.advanceDate?.split('T')[0]).format('MM-DD-YYYY')}
                      </TableCell>
                      <TableCell className="amd-advancedDt-clmn">
                        {row?.payoffDate &&
                          Moment(row?.payoffDate?.split('T')[0]).format('MM-DD-YYYY')}
                      </TableCell>
                      <TableCell className="amd-ctc-clmn">
                        {row?.cashtoClient
                          ? FormattedAmount.format(parseFloat(row.cashtoClient).toFixed(2))
                          : '-'}
                      </TableCell>
                      <TableCell className="amd-ctc-clmn">
                        {row?.surgicalAmount
                          ? FormattedAmount.format(parseFloat(row.surgicalAmount).toFixed(2))
                          : '-'}
                      </TableCell>
                      <TableCell className="amd-ctc-clmn">
                        {row?.buyoutAmount
                          ? FormattedAmount.format(parseFloat(row.buyoutAmount).toFixed(2))
                          : '-'}
                      </TableCell>
                      <TableCell className="amd-ctc-clmn">
                        {row?.commissionAmount
                          ? FormattedAmount.format(parseFloat(row.commissionAmount).toFixed(2))
                          : '-'}
                      </TableCell>
                      <TableCell className="amd-portfolio-clmn">{row.portfolioCode}</TableCell>
                      <TableCell className="amd-attorney-clmn">{row.attorney}</TableCell>
                    </TableRow>
                  ))}
              </TableBody>
            )}
            {isLoading && (
              <TableBody>
                <TableRow>
                  <td></td>
                  <td>
                    <ReactLoading type="bars" color="#7E8299" />
                  </td>
                  <td></td>
                </TableRow>
              </TableBody>
            )}

            <TableFooter></TableFooter>
          </Table>
        </TableContainer>
        <div className="float-left mt-5">
          <select onChange={handlePageSizeChange} className="form-control" defaultValue={15}>
            {[5, 10, 15, 25, 100].map((size) => (
              <option key={size} value={size}>
                {size}
              </option>
            ))}
          </select>
        </div>
        <div className="float-right mt-5">
          <Pagination
            count={pages}
            page={page}
            siblingCount={1}
            boundaryCount={1}
            variant="outlined"
            onChange={handleChangePage}
          />
        </div>
      </Col>
    </>
  );
}
