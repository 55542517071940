import React, { useEffect } from "react";
import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';

const DeleteDocumentPopup = (props) => {
    const {modal, toggle, deleteItem} = props;

    return(
        <div>
        <Modal isOpen={modal} toggle={toggle}>
            <ModalHeader toggle={toggle}>Confirmation</ModalHeader>
            <ModalBody>Are you sure you want to delete this item?</ModalBody>
            <ModalFooter>
                <Button color="primary" onClick={deleteItem}>
                    Delete
            </Button>
                <Button color="secondary" onClick={toggle}>
                    Cancel
            </Button>
            </ModalFooter>
        </Modal>
    </div>
    )
}

export default DeleteDocumentPopup
