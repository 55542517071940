import React, { useState, useEffect, useContext } from 'react';
import { useHistory, useParams } from 'react-router';
import { makeStyles, withStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import { Col } from 'reactstrap';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableFooter from '@material-ui/core/TableFooter';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import Pagination from '@material-ui/lab/Pagination';
import VReportsLawFirmDetail from '@services/VReportsLawFirmDetail';
import AppContext from '@contexts/AppContext';
import TableSortLabel from '@material-ui/core/TableSortLabel';
import ReactLoading from 'react-loading';
import FilteringFields from './FilteringFields';
import '../Styles.css';
import { Link } from 'react-router-dom';
import Moment from 'moment';
import { FormattedAmount } from '../../../../components/Utils/FormattedAmount';
import { toast } from 'react-toastify';
import fileDownload from 'js-file-download';

const StyledTableCell = withStyles((theme) => ({
  head: {
    backgroundColor: '#efefef',
    color: theme.palette.common.black,
  },
  body: {
    fontSize: 14,
  },
}))(TableCell);

const useStyles2 = makeStyles({
  table: {
    minWidth: 500,
  },
});

export default function CustomPaginationActionsTable() {
  const appContext = useContext(AppContext);
  const classes = useStyles2();
  const [page, setPage] = React.useState(1);
  const [rowsPerPage, setRowsPerPage] = React.useState(15);
  const [filters, setFilters] = useState(appContext.lawFirmDetailFilters);
  const [orderBy, setOrderBy] = useState('advanceDate');
  const [orderDir, setOrderDir] = useState('ASC');
  const [rows, setRows] = useState([]);
  const [advancedAmountSum, setAdvancedAmountSum] = useState('$0.00');
  const [averageOutstandigDays, setAverageOutstandigDays] = useState(0);
  const { lawFirmPublicId } = useParams();
  const history = useHistory();

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  useEffect(() => {
    if (!filters.dateRange || filters.dateRange == '') {
      let tempFilters = {
        ...filters,
        dateRange: 'This month',
      };
      setFilters(tempFilters);
      appContext.lawFirmDetailFilters = tempFilters;
      localStorage.setItem('lawFirmDetailFilters', JSON.stringify(tempFilters));

      if (lawFirmPublicId) {
        tempFilters.lawFirm = { publicId: lawFirmPublicId };
        loadData(tempFilters);
      } else if (tempFilters.lawFirm?.publicId) {
        loadData(tempFilters);
      }
    } else {
      if (lawFirmPublicId) {
        filters.lawFirm = { publicId: lawFirmPublicId };
        loadData(filters);
      } else if (filters.lawFirm?.publicId) {
        loadData(filters);
      }
    }
  }, [page, orderBy, orderDir, rowsPerPage]);

  const loadData = (filters) => {
    let reqFilters = filters;
    if (reqFilters && reqFilters.attorney) {
      reqFilters = { ...reqFilters, AttorneyPublicId: reqFilters.attorney.publicId };
    }
    if (reqFilters && reqFilters.lawFirm) {
      reqFilters = { ...reqFilters, LawFirmPublicId: reqFilters.lawFirm.publicId };
    }

    delete reqFilters.lawFirm;
    delete reqFilters.attorney;
    setIsLoading(true);
    VReportsLawFirmDetail.search(reqFilters, orderBy, orderDir, page, null, rowsPerPage)
      .then((response) => {
        setRows(response.data.data);
        setPages(response.data.pages.slice(-1)[0]);
        setIsLoading(false);
      })
      .catch((err) => {})
      .finally(() => {
        setIsLoading(false);
      });
  };

  const getAll = () => {
    let tempFilters = {
      dateRange: 'This month',
    };
    setFilters(tempFilters);
    appContext.lawFirmDetailFilters = tempFilters;
    localStorage.setItem('lawFirmDetailFilters', JSON.stringify(tempFilters));
    update(tempFilters);
  };

  const update = (filters) => {
    let reqFilters = filters;
    if (reqFilters && reqFilters.attorney) {
      reqFilters = { ...reqFilters, AttorneyPublicId: reqFilters.attorney.publicId };
    }
    if (reqFilters && reqFilters.lawFirm) {
      reqFilters = { ...reqFilters, LawFirmPublicId: reqFilters.lawFirm.publicId };
    }
    delete reqFilters.lawFirm;
    delete reqFilters.attorney;

    setIsLoading(true);

    VReportsLawFirmDetail.search(reqFilters, orderBy, orderDir, page, null, rowsPerPage)
      .then((response) => {
        setRows(response?.data?.data);
        setPages(response?.data?.pages?.slice(-1)[0]);
        setIsLoading(false);
      })
      .catch((err) => {})
      .finally(() => {
        setIsLoading(false);
      });
  };

  const exportTo = (filters) => {
    let reqFilters = filters;
    if (reqFilters && reqFilters.attorney) {
      reqFilters = { ...reqFilters, AttorneyPublicId: reqFilters.attorney.publicId };
    }
    if (reqFilters && reqFilters.lawFirm) {
      reqFilters = { ...reqFilters, LawFirmPublicId: reqFilters.lawFirm.publicId };
    }
    delete reqFilters.lawFirm;
    delete reqFilters.attorney;

    setIsLoading(true);

    VReportsLawFirmDetail.export(reqFilters, orderBy, orderDir, page, null, rowsPerPage)
      .then((response) => {
        const contentDisposition = response.headers['content-disposition'];
        let fileName = 'Data-Export.xlsx';
        if (contentDisposition) {
          const fileNameMatch = contentDisposition.match(/filename="(.+)"/);
          if (fileNameMatch != null && fileNameMatch.length === 2) fileName = fileNameMatch[1];
        }
        fileDownload(response.data, fileName);
        setIsLoading(false);
      })
      .catch((err) => {})
      .finally(() => {
        setIsLoading(false);
      });
  };

  const searchWithFilters = (filters) => {
    var newFilters = { ...filters };
    setFilters(newFilters);
    appContext.lawFirmDetailFilters = newFilters;
    localStorage.setItem('lawFirmDetailFilters', JSON.stringify(newFilters));
    if (page === 1) update(newFilters);
    else setPage(1);
  };

  const [modal, setModal] = useState(false);
  const toggle = () => setModal(!modal);
  const [rowIndex, setRowIndex] = useState(undefined);
  const [givenRows, setGivenRows] = useState(undefined);
  const [isLoading, setIsLoading] = useState(false);
  const [pages, setPages] = useState(undefined);

  const [activeFieldForSorting, setActiveFieldForSorting] = useState();

  const arrange = (field) => {
    if (orderBy === field) {
      if (orderDir === 'ASC') {
        setOrderDir('DESC');
      } else {
        setOrderDir('ASC');
      }
    } else {
      setOrderBy(field);
      setOrderDir('ASC');
    }

    setPage(1);
  };

  const handlePageSizeChange = (event) => {
    setRowsPerPage(event.target.value);
  };

  const exportLawFirmDetails = () => {
    exportTo(filters);
  };

  return (
    <>
      <div className="d-flex mt-5 mb-10">
        <div className="mr-auto">
          <h1>Law Firm Details Report</h1>
        </div>
        <Link
          onClick={exportLawFirmDetails}
          className="btn btn-primary lfd-export-button"
          style={{
            backgroundColor: 'var(--green)',
            borderColor: 'var(--green)',
          }}
        >
          Export
        </Link>
      </div>
      <FilteringFields searchWithFilters={searchWithFilters} getAll={getAll}></FilteringFields>
      <Col md="10" className="float-right">
        <TableContainer component={Paper}>
          <Table className={classes.table} aria-label="custom pagination table">
            <TableHead>
              <TableRow>
                <StyledTableCell>Id</StyledTableCell>

                <StyledTableCell className="client-column">CLIENT</StyledTableCell>

                <StyledTableCell className="appNumber-column">APP #</StyledTableCell>

                <StyledTableCell
                  className="advanced-date-column"
                  onClick={() => {
                    arrange('applicationNo');
                    setActiveFieldForSorting('applicationNo');
                  }}
                >
                  ADVANCE DATE
                  <TableSortLabel
                    active={activeFieldForSorting === 'applicationNo' ? true : false}
                    direction={
                      activeFieldForSorting === 'applicationNo' ? orderDir.toLowerCase() : 'asc'
                    }
                    onClick={() => arrange('applicationNo')}
                  ></TableSortLabel>
                </StyledTableCell>

                <StyledTableCell
                  className="accident-date-column"
                  onClick={() => {
                    arrange('accidentTypeName');
                    setActiveFieldForSorting('accidentTypeName');
                  }}
                >
                  ACCIDENT DATE
                  <TableSortLabel
                    active={activeFieldForSorting === 'accidentTypeName' ? true : false}
                    direction={
                      activeFieldForSorting === 'accidentTypeName' ? orderDir.toLowerCase() : 'asc'
                    }
                    onClick={() => arrange('accidentTypeName')}
                  ></TableSortLabel>
                </StyledTableCell>

                <StyledTableCell className="lfdr-advanced-amount-column">
                  ADVANCED AMOUNT
                </StyledTableCell>

                <StyledTableCell className="lfdr-total-cash-outlay-column">
                  TOTAL CASH OUTLAY
                </StyledTableCell>

                <StyledTableCell className="days-outstanding-column">
                  DAYS OUTSTANDING
                </StyledTableCell>

                <StyledTableCell
                  className="attorney-column"
                  onClick={() => {
                    arrange('attorney');
                    setActiveFieldForSorting('attorney');
                  }}
                >
                  ATTORNEY
                  <TableSortLabel
                    active={activeFieldForSorting === 'attorney' ? true : false}
                    direction={
                      activeFieldForSorting === 'attorney' ? orderDir.toLowerCase() : 'asc'
                    }
                    onClick={() => arrange('attorney')}
                  ></TableSortLabel>
                </StyledTableCell>
              </TableRow>
            </TableHead>
            {isLoading === false && (
              <TableBody>
                {rows &&
                  rows.map((row, index) => (
                    <TableRow key={row?.name}>
                      <TableCell component="th" scope="row">
                        {row?.id}
                      </TableCell>

                      <TableCell className="client-column">{row?.client}</TableCell>
                      <TableCell className="appNumber-column">{row?.applicationNo}</TableCell>
                      <TableCell className="advanced-date-column">
                        {row?.advanceDate &&
                          Moment(row?.advanceDate?.split('T')[0]).format('MM-DD-YYYY')}
                      </TableCell>
                      <TableCell className="accident-date-column">
                        {row?.accidentDate &&
                          Moment(row?.accidentDate?.split('T')[0]).format('MM-DD-YYYY')}
                      </TableCell>
                      <TableCell className="lfdr-advanced-amount-column">
                        {row?.advancedAmount
                          ? FormattedAmount.format(parseFloat(row.advancedAmount).toFixed(2))
                          : '-'}
                      </TableCell>
                      <TableCell className="lfdr-total-cash-outlay-column">
                        {row?.contractualCashOutlay
                          ? FormattedAmount.format(parseFloat(row.contractualCashOutlay).toFixed(2))
                          : '-'}
                      </TableCell>
                      <TableCell className="days-outstanding-column">
                        {row?.daysOutstanding}
                      </TableCell>
                      <TableCell className="attorney-column">{row?.attorney}</TableCell>
                    </TableRow>
                  ))}
              </TableBody>
            )}
            {isLoading && (
              <TableBody>
                <TableRow>
                  <td></td>
                  <td>
                    <ReactLoading type="bars" color="#7E8299" />
                  </td>
                  <td></td>
                </TableRow>
              </TableBody>
            )}

            <TableFooter></TableFooter>
          </Table>
        </TableContainer>
        <div className="float-left mt-5">
          <select onChange={handlePageSizeChange} className="form-control" defaultValue={15}>
            {[5, 10, 15, 25, 100].map((size) => (
              <option key={size} value={size}>
                {size}
              </option>
            ))}
          </select>
        </div>
        <div className="float-right mt-5">
          <Pagination
            count={pages}
            page={page}
            siblingCount={1}
            boundaryCount={1}
            variant="outlined"
            onChange={handleChangePage}
          />
        </div>
      </Col>
    </>
  );
}
