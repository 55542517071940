import React, { useEffect, useContext } from 'react';
import { Row, Col, Input, Label, Alert, Button, Form, FormGroup, Container } from 'reactstrap';
import { useHistory, Link } from 'react-router-dom';
import { toast } from 'react-toastify';
import AppContext from '@contexts/AppContext';
import ApplicationsGrid from '../ApplicationsGrid';

const Applications = () => {

  const history = useHistory();
  const appContext = useContext(AppContext);

  useEffect(() => {
    makeMenuItemActive();
    appContext.currentApplication = {};
  }, []);

  useEffect(() => {
    if (!(localStorage.getItem('isUserAuthenticated') === 'true'))
      history.push('/login');

    if (
      appContext.toastMessage.message === 'You have logged in sucessfully.' ||
      appContext.toastMessage.message ===
      'Your changes has been saved sucessfully.'
    )
      toast.success(appContext.toastMessage.message, { autoClose: 3000 });
    appContext.toastMessage.message = null;
  }, [appContext.toastMessage.message, history]);

  const makeMenuItemActive = () => {
    document.querySelector('#menuitem-underwriting').click();
    document
      .querySelector('#kt_header_tab_2 > div > ul > li')
      .classList.add('menu-item-active');
  };

  const createNewApplication = () => {
    appContext.newAppllicationStep = 1;
    if (appContext.currentApplication) {
      appContext.currentApplication.attorneyPublicId = undefined;
      appContext.currentApplication = 'new-application';
      localStorage.setItem('currentApplication', JSON.stringify('new-application'));
    }

    history.push("/underwriting/applications/new-application");
  };

  return (
    <div className="container mt-10 mb-10" style={{ maxWidth: "95%" }}>
      <div className="row">
        <div className="col-md-12 bg-white box-shadow-border">
          <div style={{ padding: "0.5rem" }}>
            <div className="d-flex mt-5 mb-10">
              <div className="mr-auto">
                <h1>Advances</h1>
              </div>
              <Link
                onClick={createNewApplication}
                className="btn btn-primary"
                style={{
                  backgroundColor: 'var(--green)',
                  borderColor: 'var(--green)'
                }}
              >
                New Application
              </Link>
            </div>

            <Row>
              <Col md="12"><ApplicationsGrid /></Col>
            </Row>

          </div>
        </div>
      </div>
    </div>
  );
};

export default Applications;
