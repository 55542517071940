import React, { useContext } from 'react';
import { Route, Redirect, useHistory } from 'react-router-dom';
import AppContext from '@contexts/AppContext';
import { toast } from 'react-toastify';

export const UserPermittedRoute = ({ component: Component, ...rest }) => {

    const appContext = useContext(AppContext);

    return (
        <Route {...rest} render={props => {

            if (appContext?.userPermissions) {

                if (rest.task) {

                    if (rest.task.some(x => appContext?.userPermissions?.indexOf(x) > -1)) {
                        return <Component {...props} />
                    }

                }
            }

            toast.error("You do not have permissions to view this page.");
            //return <Redirect to={{ pathname: props.location.pathname, state: { from: props.location } }} />

        }} />)
}