import React, { useEffect, useContext } from 'react';
import { Row, Col } from 'reactstrap';
import { useHistory } from 'react-router-dom';
import { toast } from 'react-toastify';
import AppContext from '@contexts/AppContext';
import ApplicationsToBeReviewedGrid from './Components/ApplicationsToBeReviewedGrid';

const ApplicationsToBeReviewed = () => {
    const history = useHistory();
    const appContext = useContext(AppContext);

    const makeMenuItemActive = () => {
        document.querySelector('#menuitem-underwriting').click();
        document
            .querySelector('#kt_header_tab_2 > div > ul > li:nth-child(2)')
            .classList.add('menu-item-active');
    };

    useEffect(() => makeMenuItemActive(), []);

    useEffect(() => {
        if (appContext.toastMessage.message)
            toast.success(appContext.toastMessage.message, { autoClose: 3000 });
        appContext.toastMessage.message = null;
    }, [appContext.toastMessage.message, history]);

    return (
        <div className="container mt-10 mb-10" style={{ maxWidth: '95%' }}>
            <div className="row">
                <div className="col-md-12 bg-white box-shadow-border">
                    <div style={{ padding: '0.5rem' }}>
                        <div className="d-flex mt-5 mb-10">
                            <div className="mr-auto">
                                <h1>Applications To Be Reviewed</h1>
                            </div>
                        </div>

                        <Row>
                            <Col md="12">
                                <ApplicationsToBeReviewedGrid />
                            </Col>
                        </Row>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default ApplicationsToBeReviewed;
