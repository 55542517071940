import React, { useEffect } from 'react';
import { Col, Row } from 'reactstrap';
import Grid from './Components/Grid';

const AccountManagerSummary = () => {

  const makeMenuItemActive = () => {
    document.querySelector('#menuitem-reports').click();
    document
      .querySelector('#kt_header_menu > ul > li')
      .classList.add('menu-item-active');
  };

  useEffect(() => makeMenuItemActive(), []);

  return (
    <div className="container mt-10 mb-10 main-container">
      <div className="row">
        <div className="col-md-12 bg-white box-shadow-border col-xl-8 offset-xl-2">
          <div className="p-1 pb-10">
            <div className="d-flex mt-5 mb-10">
              <div className="mr-auto">
                <h1>Account Manager Summary Report</h1>
              </div>
            </div>
            <Row>
              <Col md="12"><Grid /></Col>
            </Row>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AccountManagerSummary;
