import React, { useContext, useEffect, useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import { useForm } from 'react-hook-form';
import ApplicationsNewMenu from '../../../Menu/ApplicationsNewMenu';
import { Alert, Col, Row, Table } from 'reactstrap';
import { ErrorMessage } from '@hookform/error-message';
import AppContext from '@contexts/AppContext';
import ApplicationConcessionRequestService from '@services/ApplicationConcessionRequestService';
import ReactLoading from 'react-loading';
import ApplicationsService from '@services/ApplicationsService';
import Moment from 'moment';
import './style.css';
import AttorneyService from '@services/AttorneyService';

const CONCESSION_REQUEST_TEXT = 'new-concession-request';
const EMPTY_GUID = '00000000-0000-0000-0000-000000000000';
const user = JSON.parse(localStorage.getItem('lasuris-user'));

const NewConcessionRequest = (props) => {
  const appContext = useContext(AppContext);
  const history = useHistory();
  const { applicationId, concessionRequestId } = useParams();
  const [applicationPublicId, setApplicationPublicId] = useState();
  const [isLoading, setIsLoading] = useState(false);
  const [isLoadingCalculations, setIsLoadingCalculations] = useState(false);

  const [canAddConcessionRequests, setCanAddConcessionRequests] = useState(false);
  const [canEditConcessionRequests, setEditConcessionRequests] = useState(false);
  const [canSubmitConcessionRequests, setCanSubmitConcessionRequests] = useState(false);
  const [canApproveConcessionRequests, setCanApproveConcessionRequests] = useState(false);
  const [canDenyConcessionRequests, seCanDenyConcessionRequests] = useState(false);

  const [successMessage, setSuccessMessage] = useState('');
  const [advances, setAdvances] = useState([]);
  const { register, handleSubmit, watch, errors, formState } = useForm({ mode: 'onBlur' });

  const [client, setClient] = useState(
    appContext.currentApplication &&
      appContext.currentApplication &&
      Object.keys(appContext.currentApplication).length > 0
      ? appContext.currentApplication.client
      : ''
  );

  const [attorney, setAttorney] = useState();

  const [formInputValues, setFormInputValues] = useState({
    publicId: concessionRequestId === CONCESSION_REQUEST_TEXT ? EMPTY_GUID : concessionRequestId,
    createdByPublicId: user.publicId,
    isSentforApproval: false,
  });

  useEffect(() => {
    if (appContext?.userPermissions?.indexOf('Add Concession Requests') > 0)
      setCanAddConcessionRequests(true);

    if (appContext?.userPermissions?.indexOf('Edit Concession Requests') > 0)
      setEditConcessionRequests(true);

    if (appContext?.userPermissions?.indexOf('Submit Concession Requests') > 0)
      setCanSubmitConcessionRequests(true);

    if (appContext?.userPermissions?.indexOf('Approve Concession Requests') > 0)
      setCanApproveConcessionRequests(true);

    if (appContext?.userPermissions?.indexOf('Deny Concession Requests') > 0)
      seCanDenyConcessionRequests(true);

    if (appContext.toastMessage.message)
      toast.success(appContext.toastMessage.message, { autoClose: 3000 });
    appContext.toastMessage.message = null;

    let item = document.querySelector('.payoffs');
    if (item) {
      item.classList.add('active');
    }

    ApplicationsService.search({ applicationNo: applicationId }, null, null, 1, {}, 1).then(
      (response) => {
        setApplicationPublicId(response.data.data[0].publicId);

        AttorneyService.read(response.data.data[0].attorneyPublicId).then((response) => {
          setAttorney(response.data.displayName);
        });

        setFormInputValues({
          ...formInputValues,
          applicationPublicId: response.data.data[0].publicId,
        });

        if (concessionRequestId === CONCESSION_REQUEST_TEXT) {
          setIsLoading(true);
          loadInitialData(response.data.data[0].publicId);
        } else {
          setIsLoading(true);
          ApplicationConcessionRequestService.read(concessionRequestId)
            .then((res) => {
              let data = res.data;
              setFormInputValues({ ...formInputValues, ...data });
              //handleCalculateData()
              if (res.data.applicationConcessionRequestAdvances)
                setAdvances(res.data.applicationConcessionRequestAdvances);
              setIsLoading(false);
            })
            .catch((err) => {
              setIsLoading(false);
            });
        }
      }
    );
  }, []);

  const loadInitialData = (applicationPublicId) => {
    var reqData = {
      applicationPublicId: applicationPublicId,
      createdByPublicId: user.publicId,
      concessionPayoffAmount: 0,
      notes: '',
      marketingExpense: 0,
      liabilities: 0,
      caseValueAtConcession: 0,
    };

    ApplicationConcessionRequestService.calculate(reqData)
      .then((res) => {
        if (res.data.advances === '') {
          toast.error('No advances found. Concession request cannot be created!', {
            autoClose: 3000,
          });

          setFormInputValues({
            isApproved: true,
            isSentforApproval: true,
          });
        } else {
          setFormInputValues(res.data);
          if (res.data.applicationConcessionRequestAdvances)
            setAdvances(res.data.applicationConcessionRequestAdvances);
          concessionRequestId = res.data.data.publicId;

          history.push(
            `/underwriting/applications/${applicationId}/payoffs/concession-request/${res.data.data.publicId}`
          );
        }
        setIsLoading(false);
      })
      .catch((err) => {
        setIsLoading(false);
      });
  };

  const handleFormSubmitForApproval = (data) => {
    const reqData = {
      ...formInputValues,
      concessionPayoffAmount: formInputValues.concessionPayoffAmount
        ? parseFloat(formInputValues.concessionPayoffAmount)
        : 0,
      marketingExpense: formInputValues.marketingExpense
        ? parseFloat(formInputValues.marketingExpense)
        : 0,
      liabilities: formInputValues.liabilities ? parseFloat(formInputValues.liabilities) : 0,
      caseValueAtConcession: formInputValues.caseValueAtConcession
        ? parseFloat(formInputValues.caseValueAtConcession)
        : 0,
      createdByPublicId: user.publicId,
    };

    setIsLoadingCalculations(true);
    ApplicationConcessionRequestService.sendForApproval(reqData)
      .then((res) => {
        let data = res.data;
        setFormInputValues({ ...formInputValues, ...data });
        appContext.toastMessage.message = 'Concession request has been successfully submitted.';
        setSuccessMessage('');
        history.push('/underwriting/applications/' + applicationId + '/payoffs');
        setIsLoadingCalculations(false);
      })
      .finally(() => {
        setIsLoadingCalculations(false);
      });
  };

  const handleApprovalOrDenial = (data) => {
    const reqData = {
      ...formInputValues,
      concessionPayoffAmount: formInputValues.concessionPayoffAmount
        ? parseFloat(formInputValues.concessionPayoffAmount)
        : 0,
      marketingExpense: formInputValues.marketingExpense
        ? parseFloat(formInputValues.marketingExpense)
        : 0,
      liabilities: formInputValues.liabilities ? parseFloat(formInputValues.liabilities) : 0,
      caseValueAtConcession: formInputValues.caseValueAtConcession
        ? parseFloat(formInputValues.caseValueAtConcession)
        : 0,
      approvalReviewedByPublicId: user.publicId,
      isApproved: data,
    };

    setIsLoadingCalculations(true);
    ApplicationConcessionRequestService.approvalordenial(reqData)
      .then((res) => {
        let data = res.data;
        setFormInputValues({ ...formInputValues, ...data });
        appContext.toastMessage.message = 'Concession request has been successfully completed.';
        setSuccessMessage('');
        history.push('/underwriting/applications/' + applicationId + '/payoffs');
        setIsLoadingCalculations(false);
      })
      .finally(() => {
        setIsLoadingCalculations(false);
      });
  };

  const handleFormSubmitForSave = (data) => {
    const reqData = {
      ...formInputValues,
      concessionPayoffAmount: formInputValues.concessionPayoffAmount
        ? parseFloat(formInputValues.concessionPayoffAmount)
        : 0,
      marketingExpense: formInputValues.marketingExpense
        ? parseFloat(formInputValues.marketingExpense)
        : 0,
      liabilities: formInputValues.liabilities ? parseFloat(formInputValues.liabilities) : 0,
      caseValueAtConcession: formInputValues.caseValueAtConcession
        ? parseFloat(formInputValues.caseValueAtConcession)
        : 0,
      createdByPublicId: user.publicId,
    };

    setIsLoadingCalculations(true);
    ApplicationConcessionRequestService.update(reqData)
      .then((res) => {
        let data = res.data;
        setFormInputValues({ ...formInputValues, ...data });
        appContext.toastMessage.message = 'Concession request has been successfully submitted.';
        setSuccessMessage('');
        history.push('/underwriting/applications/' + applicationId + '/payoffs');
        setIsLoadingCalculations(false);
      })
      .catch((err) => {})
      .finally(() => {
        setIsLoadingCalculations(false);
      });
  };

  const handleCalculateData = (data) => {
    const reqData = {
      ...formInputValues,
      concessionPayoffAmount: formInputValues.concessionPayoffAmount
        ? parseFloat(formInputValues.concessionPayoffAmount)
        : 0,
      marketingExpense: formInputValues.marketingExpense
        ? parseFloat(formInputValues.marketingExpense)
        : 0,
      liabilities: formInputValues.liabilities ? parseFloat(formInputValues.liabilities) : 0,
      caseValueAtConcession: formInputValues.caseValueAtConcession
        ? parseFloat(formInputValues.caseValueAtConcession)
        : 0,
      createdByPublicId: user.publicId,
    };

    setIsLoadingCalculations(true);
    ApplicationConcessionRequestService.calculate(reqData)
      .then((res) => {
        setFormInputValues(res.data);
        if (res.data.applicationConcessionRequestAdvances)
          setAdvances(res.data.applicationConcessionRequestAdvances);
        setIsLoadingCalculations(false);
      })
      .catch((err) => {})
      .finally(() => {
        setIsLoadingCalculations(false);
      });
  };

  const handleCancel = () => {
    let path = applicationId ? applicationId : 'new-application';
    history.push('/underwriting/applications/' + path + '/payoffs');
  };

  const handleChange = (evt) => {
    const value = evt.target.value;
    setFormInputValues({
      ...formInputValues,
      [evt.target.name]: value,
    });
  };

  const handleFormSubmit = (data) => {};

  const moneyFormatter = new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: 'USD',

    // These options are needed to round to whole numbers if that's what you want.
    //minimumFractionDigits: 0, // (this suffices for whole numbers, but will print 2500.10 as $2,500.1)
    //maximumFractionDigits: 0, // (causes 2500.99 to be printed as $2,501)
  });

  const parseDollarString = (val) => {
    return val ? moneyFormatter.format(parseFloat(val).toFixed(2)) : '-';
    //return val? '$' + parseFloat(val).toFixed(2) : "-";
  };

  const parsePercentageString = (val) => {
    return val ? parseFloat(val).toFixed(2) + '%' : '-';
  };

  const parseMoicString = (val) => {
    return val ? parseFloat(val).toFixed(2) + 'x' : '-';
  };

  return (
    <div className="container mt-10 mb-10">
      <div className="row">
        <div className="col-md-12 mx-auto bg-white box-shadow-border">
          <div className="p-10">
            <div className="mt-5 mb-10">
              <div>
                <h1>
                  {applicationId != 'new-application'
                    ? applicationId +
                      (client ? ` - ${client} - ` : ' - ') +
                      (attorney ? `${attorney} - ` : '')
                    : undefined}

                  {!props.match.params.noteId ? 'Concession Request' : 'Concession Request'}
                </h1>

                <ApplicationsNewMenu
                  applicationId={applicationId}
                  // menuItemIndex="2"
                />
                {isLoading ? (
                  <div>
                    <ReactLoading type="bars" color="#7E8299" />
                    Calculating...
                  </div>
                ) : (
                  <form
                    onSubmit={handleSubmit(handleFormSubmit)}
                    noValidate
                    className="concession-form"
                  >
                    <Row>
                      <Col sm="6" className="p-10 first-half">
                        <Row className="listing-rows mb-2">
                          <Col sm="6" className="p-0">
                            <h6>Concession Date</h6>
                          </Col>
                          {formInputValues.isSentforApproval ? (
                            <span>{Moment(formInputValues.requestDate).format('MM/DD/YYYY')}</span>
                          ) : (
                            <Col sm="6" className="p-0">
                              {' '}
                              <input
                                type="date"
                                name="requestDate"
                                id="requestDate"
                                className="form-control concession-date-datepicker float-right"
                                onBlur={handleCalculateData}
                                onChange={handleChange}
                                ref={register({ required: 'Concession date is required' })}
                                defaultValue={
                                  formInputValues.requestDate
                                    ? formInputValues.requestDate.split('T')[0]
                                    : ''
                                }
                              />
                            </Col>
                          )}
                        </Row>
                        <Row className="listing-rows mb-2">
                          <h6>Avg. Advance Day</h6>
                          <span>{Moment(formInputValues.avgAdvanceDay).format('MM/DD/YYYY')}</span>
                        </Row>
                        <Row className="listing-rows mb-2">
                          <h6>Avg. Day Outstanding</h6>
                          <span>{formInputValues.avgDaysOutstanding}</span>
                        </Row>
                        <Row className="listing-rows mb-2">
                          <h6>Advanced Amount</h6>
                          <span>{parseDollarString(formInputValues.advancedAmount)}</span>
                        </Row>
                        <Row className="listing-rows mb-2">
                          <h6>Total Cash Outlay</h6>
                          <span>{parseDollarString(formInputValues.totalCashOutlay)}</span>
                        </Row>
                        <Row className="listing-rows mb-2">
                          <h6>Actual Payoff Amount</h6>
                          <span>{parseDollarString(formInputValues.actualPayoffAmount)}</span>
                        </Row>
                        <hr />
                        <Row className="listing-rows mb-2">
                          <Col sm="6" className="p-0">
                            <h6>Concession Payoff Amount</h6>
                          </Col>
                          {formInputValues.isSentforApproval ? (
                            <span>{parseDollarString(formInputValues.concessionPayoffAmount)}</span>
                          ) : (
                            <Col sm="4" className="p-0">
                              <div className="input-group input-group-sm float-right">
                                <div className="input-group-prepend">
                                  <span className="input-group-text">$</span>
                                </div>
                                <input
                                  name="concessionPayoffAmount"
                                  id="concessionPayoffAmount"
                                  type="number"
                                  className="form-control number-input"
                                  onChange={handleChange}
                                  onBlur={handleCalculateData}
                                  value={formInputValues.concessionPayoffAmount}
                                  ref={register({
                                    required: 'Concession Payoff Amount is required.',
                                  })}
                                  required="required"
                                  aria-describedby="basic-addon2"
                                />
                              </div>
                            </Col>
                          )}
                        </Row>
                        <Row className="listing-rows mb-2">
                          <h6>Concession Amount</h6>
                          <span>{parseDollarString(formInputValues.concessionAmount)}</span>
                        </Row>
                        <Row className="listing-rows mb-2">
                          <h6>Case Related Concession</h6>
                          <span>{parseDollarString(formInputValues.caseRelatedConcession)}</span>
                        </Row>
                        <Row className="listing-rows mb-2">
                          <Col sm="6" className="p-0">
                            <h6>Marketing Expenses</h6>
                          </Col>
                          {formInputValues.isSentforApproval ? (
                            <span>{parseDollarString(formInputValues.marketingExpense)}</span>
                          ) : (
                            <Col sm="4" className="p-0">
                              <div className="input-group input-group-sm float-right">
                                <div className="input-group-prepend">
                                  <span className="input-group-text">$</span>
                                </div>
                                <input
                                  name="marketingExpense"
                                  id="marketingExpense"
                                  type="number"
                                  onChange={handleChange}
                                  onBlur={handleCalculateData}
                                  disabled={formInputValues.isSentforApproval}
                                  ref={register({ required: 'Marketing Expenses is required.' })}
                                  value={formInputValues.marketingExpense}
                                  className="form-control float-right number-input {text-align: right} "
                                  required="required"
                                />
                              </div>
                            </Col>
                          )}
                        </Row>
                        <Row className="listing-rows mb-2">
                          <h6>APR</h6>
                          <span>{parsePercentageString(formInputValues.concessionApr)}</span>
                        </Row>
                        <Row className="listing-rows mb-2">
                          <h6>Concession %</h6>
                          <span>{parsePercentageString(formInputValues.concessionPerc)}</span>
                        </Row>
                        <Row className="listing-rows mb-2">
                          <Col sm="6" className="p-0">
                            <h6>Liabilities Issues</h6>
                          </Col>
                          {formInputValues.isSentforApproval ? (
                            <span>{parseDollarString(formInputValues.liabilities)}</span>
                          ) : (
                            <Col sm="4" className="p-0">
                              <div className="input-group input-group-sm float-right">
                                <div className="input-group-prepend">
                                  <span className="input-group-text">$</span>
                                </div>
                                <input
                                  name="liabilities"
                                  id="liabilities"
                                  type="number"
                                  onChange={handleChange}
                                  onBlur={handleCalculateData}
                                  ref={register({ required: 'Liabilities is required.' })}
                                  value={formInputValues.liabilities}
                                  className="form-control float-right number-input"
                                  required="required"
                                />
                              </div>
                            </Col>
                          )}
                        </Row>
                        <Row className="listing-rows mb-2">
                          <h6>MOIC</h6>
                          <span>{parseMoicString(formInputValues.moic)}</span>
                        </Row>
                      </Col>
                      <Col sm="12" md="7" className="p-10 mb-2">
                        <Row className="listing-rows">
                          <h6>Case Value</h6>
                        </Row>
                        <Row>
                          <Table bordered>
                            <thead>
                              <tr>
                                <th className="tb-hdng">At UnderWriting</th>
                                <th className="tb-hdng">At Concession</th>
                              </tr>
                            </thead>
                            <tbody>
                              <tr>
                                <td>
                                  <Col className="">
                                    <Row className="listing-rows mb-4 mt-2">
                                      <span>Case Value</span>
                                      <span>
                                        {parseDollarString(formInputValues.caseValueAtUnderWriting)}
                                      </span>
                                    </Row>
                                    <Row className="listing-rows mb-2">
                                      <span>Liens</span>
                                      <span>
                                        {parseDollarString(formInputValues.liensAtUnderWriting)}
                                      </span>
                                    </Row>
                                    <Row className="listing-rows mb-2">
                                      <span>Liabilities</span>
                                      <span>
                                        {parseDollarString(
                                          formInputValues.liabilitiesAtUnderWriting
                                        )}
                                      </span>
                                    </Row>
                                    <Row className="listing-rows mb-2">
                                      <span>Attorney Fee</span>
                                      <span>
                                        {parseDollarString(
                                          formInputValues.attorneyFeeAtUnderWriting
                                        )}
                                      </span>
                                    </Row>
                                  </Col>
                                </td>
                                <td>
                                  <Col className="">
                                    <Row
                                      className={
                                        formInputValues.isSentforApproval
                                          ? 'listing-rows mb-4 mt-2'
                                          : 'listing-rows mb-2'
                                      }
                                    >
                                      <Col sm="6" className="p-0">
                                        <span>Case Value</span>
                                      </Col>
                                      {formInputValues.isSentforApproval ? (
                                        <span>
                                          {parseDollarString(formInputValues.caseValueAtConcession)}
                                        </span>
                                      ) : (
                                        <Col sm="6" className="p-0">
                                          <div className="input-group input-group-sm float-right">
                                            <div className="input-group-prepend">
                                              <span className="input-group-text">$</span>
                                            </div>
                                            <input
                                              name="caseValueAtConcession"
                                              id="caseValueAtConcession"
                                              type="number"
                                              onChange={handleChange}
                                              onBlur={handleCalculateData}
                                              ref={register({
                                                required: 'Case Value is required.',
                                              })}
                                              value={formInputValues.caseValueAtConcession}
                                              className="form-control float-right number-input"
                                              required="required"
                                            />
                                          </div>
                                        </Col>
                                      )}
                                    </Row>
                                    <Row className="listing-rows mb-2">
                                      <span>Liens</span>
                                      <span>
                                        {parseDollarString(formInputValues.liensAtConcession)}
                                      </span>
                                    </Row>
                                    <Row className="listing-rows mb-2">
                                      <span>Liabilities</span>
                                      <span>
                                        {parseDollarString(formInputValues.liabilitiesAtConcession)}
                                      </span>
                                    </Row>
                                    <Row className="listing-rows mb-2">
                                      <span>Attorney Fee</span>
                                      <span>
                                        {parseDollarString(formInputValues.attorneyFeeAtConcession)}
                                      </span>
                                    </Row>
                                  </Col>
                                </td>
                              </tr>
                              <tr>
                                <td>
                                  <Col className="">
                                    <Row className="listing-rows mb-2">
                                      <span>Net Case Value</span>
                                      <span>
                                        {parseDollarString(
                                          formInputValues.netCaseValueAtUnderWriting
                                        )}
                                      </span>
                                    </Row>
                                    <Row className="listing-rows mb-2">
                                      <span>Present Value of Payoff</span>
                                      <span>
                                        {parseDollarString(
                                          formInputValues.presentValueofPayoffAtUnderWriting
                                        )}
                                      </span>
                                    </Row>
                                  </Col>
                                </td>
                                <td>
                                  <Col className="">
                                    <Row className="listing-rows mb-2">
                                      <span>Net Case Value</span>
                                      <span>
                                        {parseDollarString(
                                          formInputValues.netCaseValueAtConcession
                                        )}
                                      </span>
                                    </Row>
                                    <Row className="listing-rows mb-2">
                                      <span>Concession Payoff Amount</span>
                                      <span>
                                        {parseDollarString(
                                          formInputValues.presentValueofPayoffAtConcession
                                        )}
                                      </span>
                                    </Row>
                                  </Col>
                                </td>
                              </tr>
                              <tr>
                                <td>
                                  <Col className="">
                                    <Row className="listing-rows mb-2">
                                      <span>Excess Proceeds</span>
                                      <span>
                                        {parseDollarString(
                                          formInputValues.excessProceedsAtUnderWriting
                                        )}
                                      </span>
                                    </Row>
                                  </Col>
                                </td>
                                <td>
                                  <Col className="">
                                    <Row className="listing-rows mb-2">
                                      <span>Excess Proceeds</span>
                                      <span>
                                        {parseDollarString(
                                          formInputValues.excessProceedsAtConcession
                                        )}
                                      </span>
                                    </Row>
                                  </Col>
                                </td>
                              </tr>
                            </tbody>
                          </Table>
                        </Row>

                        <Row className="listing-rows mt-5">
                          <h6>Advances</h6>
                          <div className="col-md-12 col-sm-12 p-0">
                            <span>{formInputValues.advances}</span>

                            {/* {advances?.length > 0 && advances.map((item, index) => (
                                                                <span>{index == 0 ? '' : ', '}{item.advanceNo}</span>
                                                            ))} */}
                          </div>
                        </Row>
                      </Col>
                    </Row>
                    <hr />
                    <Row className="mb-6 mt-6">
                      <Col className="col-sm-8">
                        <h6>Notes</h6>
                        <textarea
                          name="notes"
                          id="notes"
                          rows="5"
                          onChange={handleChange}
                          value={formInputValues.notes}
                          className="form-control"
                          disabled={formInputValues.isApproved}
                          ref={register()}
                          required="required"
                        />
                        {errors.notes && (
                          <Alert color="danger" className="mt-5">
                            <ErrorMessage errors={errors} name="notes" />
                          </Alert>
                        )}
                      </Col>
                    </Row>

                    <div className="row">
                      <div className="col-md-6">
                        <div className="form-group">
                          {formInputValues.isSentforApproval &&
                            !formInputValues.isApproved &&
                            !formInputValues.approvalReviewedByPublicId && (
                              <>
                                {canApproveConcessionRequests && (
                                  <button
                                    type="submit"
                                    onClick={() => handleApprovalOrDenial(true)}
                                    className="btn btn-primary"
                                  >
                                    Approve
                                  </button>
                                )}

                                {canDenyConcessionRequests && (
                                  <button
                                    type="submit"
                                    onClick={() => handleApprovalOrDenial(false)}
                                    className="ml-5 mr-5 btn btn-danger"
                                  >
                                    Deny
                                  </button>
                                )}
                              </>
                            )}

                          {!formInputValues.isSentforApproval &&
                            !formInputValues.isApproved &&
                            !formInputValues.approvalReviewedByPublicId && (
                              <>
                                {canAddConcessionRequests && canEditConcessionRequests && (
                                  <button
                                    type="submit"
                                    onClick={() => handleFormSubmitForSave()}
                                    className="btn btn-primary"
                                  >
                                    Save
                                  </button>
                                )}

                                {canApproveConcessionRequests && (
                                  <button
                                    type="submit"
                                    onClick={() => handleFormSubmitForApproval()}
                                    className="ml-5 mr-5 btn btn-primary"
                                  >
                                    Submit for Approval
                                  </button>
                                )}
                              </>
                            )}

                          {formInputValues.isSentforApproval &&
                            !formInputValues.isApproved &&
                            !formInputValues.approvalReviewedByPublicId &&
                            canAddConcessionRequests &&
                            canEditConcessionRequests && (
                              <>
                                <button
                                  type="submit"
                                  onClick={() => handleFormSubmitForSave()}
                                  className="mr-5 btn btn-primary"
                                >
                                  Save
                                </button>
                              </>
                            )}

                          <button
                            type="button"
                            className="btn btn-secondary"
                            onClick={() => handleCancel()}
                          >
                            Cancel{' '}
                          </button>
                        </div>
                      </div>
                    </div>
                  </form>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default NewConcessionRequest;
