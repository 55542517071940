// Application URL constancts

export const APPLICATION_URL = '/Application/';

export const APPLICATION_NOTE_URL = '/ApplicationNote/';

//APPLICATION ADVANCES
export const APPLICATION_ADVANCE_APPROVAL_URL = '/ApplicationAdvance/applicationadvanceapproval';

export const APPLICATION_ADVANCE_GENERATEADVANCEDOCUMENT_URL =
  '/ApplicationAdvance/generateadvancecontract';
export const APPLICATION_ADVANCE_GENERATETHANKYOULETTER_URL =
  '/ApplicationAdvance/generateadvancethankyouletter';
export const APPLICATION_ADVANCE_SENDTODOCUSIGN_URL =
  '/ApplicationAdvance/sendApprovedAdvanceToDocuSign';
