import React, { useState, useEffect, useContext, useRef } from 'react';
import { useHistory, Link, withRouter, useParams } from 'react-router-dom';
import ApplicationsService from '@services/ApplicationsService';

import { makeStyles, useTheme, withStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableFooter from '@material-ui/core/TableFooter';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import Pagination from '@material-ui/lab/Pagination';
import EditOutlinedIcon from '@material-ui/icons/EditOutlined';
import AttachMoneyOutlinedIcon from '@material-ui/icons/AttachMoneyOutlined';
import AssignmentTurnedInOutlinedIcon from '@material-ui/icons/AssignmentTurnedInOutlined';
import InsertDriveFileOutlinedIcon from '@material-ui/icons/InsertDriveFileOutlined';
import OpenInBrowserOutlinedIcon from '@material-ui/icons/OpenInBrowserOutlined';
import MapOutlinedIcon from '@material-ui/icons/MapOutlined';
import DeleteOutlinedIcon from '@material-ui/icons/DeleteOutline';
import ApplicationAdvanceService from '@services/ApplicationAdvanceService';
import { Tooltip } from '@material-ui/core';
import AppContext from '@contexts/AppContext';
import TableSortLabel from '@material-ui/core/TableSortLabel';
import ReactLoading from 'react-loading';
import Moment from 'moment';
import { toast } from 'react-toastify';
import GridService from '@services/GridService';
import { APPLICATION_ADVANCE_APPROVAL_URL } from '@services/Helpers/Constants';
import { Button, Alert, Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import FormGroup from '@material-ui/core/FormGroup';
import { FormattedAmount } from '@components/Utils/FormattedAmount';
import {
  APPLICATION_ADVANCE_GENERATEADVANCEDOCUMENT_URL,
  APPLICATION_ADVANCE_GENERATETHANKYOULETTER_URL,
  APPLICATION_ADVANCE_SENDTODOCUSIGN_URL,
} from '@services/Helpers/Constants';

const StyledTableCell = withStyles((theme) => ({
  head: {
    backgroundColor: '#efefef',
    color: theme.palette.common.black,
  },
  body: {
    fontSize: 14,
  },
}))(TableCell);

const useStyles2 = makeStyles({
  table: {
    minWidth: 500,
  },
  MuiFormControlLabel: {
    label: {
      marginBottom: '0rem',
    },
  },
});

const AdvancesGrid = (props) => {
  const classes = useStyles2();
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(15);
  const [filters, setFilters] = useState({});
  const [orderBy, setOrderBy] = useState('');
  const [applicationPublicId, setApplicationPublicId] = useState();
  const [orderDir, setOrderDir] = useState('ASC');
  const [rows, setRows] = useState([]);
  const [approvalModal, setApprovalModal] = useState(false);
  const [uecv, setUecv] = useState(0.0);
  const [historicalCaseValueModal, setHistoricalCaseValueModal] = useState(false);
  const [approvalComments, setApprovalComments] = useState('');
  const [estimatedCaseValue, setEstimatedCaseValue] = useState(0);
  const [estimatedCaseValueError, setEstimatedCaseValueError] = useState(false);
  const [selectedAdvance, setSelectedAdvance] = useState();

  const appContext = useContext(AppContext);
  const [modal, setModal] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [pages, setPages] = useState(undefined);
  const [deleteModalDialog, setDeleteModalDialog] = useState(false);

  const params = useParams();
  const [HCVValues, setHCVValues] = useState(null);
  const [historicalCaseValuePerc, setHistoricalCaseValuePerc] = useState(0.5);
  const [activeFieldForSorting, setActiveFieldForSorting] = useState();

  const history = useHistory();
  const { applicationId } = useParams();
  const mountedRef = useRef();

  useEffect(() => {
    if (approvalModal == true) {
      GridService.search(
        '/ApplicationCoverage/',
        { applicationNo: applicationId },
        null,
        null,
        1,
        null,
        10
      )
        .then((response) => {
          if (!response.data) setUecv(0);
          if (response.data.data.length > 0) {
            setUecv(response.data.data[0].estimatedSettlementValue);
          }
        })
        .catch((err) => {})
        .finally(() => {
          setIsLoading(false);
        });
    }
  }, [approvalModal]);

  useEffect(() => {
    if (!mountedRef.current) return;
    update();
  }, [orderBy, orderDir, rowsPerPage, page]);

  useEffect(() => {
    mountedRef.current = true;
    setPage(1);
  }, []);

  const handlePageSizeChange = (event) => {
    setRowsPerPage(event.target.value);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const update = () => {
    if (!applicationId || applicationId == 'undefined') {
      appContext.toastMessage.message = 'Application No was not found!';
      history.push('/underwriting/applications/new-application/');
      return;
    }

    setIsLoading(true);

    ApplicationsService.search({ applicationNo: params.applicationId }, null, null, 1, {}, 10)
      .then((response) => {
        setApplicationPublicId(response.data.data[0].publicId);

        let newFilters = { ...filters };
        newFilters['applicationPublicId'] = response.data.data[0].publicId;
        setFilters(newFilters);

        ApplicationAdvanceService.search(newFilters, orderBy, orderDir, page, null, rowsPerPage)
          .then((response) => {
            setRows(response.data.data);
            setPages(response.data.pages.slice(-1)[0]);
            setIsLoading(false);
          })
          .catch((err) => {})
          .finally(() => {});
      })
      .catch((err) => {})
      .finally(() => {
        setIsLoading(false);
      });
  };

  const searchColumn = (event, columnName) => {
    var newFilters = filters;

    if (columnName === 'isActive' && event.target.value != 'All') {
      newFilters[columnName] = event.target.value === 'true' ? true : false;
    } else if (columnName === 'isActive' && event.target.value === 'All') {
      newFilters[columnName] = undefined;
    } else {
      newFilters[columnName] = event.target.value;
    }

    setFilters(newFilters);
    update();
  };

  const arrange = (field) => {
    if (orderBy === field) {
      if (orderDir === 'ASC') {
        setOrderDir('DESC');
      } else {
        setOrderDir('ASC');
      }
    } else {
      setOrderBy(field);
      setOrderDir('ASC');
    }

    setPage(1);
  };

  const showApprovalModal = (selectedRow) => {
    if (!(appContext?.userPermissions?.indexOf('Approve') > -1)) {
      appContext.toastMessage.message = 'You do not have permissions to perform this action.';
      toast.error(appContext.toastMessage.message, { autoClose: 3000 });
      appContext.toastMessage.message = null;
      return;
    }

    setSelectedAdvance(selectedRow);

    setApprovalModal(true);
  };

  const generateContractDocument = (selectedRow) => {
    if (!(appContext?.userPermissions?.indexOf('Approve') > -1)) {
      appContext.toastMessage.message = 'You do not have permissions to perform this action.';
      toast.error(appContext.toastMessage.message, { autoClose: 3000 });
      appContext.toastMessage.message = null;
      return;
    }

    setIsLoading(true);
    appContext.toastMessage.message = 'Document generation started';
    toast.success(appContext.toastMessage.message, { autoClose: 3000 });

    const req = {
      applicationAdvancePublicId: selectedRow.publicId,
      userPublicId: appContext.user.publicId,
    };

    GridService.create(APPLICATION_ADVANCE_GENERATEADVANCEDOCUMENT_URL, req)
      .then((resp) => {
        if (!resp.data) return;

        appContext.toastMessage.message = 'Document generated successfully';
        toast.success(appContext.toastMessage.message, { autoClose: 3000 });
      })
      .catch((err) => {})
      .finally(() => {
        setIsLoading(false);
      });
  };

  const generateContractDocumentAndSendToDocusign = (selectedRow) => {
    if (!(appContext?.userPermissions?.indexOf('Approve') > -1)) {
      appContext.toastMessage.message = 'You do not have permissions to perform this action.';
      toast.error(appContext.toastMessage.message, { autoClose: 3000 });
      appContext.toastMessage.message = null;
      return;
    }

    setIsLoading(true);
    appContext.toastMessage.message = 'Document generation  and uploading to DocuSign started';
    toast.success(appContext.toastMessage.message, { autoClose: 3000 });

    const req = {
      applicationAdvancePublicId: selectedRow.publicId,
      userPublicId: appContext.user.publicId,
    };

    GridService.create(APPLICATION_ADVANCE_SENDTODOCUSIGN_URL, req)
      .then((resp) => {
        if (!resp.data) return;

        appContext.toastMessage.message =
          'Document generated and uploaded to DocuSign successfully';
        toast.success(appContext.toastMessage.message, { autoClose: 3000 });
      })
      .catch((err) => {})
      .finally(() => {
        setIsLoading(false);
      });
  };

  const generateThankYouLetter = (selectedRow) => {
    if (!(appContext?.userPermissions?.indexOf('Approve') > -1)) {
      appContext.toastMessage.message = 'You do not have permissions to perform this action.';
      toast.error(appContext.toastMessage.message, { autoClose: 3000 });
      appContext.toastMessage.message = null;
      return;
    }

    setIsLoading(true);

    const req = {
      applicationAdvancePublicId: selectedRow.publicId,
      applicationPublicId: applicationPublicId,
      userPublicId: appContext.user.publicId,
    };

    GridService.create(APPLICATION_ADVANCE_GENERATETHANKYOULETTER_URL, req)
      .then((resp) => {
        if (!resp.data) return;

        appContext.toastMessage.message = 'Thank you letter genereated successfully';
        toast.success(appContext.toastMessage.message, { autoClose: 3000 });
      })
      .catch((err) => {})
      .finally(() => {
        setIsLoading(false);
      });
  };

  const deleteAdvance = (selectedRow) => {
    if (!(appContext?.userPermissions?.indexOf('Approve') > -1)) {
      appContext.toastMessage.message = 'You do not have permissions to perform this action.';
      toast.error(appContext.toastMessage.message, { autoClose: 3000 });
      appContext.toastMessage.message = null;
      return;
    }

    setIsLoading(true);

    ApplicationAdvanceService.delete(selectedRow.publicId)
      .then((_) => {
        const filteredArray = rows.filter((item) => item.publicId !== selectedRow.publicId);
        setRows(filteredArray);
        setDeleteModalDialog(false);
        appContext.toastMessage.message = 'The Advance letter deleted';
        toast.success(appContext.toastMessage.message, { autoClose: 3000 });
      })
      .catch((err) => {})
      .finally(() => {
        setIsLoading(false);
      });
  };

  const openHistoricalCaseModal = () => {
    setApprovalModal(false);
    setHistoricalCaseValueModal(true);
  };

  const toggleApproval = () => {
    setApprovalModal(!approvalModal);
    document.getElementById('action').selectedIndex = 0;
  };

  const toggleHistoricalCaseValueModal = () => {
    setHistoricalCaseValueModal(!historicalCaseValueModal);
    document.getElementById('action').selectedIndex = 0;
  };

  //Approve
  const approveSelectedAdvance = () => {
    if (!(appContext?.userPermissions?.indexOf('Approve') > -1)) {
      appContext.toastMessage.message = 'You do not have permissions to perform this action.';
      toast.error(appContext.toastMessage.message, { autoClose: 3000 });
      appContext.toastMessage.message = null;
      return;
    }

    if (estimatedCaseValue === '') {
      setEstimatedCaseValueError(true);
      return;
    }

    setIsLoading(true);

    const req = {
      applicationAdvancePublicId: selectedAdvance.publicId,
      note: approvalComments,
      userPublicId: appContext.user.publicId,
      approvedAmount: parseFloat(estimatedCaseValue),
    };

    GridService.create(APPLICATION_ADVANCE_APPROVAL_URL, req)
      .then((resp) => {
        if (!resp.data) return;
        appContext.currentApplication.applicationStatus = resp.data.applicationStatus;
        setSelectedAdvance({ ...selectedAdvance, approvedAmount: resp.data.approvedAmount });
        toggleApproval();
        setApprovalComments(null);
        setEstimatedCaseValue(null);
        appContext.toastMessage.message = 'Application Advance approved successfully';
        toast.success(appContext.toastMessage.message, { autoClose: 3000 });
      })
      .catch((err) => {})
      .finally(() => {
        setIsLoading(false);
      });
  };

  const moneyFormatter = new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: 'USD',

    // These options are needed to round to whole numbers if that's what you want.
    //minimumFractionDigits: 0, // (this suffices for whole numbers, but will print 2500.10 as $2,500.1)
    //maximumFractionDigits: 0, // (causes 2500.99 to be printed as $2,501)
  });

  const calculateHistoricalCaseValue = () => {
    const req = {
      applicationPublicId: appContext.currentApplication.publicId,
      percentage: historicalCaseValuePerc,
    };

    ApplicationAdvanceService.calculateHCV(req).then((response) => {
      setHCVValues(response?.data);
    });
  };

  const toggleDeleteModal = (row) => {
    setDeleteModalDialog(!deleteModalDialog);
    setSelectedAdvance(row);
  };

  return (
    <>
      <Modal isOpen={approvalModal} toggle={toggleApproval}>
        <ModalHeader>Approve</ModalHeader>
        {isLoading == true && (
          <div className="p-10">
            <ReactLoading type="bars" color="#7E8299" />
          </div>
        )}
        {isLoading == false && (
          <>
            <ModalBody id="approvalModal">
              <p>Underwriter Estimated Case Value</p>
              <div className="col-md-6 p-0">
                {uecv ? (
                  moneyFormatter.format(parseFloat(uecv, 2).toFixed(2))
                ) : (
                  <Alert color="danger">No data available.</Alert>
                )}
              </div>
              <button
                className="btn btn-primary mb-5 mt-5"
                onClick={() => openHistoricalCaseModal()}
              >
                Show Historical Data
              </button>
              <FormGroup>
                <label>Approved Amount</label>
                <input
                  className="form-control"
                  style={{ width: '60%' }}
                  type="number"
                  defaultValue={0}
                  onChange={(e) => {
                    setEstimatedCaseValueError(null);
                    setEstimatedCaseValue(e.target.value);
                  }}
                  maxLength={10}
                />
                {estimatedCaseValueError && (
                  <Alert color="danger" className="mt-3">
                    Estimated Case Value is needed.
                  </Alert>
                )}
              </FormGroup>
              <FormGroup className="mt-5">
                <label>Comments</label>
                <textarea
                  className="form-control"
                  style={{ width: '100%', resize: 'none' }}
                  rows="8"
                  value={approvalComments}
                  onChange={(e) => {
                    setApprovalComments(e.target.value);
                  }}
                  maxLength={4000}
                />
              </FormGroup>
            </ModalBody>
            <ModalFooter>
              <Button color="primary" onClick={approveSelectedAdvance}>
                Save
              </Button>
              <Button color="secondary" onClick={toggleApproval}>
                Cancel
              </Button>
            </ModalFooter>
          </>
        )}
      </Modal>

      <Modal size="lg" isOpen={historicalCaseValueModal} toggle={toggleHistoricalCaseValueModal}>
        <ModalHeader>Historical Case Values</ModalHeader>
        <ModalBody id="historicalCaseValueModal">
          <div className="row">
            <div
              className="col-md-1"
              style={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
              }}
            >
              <label>Perc</label>
            </div>
            <div className="col-md-2">
              <input
                name="historicalCaseValuePerc"
                defaultValue={0.5}
                className="form-control"
                onChange={(e) => setHistoricalCaseValuePerc(parseFloat(e.target.value))}
              />
            </div>
            <button className="btn btn-primary" onClick={() => calculateHistoricalCaseValue()}>
              Calculate
            </button>
          </div>
          <div className="row">
            <div className="col-md-12">
              {HCVValues == '' ? (
                <div className="col-md-6 mt-5 p-0">
                  <Alert color="danger">No data found.</Alert>
                </div>
              ) : undefined}
              {HCVValues?.map((item, index) => (
                <div className="mt-10" style={{ overflowY: 'scroll' }}>
                  <div>
                    Historical Case Value - {item.specificInjuryName}
                    <br />
                    <strong>
                      {FormattedAmount.format(parseFloat(item.historicalCaseValue).toFixed(2))}
                    </strong>
                  </div>
                  <Table stripedRows>
                    <thead>
                      <tr>
                        <th>Application #</th>
                        <th>Client age</th>
                        <th>County</th>
                        <th>Gender</th>
                        <th>Case Value</th>
                      </tr>
                    </thead>
                    <tbody>
                      {item?.historicalCases.map((item2, index2) => (
                        <tr>
                          <td>{item2?.applicationNo}</td>
                          <td>{item2?.clientAge}</td>
                          <td>{item2?.county}</td>
                          <td>{item2?.gender}</td>
                          <td>{FormattedAmount.format(parseFloat(item2?.caseValue).toFixed(2))}</td>
                        </tr>
                      ))}
                    </tbody>
                  </Table>
                </div>
              ))}
            </div>
          </div>
        </ModalBody>
        <ModalFooter>
          <Button
            color="secondary"
            onClick={() => {
              toggleHistoricalCaseValueModal();
              toggleApproval();
            }}
          >
            Close
          </Button>
        </ModalFooter>
      </Modal>

      <Modal isOpen={deleteModalDialog} toggle={toggleDeleteModal}>
        <ModalHeader toggle={toggleDeleteModal}>Confirmation</ModalHeader>
        <ModalBody>Are you sure you want to delete this Advance?</ModalBody>
        <ModalFooter>
          <Button
            color="primary"
            onClick={() => {
              deleteAdvance(selectedAdvance);
            }}
          >
            Delete
          </Button>
          <Button color="secondary" onClick={toggleDeleteModal}>
            Cancel
          </Button>
        </ModalFooter>
      </Modal>

      <TableContainer component={Paper}>
        <Table className={classes.table} aria-label="custom pagination table">
          <TableHead>
            <TableRow>
              <StyledTableCell>Id</StyledTableCell>

              <StyledTableCell
                width="5%"
                onClick={() => {
                  arrange('advanceNo');
                  setActiveFieldForSorting('advanceNo');
                }}
              >
                ADV. #
                <TableSortLabel
                  active={activeFieldForSorting === 'advanceNo' ? true : false}
                  direction={activeFieldForSorting === 'advanceNo' ? orderDir.toLowerCase() : 'asc'}
                  onClick={() => arrange('advanceNo')}
                ></TableSortLabel>
              </StyledTableCell>

              <StyledTableCell
                width="10%"
                onClick={() => {
                  arrange('advanceDate');
                  setActiveFieldForSorting('advanceDate');
                }}
              >
                ADVANCE DATE
                <TableSortLabel
                  active={activeFieldForSorting === 'advanceDate' ? true : false}
                  direction={
                    activeFieldForSorting === 'advanceDate' ? orderDir.toLowerCase() : 'asc'
                  }
                  onClick={() => arrange('advanceDate')}
                ></TableSortLabel>
              </StyledTableCell>

              <StyledTableCell
                width="10%"
                onClick={() => {
                  arrange('advanceStatus');
                  setActiveFieldForSorting('advanceStatus');
                }}
              >
                ADVANCE STATUS
                <TableSortLabel
                  active={activeFieldForSorting === 'advanceStatus' ? true : false}
                  direction={
                    activeFieldForSorting === 'advanceStatus' ? orderDir.toLowerCase() : 'asc'
                  }
                  onClick={() => arrange('advanceStatus')}
                ></TableSortLabel>
              </StyledTableCell>

              <StyledTableCell
                width="8%"
                onClick={() => {
                  arrange('fundingVia');
                  setActiveFieldForSorting('fundingVia');
                }}
              >
                FUNDING VIA
                <TableSortLabel
                  active={activeFieldForSorting === 'fundingVia' ? true : false}
                  direction={
                    activeFieldForSorting === 'fundingVia' ? orderDir.toLowerCase() : 'asc'
                  }
                  onClick={() => arrange('fundingVia')}
                ></TableSortLabel>
              </StyledTableCell>

              <StyledTableCell
                width="10%"
                onClick={() => {
                  arrange('portfolioName');
                  setActiveFieldForSorting('portfolioName');
                }}
              >
                PORTFOLIO
                <TableSortLabel
                  active={activeFieldForSorting === 'portfolioName' ? true : false}
                  direction={
                    activeFieldForSorting === 'portfolioName' ? orderDir.toLowerCase() : 'asc'
                  }
                  onClick={() => arrange('portfolioName')}
                ></TableSortLabel>
              </StyledTableCell>

              <StyledTableCell
                width="8%"
                onClick={() => {
                  arrange('product');
                  setActiveFieldForSorting('product');
                }}
              >
                PRODUCT
                <TableSortLabel
                  active={activeFieldForSorting === 'product' ? true : false}
                  direction={activeFieldForSorting === 'product' ? orderDir.toLowerCase() : 'asc'}
                  onClick={() => arrange('product')}
                ></TableSortLabel>
              </StyledTableCell>

              <StyledTableCell
                width="10%"
                onClick={() => {
                  arrange('details');
                  setActiveFieldForSorting('details');
                }}
              >
                DETAILS
                <TableSortLabel
                  active={activeFieldForSorting === 'details' ? true : false}
                  direction={activeFieldForSorting === 'details' ? orderDir.toLowerCase() : 'asc'}
                  onClick={() => arrange('details')}
                ></TableSortLabel>
              </StyledTableCell>

              <StyledTableCell
                width="10%"
                onClick={() => {
                  arrange('amount');
                  setActiveFieldForSorting('amount');
                }}
              >
                CASH TO CLIENT
                <TableSortLabel
                  active={activeFieldForSorting === 'amount' ? true : false}
                  direction={activeFieldForSorting === 'amount' ? orderDir.toLowerCase() : 'asc'}
                  onClick={() => arrange('amount')}
                ></TableSortLabel>
              </StyledTableCell>

              <StyledTableCell
                width="10%"
                onClick={() => {
                  arrange('advancedAmount');
                  setActiveFieldForSorting('advancedAmount');
                }}
              >
                ADV. AMOUNT
                <TableSortLabel
                  active={activeFieldForSorting === 'advancedAmount' ? true : false}
                  direction={
                    activeFieldForSorting === 'advancedAmount' ? orderDir.toLowerCase() : 'asc'
                  }
                  onClick={() => arrange('advancedAmount')}
                ></TableSortLabel>
              </StyledTableCell>

              <StyledTableCell
                width="8%"
                onClick={() => {
                  arrange('createdBy');
                  setActiveFieldForSorting('createdBy');
                }}
              >
                CREATED BY
                <TableSortLabel
                  active={activeFieldForSorting === 'createdBy' ? true : false}
                  direction={activeFieldForSorting === 'createdBy' ? orderDir.toLowerCase() : 'asc'}
                  onClick={() => arrange('createdBy')}
                ></TableSortLabel>
              </StyledTableCell>

              <StyledTableCell width="20%" align="center">
                ACTIONS
              </StyledTableCell>
            </TableRow>
            {/*<TableRow>
              <StyledTableCell width="5%">
                <input
                  type="search"
                  className="form-control"
                  placeholder="Search..."
                  onChange={(e) => searchColumn(e, 'id')}
                />
              </StyledTableCell>
              <StyledTableCell width="5%">
                <input
                  type="search"
                  className="form-control"
                  placeholder="Search..."
                  onChange={(e) => searchColumn(e, 'advanceNo')}
                />
              </StyledTableCell>
              <StyledTableCell width="10%">
                <input
                  type="search"
                  className="form-control"
                  placeholder="Search..."
                  onChange={(e) => searchColumn(e, 'advanceDate')}
                />
              </StyledTableCell>
              <StyledTableCell width="10%">
                <input
                  type="search"
                  className="form-control"
                  placeholder="Search..."
                  onChange={(e) => searchColumn(e, 'fundingVia')}
                />
              </StyledTableCell>
              <StyledTableCell width="10%">
                <input
                  type="search"
                  className="form-control"
                  placeholder="Search..."
                  onChange={(e) => searchColumn(e, 'product')}
                />
              </StyledTableCell>
              <StyledTableCell width="15%">
                <input
                  type="search"
                  className="form-control"
                  placeholder="Search..."
                  onChange={(e) => searchColumn(e, 'details')}
                />
              </StyledTableCell>
              <StyledTableCell width="10%">
                <input
                  type="search"
                  className="form-control"
                  placeholder="Search..."
                  onChange={(e) => searchColumn(e, 'amount')}
                />
              </StyledTableCell>
              <StyledTableCell width="10%">
                <input
                  type="search"
                  className="form-control"
                  placeholder="Search..."
                  onChange={(e) => searchColumn(e, 'delFee')}
                />
              </StyledTableCell>
              <StyledTableCell width="10%">
                <input
                  type="search"
                  className="form-control"
                  placeholder="Search..."
                  onChange={(e) => searchColumn(e, 'createdBy')}
                />
              </StyledTableCell>
              <StyledTableCell width="15%"></StyledTableCell>
            </TableRow>
            */}
          </TableHead>
          {isLoading === false && (
            <TableBody>
              {rows &&
                rows.map((row, index) => (
                  <TableRow key={row.id}>
                    <TableCell>{row.id}</TableCell>
                    <TableCell>{row.advanceNo}</TableCell>
                    <TableCell>
                      {row.advanceDate &&
                        Moment(row.advanceDate.split('T')[0]).format('MM-DD-YYYY')}
                    </TableCell>
                    <TableCell>{row.advanceStatus}</TableCell>
                    <TableCell>{row.fundingType}</TableCell>
                    <TableCell>{row.portfolioName}</TableCell>
                    <TableCell>{row.productType}</TableCell>
                    <TableCell>{row.details}</TableCell>
                    <TableCell>
                      {row.amount && moneyFormatter.format(parseFloat(row?.amount).toFixed(2))}
                    </TableCell>
                    <TableCell>
                      {row.advancedAmount &&
                        moneyFormatter.format(parseFloat(row?.advancedAmount).toFixed(2))}
                    </TableCell>
                    <TableCell>{row.createdByDisplayName}</TableCell>
                    <TableCell className="p-2" align="right">
                      {!row.approvedAmount &&
                        (!selectedAdvance || !selectedAdvance.approvedAmount) && (
                          <Tooltip title="Approve Advance">
                            <button
                              className="btn mr-1 btn-sm-sm"
                              onClick={() => showApprovalModal(row)}
                            >
                              <AssignmentTurnedInOutlinedIcon />
                            </button>
                          </Tooltip>
                        )}
                      <Tooltip title="Get Product">
                        <button
                          className="btn mr-1 btn-sm-sm"
                          onClick={() =>
                            history.push(
                              '/underwriting/applications/' +
                                applicationId +
                                '/advances/' +
                                row.publicId +
                                '/product'
                            )
                          }
                        >
                          <AttachMoneyOutlinedIcon />
                        </button>
                      </Tooltip>
                      <Tooltip title="Get Advances">
                        <button
                          className="btn mr-1 btn-sm-sm"
                          onClick={() =>
                            history.push(
                              '/underwriting/applications/' +
                                applicationId +
                                '/advances/' +
                                row.publicId
                            )
                          }
                        >
                          <EditOutlinedIcon />
                        </button>
                      </Tooltip>

                      {row.approvedAmount && (
                        <>
                          <Tooltip title="Generate Contract Document">
                            <button
                              className="btn mr-1 btn-sm-sm"
                              onClick={() => generateContractDocument(row)}
                            >
                              <InsertDriveFileOutlinedIcon />
                            </button>
                          </Tooltip>
                          <Tooltip title="Generate and send to DocuSign">
                            <button
                              className="btn mr-1 btn-sm-sm"
                              onClick={() => generateContractDocumentAndSendToDocusign(row)}
                            >
                              <OpenInBrowserOutlinedIcon />
                            </button>
                          </Tooltip>
                          <Tooltip title="Generate Thank you letter">
                            <button
                              className="btn mr-1 btn-sm-sm"
                              onClick={() => generateThankYouLetter(row)}
                            >
                              <MapOutlinedIcon />
                            </button>
                          </Tooltip>
                        </>
                      )}
                      {/* {(row.approvedAmount == null || row.approvedAmount == 0) && ( */}
                      <Tooltip title="Delete Advance">
                        <button
                          className="btn mr-1 btn-sm-sm"
                          onClick={() => toggleDeleteModal(row)}
                        >
                          <DeleteOutlinedIcon />
                        </button>
                      </Tooltip>
                      {/* )} */}
                    </TableCell>
                  </TableRow>
                ))}
            </TableBody>
          )}
          {isLoading && (
            <TableBody>
              <TableRow>
                <td></td>
                <td>
                  <ReactLoading type="bars" color="#7E8299" />
                </td>
                <td></td>
              </TableRow>
            </TableBody>
          )}

          <TableFooter></TableFooter>
        </Table>
      </TableContainer>
      <div className="float-left mt-5">
        <select onChange={handlePageSizeChange} className="form-control" defaultValue={15}>
          {[5, 10, 15, 25, 100].map((size) => (
            <option key={size} value={size}>
              {size}
            </option>
          ))}
        </select>
      </div>
      <div className="float-right mt-5">
        <Pagination
          count={pages}
          page={page}
          siblingCount={1}
          boundaryCount={1}
          variant="outlined"
          onChange={handleChangePage}
        />
      </div>
    </>
  );
};

export default withRouter(AdvancesGrid);
