/* eslint-disable jsx-a11y/label-has-associated-control */
import React, { useContext, useEffect } from 'react';
import { Row, Col } from 'reactstrap';
import { useHistory, useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import ApplicationsNewMenu from '../Menu';
import Header from '../Header';
import Grid from './components/Grid';

import AppContext from '@contexts/AppContext';

const Liens = () => {
  const appContext = useContext(AppContext);
  const history = useHistory();
  const { applicationId } = useParams();
  const application = appContext.currentApplication;

  useEffect(() => {

    document.body.scrollTop = 0; // For Safari
    document.documentElement.scrollTop = 0; // For Chrome, Firefox, IE and Opera

    if (appContext.toastMessage.message)
      toast.success(appContext.toastMessage.message, { autoClose: 3000 });
    appContext.toastMessage.message = null;

    if (!applicationId || applicationId == 'undefined') {
      appContext.toastMessage.message = 'Application No was not found!';
      history.push('/underwriting/applications/new-application/');
      return;
    }

  }, []);

  const handleSaveAndExit = () => {
    appContext.toastMessage.message = 'Your changes has been sucessfully saved.';
    appContext.newAppllicationStep += 1;
    history.push('/underwriting/applications');
  };

  const handleNewItem = () => {
    history.push('/underwriting/applications/' + applicationId + '/liens/new-lien');
  };

  return (
    <div className="container mt-10 mb-10">
      <div className="row">
        <div className="col-md-12 mx-auto bg-white box-shadow-border">
          <div className="p-10">
            <div className="mt-5 mb-10">
              <Header
                applicationId={applicationId}
                handleSaveAndExit={handleSaveAndExit}
              />

              <ApplicationsNewMenu applicationId={applicationId} menuItemIndex="6" />

              <Row>
                <div className="col-md-4">
                  <h1 className="mb-10">Liens</h1>
                </div>
                <div className="col-md-8">
                  <button
                    type="button"
                    className="btn btn-primary float-right mr-5"
                    onClick={handleNewItem}
                    style={{ backgroundColor: 'var(--green)', borderColor: 'var(--green)' }}
                  >
                    Add Lien
                    </button>
                </div>
              </Row>

              <Row>
                <Col md="12">
                  <Grid />
                </Col>
              </Row>

            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Liens;
