import React, { useEffect, useContext } from 'react';
import { useHistory } from 'react-router-dom';
import Grid from './Grid';
import { Col, Row } from 'reactstrap';
import AppContext from '@contexts/AppContext';
import './Styles.css';

const ConcessionDiary = () => {
  const history = useHistory();
  const appContext = useContext(AppContext);

  const makeMenuItemActive = () => {
    document.querySelector('#menuitem-portfolios').click();
    document
      .querySelector('#kt_header_tab_3 > div > div > ul > li:nth-child(2)')
      .classList.add('menu-item-active');
  };

  useEffect(() => makeMenuItemActive(), []);

  return (
    <div className="container mt-10 mb-10" style={{ maxWidth: '95%' }}>
      <div className="row">
        <div className="col-md-12 bg-white box-shadow-border">
          <div className="padding-left">
            <Row>
              <Col md="12">
                <Grid />
              </Col>
            </Row>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ConcessionDiary;
