import React, { useEffect } from 'react';
import { useHistory, Link } from 'react-router-dom';
import { Col, Row } from 'reactstrap';
import Grid from './Grid';
import './Styles.css';

const FinancialCenter = () => {
  const history = useHistory();
  const makeMenuItemActive = () => {
    document.querySelector('#menuitem-reports').click();
    document
      .querySelector('#kt_header_menu > ul > li')
      .classList.add('menu-item-active');
  };

  useEffect(() => makeMenuItemActive(), []);

  const getExportFile = () => {
    //Download file as exported one
  }

  return (
    <div className="container mt-10 mb-10" style={{ maxWidth: "95%" }}>
      <div className="row">
        <div className="col-md-12 bg-white box-shadow-border">
          <div style={{ padding: "0.5rem" }}>
            
            {/* <div className="d-flex mt-5 mb-10">
              <div className="mr-auto">
                 <h1>Financial Center Report</h1>
              </div>
            </div> */}

            <Row>
              <Col md="12"><Grid /></Col>
            </Row>
          </div>
        </div>
      </div>
    </div>
  );
};

export default FinancialCenter;
