import React, { useState } from 'react';
import NecessityFundingCaseStatusUpdatesGrid from './NecessityFundingCaseStatusUpdatesGrid';
import { Row, Col } from 'reactstrap';

const NecessityFundingCaseStatusUpdates = () => {
  const [lawFirmName, setLawFirmName] = useState([]);

  return (
    <div className="container mt-10 mb-10">
      <div className="row">
        <div className="col-md-12 bg-white box-shadow-border">
          <div className="p-10">
            <div className="d-flex mt-5 mb-10">
              <div className="mr-auto">
                <h1>Necessity Funding Case Status Updates</h1>
                <h3>{lawFirmName}</h3>
              </div>
            </div>

            <Row>
              <Col md="12">
                <NecessityFundingCaseStatusUpdatesGrid setLawFirmName={setLawFirmName} />
              </Col>
            </Row>
          </div>
        </div>
      </div>
    </div>
  );
};

export default NecessityFundingCaseStatusUpdates;
