import React, { useState, useEffect, useRef, useContext } from 'react';
import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import { useHistory, Link, withRouter } from 'react-router-dom';

import PropTypes from 'prop-types';
import { makeStyles, useTheme, withStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableFooter from '@material-ui/core/TableFooter';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import Pagination from '@material-ui/lab/Pagination';
import EditIcon from '@material-ui/icons/Edit';
import DeleteIcon from '@material-ui/icons/Delete';
import ClientService from '@services/ClientService';
import AppContext from '@contexts/AppContext';
import TableSortLabel from '@material-ui/core/TableSortLabel';
import ReactLoading from 'react-loading';
import { toast } from 'react-toastify';

const useStyles1 = (theme) => ({
  root: {
    flexShrink: 0,
    marginLeft: theme.spacing(2.5),
  },
});

const StyledTableCell = withStyles((theme) => ({
  head: {
    backgroundColor: '#efefef',
    color: theme.palette.common.black,
  },
  body: {
    fontSize: 14,
  },
}))(TableCell);

const useStyles2 = makeStyles({
  table: {
    minWidth: 500,
  },
});

const CustomPaginationActionsTable = () => {
  const appContext = useContext(AppContext);
  const classes = useStyles2();
  const [page, setPage] = React.useState(1);
  const [rowsPerPage, setRowsPerPage] = React.useState(15);
  const [filters, setFilters] = useState(appContext.underwritingClientFilters);
  const [orderBy, setOrderBy] = useState('');
  const [orderDir, setOrderDir] = useState('ASC');
  const [rows, setRows] = useState([]);
  const [searchFirstName, setSearchFirstName] = useState('');
  const [searchLastName, setSearchLastName] = useState('');
  const [searchStreetAddress, setSearchStreetAddress] = useState('');
  const [searchHomePhone, setSearchHomePhone] = useState('');
  const [searchEmailAddress, setSearchEmailAddress] = useState('');
  const [searchIsActive, setSearchIsActive] = useState(undefined);
  const [modal, setModal] = useState(false);
  const [rowIndex, setRowIndex] = useState(undefined);
  const [givenRows, setGivenRows] = useState(undefined);
  const [isLoading, setIsLoading] = useState(false);
  const [pages, setPages] = useState(undefined);
  const [activeFieldForSorting, setActiveFieldForSorting] = useState();

  const history = useHistory();
  const toggle = () => setModal(!modal);

  useEffect(() => {
    update();
  }, [page, orderBy, orderDir, rowsPerPage]);

  useEffect(() => {
    setPage(1);

    const underwritingClientFilters = appContext.underwritingClientFilters;
    const isFiltersEmpty = Object.keys(underwritingClientFilters).length == 0;
    if (!isFiltersEmpty) {
      if (underwritingClientFilters.firstName) {
        setSearchFirstName(underwritingClientFilters.firstName);
      }
      if (underwritingClientFilters.lastName) {
        setSearchLastName(underwritingClientFilters.lastName);
      }
      if (underwritingClientFilters.streetAddress) {
        setSearchStreetAddress(underwritingClientFilters.streetAddress);
      }
      if (underwritingClientFilters.homePhone) {
        setSearchHomePhone(underwritingClientFilters.homePhone);
      }
      if (underwritingClientFilters.emailAddress) {
        setSearchEmailAddress(underwritingClientFilters.emailAddress);
      }
      if (underwritingClientFilters.isActive != undefined) {
        setSearchIsActive(underwritingClientFilters.isActive);
      }
    }
  }, []);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const update = () => {
    setIsLoading(true);

    ClientService.search(filters, orderBy, orderDir, page, null, rowsPerPage)
      .then((response) => {
        setRows(response.data.data);
        setPages(response.data.pages.slice(-1)[0]);
        setIsLoading(false);
      })
      .catch((err) => {})
      .finally(() => {
        setIsLoading(false);
      });
  };

  const searchColumn = (event, columnName) => {
    var newFilters = filters;
    if (columnName === 'isActive' && event.target.value != 'All') {
      newFilters[columnName] = event.target.value === 'true' ? true : false;
    } else if (columnName === 'isActive' && event.target.value === 'All') {
      newFilters[columnName] = undefined;
    } else {
      newFilters[columnName] = event.target.value;
    }
    setFilters(newFilters);
    appContext.underwritingClientFilters = newFilters;
    localStorage.setItem('underwritingClientFilters', JSON.stringify(newFilters));
    update();
  };

  const deleteItem = () => {
    if (!(appContext?.userPermissions?.indexOf('Delete Clients') > -1)) {
      toggle();
      appContext.toastMessage.message = 'You do not have permissions to perform this action.';
      toast.error(appContext.toastMessage.message, { autoClose: 3000 });
      appContext.toastMessage.message = null;
      return;
    }

    setIsLoading(true);

    ClientService.delete(givenRows[rowIndex].publicId)
      .then((result) => {
        let items = [...givenRows];
        items.splice(rowIndex, 1);
        setRows(items);
        toggle();
        appContext.toastMessage.message = 'Client has been successfully deleted.';
        toast.success(appContext.toastMessage.message, { autoClose: 3000 });
      })
      .catch((err) => {})
      .finally(() => {
        setIsLoading(false);
      });
  };

  const arrange = (field) => {
    if (orderBy === field) {
      if (orderDir === 'ASC') {
        setOrderDir('DESC');
      } else {
        setOrderDir('ASC');
      }
    } else {
      setOrderBy(field);
      setOrderDir('ASC');
    }

    setPage(1);
  };

  const handlePageSizeChange = (event) => {
    setRowsPerPage(event.target.value);
  };

  return (
    <div>
      <div>
        <Modal isOpen={modal} toggle={toggle}>
          <ModalHeader toggle={toggle}>Confirmation</ModalHeader>
          <ModalBody>Are you sure you want to delete this client?</ModalBody>
          <ModalFooter>
            <Button color="primary" onClick={deleteItem}>
              Delete
            </Button>
            <Button color="secondary" onClick={toggle}>
              Cancel
            </Button>
          </ModalFooter>
        </Modal>
      </div>

      <TableContainer component={Paper}>
        <Table className={classes.table} aria-label="custom pagination table">
          <TableHead>
            <TableRow>
              <StyledTableCell>Id</StyledTableCell>

              <StyledTableCell
                onClick={() => {
                  arrange('firstName');
                  setActiveFieldForSorting('firstName');
                }}
              >
                FIRST NAME
                <TableSortLabel
                  active={activeFieldForSorting === 'firstName' ? true : false}
                  direction={activeFieldForSorting === 'firstName' ? orderDir.toLowerCase() : 'asc'}
                  onClick={() => arrange('firstName')}
                ></TableSortLabel>
              </StyledTableCell>

              <StyledTableCell
                onClick={() => {
                  arrange('lastName');
                  setActiveFieldForSorting('lastName');
                }}
              >
                LAST NAME
                <TableSortLabel
                  active={activeFieldForSorting === 'lastName' ? true : false}
                  direction={activeFieldForSorting === 'lastName' ? orderDir.toLowerCase() : 'asc'}
                  onClick={() => arrange('lastName')}
                ></TableSortLabel>
              </StyledTableCell>

              <StyledTableCell
                onClick={() => {
                  arrange('streetAddress');
                  setActiveFieldForSorting('streetAddress');
                }}
              >
                ADDRESS
                <TableSortLabel
                  active={activeFieldForSorting === 'streetAddress' ? true : false}
                  direction={
                    activeFieldForSorting === 'streetAddress' ? orderDir.toLowerCase() : 'asc'
                  }
                  onClick={() => arrange('streetAddress')}
                ></TableSortLabel>
              </StyledTableCell>

              <StyledTableCell
                onClick={() => {
                  arrange('homePhone');
                  setActiveFieldForSorting('homePhone');
                }}
              >
                PHONE
                <TableSortLabel
                  active={activeFieldForSorting === 'homePhone' ? true : false}
                  direction={activeFieldForSorting === 'homePhone' ? orderDir.toLowerCase() : 'asc'}
                  onClick={() => arrange('homePhone')}
                ></TableSortLabel>
              </StyledTableCell>

              <StyledTableCell
                onClick={() => {
                  arrange('emailAddress');
                  setActiveFieldForSorting('emailAddress');
                }}
              >
                EMAIL
                <TableSortLabel
                  active={activeFieldForSorting === 'emailAddress' ? true : false}
                  direction={
                    activeFieldForSorting === 'emailAddress' ? orderDir.toLowerCase() : 'asc'
                  }
                  onClick={() => arrange('emailAddress')}
                ></TableSortLabel>
              </StyledTableCell>

              <StyledTableCell
                onClick={() => {
                  arrange('isActive');
                  setActiveFieldForSorting('isActive');
                }}
              >
                STATUS
                <TableSortLabel
                  active={activeFieldForSorting === 'isActive' ? true : false}
                  direction={activeFieldForSorting === 'isActive' ? orderDir.toLowerCase() : 'asc'}
                  onClick={() => arrange('isActive')}
                ></TableSortLabel>
              </StyledTableCell>

              <StyledTableCell></StyledTableCell>
              <StyledTableCell></StyledTableCell>
            </TableRow>
            <TableRow>
              <StyledTableCell>
                <input
                  type="search"
                  className="form-control"
                  placeholder="Search..."
                  onChange={(e) => searchColumn(e, 'id')}
                />
              </StyledTableCell>
              <StyledTableCell>
                <input
                  type="search"
                  className="form-control"
                  placeholder="Search..."
                  onChange={(e) => {
                    searchColumn(e, 'firstName');
                    setSearchFirstName(e.target.value);
                  }}
                  value={searchFirstName}
                />
              </StyledTableCell>
              <StyledTableCell>
                <input
                  type="search"
                  className="form-control"
                  placeholder="Search..."
                  onChange={(e) => {
                    searchColumn(e, 'lastName');
                    setSearchLastName(e.target.value);
                  }}
                  value={searchLastName}
                />
              </StyledTableCell>
              <StyledTableCell>
                <input
                  type="search"
                  className="form-control"
                  placeholder="Search..."
                  onChange={(e) => {
                    searchColumn(e, 'streetAddress');
                    setSearchStreetAddress(e.target.value);
                  }}
                  value={searchStreetAddress}
                />
              </StyledTableCell>
              <StyledTableCell>
                <input
                  type="search"
                  className="form-control"
                  placeholder="Search..."
                  onChange={(e) => {
                    searchColumn(e, 'homePhone');
                    setSearchHomePhone(e.target.value);
                  }}
                  value={searchHomePhone}
                />
              </StyledTableCell>
              <StyledTableCell>
                <input
                  type="search"
                  className="form-control"
                  placeholder="Search..."
                  onChange={(e) => {
                    searchColumn(e, 'emailAddress');
                    setSearchEmailAddress(e.target.value);
                  }}
                  value={searchEmailAddress}
                />
              </StyledTableCell>
              <StyledTableCell width={'120px'}>
                <select
                  value={searchIsActive}
                  className="form-control"
                  onChange={(e) => {
                    searchColumn(e, 'isActive');
                    setSearchIsActive(e.target.value);
                  }}
                >
                  <option value={undefined}>All</option>
                  <option value={true}>Active</option>
                  <option value={false}>Inactive</option>
                </select>
              </StyledTableCell>
              <StyledTableCell></StyledTableCell>
              <StyledTableCell></StyledTableCell>
            </TableRow>
          </TableHead>
          {isLoading === false && (
            <TableBody>
              {rows &&
                rows.map((row, index) => (
                  <TableRow key={index}>
                    <TableCell key={row.id} component="th" scope="row">
                      {row.id}
                    </TableCell>
                    <TableCell key={index + 100}>{row.firstName}</TableCell>
                    <TableCell key={index + 101}>{row.lastName}</TableCell>
                    <TableCell
                      key={index + 102}
                    >{`${row.streetAddress}, ${row.zipCode}`}</TableCell>
                    <TableCell key={index + 103}>{row.homePhone}</TableCell>
                    <TableCell key={index + 104}>{row.emailAddress}</TableCell>
                    <TableCell key={index + 105}>
                      {row.isActive === true ? (
                        <span className="text-success">Active</span>
                      ) : (
                        <span className="text-danger">Inactive</span>
                      )}
                    </TableCell>
                    <TableCell key={index + 106} className="p-2">
                      <a className="btn float-right" href={'clients/' + row.publicId}>
                        <EditIcon />
                      </a>
                    </TableCell>

                    <TableCell key={index + 107} className="p-2">
                      <a
                        className="btn float-left"
                        onClick={() => {
                          setRowIndex(index);
                          setGivenRows(rows);
                          toggle();
                        }}
                      >
                        <DeleteIcon style={{ color: 'var(--red)' }} />
                      </a>
                    </TableCell>
                  </TableRow>
                ))}
            </TableBody>
          )}
          {isLoading && (
            <TableBody>
              <TableRow>
                <td></td>
                <td>
                  <ReactLoading type="bars" color="#7E8299" />
                </td>
                <td></td>
              </TableRow>
            </TableBody>
          )}

          <TableFooter></TableFooter>
        </Table>
      </TableContainer>
      <div className="float-left mt-5">
        <select onChange={handlePageSizeChange} className="form-control" defaultValue={15}>
          {[5, 10, 15, 25, 100].map((size) => (
            <option key={size} value={size}>
              {size}
            </option>
          ))}
        </select>
      </div>
      <div className="float-right mt-5">
        <Pagination
          count={pages}
          page={page}
          siblingCount={1}
          boundaryCount={1}
          variant="outlined"
          onChange={handleChangePage}
        />
      </div>
    </div>
  );
};

export default withRouter(CustomPaginationActionsTable);
