import React, { useEffect } from 'react';

const PayoffDetails = () => {

  const makeMenuItemActive = () => {
    document.querySelector('#menuitem-reports').click();
    document
      .querySelector('#kt_header_menu > ul > li')
      .classList.add('menu-item-active');
  };

  useEffect(() => makeMenuItemActive(), []);

  return (
    <div className="container mt-10 mb-10">
      <div className="row">
        <div className="col-md-12 bg-white box-shadow-border">
          <div className="p-10">
            <div className="d-flex mt-5 mb-10">
              <div className="mr-auto">
                <h1>Payoff Details Report</h1>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default PayoffDetails;
