import React, { useState, useEffect, useContext } from 'react';
import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import { useHistory } from 'react-router';
import PropTypes from 'prop-types';
import { makeStyles, useTheme, withStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import { Row, Col } from 'reactstrap';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableFooter from '@material-ui/core/TableFooter';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import Pagination from '@material-ui/lab/Pagination';
import EditIcon from '@material-ui/icons/Edit';
import ConcessionDiaryService from '@services/ConcessionDiaryService';
import AppContext from '@contexts/AppContext';
import TableSortLabel from '@material-ui/core/TableSortLabel';
import ReactLoading from 'react-loading';
import { Link } from 'react-router-dom';
import fileDownload from 'js-file-download';
import Moment from 'moment';

const StyledTableCell = withStyles((theme) => ({
  head: {
    backgroundColor: '#efefef',
    color: theme.palette.common.black,
  },
  body: {
    fontSize: 14,
  },
}))(TableCell);

const useStyles2 = makeStyles({
  table: {
    minWidth: 1200,
  },
});

const moneyFormatter = new Intl.NumberFormat('en-US', {
  style: 'currency',
  currency: 'USD',
});

export default function CustomPaginationActionsTable() {
  const appContext = useContext(AppContext);
  const classes = useStyles2();
  const [page, setPage] = React.useState(1);
  const [rowsPerPage, setRowsPerPage] = React.useState(15);
  const [filters, setFilters] = useState({});
  const [orderBy, setOrderBy] = useState('');
  const [orderDir, setOrderDir] = useState('ASC');
  const [rows, setRows] = useState([]);
  const [application, setApplication] = useState([]);
  const [applicationPages, setApplicationPages] = useState([]);

  const history = useHistory();

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  useEffect(() => {
    update();
  }, [page, orderBy, orderDir, rowsPerPage]);

  useEffect(() => {
    setPage(1);
  }, []);

  const update = () => {
    setIsLoading(true);

    ConcessionDiaryService.search(filters, orderBy, orderDir, page, null, rowsPerPage)
      .then((response) => {
        setRows(response.data.data);
        setPages(response.data.pages.slice(-1)[0]);
        setIsLoading(false);
      })
      .catch((err) => {})
      .finally(() => {
        setIsLoading(false);
      });
  };

  const [modal, setModal] = useState(false);
  const toggle = () => setModal(!modal);
  const [rowIndex, setRowIndex] = useState(undefined);
  const [givenRows, setGivenRows] = useState(undefined);
  const [isLoading, setIsLoading] = useState(false);
  const [pages, setPages] = useState(undefined);

  const [activeFieldForSorting, setActiveFieldForSorting] = useState();

  const arrange = (field) => {
    if (orderBy === field) {
      if (orderDir === 'ASC') {
        setOrderDir('DESC');
      } else {
        setOrderDir('ASC');
      }
    } else {
      setOrderBy(field);
      setOrderDir('ASC');
    }

    setPage(1);
  };

  const editClick = (row) => {
    history.push(
      window.open(
        '/underwriting/applications/' +
          row.applicationNo +
          '/payoffs/concession-request/' +
          row.concessionRequestPublicId
      ),
      '_blank'
    );
  };
  const handlePageSizeChange = (event) => {
    setRowsPerPage(event.target.value);
  };

  const exportTo = (filters) => {
    let reqFilters = filters;
    if (reqFilters && reqFilters.attorney) {
      reqFilters = { ...reqFilters, AttorneyPublicId: reqFilters.attorney.publicId };
    }
    if (reqFilters && reqFilters.lawFirm) {
      reqFilters = { ...reqFilters, LawFirmPublicId: reqFilters.lawFirm.publicId };
    }

    delete reqFilters.lawFirm;
    delete reqFilters.attorney;

    setIsLoading(true);
    ConcessionDiaryService.export(reqFilters, orderBy, orderDir, page, null, rowsPerPage)
      .then((response) => {
        const contentDisposition = response.headers['content-disposition'];
        let fileName = 'Concession-Diary-Data-Export.xlsx';
        if (contentDisposition) {
          const fileNameMatch = contentDisposition.match(/filename="(.+)"/);
          if (fileNameMatch != null && fileNameMatch.length === 2) fileName = fileNameMatch[1];
        }
        fileDownload(response.data, fileName);
        setIsLoading(false);
      })
      .catch((err) => {})
      .finally(() => {
        setIsLoading(false);
      });
  };

  const exportAttorneyDataReport = () => {
    exportTo(filters);
  };

  return (
    <>
      <Col md="12" className="float-right">
        <div className="d-flex mt-5 mb-10">
          <div className="mr-auto margin-heading">
            <h1>Concession Diary</h1>
          </div>
          <Link
            onClick={exportAttorneyDataReport}
            className="btn btn-primary"
            style={{
              backgroundColor: 'var(--green)',
              borderColor: 'var(--green)',
              minWidth: '100px',
            }}
          >
            Export
          </Link>
        </div>

        <TableContainer component={Paper}>
          <Table className={classes.table} aria-label="custom pagination table">
            <TableHead>
              <TableRow>
                <StyledTableCell>Id</StyledTableCell>

                <StyledTableCell
                  style={{ width: '8%', whiteSpace: 'nowrap' }}
                  onClick={() => {
                    arrange('concessionEnteredDate');
                    setActiveFieldForSorting('concessionEnteredDate');
                  }}
                >
                  ENTERED DATE
                  <TableSortLabel
                    active={activeFieldForSorting === 'concessionEnteredDate' ? true : false}
                    direction={
                      activeFieldForSorting === 'concessionEnteredDate'
                        ? orderDir.toLowerCase()
                        : 'asc'
                    }
                    onClick={() => arrange('concessionEnteredDate')}
                  ></TableSortLabel>
                </StyledTableCell>

                <StyledTableCell
                  style={{ width: '15%', whiteSpace: 'nowrap' }}
                  onClick={() => {
                    arrange('concessionApprovalDate');
                    setActiveFieldForSorting('concessionApprovalDate');
                  }}
                >
                  APPROVAL DATE
                  <TableSortLabel
                    active={activeFieldForSorting === 'concessionApprovalDate' ? true : false}
                    direction={
                      activeFieldForSorting === 'concessionApprovalDate'
                        ? orderDir.toLowerCase()
                        : 'asc'
                    }
                    onClick={() => arrange('concessionApprovalDate')}
                  ></TableSortLabel>
                </StyledTableCell>

                <StyledTableCell
                  style={{ width: '15%', whiteSpace: 'nowrap' }}
                  onClick={() => {
                    arrange('concessionApprovedBy');
                    setActiveFieldForSorting('concessionApprovedBy');
                  }}
                >
                  APPROVED BY
                  <TableSortLabel
                    active={activeFieldForSorting === 'concessionApprovedBy' ? true : false}
                    direction={
                      activeFieldForSorting === 'concessionApprovedBy'
                        ? orderDir.toLowerCase()
                        : 'asc'
                    }
                    onClick={() => arrange('concessionApprovedBy')}
                  ></TableSortLabel>
                </StyledTableCell>

                <StyledTableCell
                  style={{ width: '8%', whiteSpace: 'nowrap' }}
                  onClick={() => {
                    arrange('client');
                    setActiveFieldForSorting('client');
                  }}
                >
                  CLIENT
                  <TableSortLabel
                    active={activeFieldForSorting === 'client' ? true : false}
                    direction={activeFieldForSorting === 'client' ? orderDir.toLowerCase() : 'asc'}
                    onClick={() => arrange('client')}
                  ></TableSortLabel>
                </StyledTableCell>

                <StyledTableCell
                  style={{ width: '8%', whiteSpace: 'nowrap' }}
                  onClick={() => {
                    arrange('allAdvanceNos');
                    setActiveFieldForSorting('allAdvanceNos');
                  }}
                >
                  ADV. NOS.
                  <TableSortLabel
                    active={activeFieldForSorting === 'allAdvanceNos' ? true : false}
                    direction={
                      activeFieldForSorting === 'allAdvanceNos' ? orderDir.toLowerCase() : 'asc'
                    }
                    onClick={() => arrange('allAdvanceNos')}
                  ></TableSortLabel>
                </StyledTableCell>

                <StyledTableCell
                  style={{ width: '8%', whiteSpace: 'nowrap' }}
                  onClick={() => {
                    arrange('attorney');
                    setActiveFieldForSorting('attorney');
                  }}
                >
                  ATTORNEY
                  <TableSortLabel
                    active={activeFieldForSorting === 'attorney' ? true : false}
                    direction={
                      activeFieldForSorting === 'attorney' ? orderDir.toLowerCase() : 'asc'
                    }
                    onClick={() => arrange('attorney')}
                  ></TableSortLabel>
                </StyledTableCell>

                <StyledTableCell
                  style={{ width: '8%', whiteSpace: 'nowrap' }}
                  onClick={() => {
                    arrange('lawFirm');
                    setActiveFieldForSorting('lawFirm');
                  }}
                >
                  LAW FIRM
                  <TableSortLabel
                    active={activeFieldForSorting === 'lawFirm' ? true : false}
                    direction={activeFieldForSorting === 'lawFirm' ? orderDir.toLowerCase() : 'asc'}
                    onClick={() => arrange('lawFirm')}
                  ></TableSortLabel>
                </StyledTableCell>

                <StyledTableCell
                  style={{ width: '8%', whiteSpace: 'nowrap' }}
                  onClick={() => {
                    arrange('accountManagerName');
                    setActiveFieldForSorting('accountManagerName');
                  }}
                >
                  ACCOUNT MANAGER
                  <TableSortLabel
                    active={activeFieldForSorting === 'accountManagerName' ? true : false}
                    direction={
                      activeFieldForSorting === 'accountManagerName'
                        ? orderDir.toLowerCase()
                        : 'asc'
                    }
                    onClick={() => arrange('accountManagerName')}
                  ></TableSortLabel>
                </StyledTableCell>

                <StyledTableCell
                  style={{ width: '10%', whiteSpace: 'nowrap' }}
                  onClick={() => {
                    arrange('concessionPayoffAmount');
                    setActiveFieldForSorting('concessionPayoffAmount');
                  }}
                >
                  CON.PAYOFF AMT.
                  <TableSortLabel
                    active={activeFieldForSorting === 'concessionPayoffAmount' ? true : false}
                    direction={
                      activeFieldForSorting === 'concessionPayoffAmount'
                        ? orderDir.toLowerCase()
                        : 'asc'
                    }
                    onClick={() => arrange('concessionPayoffAmount')}
                  ></TableSortLabel>
                </StyledTableCell>

                <StyledTableCell
                  style={{ width: '10%', whiteSpace: 'nowrap' }}
                  onClick={() => {
                    arrange('actualPayoff');
                    setActiveFieldForSorting('actualPayoff');
                  }}
                >
                  ACTUAL PAYOFF
                  <TableSortLabel
                    active={activeFieldForSorting === 'actualPayoff' ? true : false}
                    direction={
                      activeFieldForSorting === 'actualPayoff' ? orderDir.toLowerCase() : 'asc'
                    }
                    onClick={() => arrange('actualPayoff')}
                  ></TableSortLabel>
                </StyledTableCell>

                <StyledTableCell
                  style={{ width: '15%', whiteSpace: 'nowrap' }}
                  onClick={() => {
                    arrange('totalCashOutlay');
                    setActiveFieldForSorting('totalCashOutlay');
                  }}
                >
                  TOTAL CASH OUTLAY
                  <TableSortLabel
                    active={activeFieldForSorting === 'totalCashOutlay' ? true : false}
                    direction={
                      activeFieldForSorting === 'totalCashOutlay' ? orderDir.toLowerCase() : 'asc'
                    }
                    onClick={() => arrange('totalCashOutlay')}
                  ></TableSortLabel>
                </StyledTableCell>

                <StyledTableCell
                  style={{ width: '10%', whiteSpace: 'nowrap' }}
                  onClick={() => {
                    arrange('percentageReceived');
                    setActiveFieldForSorting('percentageReceived');
                  }}
                >
                  PER. RECEIVED
                  <TableSortLabel
                    active={activeFieldForSorting === 'percentageReceived' ? true : false}
                    direction={
                      activeFieldForSorting === 'percentageReceived'
                        ? orderDir.toLowerCase()
                        : 'asc'
                    }
                    onClick={() => arrange('percentageReceived')}
                  ></TableSortLabel>
                </StyledTableCell>

                <StyledTableCell
                  style={{ width: '10%', whiteSpace: 'nowrap' }}
                  onClick={() => {
                    arrange('netRevenue');
                    setActiveFieldForSorting('netRevenue');
                  }}
                >
                  NET REVENUE
                  <TableSortLabel
                    active={activeFieldForSorting === 'netRevenue' ? true : false}
                    direction={
                      activeFieldForSorting === 'netRevenue' ? orderDir.toLowerCase() : 'asc'
                    }
                    onClick={() => arrange('netRevenue')}
                  ></TableSortLabel>
                </StyledTableCell>

                <StyledTableCell
                  style={{ width: '10%', whiteSpace: 'nowrap' }}
                  onClick={() => {
                    arrange('concessionAmount');
                    setActiveFieldForSorting('concessionAmount');
                  }}
                >
                  CON. AMOUNT
                  <TableSortLabel
                    active={activeFieldForSorting === 'concessionAmount' ? true : false}
                    direction={
                      activeFieldForSorting === 'concessionAmount' ? orderDir.toLowerCase() : 'asc'
                    }
                    onClick={() => arrange('concessionAmount')}
                  ></TableSortLabel>
                </StyledTableCell>

                <StyledTableCell
                  style={{ width: '10%', whiteSpace: 'nowrap' }}
                  onClick={() => {
                    arrange('concessionPerc');
                    setActiveFieldForSorting('concessionPerc');
                  }}
                >
                  CON. PERCENTAGE
                  <TableSortLabel
                    active={activeFieldForSorting === 'concessionPerc' ? true : false}
                    direction={
                      activeFieldForSorting === 'concessionPerc' ? orderDir.toLowerCase() : 'asc'
                    }
                    onClick={() => arrange('concessionPerc')}
                  ></TableSortLabel>
                </StyledTableCell>

                <StyledTableCell
                  style={{ width: '10%', whiteSpace: 'nowrap' }}
                  onClick={() => {
                    arrange('averageDaysOutstanding');
                    setActiveFieldForSorting('averageDaysOutstanding');
                  }}
                >
                  AVG.DAYS.OUST
                  <TableSortLabel
                    active={activeFieldForSorting === 'averageDaysOutstanding' ? true : false}
                    direction={
                      activeFieldForSorting === 'averageDaysOutstanding'
                        ? orderDir.toLowerCase()
                        : 'asc'
                    }
                    onClick={() => arrange('averageDaysOutstanding')}
                  ></TableSortLabel>
                </StyledTableCell>

                <StyledTableCell
                  style={{ width: '10%', whiteSpace: 'nowrap' }}
                  onClick={() => {
                    arrange('numberofFiles');
                    setActiveFieldForSorting('numberofFiles');
                  }}
                >
                  NUM.OF FILES
                  <TableSortLabel
                    active={activeFieldForSorting === 'numberofFiles' ? true : false}
                    direction={
                      activeFieldForSorting === 'numberofFiles' ? orderDir.toLowerCase() : 'asc'
                    }
                    onClick={() => arrange('numberofFiles')}
                  ></TableSortLabel>
                </StyledTableCell>

                <StyledTableCell></StyledTableCell>
              </TableRow>
            </TableHead>
            {isLoading === false && (
              <TableBody>
                {rows &&
                  rows.map((row, index) => (
                    <TableRow key={row.name}>
                      <TableCell component="th" scope="row">
                        {row.id}
                      </TableCell>
                      <TableCell style={{ width: '8%', whiteSpace: 'nowrap' }}>
                        {row?.concessionEnteredDate &&
                          Moment(row?.concessionEnteredDate.split('T')[0]).format('MM-DD-YYYY')}
                      </TableCell>
                      <TableCell style={{ width: '8%', whiteSpace: 'nowrap' }}>
                        {row?.concessionApprovalDate &&
                          Moment(row?.concessionApprovalDate?.split('T')[0]).format('MM-DD-YYYY')}
                      </TableCell>
                      <TableCell style={{ width: '8%', whiteSpace: 'nowrap' }}>
                        {row?.concessionApprovedBy}
                      </TableCell>
                      <TableCell style={{ width: '8%', whiteSpace: 'nowrap' }}>
                        {row?.client}
                      </TableCell>
                      <TableCell style={{ width: '8%', whiteSpace: 'nowrap' }}>
                        {row?.allAdvanceNos}
                      </TableCell>
                      <TableCell style={{ width: '8%', whiteSpace: 'nowrap' }}>
                        {row?.attorney}
                      </TableCell>
                      <TableCell style={{ width: '8%', whiteSpace: 'nowrap' }}>
                        {row?.lawFirm}
                      </TableCell>
                      <TableCell style={{ width: '8%', whiteSpace: 'nowrap' }}>
                        {row?.accountManagerName}
                      </TableCell>
                      <TableCell style={{ width: '8%', whiteSpace: 'nowrap' }}>
                        {row?.concessionPayoffAmount != null &&
                          moneyFormatter.format(parseFloat(row?.concessionPayoffAmount).toFixed(2))}
                      </TableCell>
                      <TableCell style={{ width: '8%', whiteSpace: 'nowrap' }}>
                        {row?.actualPayoff != null &&
                          moneyFormatter.format(parseFloat(row?.actualPayoff).toFixed(2))}
                      </TableCell>
                      <TableCell style={{ width: '8%', whiteSpace: 'nowrap' }}>
                        {row?.totalCashOutlay != null &&
                          moneyFormatter.format(parseFloat(row?.totalCashOutlay).toFixed(2))}
                      </TableCell>

                      <TableCell style={{ width: '8%', whiteSpace: 'nowrap' }}>
                        {row?.percentageReceived != null &&
                          parseFloat(row?.percentageReceived).toFixed(2) + '%'}
                      </TableCell>

                      <TableCell style={{ width: '15%', whiteSpace: 'nowrap' }}>
                        {row?.netRevenue != null &&
                          moneyFormatter.format(parseFloat(row?.netRevenue).toFixed(2))}
                      </TableCell>
                      <TableCell style={{ width: '8%', whiteSpace: 'nowrap' }}>
                        {row?.concessionAmount != null &&
                          moneyFormatter.format(parseFloat(row?.concessionAmount).toFixed(2))}
                      </TableCell>
                      <TableCell style={{ width: '8%', whiteSpace: 'nowrap' }}>
                        {row?.concessionPerc !== null
                          ? row?.concessionPerc?.toFixed(2) + '%'
                          : null}
                      </TableCell>
                      <TableCell style={{ width: '8%', whiteSpace: 'nowrap' }}>
                        {row?.averageDaysOutstanding}
                      </TableCell>
                      <TableCell style={{ width: '8%', whiteSpace: 'nowrap' }}>
                        {row?.numberofFiles}
                      </TableCell>

                      <TableCell className="p-2">
                        <a className="btn float-right" onClick={() => editClick(row)}>
                          <EditIcon />
                        </a>
                      </TableCell>
                    </TableRow>
                  ))}
              </TableBody>
            )}
            {isLoading && (
              <TableBody>
                <TableRow>
                  <td></td>
                  <td>
                    <ReactLoading type="bars" color="#7E8299" />
                  </td>
                  <td></td>
                </TableRow>
              </TableBody>
            )}

            <TableFooter></TableFooter>
          </Table>
        </TableContainer>
        <div className="float-left mt-5">
          <select onChange={handlePageSizeChange} className="form-control" defaultValue={15}>
            {[5, 10, 15, 25, 100].map((size) => (
              <option key={size} value={size}>
                {size}
              </option>
            ))}
          </select>
        </div>
        <div className="float-right mt-5">
          <Pagination
            count={pages}
            page={page}
            siblingCount={1}
            boundaryCount={1}
            variant="outlined"
            onChange={handleChangePage}
          />
        </div>
      </Col>
    </>
  );
}
