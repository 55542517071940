import React, { useState, useEffect, useContext } from 'react';
import { makeStyles, withStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import { Col } from 'reactstrap';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableFooter from '@material-ui/core/TableFooter';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import Pagination from '@material-ui/lab/Pagination';
import VReportsLawFirmSummary from '@services/VReportsLawFirmSummary';
import AppContext from '@contexts/AppContext';
import TableSortLabel from '@material-ui/core/TableSortLabel';
import ReactLoading from 'react-loading';
import FilteringFields from './FilteringFields';
import '../Styles.css';
import { FormattedAmount } from '../../../../components/Utils/FormattedAmount';
import { toast } from 'react-toastify';

const StyledTableCell = withStyles((theme) => ({
  head: {
    backgroundColor: '#efefef',
    color: theme.palette.common.black,
  },
  body: {
    fontSize: 14,
  },
}))(TableCell);

const useStyles2 = makeStyles({
  table: {
    minWidth: 500,
  },
});

export default function CustomPaginationActionsTable() {
  const appContext = useContext(AppContext);
  const classes = useStyles2();
  const [page, setPage] = React.useState(1);
  const [rowsPerPage, setRowsPerPage] = React.useState(99999);
  const [filters, setFilters] = useState(appContext.lawFirmSummaryReportFilters);
  const [orderBy, setOrderBy] = useState('lawFirm');
  const [orderDir, setOrderDir] = useState('ASC');
  const [rows, setRows] = useState([]);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  useEffect(() => {
    if (!filters.dateRange || filters.dateRange == "") {
      let tempFilters = {
        ...filters,
        dateRange: "This month"
      }
      setFilters(tempFilters);
      appContext.lawFirmSummaryReportFilters = tempFilters;
      localStorage.setItem('lawFirmSummaryReportFilters', JSON.stringify(tempFilters));
      loadData(tempFilters);
    } else {
      loadData(filters);
    }
  }, [page, orderBy, orderDir, rowsPerPage]);

  const loadData = (filters) => {
    let reqFilters = filters
    if (reqFilters.dateRange == "") {
      delete reqFilters.dateRange
    }

    setIsLoading(true);

    VReportsLawFirmSummary.search(reqFilters, orderBy, orderDir, page, null, rowsPerPage)
      .then((response) => {
        setRows(response.data.data);
        setPages(response.data.pages.slice(-1)[0]);
        setIsLoading(false);
      }).catch((err) => {
        
      }).finally(() => {
        setIsLoading(false);
      });
  };

  const getAll = () => {
    let tempFilters = {
      'dateRange': "This month"
    }
    setFilters(tempFilters);
    appContext.lawFirmSummaryReportFilters = tempFilters;
    localStorage.setItem('lawFirmSummaryReportFilters', JSON.stringify(tempFilters));
    update(tempFilters);
  }

  const update = (filters) => {
    let reqFilters = filters
    if (reqFilters.dateRange == "") {
      delete reqFilters.dateRange
    }

    setIsLoading(true);
    
    VReportsLawFirmSummary.search(reqFilters, orderBy, orderDir, page, null, rowsPerPage)
      .then((response) => {
        setRows(response.data.data);
        setPages(response.data.pages.slice(-1)[0]);
        setIsLoading(false);
      }).catch((err) => {
        
      }).finally(() => {
        setIsLoading(false);
      });
  };

  const searchWithFilters = (filters) => {
    var newFilters = { ...filters };
    setFilters(newFilters);
    appContext.lawFirmSummaryReportFilters = newFilters;
    localStorage.setItem('lawFirmSummaryReportFilters', JSON.stringify(newFilters));
    if (page === 1) update(newFilters);
    else setPage(1);
  };

  const [modal, setModal] = useState(false);
  const toggle = () => setModal(!modal);
  const [rowIndex, setRowIndex] = useState(undefined);
  const [givenRows, setGivenRows] = useState(undefined);
  const [isLoading, setIsLoading] = useState(false);
  const [pages, setPages] = useState(undefined);

  const [activeFieldForSorting, setActiveFieldForSorting] = useState();

  const arrange = (field) => {
    if (orderBy === field) {
      if (orderDir === 'ASC') {
        setOrderDir('DESC');
      } else {
        setOrderDir('ASC');
      }
    } else {
      setOrderBy(field);
      setOrderDir('ASC');
    }

    setPage(1);
  };

  const handlePageSizeChange = (event) => {
    setRowsPerPage(event.target.value);
  };


  return (
    <>
      <FilteringFields searchWithFilters={searchWithFilters} getAll={getAll}></FilteringFields>
      <Col md="8" className="float-left lfsr-table-container" >
        <TableContainer component={Paper}>
          <Table className={classes.table} aria-label="custom pagination table">
            <TableHead>
              <TableRow>
                <StyledTableCell>Id</StyledTableCell>

                <StyledTableCell
                  className="law-firm"
                >
                  Law Firm
                </StyledTableCell>

                <StyledTableCell
                  className="num-cases"
                >
                  # OF Advances
                </StyledTableCell>

                <StyledTableCell
                  className="total-advanced-amt"
                >
                  Total Advanced Amount
                </StyledTableCell>

              </TableRow>
            </TableHead>
            {isLoading === false && (
              <TableBody>
                {rows &&
                  rows.map((row, index) => (
                    <TableRow key={row.name}>
                      <TableCell component="th" scope="row">
                        {row.id}
                      </TableCell>
                      <TableCell className="law-firm"><a className="name-link" href={'/reports/law-firm-details/' + row.lawFirmPublicId} >{row.lawFirm}</a></TableCell>
                      <TableCell className="num-cases">{row?.totalAdvances ? row.totalAdvances : "-"}</TableCell>
                      <TableCell className="total-advanced-amt">{row?.totalAdvancedAmount ? FormattedAmount.format(parseFloat(row.totalAdvancedAmount).toFixed(2)) : "-"}</TableCell>
                    </TableRow>
                  ))}
              </TableBody>
            )}
            {isLoading && (
              <TableBody>
                <TableRow>
                  <td></td>
                  <td>
                    <ReactLoading type="bars" color="#7E8299" />
                  </td>
                  <td></td>
                </TableRow>
              </TableBody>
            )}

            <TableFooter></TableFooter>
          </Table>
        </TableContainer>
        <div className="float-left mt-5">
          <select onChange={handlePageSizeChange} className="form-control" defaultValue={15}>
            {[5, 10, 15, 25, 100].map((size) => (
              <option key={size} value={size}>
                {size}
              </option>
            ))}
          </select>
        </div>
        <div className="float-right mt-5">
          <Pagination
            count={pages}
            page={page}
            siblingCount={1}
            boundaryCount={1}
            variant="outlined"
            onChange={handleChangePage}
          />
        </div>
      </Col>
    </>
  );
}
