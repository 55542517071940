import React, { useContext, useEffect, useState, useRef } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import PortfolioService from '@services/PortfolioService';
import BrokerAutoSuggest from './components/BrokerAutoSuggest/BrokerAutoSuggest';
import BrokerService from '@services/BrokerService';
import PortfolioAutoSuggest from '@components/PortfoltioAutoSuggest/PortfolioAutoSuggest';
import {
  Row,
  Col,
  Input,
  Label,
  Alert,
  Button,
  Form,
  FormGroup,
  Container,
  Table,
} from 'reactstrap';
import { toast } from 'react-toastify';
import { useForm } from 'react-hook-form';
import ApplicationsNewMenu from '../Menu';
import AppContext from '@contexts/AppContext';
import Header from '../Header';
import ApplicationMiscService from '@services/ApplicationMiscService';
import ReactLoading from 'react-loading';

const Misc = () => {
  const appContext = useContext(AppContext);
  const { applicationId } = useParams();
  const [application, setApplication] = useState({});
  const [selectedPortfolio, setSelectedPortfolio] = useState('');
  const [selectedPortfolioName, setSelectedPortfolioName] = useState('');
  const [brokerRefFee, setBrokerRefFee] = useState(null);
  const [defaultValueBrokerRefFee, setDefaultValueBrokerRefFee] = useState(null);
  const [disableTabs, setDisableTabs] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [applicationCaseStatus, setApplicationCaseStatus] = useState(null);
  const [firstSelectedPortfolioRun, setFirstSelectedPortfolioRun] = useState(false);
  const [firstSelectedBroker, setFirstSelectedBroker] = useState(false);
  const [selectedBroker, setSelectedBroker] = useState('');
  const [selectedBrokerName, setSelectedBrokerName] = useState('');
  const [selectedPortfolioError, setSelectedPortfolioError] = useState(undefined);
  const [selectedBrokerError, setSelectedBrokerError] = useState(undefined);
  const history = useHistory();
  const { register, handleSubmit, watch, errors, formState } = useForm({ mode: 'onBlur' });
  const refferingPartyRef = useRef();

  useEffect(() => {
    document.body.scrollTop = 0; // For Safari
    document.documentElement.scrollTop = 0; // For Chrome, Firefox, IE and Opera

    if (appContext.toastMessage.message)
      toast.success(appContext.toastMessage.message, { autoClose: 3000 });
    appContext.toastMessage.message = null;

    if (!applicationId || applicationId == 'undefined') {
      appContext.toastMessage.message = 'Application No was not found!';
      history.push('/underwriting/applications/new-application/');
      return;
    }

    setIsLoading(true);

    ApplicationMiscService.search({ applicationNo: applicationId }, null, null, 1, {}, 10)
      .then((response) => {
        setApplication(response.data.data[0]);
        setApplicationCaseStatus(response.data.data[0].applicationCaseStatus);
        response.data.data[0].applicationProcessingFeeRates.map((item) => {
          setInputList((setInputList) => [...setInputList, item]);
        });

        if (response?.data?.data[0]?.portfolioPublicId != '00000000-0000-0000-0000-000000000000') {
          PortfolioService.read(response?.data?.data[0]?.portfolioPublicId)
            .then((response2) => {
              setSelectedPortfolio(response2?.data?.publicId);
              setSelectedPortfolioName(response2?.data?.portfolioName);
            })
            .catch((err) => {})
            .finally(() => {
              setIsLoading(false);
            });
        }

        setDefaultValueBrokerRefFee(response?.data?.data[0]?.referringPartyFee);

        if (response?.data?.data[0]?.brokerPublicId != '00000000-0000-0000-0000-000000000000') {
          BrokerService.read(response?.data?.data[0]?.brokerPublicId)
            .then((response2) => {
              setSelectedBroker(response2?.data?.publicId);
              setSelectedBrokerName(response2?.data?.companyName);
            })
            .catch((err) => {})
            .finally(() => {
              setIsLoading(false);
            });
        }
      })
      .catch((err) => {})
      .finally(() => {
        setIsLoading(false);
      });
  }, []);

  const handleFormSubmitted = (e) => {
    e.preventDefault();

    if (!(appContext?.userPermissions?.indexOf('Edit Misc. Info') > -1)) {
      appContext.toastMessage.message = 'You do not have permissions to perform this action.';
      toast.error(appContext.toastMessage.message, { autoClose: 3000 });
      appContext.toastMessage.message = null;
      return;
    }

    if (selectedPortfolio === '') {
      setSelectedPortfolioError(true);
      return;
    }

    if (!applicationId) {
      appContext.toastMessage.message = 'Application No was not found!';
      history.push('/underwriting/applications/new-application/attorney');
      return;
    }

    var applicationProcessingFeeRates = onSaveProcessingFeeRate();
    applicationProcessingFeeRates.map((item) => {
      item.applicationPublicId = application.applicationPublicId;
    });

    let requestObj = {};
    requestObj.applicationPublicId = application.applicationPublicId;
    requestObj.brokerPublicId = selectedBroker;
    if (selectedBroker == '') delete requestObj.brokerPublicId;
    requestObj.portfolioPublicId = selectedPortfolio;
    requestObj.referringPartyFee = parseFloat(brokerRefFee);
    requestObj.applicationProcessingFeeRates = applicationProcessingFeeRates;
    requestObj.applicationCaseStatus = applicationCaseStatus;

    setIsLoading(true);

    ApplicationMiscService.update(requestObj)
      .then((response) => {
        appContext.currentApplication = response.data;
        appContext.newAppllicationStep += 1;
        appContext.toastMessage.message = 'Your changes has been saved sucessfully.';
        history.push('/underwriting/applications/' + applicationId + '/liens');
      })
      .catch((err) => {})
      .finally(() => {
        setIsLoading(false);
      });
  };

  const handleCancelButtonClicked = () => {
    history.push('/underwriting/applications');
  };

  useEffect(() => {
    if (selectedPortfolio != '') {
      PortfolioService.read(selectedPortfolio)
        .then((response) => {
          setSelectedPortfolioName(response?.data?.portfolioName);
          setSelectedPortfolioError(undefined);
          setFirstSelectedPortfolioRun(true);
        })
        .catch((err) => {})
        .finally(() => {
          setIsLoading(false);
        });
    }
  }, [selectedPortfolio]);

  useEffect(() => {
    if (selectedBroker !== '') {
      BrokerService.read(selectedBroker)
        .then((response) => {
          setSelectedBrokerName(response?.data?.companyName);
          setBrokerRefFee(response?.data?.referralFee);
          refferingPartyRef.current.value = response?.data?.referralFee;
          setSelectedBrokerError(undefined);
          setFirstSelectedBroker(true);
        })
        .catch((err) => {})
        .finally(() => {
          setIsLoading(false);
        });
    }
  }, [selectedBroker, application]);

  useEffect(() => {
    if (firstSelectedPortfolioRun === true && firstSelectedBroker === true) {
      ApplicationMiscService.search({ applicationNo: applicationId }, null, null, 1, {}, 10)
        .then((response) => {
          if (response.data.data[0].referringPartyFee != null) {
            setBrokerRefFee(response.data.data[0].referringPartyFee);
          }
        })
        .catch((err) => {})
        .finally(() => {
          setIsLoading(false);
        });
    }
  }, [firstSelectedPortfolioRun, firstSelectedBroker]);

  const [inputList, setInputList] = useState([]);

  const handleInputChange = (e, index) => {
    const { name, value } = e.target;
    const list = [...inputList];
    list[index][name] = value;
    setInputList(list);
  };

  const handleRemoveClick = (index) => {
    const list = [...inputList];
    list.splice(index, 1);
    setInputList(list);
  };

  const handleAddClick = () => {
    setInputList([...inputList, { fromAmount: 0, toAmount: 0, feeAmount: 0 }]);
  };

  const onSaveProcessingFeeRate = (data) => {
    let newItems = [];
    let oldItems = [];

    inputList.map((item) => {
      if (item.publicId) {
        let obj = {
          publicId: item.publicId,
          fromAmount: parseFloat(item.fromAmount),
          toAmount: parseFloat(item.toAmount),
          feeAmount: parseFloat(item.feeAmount),
        };
        oldItems.push(obj);
      } else {
        let obj2 = {
          fromAmount: parseFloat(item.fromAmount),
          toAmount: parseFloat(item.toAmount),
          feeAmount: parseFloat(item.feeAmount),
        };
        oldItems.push(obj2);
      }
    });

    if (newItems.length > 0) {
      return newItems;
    }

    if (oldItems.length > 0) {
      return oldItems;
    }
  };

  if (isLoading) {
    return (
      <Container className="mt-10 mb-10">
        <Row>
          <Col md="12" className="mx-auto bg-white box-shadow-border">
            <div className="p-10 mt-5 mb-10">
              {disableTabs != null && (
                <Header applicationId={applicationId} handleSaveAndExit={() => {}} />
              )}

              {disableTabs != null && (
                <ApplicationsNewMenu
                  disableTabs={disableTabs}
                  applicationId={applicationId}
                  menuItemIndex="5"
                />
              )}

              <Container className="p-0">
                <Row>
                  <Col md="8">
                    <Row>
                      <Col md="4">
                        <ReactLoading type="bars" color="#7E8299" />
                      </Col>
                    </Row>
                  </Col>
                </Row>
              </Container>
            </div>
          </Col>
        </Row>
      </Container>
    );
  }

  return (
    <div className="container mt-10 mb-10">
      <div className="row">
        <div className="col-md-12 mx-auto bg-white box-shadow-border">
          <div className="p-10">
            <div className="mt-5 mb-10">
              <div>
                <Header applicationId={applicationId} handleSaveAndExit={() => {}} />

                <ApplicationsNewMenu applicationId={applicationId} menuItemIndex="5" />

                <form onSubmit={handleFormSubmitted}>
                  <Col md="3" className="p-0">
                    <div className="form-group">
                      <label htmlFor="portfolio">Portfolio</label>

                      {selectedPortfolio === '' && selectedPortfolioName === '' ? (
                        <PortfolioAutoSuggest
                          selectedPortfolio={setSelectedPortfolio}
                          defaultPortfolio={''}
                          isAdvance={false}
                        />
                      ) : selectedPortfolio != '' && selectedPortfolioName != '' ? (
                        <PortfolioAutoSuggest
                          selectedPortfolio={setSelectedPortfolio}
                          defaultPortfolio={selectedPortfolioName}
                          isAdvance={false}
                        />
                      ) : undefined}

                      {selectedPortfolioError && (
                        <Alert color="danger" className="mt-5">
                          <span>Select a Portfolio</span>
                        </Alert>
                      )}
                    </div>

                    <div className="form-group">
                      <label htmlFor="brokers">Broker</label>
                      {selectedBroker === '' && selectedBrokerName === '' ? (
                        <BrokerAutoSuggest selectedBroker={setSelectedBroker} defaultBroker={''} />
                      ) : selectedBroker != '' && selectedBrokerName != '' ? (
                        <BrokerAutoSuggest
                          selectedBroker={setSelectedBroker}
                          defaultBroker={selectedBrokerName}
                        />
                      ) : undefined}
                      {selectedBrokerError && (
                        <Alert color="danger" className="mt-5">
                          <span>Select a Broker</span>
                        </Alert>
                      )}
                    </div>

                    {/* <FormGroup>
                      <Label>Referring Party Fee</Label>
                      <input
                        type="number"
                        name="multiplierBrokerRefFee"
                        ref={refferingPartyRef}
                        className="form-control"
                        step="0.0001"
                        onChange={(e) => {
                          setBrokerRefFee(e.target.value);
                        }}
                        value={brokerRefFee}
                        defaultValue={defaultValueBrokerRefFee}
                      />
                    </FormGroup> */}

                    <FormGroup>
                      <Label>Application Case Status</Label>
                      <select
                        className="form-control"
                        name="ApplicationCaseStatus"
                        value={applicationCaseStatus}
                        onChange={(e) => {
                          setApplicationCaseStatus(e.target.value);
                        }}
                      >
                        <option value="">Select a value</option>
                        {appContext.applicationCaseStatuses.map((item) => (
                          <option value={item}>{item}</option>
                        ))}
                      </select>
                    </FormGroup>
                  </Col>

                  <Col md="12" className="p-0">
                    <div className="col-md-12 p-0 form-inline">
                      <div className="col-md-12 p-0 form-inline">
                        <Label className="mt-3 mb-5">Processing Fee Rates Table</Label>
                        <button
                          type="button"
                          className="mt-3 mb-5 ml-10 btn btn-primary"
                          style={{ backgroundColor: 'var(--green)', borderColor: 'var(--green)' }}
                          onClick={handleAddClick}
                        >
                          Add
                        </button>
                      </div>

                      {inputList.map((x, i) => {
                        return (
                          <div className="form-group mb-5 p-0 col-md-12" key={i}>
                            <input
                              name="fromAmount"
                              type="number"
                              className="form-control"
                              value={x.fromAmount}
                              style={{ width: '100px' }}
                              onChange={(e) => handleInputChange(e, i)}
                            />
                            <span>&nbsp;-&nbsp;</span>
                            <input
                              name="toAmount"
                              type="number"
                              className="form-control"
                              value={x.toAmount}
                              style={{ width: '100px' }}
                              onChange={(e) => handleInputChange(e, i)}
                            />
                            <span>&nbsp;=&nbsp;</span>
                            <input
                              name="feeAmount"
                              type="number"
                              className="form-control"
                              value={x.feeAmount}
                              style={{ width: '100px' }}
                              onChange={(e) => handleInputChange(e, i)}
                            />
                            <i
                              className="flaticon-circle text-secondary ml-2 "
                              style={{ cursor: 'pointer' }}
                              onClick={() => handleRemoveClick(i)}
                            />
                          </div>
                        );
                      })}
                    </div>
                  </Col>

                  <Row>
                    <Col md="12" className="mt-5">
                      <button type="submit" className="btn btn-primary mr-5">
                        Save
                      </button>
                      <button
                        type="button"
                        onClick={handleCancelButtonClicked}
                        className="btn btn-secondary"
                      >
                        Cancel
                      </button>
                    </Col>
                  </Row>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Misc;
