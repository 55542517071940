import React, { useState, useEffect, useContext } from 'react';
import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import { useHistory, withRouter, useParams } from 'react-router-dom';
import { toast } from 'react-toastify';

import { makeStyles, withStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableFooter from '@material-ui/core/TableFooter';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import Pagination from '@material-ui/lab/Pagination';
import EditIcon from '@material-ui/icons/Edit';
import DeleteIcon from '@material-ui/icons/Delete';
import GridService from '@services/GridService';
import AppContext from '@contexts/AppContext';
import TableSortLabel from '@material-ui/core/TableSortLabel';
import ReactLoading from 'react-loading';

const StyledTableCell = withStyles((theme) => ({
  head: {
    backgroundColor: '#efefef',
    color: theme.palette.common.black,
  },
  body: {
    fontSize: 14,
  },
}))(TableCell);

const useStyles2 = makeStyles({
  table: {
    minWidth: 500,
  },
});

const Grid = (props) => {
  const appContext = useContext(AppContext);
  const classes = useStyles2();
  const [page, setPage] = React.useState(1);
  const [rowsPerPage, setRowsPerPage] = React.useState(15);
  const [filters, setFilters] = useState({});
  const [orderBy, setOrderBy] = useState('');
  const [orderDir, setOrderDir] = useState('ASC');
  const [rows, setRows] = useState([]);
  const { advanceId, applicationId } = useParams();
  const history = useHistory();

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  useEffect(() => {
    update();
  }, [page]);

  const fetchData = () => {
    let endpoint = `/ApplicationAdvanceDisbursement/`;
    var newFilters = filters;
    newFilters['applicationAdvancePublicId'] = advanceId;
    setFilters(newFilters);
    setIsLoading(true);
    GridService.search(endpoint, newFilters, orderBy, orderDir, 1, null, rowsPerPage)
      .then((response) => {
        setIsLoading(false);
        if (!response.data) return;
        setRows(response.data.data);
        setPages(response.data.pages.slice(-1)[0]);
      })
      .catch((error) => {
        setIsLoading(false);
      });
  };
  useEffect(() => {
    fetchData();
  }, []);

  const update = () => {
    fetchData();
  };

  const [modal, setModal] = useState(false);
  const toggle = () => setModal(!modal);
  const [rowIndex, setRowIndex] = useState(undefined);
  const [givenRows, setGivenRows] = useState(undefined);
  const [isLoading, setIsLoading] = useState(false);
  const [pages, setPages] = useState(undefined);

  const deleteItem = () => {
    if (!(appContext?.userPermissions?.indexOf('Delete Advance Disbursements') > -1)) {
      appContext.toastMessage.message = 'You do not have permissions to perform this action.';
      toast.error(appContext.toastMessage.message, { autoClose: 3000 });
      appContext.toastMessage.message = null;
      return;
    }

    let endpoint = `/ApplicationAdvanceDisbursement/${givenRows[rowIndex].publicId}`;

    GridService.delete(endpoint).then((result) => {
      let items = [...givenRows];
      items.splice(rowIndex, 1);
      setRows(items);
      toggle();
      appContext.toastMessage.message = 'Disbursement has been successfully deleted.';
      toast.success(appContext.toastMessage.message, { autoClose: 3000 });
      appContext.toastMessage.message = null;
    });
  };

  const [activeFieldForSorting, setActiveFieldForSorting] = useState();

  const arrange = (field) => {
    if (orderBy === field) {
      if (orderDir === 'ASC') {
        setOrderDir('DESC');
      } else {
        setOrderDir('ASC');
      }
    } else {
      setOrderBy(field);
      setOrderDir('ASC');
    }

    setPage(1);
  };

  useEffect(() => {
    update();
  }, [orderBy, orderDir, rowsPerPage]);

  const handlePageSizeChange = (event) => {
    setRowsPerPage(event.target.value);
  };
  const getTotalAmount = () => {
    let totalAmount = 0;
    rows.map((row) => {
      totalAmount += row.amount;
    });
    return totalAmount;
  };
  return (
    <div>
      <div>
        <Modal isOpen={modal} toggle={toggle}>
          <ModalHeader toggle={toggle}>Confirmation</ModalHeader>
          <ModalBody>Are you sure you want to delete this disbursement?</ModalBody>
          <ModalFooter>
            <Button color="primary" onClick={deleteItem}>
              Delete
            </Button>
            <Button color="secondary" onClick={toggle}>
              Cancel
            </Button>
          </ModalFooter>
        </Modal>
      </div>

      <TableContainer component={Paper}>
        <Table className={classes.table} aria-label="custom pagination table">
          <TableHead>
            <TableRow>
              <StyledTableCell>Id</StyledTableCell>

              <StyledTableCell
                onClick={() => {
                  arrange('createdDate');
                  setActiveFieldForSorting('createdDate');
                }}
              >
                DATE
                <TableSortLabel
                  active={activeFieldForSorting === 'createdDate' ? true : false}
                  direction={
                    activeFieldForSorting === 'createdDate' ? orderDir.toLowerCase() : 'asc'
                  }
                  onClick={() => arrange('createdDate')}
                ></TableSortLabel>
              </StyledTableCell>

              <StyledTableCell
                onClick={() => {
                  arrange('disbursementType');
                  setActiveFieldForSorting('disbursementType');
                }}
              >
                TYPE
                <TableSortLabel
                  active={activeFieldForSorting === 'disbursementType' ? true : false}
                  direction={
                    activeFieldForSorting === 'disbursementType' ? orderDir.toLowerCase() : 'asc'
                  }
                  onClick={() => arrange('disbursementType')}
                ></TableSortLabel>
              </StyledTableCell>

              <StyledTableCell
                onClick={() => {
                  arrange('commisionable');
                  setActiveFieldForSorting('commisionable');
                }}
              >
                COMMISIONABLE
                <TableSortLabel
                  active={activeFieldForSorting === 'commisionable' ? true : false}
                  direction={
                    activeFieldForSorting === 'commisionable' ? orderDir.toLowerCase() : 'asc'
                  }
                  onClick={() => arrange('commisionable')}
                ></TableSortLabel>
              </StyledTableCell>

              <StyledTableCell
                onClick={() => {
                  arrange('payableTo');
                  setActiveFieldForSorting('payableTo');
                }}
              >
                PAYABLE TO
                <TableSortLabel
                  active={activeFieldForSorting === 'payableTo' ? true : false}
                  direction={activeFieldForSorting === 'payableTo' ? orderDir.toLowerCase() : 'asc'}
                  onClick={() => arrange('payableTo')}
                ></TableSortLabel>
              </StyledTableCell>
              <StyledTableCell
                onClick={() => {
                  arrange('amount');
                  setActiveFieldForSorting('amount');
                }}
              >
                AMOUNT
                <TableSortLabel
                  active={activeFieldForSorting === 'amount' ? true : false}
                  direction={activeFieldForSorting === 'amount' ? orderDir.toLowerCase() : 'asc'}
                  onClick={() => arrange('amount')}
                ></TableSortLabel>
              </StyledTableCell>
              <StyledTableCell
                onClick={() => {
                  arrange('checkNo');
                  setActiveFieldForSorting('checkNo');
                }}
              >
                CHECK#
                <TableSortLabel
                  active={activeFieldForSorting === 'checkNo' ? true : false}
                  direction={activeFieldForSorting === 'checkNo' ? orderDir.toLowerCase() : 'asc'}
                  onClick={() => arrange('checkNo')}
                ></TableSortLabel>
              </StyledTableCell>
              <StyledTableCell
                onClick={() => {
                  arrange('createdByDisplayName');
                  setActiveFieldForSorting('createdByDisplayName');
                }}
              >
                CREATED BY
                <TableSortLabel
                  active={activeFieldForSorting === 'createdByDisplayName' ? true : false}
                  direction={
                    activeFieldForSorting === 'createdByDisplayName'
                      ? orderDir.toLowerCase()
                      : 'asc'
                  }
                  onClick={() => arrange('createdByDisplayName')}
                ></TableSortLabel>
              </StyledTableCell>

              <StyledTableCell></StyledTableCell>
              <StyledTableCell></StyledTableCell>
            </TableRow>
          </TableHead>
          {isLoading === false && (
            <TableBody>
              {rows &&
                rows.map((row, index) => (
                  <TableRow key={row.name}>
                    <TableCell component="th" scope="row">
                      {row.id}
                    </TableCell>
                    <TableCell>{row.createdDate.split('T')[0]}</TableCell>
                    <TableCell>{row.disbursementType}</TableCell>
                    <TableCell>{row.commisionable ? 'Yes' : 'False'}</TableCell>
                    <TableCell>{row.payableTo}</TableCell>
                    <TableCell>{`$${parseFloat(row.amount).toFixed(2)}`}</TableCell>
                    <TableCell>{row.checkNo}</TableCell>
                    <TableCell>{row.createdByDisplayName}</TableCell>
                    <TableCell className="p-2">
                      <a
                        className="btn float-right"
                        onClick={() => {
                          history.push(
                            `/underwriting/applications/${applicationId}/advances/${advanceId}/disbursement/${row.publicId}`
                          );
                        }}
                      >
                        <EditIcon />
                      </a>
                    </TableCell>

                    <TableCell className="p-2">
                      <a
                        className="btn float-left"
                        onClick={() => {
                          setRowIndex(index);
                          setGivenRows(rows);
                          toggle();
                        }}
                      >
                        <DeleteIcon style={{ color: 'var(--red)' }} />
                      </a>
                    </TableCell>
                  </TableRow>
                ))}
            </TableBody>
          )}
          {isLoading && (
            <TableBody>
              <TableRow>
                <td></td>
                <td>
                  <ReactLoading type="bars" color="#7E8299" />
                </td>
                <td></td>
              </TableRow>
            </TableBody>
          )}

          <TableFooter></TableFooter>
        </Table>
      </TableContainer>
      {!isLoading && (
        <div style={{ textAlign: 'center', marginTop: '20px' }}>
          <h5>{`Total Disbursement Amount : $ ${parseFloat(getTotalAmount()).toFixed(2)}`}</h5>
        </div>
      )}
      <div className="float-left mt-5">
        <select onChange={handlePageSizeChange} className="form-control" defaultValue={15}>
          {[5, 10, 15, 25, 100].map((size) => (
            <option key={size} value={size}>
              {size}
            </option>
          ))}
        </select>
      </div>
      <div className="float-right mt-5">
        <Pagination
          count={pages}
          page={page}
          siblingCount={1}
          boundaryCount={1}
          variant="outlined"
          onChange={handleChangePage}
        />
      </div>
    </div>
  );
};

export default withRouter(Grid);
