import React, { useContext, useEffect, useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { toast } from 'react-toastify';

import ApplicationsNewMenu from '../Menu';
import Header from '../Header';
import AppContext from '@contexts/AppContext';
import AdvancesGrid from './AdvancesGrid';
import { Row, Col, Input, Label, Alert, Button, Form, FormGroup, Container } from 'reactstrap';

const Advances = () => {
  const appContext = useContext(AppContext);
  const history = useHistory();
  const { applicationId } = useParams();

  useEffect(() => {
    if (appContext.toastMessage.message === 'You are not allowed to perform this action.') {
      toast.error(appContext.toastMessage.message, { autoClose: 3000 });
      appContext.toastMessage.message = null;
    } else if (appContext.toastMessage.message != '') {
      toast.success(appContext.toastMessage.message, { autoClose: 3000 });
      appContext.toastMessage.message = null;
    }
  }, []);

  return (
    <div className="container mt-10 mb-10">
      <div className="row">
        <div className="col-md-12 mx-auto bg-white box-shadow-border">
          <div className="p-10">
            <div className="mt-5 mb-10">
              <div>
                <Header applicationId={applicationId} handleSaveAndExit={() => {}} />

                <ApplicationsNewMenu applicationId={applicationId} menuItemIndex="8" />

                <Row className="pb-5">
                  <Col md="6">
                    <h1>Advances</h1>
                  </Col>
                  <Col md="6">
                    <Button
                      color="primary"
                      className="float-right"
                      onClick={() =>
                        history.push(
                          `/underwriting/applications/${applicationId}/advances/new-advance`
                        )
                      }
                      style={{ backgroundColor: 'var(--green)', borderColor: 'var(--green)' }}
                    >
                      New Advance
                    </Button>
                  </Col>
                </Row>

                <AdvancesGrid />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Advances;
