import React, {useState, useEffect, useContext, useRef} from 'react';
import {useParams} from 'react-router-dom';

import {makeStyles, withStyles} from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableFooter from '@material-ui/core/TableFooter';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import Pagination from '@material-ui/lab/Pagination';
import DeleteIcon from '@material-ui/icons/Delete';
import GridService from '@services/GridService';
import AppContext from '@contexts/AppContext';
import TableSortLabel from '@material-ui/core/TableSortLabel';
import ReactLoading from 'react-loading';
import {Tooltip} from '@material-ui/core';
import MailOutlineOutlinedIcon from '@material-ui/icons/MailOutlineOutlined';
import CloudDownloadOutlinedIcon from '@material-ui/icons/CloudDownloadOutlined';
import EditIcon from '@material-ui/icons/Edit';
import SystemRolesService from '@services/SystemRolesService';
import {toast} from 'react-toastify';
import {useForm} from 'react-hook-form';
import fileDownload from 'js-file-download';
import DeleteDocumentPopup from '@components/DeleteDocumentPopup/DeleteDocumentPopup';
import SendEmailPopup from '@components/SendEmailPopup';
import FileRenamePopup from '@components/FileRenamePopup';
import Moment from 'moment';

const StyledTableCell = withStyles((theme) => ({
    head: {
        backgroundColor: '#efefef',
        color: theme.palette.common.black,
    },
    body: {
        fontSize: 14,
    },
}))(TableCell);

const useStyles2 = makeStyles({
    table: {
        minWidth: 500,
    },
    MuiFormControlLabel: {
        label: {
            marginBottom: '0rem',
        },
    },
});

const DocumentsGrid = () => {
    const appContext = useContext(AppContext);
    const classes = useStyles2();
    const [page, setPage] = React.useState(0);
    const [rowsPerPage, setRowsPerPage] = React.useState(15);
    const {applicationId} = useParams();
    const [filters, setFilters] = useState({
        applicationNo: applicationId,
    });
    const [orderBy, setOrderBy] = useState('uploadDate');
    const [orderDir, setOrderDir] = useState('DESC');
    const [client, setClient] = useState(false);
    const [accountManager, setAccountManager] = useState(false);
    const [attorney, setAttorney] = useState(false);
    const [broker, setBroker] = useState(false);
    const [emails, setEmails] = useState(null);
    const [subject, setSubject] = useState(null);
    const [body, setBody] = useState(null);
    const [systemUsers, setSystemUsers] = useState([]);
    const [files, setFiles] = useState([]);
    const [selectedItemPublicId, setSelectedItemPublicId] = useState(null);
    const [applicationPublicId, setApplicationPublicId] = useState(null);
    const [rows, setRows] = useState([]);
    const [modal, setModal] = useState(false);
    const [emailModal, setEmailModal] = useState(false);
    const [renameModal, setRenameModal] = useState(false);
    const [rowIndex, setRowIndex] = useState(undefined);
    const [givenRows, setGivenRows] = useState(undefined);
    const [isLoading, setIsLoading] = useState(false);
    const [pages, setPages] = useState(undefined);
    const [documentId, setDocumentId] = useState(null);
    const [documentName, setDocumentName] = useState("");
    const [document, setDocument] = useState({});
    const {register, handleSubmit, watch, errors, formState} = useForm({mode: 'onBlur'});
    const [activeFieldForSorting, setActiveFieldForSorting] = useState();
    const mountedRef = useRef();
    
    const toggle = () => setModal(!modal);
    const toggleEmail = () => setEmailModal(!emailModal);
    const toggleRename = () => setRenameModal(!renameModal);
    
    useEffect(() => {
        if (!mountedRef.current) return;
        fetchData();
    }, [page]);

    useEffect(() => {
        if (!mountedRef.current) return;
        fetchData();
    }, [orderBy, orderDir, rowsPerPage]);

    useEffect(() => {
        setIsLoading(true);

        //System Users (by role)
        SystemRolesService.search(filters, orderBy, orderDir, 1, null, 20)
            .then((response) => {
                if (!response.data) return;
                setSystemUsers(response.data.data);
            })
            .catch((err) => {
                toast.error('An error has occured. Please contact your system administrator.');
            })
            .finally(() => {
                setIsLoading(false);
            });

        mountedRef.current = true;
        setPage(1);
    }, []);

    const fetchData = () => {
        let endpoint = `/ApplicationDocument/`;

        setIsLoading(true);

        GridService.search(endpoint, filters, orderBy, orderDir, page, null, rowsPerPage)
            .then((response) => {
                if (!response.data) return;
                setIsLoading(false);
                setRows(response.data.data);
                setPages(response.data.pages.slice(-1)[0]);
            })
            .catch((err) => {
                toast.error('An error has occured. Please contact your system administrator.');
            })
            .finally(() => {
                setIsLoading(false);
            });
    };

    const searchColumn = (event, columnName) => {
        const newFilters = filters;
        newFilters[columnName] = event.target.value;
        setFilters(newFilters);
        fetchData();
    };

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const deleteItem = () => {
        if (!(appContext?.userPermissions?.indexOf('Delete Documents') > -1)) {
            appContext.toastMessage.message = 'You do not have permissions to perform this action.';
            toast.error(appContext.toastMessage.message, {autoClose: 3000});
            appContext.toastMessage.message = null;
            return;
        }

        setIsLoading(true);

        let endpoint = `/ApplicationDocument/${givenRows[rowIndex].publicId}`;

        GridService.delete(endpoint)
            .then((result) => {
                toggle();
                if (result?.response?.data?.errors) {
                    toast.error(result.response.data.errors, {autoClose: 3000});
                } else {
                    let items = [...givenRows];
                    items.splice(rowIndex, 1);
                    setRows(items);
                    toast.success('Document deleted successfully.', {autoClose: 3000});
                }
            })
            .catch((_) => {
            })
            .finally(() => {
                setIsLoading(false);
            });
    };

    const arrange = (field) => {
        if (orderBy === field) {
            if (orderDir === 'ASC') {
                setOrderDir('DESC');
            } else {
                setOrderDir('ASC');
            }
        } else {
            setOrderBy(field);
            setOrderDir('ASC');
        }

        setPage(1);
    };

    const handlePageSizeChange = (event) => {
        setRowsPerPage(event.target.value);
    };

    const handleDownload = (item) => {
        if (!(appContext?.userPermissions?.indexOf('Download Documents') > -1)) {
            appContext.toastMessage.message = 'You do not have permissions to perform this action.';
            toast.error(appContext.toastMessage.message, {autoClose: 3000});
            appContext.toastMessage.message = null;
            return;
        }

        setIsLoading(true);

        let config = {
            responseType: 'blob',
        };

        let endpoint = `/ApplicationDocument/getfile/${item.publicId}`;

        GridService.read(endpoint, config)
            .then((response) => {
                const contentDisposition = response.headers['content-disposition'];
                var fileName = 'unknown';
                if (contentDisposition && contentDisposition.indexOf('attachment') !== -1) {
                    var filenameRegex = /filename[^;=\n]*=((['"]).*?\2|[^;\n]*)/;
                    var matches = filenameRegex.exec(contentDisposition);
                    if (matches != null && matches[1]) {
                        fileName = matches[1].replace(/['"]/g, '');
                    }
                }

                fileDownload(response.data, fileName);
            })
            .catch((_) => {
            })
            .finally(() => {
                setIsLoading(false);
            });
    };

    
    const handleRenameFileNameFormSubmit = () => {
        let i;
        if (!(appContext?.userPermissions?.indexOf('Email Documents') > -1)) {
            appContext.toastMessage.message = 'You do not have permissions to perform this action.';
            toast.error(appContext.toastMessage.message, {autoClose: 3000});
            appContext.toastMessage.message = null;
            return;
        }

        document.documentName = documentName;

        let endpoint = `/ApplicationDocument/updatename/${document.publicId}`;

        setIsLoading(true);

        GridService.create(endpoint, document)
            .then((res) => {
                if (res?.data) {
                    setRenameModal(false);
                    toast.success('File Renamed successfully', {autoClose: 3000});
                } else {
                    if (res?.response?.data?.errors) {
                        toast.error(res.response.data?.errors, {autoClose: 5000});
                    }
                }
            })
            .catch((err) => {
                toast.error('An error has occured. Please contact your system administrator.');
            })
            .finally(() => {
                setIsLoading(false);
            });
    };

    const handleFormSubmit = (item) => {
        let i;
        if (!(appContext?.userPermissions?.indexOf('Email Documents') > -1)) {
            appContext.toastMessage.message = 'You do not have permissions to perform this action.';
            toast.error(appContext.toastMessage.message, {autoClose: 3000});
            appContext.toastMessage.message = null;
            return;
        }

        let formData = new FormData(); //formdata object
        let tempSysUsers =
            systemUsers.length > 0
                ? systemUsers.reduce((a, o) => (o.checked && a.push(o.publicId), a), [])
                : [];
        let tempEmails = emails?.split(';');
        let attachments = files;

        for (i = 0; i < tempSysUsers?.length; i++) {
            formData.append('SystemRoles', tempSysUsers[i]);
        }
        for (i = 0; i < tempEmails?.length; i++) {
            formData.append('ToAdditionalEmails', tempEmails[i]);
        }
        for (i = 0; i < attachments?.length; i++) {
            formData.append('Attachments', attachments[i]);
        }

        formData.append('publicGuid', selectedItemPublicId);
        formData.append('ApplicationPublicGuid', applicationPublicId);
        formData.append('ToClient', client);
        formData.append('ToAttorney', attorney);
        formData.append('ToBroker', broker);
        formData.append('ToAccountManager', accountManager);
        formData.append('Subject', subject);
        formData.append('Body', body);

        const config = {
            headers: {'content-type': 'multipart/form-data'},
        };

        let endpoint = '/ApplicationDocument/sendemail';

        setIsLoading(true);

        GridService.create(endpoint, formData, config)
            .then((res) => {
                if (res?.data === true) {
                    setEmailModal(false);
                    toast.success('Email sent successfully', {autoClose: 3000});
                } else {
                    if (res?.response?.data?.errors) {
                        toast.error(res.response.data?.errors, {autoClose: 5000});
                    }
                }
            })
            .catch((err) => {
                toast.error('An error has occured. Please contact your system administrator.');
            })
            .finally(() => {
                setIsLoading(false);
            });
    };

    return (
        <div>
            <DeleteDocumentPopup modal={modal} toggle={toggle} deleteItem={deleteItem}/>

            <FileRenamePopup
                fileNameModal={renameModal}
                togglefileName={toggleRename}
                handleRenameFileNameFormSubmit={handleRenameFileNameFormSubmit}
                setDocumentName={setDocumentName}
                documentName={documentName}
            />

            <SendEmailPopup
                emailModal={emailModal}
                toggleEmail={toggleEmail}
                handleFormSubmit={handleFormSubmit}
                setClient={setClient}
                client={client}
                setAccountManager={setAccountManager}
                accountManager={accountManager}
                setAttorney={setAttorney}
                attorney={attorney}
                setBroker={setBroker}
                broker={broker}
                setBody={setBody}
                setSystemUsers={setSystemUsers}
                systemUsers={systemUsers}
                setEmails={setEmails}
                setSubject={setSubject}
                setFiles={setFiles}
                document={document}
            />

            <TableContainer component={Paper}>
                <Table className={classes.table} aria-label="custom pagination table">
                    <TableHead>
                        <TableRow>
                            <StyledTableCell>Id</StyledTableCell>
                            <StyledTableCell
                                onClick={() => {
                                    arrange('uploadDate');
                                    setActiveFieldForSorting('uploadDate');
                                }}
                            >
                                DATE
                                <TableSortLabel
                                    active={activeFieldForSorting === 'uploadDate' ? true : false}
                                    direction={
                                        activeFieldForSorting === 'uploadDate' ? orderDir.toLowerCase() : 'asc'
                                    }
                                    onClick={() => arrange('uploadDate')}
                                ></TableSortLabel>
                            </StyledTableCell>

                            <StyledTableCell
                                onClick={() => {
                                    arrange('documentType');
                                    setActiveFieldForSorting('documentType');
                                }}
                            >
                                TYPE
                                <TableSortLabel
                                    active={activeFieldForSorting === 'documentType' ? true : false}
                                    direction={
                                        activeFieldForSorting === 'documentType' ? orderDir.toLowerCase() : 'asc'
                                    }
                                    onClick={() => arrange('documentType')}
                                ></TableSortLabel>
                            </StyledTableCell>

                            <StyledTableCell
                                onClick={() => {
                                    arrange('documentName');
                                    setActiveFieldForSorting('documentName');
                                }}
                            >
                                NAME
                                <TableSortLabel
                                    active={activeFieldForSorting === 'documentName' ? true : false}
                                    direction={
                                        activeFieldForSorting === 'documentName' ? orderDir.toLowerCase() : 'asc'
                                    }
                                    onClick={() => arrange('documentName')}
                                ></TableSortLabel>
                            </StyledTableCell>

                            <StyledTableCell></StyledTableCell>
                        </TableRow>
                        <TableRow>
                            <StyledTableCell>
                                <input
                                    type="search"
                                    className="form-control"
                                    placeholder="Search..."
                                    onChange={(e) => searchColumn(e, 'id')}
                                />
                            </StyledTableCell>
                            <StyledTableCell>
                                <input
                                    type="search"
                                    className="form-control"
                                    placeholder="Search..."
                                    onChange={(e) => searchColumn(e, 'uploadDate')}
                                />
                            </StyledTableCell>
                            <StyledTableCell>
                                <input
                                    type="search"
                                    className="form-control"
                                    placeholder="Search..."
                                    onChange={(e) => searchColumn(e, 'documentType')}
                                />
                            </StyledTableCell>
                            <StyledTableCell>
                                <input
                                    type="search"
                                    className="form-control"
                                    placeholder="Search..."
                                    onChange={(e) => searchColumn(e, 'documentName')}
                                />
                            </StyledTableCell>
                            <StyledTableCell></StyledTableCell>
                        </TableRow>
                    </TableHead>
                    {isLoading === false && (
                        <TableBody>
                            {rows &&
                            rows.map((row, index) => (
                                <TableRow key={row.name}>
                                    <TableCell component="th" scope="row">
                                        {row.id}
                                    </TableCell>
                                    <TableCell>
                                        {row.uploadDate && Moment(row.uploadDate.split('T')[0]).format('MM-DD-YYYY')}{' '}
                                        {Moment(row.uploadDate).format('h:mm:ss A')}
                                    </TableCell>
                                    <TableCell>{row?.documentType}</TableCell>
                                    <TableCell>{row?.documentName}</TableCell>
                                    <TableCell className="p-2" align="right">
                                        <Tooltip title="Rename">
                                            <button className="btn mr-1 btn-sm"  onClick={() => {
                                                    setDocument(row);
                                                    setDocumentName(row.documentName);
                                                    setDocumentId(row.publicId);
                                                    setSelectedItemPublicId(row.publicId);
                                                    setApplicationPublicId(row.applicationPublicId);
                                                    toggleRename();
                                                }}>
                                                <EditIcon/>
                                            </button>
                                        </Tooltip>
                                        {(!row.docuSignId || (row.docuSignId && row.docuSignStatus === 'completed')) &&
                                        
                                        <Tooltip title="Email">
                                            <button
                                                className="btn mr-1 btn-sm"
                                                onClick={() => {
                                                    setDocument(row);
                                                    setDocumentId(row.publicId);
                                                    setSelectedItemPublicId(row.publicId);
                                                    setApplicationPublicId(row.applicationPublicId);
                                                    toggleEmail();
                                                }}
                                            >
                                                <MailOutlineOutlinedIcon/>
                                            </button>
                                        </Tooltip>
                                        }
                                        <Tooltip title="Download">
                                            <button className="btn mr-1 btn-sm" onClick={() => handleDownload(row)}>
                                                <CloudDownloadOutlinedIcon/>
                                            </button>
                                        </Tooltip>
                                        {row.documentType != 'User File' ? (
                                            <a
                                                className="btn btn-sm"
                                                onClick={() => {
                                                    setRowIndex(index);
                                                    setGivenRows(rows);
                                                    toggle();
                                                }}
                                                style={{visibility: 'hidden'}}
                                            >
                                                <DeleteIcon style={{color: 'var(--red)'}}/>
                                            </a>
                                        ) : (
                                            <a
                                                className="btn btn-sm"
                                                onClick={() => {
                                                    setRowIndex(index);
                                                    setGivenRows(rows);
                                                    toggle();
                                                }}
                                            >
                                                <DeleteIcon style={{color: 'var(--red)'}}/>
                                            </a>
                                        )}
                                    </TableCell>
                                </TableRow>
                            ))}
                        </TableBody>
                    )}
                    {isLoading && (
                        <TableBody>
                            <TableRow>
                                <td></td>
                                <td>
                                    <ReactLoading type="bars" color="#7E8299"/>
                                </td>
                                <td></td>
                            </TableRow>
                        </TableBody>
                    )}

                    <TableFooter></TableFooter>
                </Table>
            </TableContainer>
            <div className="float-left mt-5">
                <select onChange={handlePageSizeChange} className="form-control" defaultValue={15}>
                    {[5, 10, 15, 25, 100].map((size) => (
                        <option key={size} value={size}>
                            {size}
                        </option>
                    ))}
                </select>
            </div>
            <div className="float-right mt-5">
                <Pagination
                    count={pages}
                    page={page}
                    siblingCount={1}
                    boundaryCount={1}
                    variant="outlined"
                    onChange={handleChangePage}
                />
            </div>
        </div>
    );
};

export default DocumentsGrid;
