import axios from 'axios';
import { auth, resError } from '@services/Helpers/ServiceInterceptor';

const api = axios.create({
  baseURL: process.env.REACT_APP_API_URL + '/clients/',
});

api.interceptors.request.use((config) => auth(config));
api.interceptors.response.use(
  (response) => {
    return response;
  },
  (error) => resError(error)
);

class ClientService {
  constructor() {
    if (!ClientService.instance) {
      ClientService.instance = this;
    }
    return ClientService.instance;
  }

  search = (filters, orderBy, orderDir, p, fields, perPage) => {
    const fi = { ...filters, clientsPageSearch: 'true' };
    return api.post('/search', {
      filters: Object.keys(fi).length > 0 ? fi : null,
      orderBy,
      orderDir,
      page: p,
      fields: undefined,
      perPage: parseInt(perPage),
    });
  };

  create = (entity) => {
    return api.post('/', entity);
  };

  read = (id) => {
    return api.get('/' + id);
  };

  update = (entity) => {
    return api.put('/', entity);
  };

  delete = (id) => {
    return api.delete('/' + id);
  };

  autoSuggest = (value) => {
    return api.post('/search', {
      filters: { displayName: value, isActive: true },
      perPage: 20,
      page: 1,
    });
  };
}

const instance = new ClientService();
Object.freeze(instance);

export default instance;
