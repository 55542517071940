import React, { useEffect, useContext, useState } from 'react';
import { Row, Col } from 'reactstrap';
import AppContext from '@contexts/AppContext';
import '../Styles.css';
import { useParams } from 'react-router-dom';
import CustomDateRange from '@components/CustomDateRange/CustomDateRange';

const CaseStatusUpdateActivityFilteringFields = (props) => {
  // const { params } = props.match.accountManagerId;
  const [advanceDateRange, setAdvanceDateRange] = useState(false);
  const [selectedAccountManager, setSelectedAccountManager] = useState({});

  const [advanceDateValue, setAdvanceDateValue] = useState('This month');
  const [fromAdvanceDate, setFromAdvanceDate] = useState(null);
  const [toAdvanceDate, setToAdvanceDate] = useState(null);

  const [fromPayoffDate, setFromPayoffDate] = useState(null);
  const [toPayoffDate, setToPayoffDate] = useState(null);

  const [appStatusOptions, setAppStatusOptions] = useState([]);
  const { accountManagerId } = useParams();
  const [userWithOnlyAccountManagerUserRole, setUserWithOnlyAccountManagerUserRole] = useState(
    false
  );
  const appContext = useContext(AppContext);
  const caseStatusUpdateActivityFilters = appContext.caseStatusUpdateActivityFilters ?? '';

  function clearFilters() {
    setAdvanceDateValue('This month');
    appContext.caseStatusUpdateActivityFilters = {};
    setAdvanceDateRange(false);
    setFromAdvanceDate(null);
    setToAdvanceDate(null);

    setFromPayoffDate(null);
    setToPayoffDate(null);

    setSelectedAccountManager({});
    props.clearGrid();
    //props.getAll();
  }

  function selectAdvanceDateRange() {
    if (advanceDateValue !== 'Custom') return advanceDateValue;

    let filter = {
      fromDate: new Date(fromAdvanceDate).toISOString(),
      toDate: new Date(toAdvanceDate).toISOString(),
    };

    return filter;
  }

  useEffect(() => {

    const isFiltersEmpty = Object.keys(caseStatusUpdateActivityFilters).length == 0;

    //Retaining data by checking with appcontext
    if (!isFiltersEmpty) {
      if (caseStatusUpdateActivityFilters.dateRange) {
        if (typeof caseStatusUpdateActivityFilters.dateRange == 'string') {
          setAdvanceDateValue(caseStatusUpdateActivityFilters.dateRange);
          setAdvanceDateRange(false);
          setFromAdvanceDate(null);
          setToAdvanceDate(null);
          // appContext.caseStatusUpdateActivityFilters.dateRange = dateFilter.current.value;
        } else {
          setAdvanceDateRange(true);
          appContext.caseStatusUpdateActivityFilters.dateRange =
            caseStatusUpdateActivityFilters.dateRange;
          setFromAdvanceDate(caseStatusUpdateActivityFilters.dateRange.fromDate.split('T')[0]);
          setToAdvanceDate(caseStatusUpdateActivityFilters.dateRange.toDate.split('T')[0]);
        }
      }
    }
  }, []);

  function search() {
    let filters = {
      dateRange: selectAdvanceDateRange()
    };

    if (filters.dateRange === '') delete filters.dateRange;

    props.searchWithFilters(filters);
  }

  return (
    <>
      <Col md="2" className="float-left acc-man-filter-container">
        <Row className="mt-5">
          <Col md="6">
            <h2>Filters</h2>
          </Col>
        </Row>

        <CustomDateRange
          labelText={'Date range'}
          setDateValue={setAdvanceDateValue}
          dateValue={advanceDateValue}
          fromDate={fromAdvanceDate}
          setFromDate={setFromAdvanceDate}
          toDate={toAdvanceDate}
          setToDate={setToAdvanceDate}
        />

        <Row className="mt-5 mb-5">
          <Col md="6" className="pr-0">
            <span className="a-link">
              <a className="btn clear-filter-btn" onClick={clearFilters}>
                Clear filters
              </a>
            </span>
          </Col>
          <Col md="6">
            <span className="a-link float-right">
              <a className="btn btn-primary search-btn" onClick={search}>
                <i class="fas fa-search"></i>
                Search
              </a>
            </span>
          </Col>
        </Row>
      </Col>
    </>
  );
};

export default CaseStatusUpdateActivityFilteringFields;
