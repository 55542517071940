import React, { useContext, useEffect, useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { useForm } from 'react-hook-form';
// import ApplicationsNewMenu from '../../Menu';
import LawFirmNoteService from '@services/LawFirmNoteService';
import { Row, Col, Input, Label, Alert, Button, Form, FormGroup, Container } from 'reactstrap';
import { ErrorMessage } from '@hookform/error-message';
import ApplicationsService from '@services/ApplicationsService';
import { toast } from 'react-toastify';
import AppContext from '@contexts/AppContext';
// import Header from '../../Header';
import ReactLoading from 'react-loading';

const EditNote = (props) => {
  const user = JSON.parse(localStorage.getItem('lasuris-user'));
  const appContext = useContext(AppContext);
  const history = useHistory();
  const [isLoading, setIsLoading] = useState(false);
  const [disableTabs, setDisableTabs] = useState(false);
  const { id } = useParams();
  const [note, setNote] = useState({});
  const { register, handleSubmit, watch, errors, formState } = useForm({ mode: 'onBlur' });

  const handleFormSubmit = (data) => {
    if (!(appContext?.userPermissions?.indexOf('Edit Notes') > -1)) {
      appContext.toastMessage.message = 'You do not have permissions to perform this action.';
      toast.error(appContext.toastMessage.message, { autoClose: 3000 });
      appContext.toastMessage.message = null;
      return;
    }

    // update note
    if (props.SelectedNotePublicId != '') {
      var updatedNote = { ...note };
      updatedNote.note = data.note;

      setIsLoading(true);

      LawFirmNoteService.update(updatedNote)
        .then((response) => {
          appContext.toastMessage.message = 'Note has been successfully updated.';
          props.setModal(false);
          //  history.push('/underwriting/applications/' + id + '/notes');
        })
        .catch((err) => {})
        .finally(() => {
          setIsLoading(false);
        });
    } else {
      setIsLoading(true);

      // create new note

      var updatedNote = { ...note };
      updatedNote = data;
      updatedNote.lawFirmPublicId = id;
      updatedNote.CreatedByPublicId = user.publicId;

      LawFirmNoteService.create(updatedNote)
        .then((response) => {
          appContext.toastMessage.message = 'Note has been sucessfully saved.';
          // history.push('/underwriting/applications/' + id + '/notes');
          props.setModal(false);
        })
        .catch((err) => {})
        .finally(() => {
          setIsLoading(false);
        });
    }
  };

  useEffect(() => {
    if (props.SelectedNotePublicId != '') {
      setIsLoading(true);
      LawFirmNoteService.read(props.SelectedNotePublicId)
        .then((response) => {
          setNote(response.data);
        })
        .catch((err) => {})
        .finally(() => {
          setIsLoading(false);
        });
    }
  }, []);

  const handleCancel = () => {
    props.setModal(false);
  };

  if (isLoading) {
    return (
      <Container className="mt-10 mb-10">
        <Row>
          <Col md="12" className="mx-auto bg-white box-shadow-border">
            <div className="p-10 mt-5 mb-10">
              {/* {(disableTabs != null) &&
                <Header id={id} handleSaveAndExit={() => { }} />
              }

              {(disableTabs != null) &&
                <ApplicationsNewMenu disableTabs={disableTabs} id={id} menuItemIndex="7" />
              } */}

              <Container className="p-0">
                <Row>
                  <Col md="8">
                    <Row>
                      <Col md="4">
                        <ReactLoading type="bars" color="#7E8299" />
                      </Col>
                    </Row>
                  </Col>
                </Row>
              </Container>
            </div>
          </Col>
        </Row>
      </Container>
    );
  }

  return (
    <div className="container mt-12 mb-10">
      <form onSubmit={handleSubmit(handleFormSubmit)} noValidate>
        <div className="row">
          <div className="col-md-12">
            <div className="form-group">
              <textarea
                name="note"
                id="note"
                rows="12"
                ref={register({ required: 'A value is required.' })}
                defaultValue={note?.note}
                className="form-control"
                required="required"
              />
              {errors.note && (
                <Alert color="danger" className="mt-5">
                  <ErrorMessage errors={errors} name="note" />
                </Alert>
              )}
            </div>

            <div className="form-group">
              <button type="submit" className="btn btn-primary">
                Save
              </button>
              <button
                type="button"
                className="ml-5 btn btn-secondary"
                onClick={() => handleCancel()}
              >
                Cancel
              </button>
            </div>
          </div>
        </div>
      </form>
    </div>
  );
};

export default EditNote;
