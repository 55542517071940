/* eslint-disable jsx-a11y/label-has-associated-control */
import React, { useContext, useEffect, useState } from 'react';
import { useHistory, useParams, useLocation } from 'react-router-dom';
import { toast } from 'react-toastify';
import {
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Row,
  Col,
  Label,
  Alert,
  FormGroup,
  Container,
} from 'reactstrap';
import { useForm } from 'react-hook-form';
import { ErrorMessage } from '@hookform/error-message';
import GridService from '@services/GridService';

import ApplicationsNewMenu from '../Menu';
import ApplicationsService from '@services/ApplicationsService';
import ApplicationFlagService from '@services/ApplicationFlagService';
import AttorneyService from '@services/AttorneyService';

import AppContext from '@contexts/AppContext';
import Header from '../Header';
import AttorneyAutoSuggest from '@components/AttorneyAutoSuggest/AttorneyAutoSuggestInsideApplication';
import ClientService from '@services/ClientService';
import ReactLoading from 'react-loading';
import Moment from 'moment';

const AttorneyCaseStatus = () => {
  const appContext = useContext(AppContext);
  const { applicationId } = useParams();
  const location = useLocation();
  const [isLoading, setIsLoading] = useState(false);
  const [application, setApplication] = useState(undefined);
  const history = useHistory();
  const [hasObligations, setHasObligations] = useState(false);
  const [hasBankruptchy, setHasBankruptchy] = useState(false);
  const [HasFirstButtonSubbmited, setHasFirstButtonSubbmited] = useState(true);
  const [modal, setModal] = useState(false);
  const [disableTabs, setDisableTabs] = useState(false);
  const [refreshFlags, setRefreshFlags] = useState(false);
  const [selectedAttorneyError, setSelectedAttorneyError] = useState(undefined);

  const toggle = () => setModal(!modal);

  const [caseStatus, setCaseStatus] = useState({
    submittedByAttorney: '',
    caseStatus: '',
    diaryFrame: '',
    indexNumber: '',
    timingOutlook: '',
    commentary: '',
  });
  const [attorney, setAttorney] = useState({
    displayName: '',
    attorneyPublicId: '',
  });
  const [selectedAttorneyName, setSelectedAttorneyName] = useState('');

  const { register, handleSubmit, watch, errors, formState } = useForm({ mode: 'onBlur' });

  const handleSaveAndExit = () => {
    setHasFirstButtonSubbmited(false);
    document.querySelector('button.btn.btn-primary.mr-5').click();
  };

  const handleFormSubmitted = (data) => {
    if (!(appContext?.userPermissions?.indexOf('View/Edit Attorney Case Status Update') > -1)) {
      appContext.toastMessage.message = 'You do not have permissions to perform this action.';
      toast.error(appContext.toastMessage.message, { autoClose: 3000 });
      appContext.toastMessage.message = null;
      return;
    }

    setIsLoading(true);

    application.clientPublicId = caseStatus.publicId;

    var endpoint = `ApplicationCaseStatusUpdate`;

    var request = data;
    request.applicationPublicId = application.publicId;
    request.publicId = caseStatus.publicId;

    GridService.create(endpoint, request)
      .then((resp) => {
        setCaseStatus(resp?.data);
      })
      .catch((_) => {})
      .finally(() => {
        setIsLoading(false);
      });
  };

  const handleFormDelete = (data) => {
    if (!(appContext?.userPermissions?.indexOf('View/Edit Attorney Case Status Update') > -1)) {
      appContext.toastMessage.message = 'You do not have permissions to perform this action.';
      toast.error(appContext.toastMessage.message, { autoClose: 3000 });
      appContext.toastMessage.message = null;
      return;
    }

    setIsLoading(true);

    application.clientPublicId = caseStatus.publicId;

    var endpoint = `ApplicationCaseStatusUpdate/${application.publicId}`;

    GridService.delete(endpoint)
      .then((resp) => {
        setCaseStatus({
          publicId: null,
          caseStatus: null,
          diaryFrame: null,
          indexNumber: null,
          timingOutlook: null,
          commentary: null,
          submitterName: null,
          submittedDate: null,
        });
      })
      .catch((_) => {})
      .finally(() => {
        setIsLoading(false);
      });
  };

  const handleCancelButtonClicked = () => {
    history.push('/underwriting/applications/');
  };

  const [isNewCaseStatus, setIsNewClient] = useState(undefined);

  useEffect(() => {
    if (appContext.toastMessage.message)
      toast.success(appContext.toastMessage.message, { autoClose: 3000 });
    appContext.toastMessage.message = null;

    if (!applicationId || applicationId == 'undefined') {
      appContext.toastMessage.message = 'Application No was not found!';
      history.push('/underwriting/applications/new-application/');
      return;
    }

    document.body.scrollTop = 0; // For Safari
    document.documentElement.scrollTop = 0; // For Chrome, Firefox, IE and Opera

    if (applicationId) {
      setIsLoading(true);

      ApplicationsService.search({ applicationNo: applicationId }, null, null, 1, {}, 1)
        .then((response) => {
          setApplication(response?.data?.data[0]);

          const urlParams = new URLSearchParams(window.location.search);
          const fromEditClient = urlParams.get('fromEditClient');

          let endpoint = `/ApplicationCaseStatusUpdate/byapp/${response?.data?.data[0].publicId}`;

          GridService.read(endpoint)
            .then((resp) => {
              setCaseStatus(resp?.data);
            })
            .catch((_) => {})
            .finally(() => {
              setIsLoading(false);
            });
        })
        .catch((err) => {})
        .finally(() => {
          setIsLoading(false);
        });
    } else {
      appContext.toastMessage.message =
        'You cannot perform this action. You must complete required data first.';
      setIsLoading(false);
      history.push('/underwriting/applications/new-application');
      return;
    }
  }, []);

  if (isLoading) {
    return (
      <Container className="mt-10 mb-10">
        <Row>
          <Col md="12" className="mx-auto bg-white box-shadow-border">
            <div className="p-10 mt-5 mb-10">
              {disableTabs != null && (
                <Header applicationId={applicationId} handleSaveAndExit={handleSaveAndExit} />
              )}

              {disableTabs != null && (
                <ApplicationsNewMenu
                  disableTabs={disableTabs}
                  applicationId={applicationId}
                  menuItemIndex="13"
                />
              )}

              <Container className="p-0">
                <Row>
                  <Col md="8">
                    <Row>
                      <Col md="4">
                        <ReactLoading type="bars" color="#7E8299" />
                      </Col>
                    </Row>
                  </Col>
                </Row>
              </Container>
            </div>
          </Col>
        </Row>
      </Container>
    );
  }

  return (
    <Container className="mt-10 mb-10">
      <Row>
        <Col md="12" className="mx-auto bg-white box-shadow-border">
          <div className="p-10 mt-5 mb-10">
            {disableTabs != null && (
              <Header
                refreshFlags={refreshFlags}
                applicationId={applicationId}
                disableTabs={disableTabs}
                handleSaveAndExit={handleSaveAndExit}
              />
            )}

            {disableTabs != null && (
              <ApplicationsNewMenu
                disableTabs={disableTabs}
                applicationId={applicationId}
                menuItemIndex="13"
              />
            )}

            <form onSubmit={handleSubmit(handleFormSubmitted)} noValidate>
              <Container className="p-0">
                <Row>
                  <Col md="8">
                    <div>
                      <Row>
                        <Col md="4">
                          <div className="form-group">
                            <label htmlFor="submitterName">Submitted By</label>
                            <input
                              type="text"
                              name="submitterName"
                              maxLength="30"
                              className="form-control"
                              defaultValue={caseStatus?.submitterName}
                              ref={register()}
                              disabled={'disabled'}
                            />
                          </div>
                        </Col>
                        <Col md="4">
                          <div className="form-group">
                            <label htmlFor="submittedDate">Submitted Date</label>
                            <input
                              type="text"
                              name="submittedDate"
                              maxLength="30"
                              className="form-control"
                              ref={register()}
                              defaultValue={caseStatus.submittedDate
                                ?  Moment(caseStatus.submittedDate).format('d MMM yyyy')
                                : ''}
                              // defaultValue={
                              //   caseStatus?.submittedDate === '0001-01-01T00:00:00'
                              //     ? null
                              //     : Moment(caseStatus?.submittedDate).format('d MMM yyyy')
                              // }
                              disabled={'disabled'}
                            />
                          </div>
                        </Col>
                      </Row>
                      <Row>
                        <Col md="4">
                          <div className="form-group">
                            <label htmlFor="caseStatus">Case Status</label>

                            <select
                              className="form-control"
                              name="caseStatus"
                              defaultValue={caseStatus?.caseStatus}
                              ref={register({
                                required: 'select one option',
                              })}
                              onChange={(ev) => setCaseStatus(ev.target.value)}
                            >
                              <option value="Active">Active</option>
                              <option value="Settled - Need to Collect">
                                Settled - Need to Collect
                              </option>
                              <option value="Old Check">Old Check</option>
                              <option value="Follow up">Follow up</option>
                              <option value="Paid off">Paid off</option>
                              <option value="Transferred to Different Firm">
                                Transferred to Different Firm
                              </option>
                              <option value="Trouble Firm">Trouble Firm</option>
                              <option value="Needs to be contacted">Needs to be contacted</option>
                              <option value="Demand Letter to be Sent">
                                Demand Letter to be Sent
                              </option>
                              <option value="Loss">Loss</option>
                            </select>

                            {errors.caseStatus && (
                              <Alert color="danger" className="mt-5">
                                <ErrorMessage errors={errors} name="caseStatus" />
                              </Alert>
                            )}
                          </div>
                        </Col>
                        <Col md="4">
                          <div className="form-group">
                            <label htmlFor="diaryFrame">Diary Frame</label>
                            <select
                              className="form-control"
                              name="diaryFrame"
                              ref={register({
                                required: 'select one option',
                              })}
                              defaultValue={caseStatus?.diaryFrame}
                            >
                              <option value="0 - 30 Days">0 - 30 Days</option>
                              <option value="0 - 60 Days">30 - 60 Days</option>
                              <option value="60 - 90 Days">60 - 90 Days</option>
                              <option value="90 + Days">90 + Days</option>
                            </select>

                            {errors.diaryFrame && (
                              <Alert color="danger" className="mt-5">
                                <ErrorMessage errors={errors} name="diaryFrame" />
                              </Alert>
                            )}
                          </div>
                        </Col>
                      </Row>

                      <Row>
                        <Col md="4">
                          <div className="form-group">
                            <label htmlFor="indexNumber">Index Number</label>
                            <input
                              type="tel"
                              name="indexNumber"
                              className="form-control"
                              maxLength="20"
                              ref={register({
                                maxLength: { value: 20, message: 'Max length is 20 characters.' },
                              })}
                              defaultValue={caseStatus?.indexNumber}
                              // disabled={
                              //   !isNewCaseStatus && isNewCaseStatus != undefined
                              //     ? 'disabled'
                              //     : undefined
                              // }
                              style={{ width: '250px' }}
                            />
                            {errors.indexNumber && (
                              <Alert color="danger" className="mt-5">
                                <ErrorMessage errors={errors} name="indexNumber" />
                              </Alert>
                            )}
                          </div>
                        </Col>
                        <Col md="4">
                          <div className="form-group">
                            <label htmlFor="timingOutlook">Timing Outlook</label>

                            <select
                            className="form-control"
                            name="timingOutlook"
                            ref={register({
                              required: "select one option"
                            })}
                            defaultValue={caseStatus?.timingOutlook}
                          >
                            <option value="0 - 3 Months">0 - 3 Months</option>
                            <option value="3 - 6 Months">3 - 6 Months</option>
                            <option value="12 + Months">12 + Months</option>
                            <option value="24 + Months">24 + Months</option>

                          </select>

                            {errors.timingOutlook && (
                              <Alert color="danger" className="mt-5">
                                <ErrorMessage errors={errors} name="timingOutlook" />
                              </Alert>
                            )}
                          </div>
                        </Col>
                      </Row>

                      <Row>
                        <Col md="8">
                          <FormGroup>
                            <Label htmlFor="commentary">Commentary</Label>
                            <textarea
                              name="commentary"
                              rows="6"
                              ref={register}
                              defaultValue={caseStatus?.commentary}
                              className="form-control"
                            />
                          </FormGroup>
                        </Col>
                      </Row>

                      <Row>
                        <Col md="8">
                          <button type="submit" className="btn btn-primary mr-5">
                            Save
                          </button>
                          <button
                            type="button"
                            onClick={handleCancelButtonClicked}
                            className="btn btn-secondary  mr-5"
                          >
                            Cancel
                          </button>
                          <button
                            type="button"
                            onClick={handleFormDelete}
                            className="btn btn-danger"
                          >
                            Delete
                          </button>
                        </Col>
                      </Row>
                    </div>
                  </Col>
                </Row>
              </Container>
            </form>
          </div>
        </Col>
      </Row>
    </Container>
  );
};

export default AttorneyCaseStatus;
