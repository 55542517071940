import React from 'react';
import Autosuggest from 'react-autosuggest';
import AttorneyService from '@services/AttorneyService';

class AttorneyAutoSuggestInsideApplication extends React.Component {
  constructor(props) {
    super(props);
    this.props = props;
    this.state = {
      value: '',
      suggestions: [],
    };
  }

  getSuggestionValue = (suggestion) => {
    this.props.selectedAttorney(suggestion.publicId);
    this.props.selectedAttorneyName(suggestion.displayName);
    return suggestion.displayName;
  };

  renderSuggestion = (suggestion) => {
    return <span>{suggestion.displayName}</span>;
  };

  onChange = (event, { newValue, method }) => {
    this.setState({
      value: newValue,
    });
    if (!newValue || newValue == '') {
      this.props.selectedAttorneyName('');
      this.props.selectedAttorney(null);
    }
  };

  onSuggestionsFetchRequested = ({ value }) => {
    AttorneyService.autoSuggest(value).then((response) => {
      this.setState({ suggestions: response.data.data });
    });
  };

  onSuggestionsClearRequested = () => {
    this.setState({
      suggestions: [],
    });
  };

  componentDidMount = () => {
    this.setState({
      suggestions: [],
      value: this.props.defaultAttorney ? this.props.defaultAttorney : '',
    });
  };

  componentDidUpdate(prevProps, prevState) {
    if (prevProps.defaultAttorney != this.props.defaultAttorney) {
      this.setState({
        value: this.props.defaultAttorney ? this.props.defaultAttorney : '',
      });
    }
  }

  render() {
    const { value, suggestions } = this.state;
    const inputProps = {
      placeholder: 'Search Attorney...',
      value,
      onChange: this.onChange,
    };

    return (
      <Autosuggest
        suggestions={suggestions}
        onSuggestionsFetchRequested={this.onSuggestionsFetchRequested}
        onSuggestionsClearRequested={this.onSuggestionsClearRequested}
        getSuggestionValue={this.getSuggestionValue}
        renderSuggestion={this.renderSuggestion}
        inputProps={inputProps}
      />
    );
  }
}

export default AttorneyAutoSuggestInsideApplication;
