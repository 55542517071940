import axios from 'axios';
import { auth, resError } from '@services/Helpers/ServiceInterceptor';

const api = axios.create({
    baseURL: process.env.REACT_APP_API_URL + '/lientype/',
});

api.interceptors.request.use((config) => auth(config));
api.interceptors.response.use(
  (response) => {
    return response;
  },
  (error) => resError(error)
);

class LienTypeService {

    constructor() {
        if (!LienTypeService.instance) {
            LienTypeService.instance = this;
        }
        return LienTypeService.instance;
    };

    search = (filters, p, fields) => {
        return api.post('/search', {
            filters: Object.keys(filters).length > 0 ? filters : null,
            page: p,
            fields: fields?.length ? fields : null
        });
    };

    create = (entity) => {
        return api.post('/', entity);
    };

    read = (id) => {
        return api.get('/' + id);
    };

    update = (entity) => {
        return api.put('/', entity);
    };

    delete = (id) => {
        return api.delete('/' + id);
    };

    autoSuggest = (value) => {
        return api.post('/search', { filters: { lastName: value }, perPage: 15, page: 1 });
    };

}

const instance = new LienTypeService();
Object.freeze(instance);

export default instance;