import React, { useState, useEffect, useContext } from 'react';
import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import { useHistory } from 'react-router';
import PropTypes from 'prop-types';
import { makeStyles, useTheme, withStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import { Row, Col } from 'reactstrap';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableFooter from '@material-ui/core/TableFooter';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import Pagination from '@material-ui/lab/Pagination';
import EditIcon from '@material-ui/icons/Edit';
import FinancialSummaryReportService from '@services/FinancialSummaryReportService';
import FinancialDetailsService from '@services/VReportsFinancialDetails';
import AppContext from '@contexts/AppContext';
import TableSortLabel from '@material-ui/core/TableSortLabel';
import ReactLoading from 'react-loading';
import FilteringFields from './FilteringFields';
import SearchIcon from '@material-ui/icons/Search';
import { Link } from 'react-router-dom';
import { FormattedAmount } from '../../../components/Utils/FormattedAmount';
import { toast } from 'react-toastify';
import fileDownload from 'js-file-download';

const StyledTableCell = withStyles((theme) => ({
  head: {
    backgroundColor: '#efefef',
    color: theme.palette.common.black,
  },
  body: {
    fontSize: 14,
  },
}))(TableCell);

const useStyles2 = makeStyles({
  table: {
    minWidth: 500,
  },
});

const user = JSON.parse(localStorage.getItem('lasuris-user'));

export default function CustomPaginationActionsTable() {
  const appContext = useContext(AppContext);
  const classes = useStyles2();
  const [page, setPage] = React.useState(1);
  const [rowsPerPage, setRowsPerPage] = React.useState(9999);
  const [filters, setFilters] = useState(appContext.financialSummaryReportFilters);
  const [orderBy, setOrderBy] = useState('StateName');
  const [orderDir, setOrderDir] = useState('ASC');
  const [rows, setRows] = useState([]);

  const history = useHistory();

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  useEffect(() => {
    if (!filters.dateRange || filters.dateRange == '') {
      let tempFilters = {
        ...filters,
        dateRange: 'This month',
      };
      setFilters(tempFilters);
      appContext.financialSummaryReportFilters = tempFilters;
      localStorage.setItem('financialSummaryReportFilters', JSON.stringify(tempFilters));
      loadData(tempFilters);
    } else {
      loadData(filters);
    }
  }, [page, orderBy, orderDir, rowsPerPage]);

  const loadData = (filters) => {
    let reqFilters = filters;
    if (reqFilters.portfolioName) reqFilters.PortfolioPublicId = reqFilters.portfolioName;
    if (reqFilters.applicationStatus) reqFilters.ApplicationStatus = reqFilters.applicationStatus;
    if (reqFilters.lawFirm) {
      reqFilters = { ...reqFilters, LawFirmPublicId: reqFilters.lawFirm.publicId };
    }

    delete reqFilters.lawFirm;
    delete reqFilters.portfolioName;
    delete reqFilters.applicationStatus;
    setIsLoading(true);
    FinancialSummaryReportService.search(reqFilters, orderBy, orderDir, page, null, rowsPerPage)
      .then((response) => {
        setRows(response.data.data);
        setPages(response.data.pages.slice(-1)[0]);
        setIsLoading(false);
      })
      .catch((err) => {})
      .finally(() => {
        setIsLoading(false);
      });
  };

  const getAll = () => {
    let tempFilters = {
      dateRange: 'This month',
    };
    setFilters(tempFilters);
    appContext.financialSummaryReportFilters = tempFilters;
    localStorage.setItem('financialSummaryReportFilters', JSON.stringify(tempFilters));
    update(tempFilters);
  };

  const update = (filters) => {
    let reqFilters = filters;
    if (reqFilters.portfolioName) reqFilters.PortfolioPublicId = reqFilters.portfolioName;
    if (reqFilters.applicationStatus) reqFilters.ApplicationStatus = reqFilters.applicationStatus;
    if (reqFilters.lawFirm) {
      reqFilters = { ...reqFilters, LawFirmPublicId: reqFilters.lawFirm.publicId };
    }
    delete reqFilters.lawFirm;
    delete reqFilters.portfolioName;
    delete reqFilters.applicationStatus;

    setIsLoading(true);

    FinancialSummaryReportService.search(reqFilters, orderBy, orderDir, page, null, rowsPerPage)
      .then((response) => {
        setRows(response.data.data);
        setPages(response.data.pages.slice(-1)[0]);
        setIsLoading(false);
      })
      .catch((err) => {})
      .finally(() => {
        setIsLoading(false);
      });
  };

  const exportTo = (filters) => {
    let reqFilters = filters;
    if (reqFilters.portfolioName) reqFilters.PortfolioPublicId = reqFilters.portfolioName;
    if (reqFilters.applicationStatus) reqFilters.ApplicationStatus = reqFilters.applicationStatus;

    delete reqFilters.portfolioName;
    delete reqFilters.applicationStatus;
    if (reqFilters.lawFirm) {
      reqFilters = { ...reqFilters, LawFirmPublicId: reqFilters.lawFirm.publicId };
    }

    delete reqFilters.lawFirm;
   
    setIsLoading(true);

    FinancialDetailsService.export(filters, orderBy, orderDir, page, null, rowsPerPage)
      .then((response) => {
        const contentDisposition = response.headers['content-disposition'];
        let fileName = 'Data-Export.xlsx';
        if (contentDisposition) {
          const fileNameMatch = contentDisposition.match(/filename="(.+)"/);
          if (fileNameMatch != null && fileNameMatch.length === 2) fileName = fileNameMatch[1];
        }
        fileDownload(response.data, fileName);
        setIsLoading(false);
      })
      .catch((err) => {})
      .finally(() => {
        setIsLoading(false);
      });
  };

  const searchWithFilters = (filters) => {
    var newFilters = { ...filters };
    setFilters(newFilters);
    appContext.financialSummaryReportFilters = newFilters;
    localStorage.setItem('financialSummaryReportFilters', JSON.stringify(newFilters));
    if (page === 1) update(newFilters);
    else setPage(1);
  };

  const [modal, setModal] = useState(false);
  const toggle = () => setModal(!modal);
  const [rowIndex, setRowIndex] = useState(undefined);
  const [givenRows, setGivenRows] = useState(undefined);
  const [isLoading, setIsLoading] = useState(false);
  const [pages, setPages] = useState(undefined);

  const [activeFieldForSorting, setActiveFieldForSorting] = useState();

  const arrange = (field) => {
    if (orderBy === field) {
      if (orderDir === 'ASC') {
        setOrderDir('DESC');
      } else {
        setOrderDir('ASC');
      }
    } else {
      setOrderBy(field);
      setOrderDir('ASC');
    }

    setPage(1);
  };

  const handlePageSizeChange = (event) => {
    setRowsPerPage(event.target.value);
  };

  const exportToFile = (stateName) => {
    var reqFilters = { ...filters };
    reqFilters.stateName = stateName;
    exportTo(reqFilters);
  };

  return (
    <>
      <FilteringFields searchWithFilters={searchWithFilters} getAll={getAll}></FilteringFields>
      <Col md="9" className="float-left fin-smry-table-container">
        <h3>Totals (by State)</h3>
        <TableContainer component={Paper}>
          <Table className={classes.table} aria-label="custom pagination table">
            <TableHead>
              <TableRow>
                <StyledTableCell>Id</StyledTableCell>

                <StyledTableCell className="fsr-state-name">STATE</StyledTableCell>

                <StyledTableCell className="fsr-total-advances">TOTAL ADVANCES</StyledTableCell>

                <StyledTableCell className="fsr-total-present-value">
                  TOTAL PRESENT VALUE
                </StyledTableCell>
              </TableRow>
            </TableHead>
            {isLoading === false && (
              <TableBody>
                {rows &&
                  rows[0] &&
                  rows[0].stateTotals &&
                  rows[0].stateTotals.map((row, index) => (
                    <TableRow key={row.name}>
                      <TableCell component="th" scope="row">
                        {row.id}
                      </TableCell>
                      <TableCell className="fsr-state-name">
                        {row.stateName ? (
                          <Link onClick={() => exportToFile(row.stateName)}> {row.stateName}</Link>
                        ) : null}
                      </TableCell>
                      <TableCell className="fsr-total-advances">{row.totalAdvances}</TableCell>
                      <TableCell className="fsr-total-present-value">
                        {row?.totalPresentValue
                          ? FormattedAmount.format(parseFloat(row.totalPresentValue).toFixed(2))
                          : '-'}
                      </TableCell>
                    </TableRow>
                  ))}
              </TableBody>
            )}
            {isLoading && (
              <TableBody>
                <TableRow>
                  <td></td>
                  <td>
                    <ReactLoading type="bars" color="#7E8299" />
                  </td>
                  <td></td>
                </TableRow>
              </TableBody>
            )}

            <TableFooter></TableFooter>
          </Table>
        </TableContainer>

        {/*

<div className="float-left mt-5">
          <select onChange={handlePageSizeChange} className="form-control" defaultValue={15}>
            {[5, 10, 15, 25, 100].map((size) => (
              <option key={size} value={size}>
                {size}
              </option>
            ))}
          </select>
        </div>
        
        
          <div className="float-right mt-5">
          <Pagination
            count={pages}
            page={page}
            siblingCount={1}
            boundaryCount={1}
            variant="outlined"
            onChange={handleChangePage}
          />
        </div>
        */}

        <Row className="mt-25">
          <Col md="12" sm="12" className="table-totals-all-states">
            <h3>Totals (all States)</h3>
            <div>
              <TableContainer
                style={{ border: '0px solid', borderRadius: 0 }}
                className="tableFinancial"
                component={Paper}
              >
                <Table className={classes.table} aria-label="custom pagination table">
                  <TableHead></TableHead>
                  <TableBody>
                    <TableRow>
                      <TableCell component="th" scope="row"></TableCell>
                      <TableCell className="highlight-text">Total Advances</TableCell>
                      <TableCell>{rows[0]?.totalAdvances}</TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell component="th" scope="row"></TableCell>
                      <TableCell className="highlight-text">Advanced Amount</TableCell>
                      <TableCell>
                        {' '}
                        {rows[0]?.totalAdvanceAmount
                          ? FormattedAmount.format(
                              parseFloat(rows[0].totalAdvanceAmount).toFixed(2)
                            )
                          : '-'}
                      </TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell component="th" scope="row"></TableCell>
                      <TableCell className="highlight-text">NFP Fees</TableCell>
                      <TableCell>
                        {' '}
                        {rows[0]?.totalNfpfees
                          ? FormattedAmount.format(parseFloat(rows[0].totalNfpfees).toFixed(2))
                          : '-'}
                      </TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell component="th" scope="row"></TableCell>
                      <TableCell className="highlight-text">Referral Fees</TableCell>
                      <TableCell>
                        {' '}
                        {rows[0]?.totalReferralFees
                          ? FormattedAmount.format(parseFloat(rows[0].totalReferralFees).toFixed(2))
                          : '-'}
                      </TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell component="th" scope="row"></TableCell>
                      <TableCell className="highlight-text">Financial Store Fees</TableCell>
                      <TableCell>
                        {' '}
                        {rows[0]?.totalStoreFees
                          ? FormattedAmount.format(parseFloat(rows[0].totalStoreFees).toFixed(2))
                          : '-'}
                      </TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell component="th" scope="row"></TableCell>
                      <TableCell className="highlight-text">Bank Wire Fees</TableCell>
                      <TableCell>
                        {' '}
                        {rows[0]?.totalBankWireFees
                          ? FormattedAmount.format(parseFloat(rows[0].totalBankWireFees).toFixed(2))
                          : '-'}
                      </TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell component="th" scope="row"></TableCell>
                      <TableCell className="highlight-text">Overnight Check Fees</TableCell>
                      <TableCell>
                        {' '}
                        {rows[0]?.totalOvernightCheckFees
                          ? FormattedAmount.format(
                              parseFloat(rows[0].totalOvernightCheckFees).toFixed(2)
                            )
                          : '-'}
                      </TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell component="th" scope="row"></TableCell>
                      <TableCell className="highlight-text">Other Disbursements</TableCell>
                      <TableCell>
                        {' '}
                        {rows[0]?.totalOtherDisbursements
                          ? FormattedAmount.format(
                              parseFloat(rows[0].totalOtherDisbursements).toFixed(2)
                            )
                          : '-'}
                      </TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell component="th" scope="row"></TableCell>
                      <TableCell className="highlight-text">Cash To Client</TableCell>
                      <TableCell>
                        {' '}
                        {rows[0]?.totalCashtoClient
                          ? FormattedAmount.format(parseFloat(rows[0].totalCashtoClient).toFixed(2))
                          : '-'}
                      </TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell component="th" scope="row"></TableCell>
                      <TableCell className="highlight-text">Total Cash Outlay</TableCell>
                      <TableCell>
                        {' '}
                        {rows[0]?.totalCashOutlay
                          ? FormattedAmount.format(parseFloat(rows[0].totalCashOutlay).toFixed(2))
                          : '-'}
                      </TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell component="th" scope="row"></TableCell>
                      <TableCell className="highlight-text">Average Days Outstanding</TableCell>
                      <TableCell>
                        {' '}
                        {rows[0]?.avgDaysOutstanding ? rows[0].avgDaysOutstanding : '-'}
                      </TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell component="th" scope="row"></TableCell>
                      <TableCell className="highlight-text">Multiplier Average</TableCell>
                      <TableCell> {rows[0]?.avgMultiplier ? rows[0].avgMultiplier : '-'}</TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell component="th" scope="row"></TableCell>
                      <TableCell className="highlight-text">Acrual Rate Average</TableCell>
                      <TableCell>
                        {' '}
                        {rows[0]?.avgAccrualRate ? rows[0].avgAccrualRate : '-'}
                      </TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell component="th" scope="row"></TableCell>
                      <TableCell className="highlight-text">Profits</TableCell>
                      <TableCell>
                        {' '}
                        {rows[0]?.totalProfit
                          ? FormattedAmount.format(parseFloat(rows[0].totalProfit).toFixed(2))
                          : '-'}
                      </TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell component="th" scope="row"></TableCell>
                      <TableCell className="highlight-text">Present Value</TableCell>
                      <TableCell>
                        {' '}
                        {rows[0]?.totalPresentValue
                          ? FormattedAmount.format(parseFloat(rows[0].totalPresentValue).toFixed(2))
                          : '-'}
                      </TableCell>
                    </TableRow>
                  </TableBody>

                  <TableFooter></TableFooter>
                </Table>
              </TableContainer>
            </div>
          </Col>
        </Row>
      </Col>
    </>
  );
}
