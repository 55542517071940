/* eslint-disable jsx-a11y/label-has-associated-control */
import React, { useState, useEffect, useContext, useRef } from 'react';
import { Row, Col, Input, Label, Alert, Button, Form, Container } from 'reactstrap';
import { useParams } from 'react-router-dom';
import { useHistory } from 'react-router';
import PortfolioService from '@services/PortfolioService';
import AppContext from '@contexts/AppContext';
import { withStyles } from '@material-ui/core/styles';
import { green } from '@material-ui/core/colors';
import Checkbox from '@material-ui/core/Checkbox';
import { useForm } from 'react-hook-form';
import { toast } from 'react-toastify';
import ReactLoading from 'react-loading';

const GreenCheckbox = withStyles({
  root: {
    color: green[400],
    '&$checked': {
      color: green[600],
    },
  },
  checked: {},
})((props) => <Checkbox color="default" {...props} />);

const Portfolio = () => {
  const { register, errors, handleSubmit, watch, formState } = useForm({ mode: 'onBlur' });
  const history = useHistory();
  const { id } = useParams();
  const form = useRef();
  const [errorMessage, setErrorMessage] = useState('');
  const [successMessage, setSuccessMessage] = useState('');
  const [isLoading, setIsLoading] = useState(true);
  const [error, setErrors] = useState(null);
  const [roleName, setRoleName] = useState();
  const appContext = useContext(AppContext);
  const [portfolio, setPortfolio] = useState({
    publicId: '',
    portfolioCode: '',
    portfolioName: '',
  });

  useEffect(() => {
    const LoadPortfolioIfPresent = () => {
      if (id) {

        setIsLoading(true);

        PortfolioService.read(id)
          .then((response) => {
            if (response.data) {
              setPortfolio(response.data);
              setIsLoading(false);
            }
          })
          .catch((err) => {
            
          })
          .finally(() => {
            setIsLoading(false);
          });
      }

      setIsLoading(false);
    };

    LoadPortfolioIfPresent();
  }, []);

  const handleFormSubmitted = (data) => {
    if (id) {
      if (!(appContext?.userPermissions?.indexOf('Edit Potfoilios') > -1)) {
        appContext.toastMessage.message = 'You do not have permissions to perform this action.';
        toast.error(appContext.toastMessage.message, { autoClose: 3000 });
        appContext.toastMessage.message = null;
        return;
      }

      const entity = {
        publicId: id,
        isActive: data.isActive,
        portfolioCode: data.portfolioCode,
        portfolioName: data.portfolioName,
      };

      setIsLoading(true);

      PortfolioService.update(entity)
        .then((response) => {
          appContext.toastMessage.message = 'Portfolio has been successfully updated.';
          history.push('/portfolios/portfolios-list');
        })
        .catch((err) => {
          
        })
        .finally(() => {
          setIsLoading(false);
        });

    } else {
      if (!(appContext?.userPermissions?.indexOf('Add Potfoilios') > -1)) {
        appContext.toastMessage.message = 'You do not have permissions to perform this action.';
        toast.error(appContext.toastMessage.message, { autoClose: 3000 });
        appContext.toastMessage.message = null;
        return;
      }

      const entity = {
        publicId: id,
        isActive: true,
        portfolioCode: data.portfolioCode,
        portfolioName: data.portfolioName,
      };

      setIsLoading(true);

      PortfolioService.create(entity)
        .then((response) => {
          appContext.toastMessage.message = 'Portfolio has been successfully created.';
          history.push('/portfolios/portfolios-list');
        })
        .catch((err) => {
          
        })
        .finally(() => {
          setIsLoading(false);
        });
    }
  };

  const handleCancelButton = () => {
    history.push('/portfolios/portfolios-list');
  };

  if (isLoading) {
    return (
      <div className="container mt-10 mb-10">
        <div className="row">
          <div className="col-md-12 mx-auto bg-white box-shadow-border">
            <div className="p-10">
              <div className="mt-5 mb-10">
                <ReactLoading type="bars" color="#7E8299" />
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }

  return (
    <div className="container mt-10 mb-10">
      <div className="row">
        <div className="col-md-12 mx-auto bg-white box-shadow-border">
          <div className="p-10">
            <div className="mt-5 mb-10">
              {errorMessage && (
                <div className="alert alert-custom alert-danger mb-10" role="alert">
                  <div className="alert-icon">
                    <i className="flaticon-warning" />
                  </div>
                  <div className="alert-text"> {errorMessage} </div>
                </div>
              )}
              <div className="mb-10">
                <h1>{id ? 'Portfolio' : 'New Portfolio'}</h1>
              </div>
              <form ref={form} onSubmit={handleSubmit(handleFormSubmitted)} noValidate>
                {successMessage && <div className="alert alert-success">{successMessage}</div>}
                <div className="row">
                  <div className="col-md-2">
                    <div className="form-group">
                      <label htmlFor="portfolioCode">Code</label>
                      <input
                        type="text"
                        name="portfolioCode"
                        className="form-control"
                        style={{ width: '100px' }}
                        defaultValue={portfolio.portfolioCode}
                        ref={register}
                      />
                      {errors && errors['portfolioCode'] && (
                        <Alert color="danger" className="mt-3">
                          {errors['portfolioCode']}
                        </Alert>
                      )}
                    </div>
                  </div>
                  <Col md="1">
                    <div className="form-group">
                      <Label>Active</Label>{' '}
                      <input
                        type="checkbox"
                        {...(!id && { checked: true })}
                        defaultChecked={portfolio.isActive}
                        name="isActive"
                        ref={register}
                      />
                      {errors && errors['isactivr'] && (
                        <Alert color="danger" className="mt-3">
                          {errors['isactivr']}
                        </Alert>
                      )}
                    </div>
                  </Col>
                </div>

                <div className="row">
                  <div className="col-md-2">
                    <div className="form-group">
                      <label htmlFor="portfolioName">Name</label>
                      <input
                        type="text"
                        name="portfolioName"
                        className="form-control"
                        style={{ width: '300px' }}
                        defaultValue={portfolio.portfolioName}
                        ref={register}
                      />
                      {errors && errors['portfolioName'] && (
                        <Alert color="danger" className="mt-3">
                          {errors['portfolioName']}
                        </Alert>
                      )}
                    </div>
                  </div>
                </div>

                <button type="submit" className="btn btn-primary mr-5">
                  Save
                </button>
                <a href="##" onClick={handleCancelButton} className="btn btn-secondary">
                  Cancel
                </a>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
export default Portfolio;
