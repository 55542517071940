import axios from 'axios';
import { auth, resError } from '@services/Helpers/ServiceInterceptor';

const api = axios.create({
    baseURL: process.env.REACT_APP_API_URL + '/usState/',
});

api.interceptors.request.use((config) => auth(config));
api.interceptors.response.use(
  (response) => {
    return response;
  },
  (error) => resError(error)
);

class UsStateService {

    constructor() {
        if (!UsStateService.instance) {
            UsStateService.instance = this;
        }
        return UsStateService.instance;
    };

    search = (filters, p, fields) => {
        return api.post('/search', { filters: Object.keys(filters).length > 0 ? filters : null, page: p, fields: fields.length ? fields : null });
    };

    create = (entity) => {
        return api.post('/', entity);
    };

    read = (id) => {
        return api.get('/' + id);
    };

    update = (entity) => {
        return api.put('/', entity);
    };

    delete = (id) => {
        return api.delete('/' + id);
    };

}

const instance = new UsStateService();
Object.freeze(instance);

export default instance;