import React, { useState, useEffect, useContext, useRef } from 'react';
import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import { useHistory, Link, withRouter, useParams } from 'react-router-dom';
import { makeStyles, useTheme, withStyles } from '@material-ui/core/styles';
import { toast } from 'react-toastify';
import Moment from 'moment';

import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableFooter from '@material-ui/core/TableFooter';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import Pagination from '@material-ui/lab/Pagination';
import EditIcon from '@material-ui/icons/Edit';
import DeleteIcon from '@material-ui/icons/Delete';
import AttorneyNoteService from '@services/AttorneyNoteService';
import AppContext from '@contexts/AppContext';
import TableSortLabel from '@material-ui/core/TableSortLabel';
import ReactLoading from 'react-loading';

const StyledTableCell = withStyles((theme) => ({
    head: {
        backgroundColor: '#efefef',
        color: theme.palette.common.black,
    },
    body: {
        fontSize: 14,
    },
}))(TableCell);

const useStyles2 = makeStyles({
    table: {
        minWidth: 500,
    },
});

export default function NotesGrid(props) {
    const classes = useStyles2();
    const [page, setPage] = React.useState(1);
    const [rowsPerPage, setRowsPerPage] = React.useState(15);
    const [filters, setFilters] = useState({});
    const [orderBy, setOrderBy] = useState('');
    const [orderDir, setOrderDir] = useState('ASC');
    const [rows, setRows] = useState([]);
    const [attorneyPublicId, setAttorneyPublicId] = useState();
    const appContext = useContext(AppContext);
    const params = useParams();
    const [modal, setModal] = useState(false);
    const toggle = () => setModal(!modal);
    const [rowIndex, setRowIndex] = useState(undefined);
    const [givenRows, setGivenRows] = useState(undefined);
    const [isLoading, setIsLoading] = useState(false);
    const [pages, setPages] = useState(undefined);
    const [activeFieldForSorting, setActiveFieldForSorting] = useState();
    const history = useHistory();
    const mountedRef = useRef();

    const { id } = useParams();

    useEffect(() => {
        if (!mountedRef.current) return;
        update();
    }, [orderBy, orderDir, rowsPerPage, page]);

    useEffect(() => {
        mountedRef.current = true;
        setPage(1);
    }, []);

    useEffect(() => {
        if (!props.modal)
            update();
    }, [props.modal]);

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const update = () => {
        if (params.applicationId === 'undefined') return;

        setIsLoading(true);

        let newFilters = { ...filters };
        newFilters['attorneyPublicId'] = id;
        setFilters(newFilters);

        if (id) {
            AttorneyNoteService.search(newFilters, orderBy, orderDir, page, null, rowsPerPage)
                .then((response) => {
                    setRows(response.data.data);
                    setPages(response.data.pages.slice(-1)[0]);
                    setIsLoading(false);
                })
                .catch((err) => { })
                .finally(() => {
                    setIsLoading(false);
                });
        }
    };

    const searchColumn = (event, columnName) => {
        var newFilters = filters;
        if (columnName === 'isActive' && event.target.value != 'All') {
            newFilters[columnName] = event.target.value === 'true' ? true : false;
        } else if (columnName === 'isActive' && event.target.value === 'All') {
            newFilters[columnName] = undefined;
        } else {
            newFilters[columnName] = event.target.value;
        }
        setFilters(newFilters);
        update();
    };

    const deleteItem = () => {
        if (!(appContext?.userPermissions?.indexOf('Delete Notes') > -1)) {
            toggle();
            appContext.toastMessage.message = 'You do not have permissions to perform this action.';
            toast.error(appContext.toastMessage.message, { autoClose: 3000 });
            appContext.toastMessage.message = null;
            return;
        }

        setIsLoading(true);

        AttorneyNoteService.delete(givenRows[rowIndex].publicId)
            .then((result) => {
                let items = [...givenRows];
                items.splice(rowIndex, 1);
                setRows(items);
                toggle();
                appContext.toastMessage.message = 'Note has been successfully deleted.';
                toast.success(appContext.toastMessage.message, { autoClose: 3000 });
                appContext.toastMessage.message = null;
            })
            .catch((err) => { })
            .finally(() => {
                setIsLoading(false);
            });
    };

    const arrange = (field) => {
        if (orderBy === field) {
            if (orderDir === 'ASC') {
                setOrderDir('DESC');
            } else {
                setOrderDir('ASC');
            }
        } else {
            setOrderBy(field);
            setOrderDir('ASC');
        }

        setPage(1);
    };

    const handlePageSizeChange = (event) => {
        setRowsPerPage(event.target.value);
    };

    return (
        <div>
            <div>
                <Modal isOpen={modal} toggle={toggle}>
                    <ModalHeader toggle={toggle}>Confirmation</ModalHeader>
                    <ModalBody>Are you sure you want to delete this note?</ModalBody>
                    <ModalFooter>
                        <Button color="primary" onClick={deleteItem}>
                            Delete
            </Button>
                        <Button color="secondary" onClick={toggle}>
                            Cancel
            </Button>
                    </ModalFooter>
                </Modal>
            </div>
            <Table className={classes.table} aria-label="custom pagination table">
                <TableHead>
                    <TableRow>
                        <StyledTableCell>Id</StyledTableCell>

                        <StyledTableCell
                            onClick={() => {
                                arrange('dated');
                                setActiveFieldForSorting('dated');
                            }}
                        >
                            DATE
              <TableSortLabel
                                active={activeFieldForSorting === 'dated' ? true : false}
                                direction={activeFieldForSorting === 'dated' ? orderDir.toLowerCase() : 'asc'}
                                onClick={() => arrange('dated')}
                            ></TableSortLabel>
                        </StyledTableCell>

                        <StyledTableCell
                            onClick={() => {
                                arrange('note');
                                setActiveFieldForSorting('note');
                            }}
                        >
                            NOTE
              <TableSortLabel
                                active={activeFieldForSorting === 'note' ? true : false}
                                direction={activeFieldForSorting === 'note' ? orderDir.toLowerCase() : 'asc'}
                                onClick={() => arrange('note')}
                            ></TableSortLabel>
                        </StyledTableCell>

                        <StyledTableCell
                            onClick={() => {
                                arrange('createdByDisplayName');
                                setActiveFieldForSorting('createdByDisplayName');
                            }}
                        >
                            CREATED BY
              <TableSortLabel
                                active={activeFieldForSorting === 'createdByDisplayName' ? true : false}
                                direction={
                                    activeFieldForSorting === 'createdByDisplayName'
                                        ? orderDir.toLowerCase()
                                        : 'asc'
                                }
                                onClick={() => arrange('createdByDisplayName')}
                            ></TableSortLabel>
                        </StyledTableCell>

                        <StyledTableCell></StyledTableCell>
                        <StyledTableCell></StyledTableCell>
                    </TableRow>
                    <TableRow>
                        <StyledTableCell>
                            <input
                                type="search"
                                className="form-control"
                                placeholder="Search..."
                                onChange={(e) => searchColumn(e, 'dated')}
                            />
                        </StyledTableCell>
                        <StyledTableCell></StyledTableCell>
                        <StyledTableCell>
                            <input
                                type="search"
                                className="form-control"
                                placeholder="Search..."
                                onChange={(e) => searchColumn(e, 'note')}
                            />
                        </StyledTableCell>
                        <StyledTableCell>
                            <input
                                type="search"
                                className="form-control"
                                placeholder="Search..."
                                onChange={(e) => searchColumn(e, 'createdByDisplayName')}
                            />
                        </StyledTableCell>

                        <StyledTableCell></StyledTableCell>
                        <StyledTableCell></StyledTableCell>
                    </TableRow>
                </TableHead>
                {isLoading === false && (
                    <TableBody>
                        {rows &&
                            rows.map((row, index) => (
                                <TableRow key={row.name}>
                                    <TableCell component="th" scope="row">
                                        {row.publicId}
                                    </TableCell>
                                    <TableCell>
                                        {row.dated && Moment(row.dated.split('T')[0]).format('MM-DD-YYYY')}
                                    </TableCell>
                                    <TableCell>{row.note}</TableCell>
                                    <TableCell>{row.createdByDisplayName}</TableCell>
                                    <TableCell className="p-2">
                                        <a
                                            className="btn float-right"
                                            onClick={() => props.handleNewItem(row.publicId)}
                                        >
                                            <EditIcon />
                                        </a>
                                    </TableCell>

                                    <TableCell className="p-2" style={{ width: '30px' }}>
                                        <a
                                            className="btn float-left"
                                            onClick={() => {
                                                setRowIndex(index);
                                                setGivenRows(rows);
                                                toggle();
                                            }}
                                        >
                                            <DeleteIcon style={{ color: 'var(--red)' }} />
                                        </a>
                                    </TableCell>
                                </TableRow>
                            ))}
                    </TableBody>
                )}
                {isLoading && (
                    <TableBody>
                        <TableRow>
                            <td></td>
                            <td>
                                <ReactLoading type="bars" color="#7E8299" />
                            </td>
                            <td></td>
                        </TableRow>
                    </TableBody>
                )}

                <TableFooter></TableFooter>
            </Table>
            <div className="float-left mt-5">
                <select onChange={handlePageSizeChange} className="form-control" defaultValue={15}>
                    {[5, 10, 15, 25, 100].map((size) => (
                        <option key={size} value={size}>
                            {size}
                        </option>
                    ))}
                </select>
            </div>
            <div className="float-right mt-5">
                <Pagination
                    count={pages}
                    page={page}
                    siblingCount={1}
                    boundaryCount={1}
                    variant="outlined"
                    onChange={handleChangePage}
                />
            </div>
        </div>
    );
}
