import React from 'react';
import { Route, Redirect } from 'react-router-dom';

export const PrivateRoute = ({ component: Component, ...rest }) => (
    <Route {...rest} render={props => {
        const jwt = localStorage.getItem('lasuris-jwt');
        if (jwt) {
            // authorised so return component
            return <Component {...props} />
        }
        return <Redirect to={{ pathname: '/login', state: { from: props.location } }} />
    }} />
)