import React from 'react';
import Autosuggest from 'react-autosuggest';
import UserService from '../../services/UserService';

class UsersAutoSuggest extends React.Component {
    
    constructor(props) {
        super(props);
        this.props = props;
        this.state = {
            value: '',
            suggestions: [],
        };
    }

    getSuggestionValue = ((suggestion) => {
        this.props.selectedUser(suggestion.publicId)
        return suggestion.displayName;
    });
    
    renderSuggestion = ((suggestion)=> {
        return (
            <span>{suggestion.displayName}</span>
        );
    });

    onChange = (event, { newValue, method }) => {
        this.setState({
            value: newValue
        });
    };

    onSuggestionsFetchRequested = ({ value }) => {

            UserService.autoSuggest(value).then((response)=>{
            this.setState({suggestions:response.data.data});
        });
        
    }

    onSuggestionsClearRequested = () => {
        this.setState({
            suggestions: []
        });
    };

    componentDidMount = () =>{
        this.setState({suggestions:[], value: this.props.defaultUser});
    };

    render() {
        const { value, suggestions } = this.state;
        const inputProps = {
            placeholder: "Search Account Manager...",
            value,
            onChange: this.onChange
        };

        return (
            <Autosuggest
                suggestions={suggestions}
                onSuggestionsFetchRequested={this.onSuggestionsFetchRequested}
                onSuggestionsClearRequested={this.onSuggestionsClearRequested}
                getSuggestionValue={this.getSuggestionValue}
                renderSuggestion={this.renderSuggestion}
                inputProps={inputProps} 
                />
        );
    }
}

export default UsersAutoSuggest;