import React, { useContext, useEffect, useState, useRef } from 'react';

const MultipleSelect = ({
  x,
  i,
  injuriesList,
  handleRemoveClick,
  handleAddClick,
  selectedInjuryList,
  setSelectedInjuryList,
  genericInjuryOptions,
}) => {
  //const genericInjuryRef = useRef();

  const [specificInjuryOptions, setSpecificInjuryOptions] = useState('');
  // const [selectedSpecificInjuryPublicId, setSelectedSpecificInjuryPublicId] = useState('');
  //const [selectedGenericInjuryPublicId, setSelectedGenericInjuryPublicId] = useState('');

  const handleGenericInjuryChange = (e, index) => {
    const { name, value } = e.target;

    // setSelectedGenericInjuryPublicId(value);

    selectedInjuryList[index][name] = value;

    //const givenItem = injuriesList.find((item) => item.publicId === genericInjuryRef.current.value);

    //Get parent-detail object
    const givenItem = injuriesList.find((item) => item.publicId === value);

    const defaultValue = (
      <option value="00000000-0000-0000-0000-000000000000">Please select a value</option>
    );

    const specificInjuriesOptions = givenItem.specificInjuries.map((item) => (
      <option key={item.publicId} value={item.publicId}>
        {item.specificInjuryName}
      </option>
    ));

    setSpecificInjuryOptions([defaultValue, specificInjuriesOptions]);
  };

  const handleSpecificInjuryChange = (e, index) => {
    const { name, value } = e.target;
    //   setSelectedSpecificInjuryPublicId(value);

    const list = [...selectedInjuryList];
    list[index][name] = value;
    setSelectedInjuryList(list);
  };

  useEffect(() => {
    setValues();
  }, [selectedInjuryList[i]?.genericInjuryPublicId]);

  useEffect(() => {
    setValues();
  }, [injuriesList]);

  const setValues = () => {
    if (!injuriesList) return;

    const givenItem = injuriesList.find(
      (item) => item.publicId === selectedInjuryList[i]?.genericInjuryPublicId
    );

    if (!givenItem) return;

    const defaultValue = (
      <option value="00000000-0000-0000-0000-000000000000">Please select a value</option>
    );

    const spInjOptions = givenItem.specificInjuries.map((item) => (
      <option key={item.publicId} value={item.publicId}>
        {item.specificInjuryName}
      </option>
    ));

    setSpecificInjuryOptions([defaultValue, spInjOptions]);
  };

  //if (selectedInjuryList !== undefined) {
  return (
    <div className="form-group mb-5 form-inline" key={i}>
      <div className="form-group">
        <select
          key={i + 100}
          name="genericInjuryPublicId"
          className="form-control mr-5"
          //ref={genericInjuryRef}
          placeholder="generic injury here"
          //  defaultValue={selectedGenericInjuryPublicId}
          value={selectedInjuryList[i]?.['genericInjuryPublicId']}
          onChange={(e) => handleGenericInjuryChange(e, i)}
        >
          {genericInjuryOptions}
        </select>
      </div>
      <div className="form-group">
        {/* {specificInjuryOptions?.length > 0 ? ( */}
        <select
          key={i + 200}
          name="specificInjuryPublicId"
          className="form-control"
          placeholder="specific injury here"
          // defaultValue={selectedSpecificInjuryPublicId}
          value={selectedInjuryList[i]?.['specificInjuryPublicId']}
          onChange={(e) => handleSpecificInjuryChange(e, i)}
        >
          {specificInjuryOptions}
        </select>
        {/* ) : (
            'No specific injury found in this category'
          )} */}
      </div>

      {selectedInjuryList && (
        <i
          class="flaticon-circle text-secondary ml-2"
          onClick={() => handleRemoveClick(i)}
          style={{ cursor: 'pointer' }}
        ></i>
      )}
    </div>
  );
  // } else {
  //   return null;
  // }
};

export default MultipleSelect;
