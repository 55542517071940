import axios from 'axios';
import { auth, resError } from '@services/Helpers/ServiceInterceptor';

const api = axios.create({
    baseURL: process.env.REACT_APP_API_URL + '/systemroles/',
});

api.interceptors.request.use((config) => auth(config));
api.interceptors.response.use(
  (response) => {
    return response;
  },
  (error) => resError(error)
);

class SystemRolesService {

    constructor() {
        if (!SystemRolesService.instance) {
            SystemRolesService.instance = this;
        }
        return SystemRolesService.instance;
    };

    search = (filters, orderBy, orderDir, p, fields, perPage) => {
        return api.post('/search', { filters: Object.keys(filters).length > 0 ? filters : null, orderBy, orderDir, page: p, fields: undefined, perPage: parseInt(perPage) });
    };

    search1 = (filters, orderBy, orderDir, p, fields, perPage, userRoles) => {
        return api.post('/search', { filters: Object.keys(filters).length > 0 ? filters : { publicGuids: userRoles }, orderBy, orderDir, page: p, fields: undefined, perPage: parseInt(perPage) })
    };

    search2 = (search = '', page, perPage, orderBy, orderDir, filters = {}) => {
        return api.post('/search', {
            search: search && search != '' ? search : '',
            page: page,
            perPage: perPage,
            orderBy: orderBy,
            orderDir: orderDir,
            filters: Object.keys(filters).length > 0 ? filters : null
        });
    };

    getAllPermittedTask = () => {
        return api.get('/getllpermittedtasks');
    };

    create = (entity) => {
        return api.post('/', entity);
    };

    read = (id) => {
        return api.get('/' + id);
    };

    readAll = () => {
        return api.get('/');
    };

    update = (entity) => {
        return api.put('/', entity);
    };

    delete = (id) => {
        return api.delete('/' + id);
    };

}

const instance = new SystemRolesService();
Object.freeze(instance);

export default instance;