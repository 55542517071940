import React, { useEffect, useContext } from 'react';
import { useHistory, Link } from 'react-router-dom';
import Grid from './UsersGrid';
import { Row, Col } from 'reactstrap';
import { toast } from 'react-toastify';

import AppContext from '@contexts/AppContext';

const Users = () => {
  const appContext = useContext(AppContext);
  const history = useHistory();

  const makeMenuItemActive = () => {
    document.querySelector('#menuitem-settings').click();
    document
      .querySelector('#kt_header_tab_8 > div > div > ul > li:nth-child(1)')
      .classList.add('menu-item-active');
  };

  useEffect(() => makeMenuItemActive(), []);

  useEffect(() => {
    if (appContext.toastMessage.message) {
      toast.success(appContext.toastMessage.message, { autoClose: 3000 });
      appContext.toastMessage.message = null;
    }
  });

  return (
    <div className="container mt-10 mb-10">
      <div className="row">
        <div className="col-md-12 bg-white box-shadow-border">
          <div className="p-10">
            <div className="d-flex mt-5 mb-10">
              <div className="mr-auto">
                <h1>Users</h1>
              </div>
              <Link
                to="/settings/users/new-user"
                className="btn btn-primary"
                style={{
                  backgroundColor: 'var(--green)',
                  borderColor: 'var(--green)',
                }}
              >
                New User
              </Link>
            </div>

            <Row>
              <Col md="12">
                <Grid />
              </Col>
            </Row>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Users;
