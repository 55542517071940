import React from 'react';
import Autosuggest from 'react-autosuggest';
import PortfolioService from '@services/PortfolioService';

class PortfolioAutoSuggest extends React.Component {
  constructor(props) {
    super(props);
    this.props = props;
    this.state = {
      value: '',
      suggestions: [],
      isAdvance: false,
    };
  }

  getSuggestionValue = (suggestion) => {
    this.props.selectedPortfolio(suggestion.publicId);
    return suggestion.portfolioName;
  };

  renderSuggestion = (suggestion) => {
    return <span>{suggestion.portfolioName}</span>;
  };

  onChange = (event, { newValue, method }) => {
    this.setState({
      value: newValue,
    });
  };

  onSuggestionsFetchRequested = ({ value }) => {
    PortfolioService.autoSuggest(value, this.props.isAdvance).then((response) => {
      this.setState({ suggestions: response.data.data });
    });
  };

  onSuggestionsClearRequested = () => {
    this.setState({
      suggestions: [],
    });
  };

  componentDidMount = () => {
    this.setState({
      suggestions: [],
      value: this.props.defaultPortfolio,
      isAdvance: this.props.isAdvance,
    });
  };

  render() {
    const { value, suggestions, isAdvance } = this.state;
    const inputProps = {
      placeholder: 'Search Portfolio ...',
      value,
      isAdvance,
      onChange: this.onChange,
    };

    return (
      <Autosuggest
        suggestions={suggestions}
        onSuggestionsFetchRequested={this.onSuggestionsFetchRequested}
        onSuggestionsClearRequested={this.onSuggestionsClearRequested}
        getSuggestionValue={this.getSuggestionValue}
        renderSuggestion={this.renderSuggestion}
        inputProps={inputProps}
        ref={this.props.internalRef}
      />
    );
  }
}

export default PortfolioAutoSuggest;
