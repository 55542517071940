import React, { useEffect, useState, useContext, Fragment, useRef } from 'react';
import { Row, Col, Input, Label, Alert, Nav, NavItem, NavLink, Modal, ModalHeader, Button, Form, FormGroup, TabContent, TabPane, Container } from 'reactstrap';
import { useParams, Link } from 'react-router-dom';
import { useHistory } from 'react-router';
import UsZipCodeService from '@services/UsZipCodeService';
import StateSelect from '@components/StateSelect';
import AppContext from '@contexts/AppContext';
import ReactLoading from 'react-loading';
import LawFirmService from '../../../../services/LawFirmService';
import LawFirmsAdvancesTable from '../Advances/LawFirmsAdvancesTable';

import Notes from '../Notes';
import { useForm } from 'react-hook-form';
import { ErrorMessage } from '@hookform/error-message';
import { toast } from 'react-toastify';
import classnames from 'classnames';

const LawFirm = () => {
  const { id } = useParams();
  const [successMessage, setSuccessMessage] = useState('');
  const [errorMessage, setErrorMessage] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [activeTab, setActiveTab] = useState('1');
  const appContext = useContext(AppContext);
  const { register, handleSubmit, watch, errors, formState } = useForm({ mode: 'onBlur' });
  const [lawFirm, setLawFirm] = useState({
    publicId: '',
    lawFirmName: '',
    streetAddress: '',
    city: '',
    stateCode: '',
    zipCode: '',
    workingPhoneNo: '',
    tollFreeNo: '',
    isActive: true,
    website: '',
    notes: '',
  });

  const history = useHistory();

  const toggle = (tab) => {
    // if (activeTab !== tab) {
    setActiveTab(tab);
    // }
  }

  useEffect(() => {
    toggle('1');

    const makeMenuItemActive = () => {
      document.querySelector('#menuitem-contacts').click();
    };

    makeMenuItemActive();

    LoadLawFirmDetailsIfIdPresent();

  }, []);

  const LoadLawFirmDetailsIfIdPresent = () => {

    if (id) {
      setIsLoading(true);

      LawFirmService.read(id)
        .then((response) => {
          if (response.data) {
            setLawFirm(response.data);
            setIsLoading(false);
          }
        })
        .catch((err) => {

        })
        .finally(() => {
          setIsLoading(false);
        });
    }
  };

  const handleFormSubmitted = (data) => {
    setIsLoading(true);

    if (!id) {
      LawFirmService.create(data)
        .then((response) => {
          appContext.toastMessage.message = 'Law Firm has been successfully saved.';
          setIsLoading(false);
          history.push('/contacts/law-firms');
        })
        .catch((err) => {

        })
        .finally(() => {
          setIsLoading(false);
        });
    } else {

      if (!(appContext?.userPermissions?.indexOf('Edit Law Firms') > -1)) {
        appContext.toastMessage.message = 'You do not have permissions to perform this action.';
        toast.error(appContext.toastMessage.message, { autoClose: 3000 });
        appContext.toastMessage.message = null;
        return;
      }

      data.publicId = lawFirm.publicId;

      LawFirmService.update(data)
        .then((response) => {
          appContext.toastMessage.message = 'Law Firm has been successfully updated.';
          setIsLoading(false);
          history.push('/contacts/law-firms');
        })
        .catch((err) => {

        })
        .finally(() => {
          setIsLoading(false);
        });
    }
  };

  const handleCancelButtonClicked = () => {
    history.push('/contacts/law-firms');
  };

  const form = useRef();

  const hideForm = () => {
    form.current.style.display = 'none';
  };

  const getStateFromZipCode = (event) => {
    if (event.target.value.length > 4) {
      var filters = {};
      filters.zipCode = event.target.value;

      UsZipCodeService.search(filters)
        .then((response) => {
          let newLawFirm = { ...lawFirm };
          newLawFirm.county = response.data?.data[0]?.countyName;
          setLawFirm(newLawFirm);
        })
        .catch((err) => {

        })
        .finally(() => {
          setIsLoading(false);
        });
    }
  };

  if (isLoading) {
    return (
      <div className="container mt-10 mb-10">
        <div className="row">
          <div className="col-md-12 mx-auto bg-white box-shadow-border">
            <div className="p-10">
              <div className="mt-5 mb-10">
                <ReactLoading type="bars" color="#7E8299" />
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }

  return (
    <Fragment>
      <Container className="mt-10 mb-10">
        <Row>
          <Col md="12" className="mx-auto bg-white box-shadow-border">
            <div className="p-10">
              <div className="mt-5 mb-10">
                <h1>{!id ? 'New Law Firm' : 'Law Firm Details'}</h1>
                <div>
                  {id &&
                    (
                      <>
                        <Nav tabs>
                          <NavItem>
                            <NavLink
                              className={classnames({ active: activeTab === '1' })}
                              onClick={() => { toggle('1'); }}
                            >
                              Details
                            </NavLink>
                          </NavItem>

                          <NavItem>
                            <NavLink
                              className={classnames({ active: activeTab === '2' })}
                              onClick={() => { toggle('2'); }}
                            >
                              Notes
                            </NavLink>
                          </NavItem>

                          <NavItem>
                            <NavLink
                              className={classnames({ active: activeTab === '3' })}
                              onClick={() => { toggle('3'); }}
                            >
                              Advances Totals
                            </NavLink>
                          </NavItem>

                        </Nav>

                        <TabContent activeTab={activeTab}>
                          <TabPane tabId="1">
                            <Row>
                              <Col md="7 pt-5 pb-5">
                                {successMessage && <Alert color="success">{successMessage}</Alert>}
                                {successMessage && hideForm()}
                                {errorMessage && (
                                  <Alert color="danger" className="mt-3">
                                    {errorMessage}
                                  </Alert>
                                )}
                              </Col>
                            </Row>
                            <Container className="formContainer p-0">
                              <form ref={form} onSubmit={handleSubmit(handleFormSubmitted)} noValidate>
                                <Row>
                                  <Col md="8">
                                    <Row>
                                      <Col md="7">
                                        <div className="form-group">
                                          <label htmlFor="lawFirmName">Law Firm Name</label>
                                          <input
                                            type="text"
                                            defaultValue={lawFirm.lawFirmName}
                                            name="lawFirmName"
                                            className="form-control"
                                            maxLength="100"
                                            ref={register({
                                              required: 'Law Firm Name is required.',
                                              maxLength: {
                                                value: 100,
                                                message: 'Max length is 100 characters.',
                                              },
                                            })}
                                          />
                                          {errors.lawFirmName && (
                                            <Alert color="danger" className="mt-5">
                                              <ErrorMessage errors={errors} name="lawFirmName" />
                                            </Alert>
                                          )}
                                        </div>
                                      </Col>
                                      <Col md="1">
                                        <div className="form-group">
                                          <Label>Active</Label>{' '}
                                          <input
                                            type="checkbox"
                                            {...(!id && { disabled: true })}
                                            defaultChecked={lawFirm.isActive}
                                            name="isActive"
                                            ref={register}
                                          />
                                          {errors && errors['IsActive'] && (
                                            <Alert color="danger" className="mt-3">
                                              {errors['IsActive']}
                                            </Alert>
                                          )}
                                        </div>
                                      </Col>
                                    </Row>

                                    <Row>
                                      <Col md="8">
                                        <div className="form-group">
                                          <label htmlFor="streetAddress">Address</label>
                                          <input
                                            type="text"
                                            defaultValue={lawFirm.streetAddress}
                                            name="streetAddress"
                                            className="form-control"
                                            maxLength="100"
                                            ref={register({
                                              required: 'Address is required',
                                              maxLength: {
                                                value: 100,
                                                maxLength: 'Max length is 100 characters.',
                                              },
                                            })}
                                          />
                                          {errors.streetAddress && (
                                            <Alert color="danger" className="mt-5">
                                              <ErrorMessage errors={errors} name="streetAddress" />
                                            </Alert>
                                          )}
                                        </div>
                                      </Col>
                                    </Row>

                                    <Row>
                                      <Col md="8">
                                        <div className="form-group">
                                          <label htmlFor="city">City</label>
                                          <input
                                            type="text"
                                            defaultValue={lawFirm.city}
                                            name="city"
                                            className="form-control"
                                            maxLength="100"
                                            ref={register({
                                              required: 'City is required',
                                              maxLength: {
                                                value: 30,
                                                maxLength: 'Max length is 30 characters.',
                                              },
                                            })}
                                          />
                                          {errors.city && (
                                            <Alert color="danger" className="mt-5">
                                              <ErrorMessage errors={errors} name="city" />
                                            </Alert>
                                          )}
                                        </div>
                                      </Col>
                                    </Row>

                                    <Row>
                                      <Col md="4">
                                        <StateSelect
                                          name="stateCode"
                                          label="State"
                                          useRef={register({ required: 'State is required.' })}
                                          defaultValue={lawFirm.stateCode}
                                          setObject={setLawFirm}
                                          givenObject={lawFirm}
                                          errors={errors}
                                        />
                                      </Col>

                                      <Col md="4">
                                        <div className="form-group">
                                          <label htmlFor="zipCode">Zip Code</label>
                                          <input
                                            type="text"
                                            inputMode="numeric"
                                            maxLength="10"
                                            name="zipCode"
                                            className="form-control"
                                            ref={register({
                                              required: 'Zip Code is required.',
                                              maxLength: { value: 10, message: 'Max length is 10 characters.' },
                                              pattern: {
                                                value: /^\d{5}(?:[-\s]\d{4})?$/,
                                                message: 'The US Zip code provided is wrong.',
                                              },
                                            })}
                                            onKeyUp={getStateFromZipCode}
                                            defaultValue={lawFirm.zipCode}
                                          />
                                          {errors.zipCode && (
                                            <Alert color="danger" className="mt-5">
                                              <ErrorMessage errors={errors} name="zipCode" />
                                            </Alert>
                                          )}
                                        </div>
                                      </Col>
                                    </Row>

                                    <Row>
                                      <Col md="4">
                                        <div className="form-group">
                                          <label htmlFor="workingPhoneNo">Phone</label>
                                          <input
                                            type="tel"
                                            name="workingPhoneNo"
                                            className="form-control"
                                            maxLength="20"
                                            ref={register({
                                              required: 'Phone is required.',
                                              maxLength: { value: 20, message: 'Max length is 20 characters.' },
                                            })}
                                            defaultValue={lawFirm.workingPhoneNo}
                                          />
                                          {errors.workingPhoneNo && (
                                            <Alert color="danger" className="mt-5">
                                              <ErrorMessage errors={errors} name="workingPhoneNo" />
                                            </Alert>
                                          )}
                                        </div>
                                      </Col>

                                      <Col md="4">
                                        <div className="form-group">
                                          <label htmlFor="tollFreeNo">Toll Free Number</label>
                                          <input
                                            type="text"
                                            defaultValue={lawFirm.tollFreeNo}
                                            name="tollFreeNo"
                                            className="form-control"
                                            maxLength="20"
                                            ref={register({
                                              required: 'Toll Free Number is required',
                                              maxLength: {
                                                value: 20,
                                                maxLength: 'Max length is 20 characters.',
                                              },
                                            })}
                                          />
                                          {errors.tollFreeNo && (
                                            <Alert color="danger" className="mt-5">
                                              <ErrorMessage errors={errors} name="tollFreeNo" />
                                            </Alert>
                                          )}
                                        </div>
                                      </Col>
                                    </Row>

                                    <Row>
                                      <Col md="8">
                                        <div className="form-group">
                                          <label htmlFor="website">Website</label>
                                          <input
                                            type="text"
                                            defaultValue={lawFirm.website}
                                            name="website"
                                            className="form-control"
                                            maxLength="100"
                                            ref={register({
                                              required: 'Website is required',
                                              maxLength: {
                                                value: 20,
                                                maxLength: 'Max length is 20 characters.',
                                              },
                                            })}
                                          />
                                          {errors.website && (
                                            <Alert color="danger" className="mt-5">
                                              <ErrorMessage errors={errors} name="website" />
                                            </Alert>
                                          )}
                                        </div>
                                      </Col>
                                    </Row>

                                    <Row>
                                      <Col md="8">
                                        <div className="form-group">
                                          <Label>Notes</Label>
                                          <textarea
                                            className="form-control"
                                            rows="8"
                                            name="notes"
                                            defaultValue={lawFirm.notes}
                                            ref={register}
                                          />
                                        </div>
                                      </Col>
                                    </Row>

                                    <Row>
                                      <Col md="8">
                                        <button type="submit" className="btn btn-primary mr-5">
                                          Save
                                        </button>
                                        <button
                                          type="button"
                                          onClick={handleCancelButtonClicked}
                                          className="btn btn-secondary"
                                        >
                                          Cancel
                                        </button>
                                      </Col>
                                    </Row>
                                  </Col>
                                </Row>
                              </form>
                            </Container>
                          </TabPane >

                          <TabPane tabId="2" >
                            <Notes />
                          </TabPane >

                          <TabPane tabId="3" >
                            <LawFirmsAdvancesTable />
                          </TabPane >
                        </TabContent >
                      </>
                    )
                  }

                  {!id && (
                      <form ref={form} onSubmit={handleSubmit(handleFormSubmitted)} noValidate>
                      <Row>
                        <Col md="8">
                          <Row>
                            <Col md="7">
                              <div className="form-group">
                                <label htmlFor="lawFirmName">Law Firm Name</label>
                                <input
                                  type="text"
                                  defaultValue={lawFirm.lawFirmName}
                                  name="lawFirmName"
                                  className="form-control"
                                  maxLength="100"
                                  ref={register({
                                    required: 'Law Firm Name is required.',
                                    maxLength: {
                                      value: 100,
                                      message: 'Max length is 100 characters.',
                                    },
                                  })}
                                />
                                {errors.lawFirmName && (
                                  <Alert color="danger" className="mt-5">
                                    <ErrorMessage errors={errors} name="lawFirmName" />
                                  </Alert>
                                )}
                              </div>
                            </Col>
                            <Col md="1">
                              <div className="form-group">
                                <Label>Active</Label>{' '}
                                <input
                                  type="checkbox"
                                  {...(!id && { disabled: true })}
                                  defaultChecked={lawFirm.isActive}
                                  name="isActive"
                                  ref={register}
                                />
                                {errors && errors['IsActive'] && (
                                  <Alert color="danger" className="mt-3">
                                    {errors['IsActive']}
                                  </Alert>
                                )}
                              </div>
                            </Col>
                          </Row>

                          <Row>
                            <Col md="8">
                              <div className="form-group">
                                <label htmlFor="streetAddress">Address</label>
                                <input
                                  type="text"
                                  defaultValue={lawFirm.streetAddress}
                                  name="streetAddress"
                                  className="form-control"
                                  maxLength="100"
                                  ref={register({
                                    required: 'Address is required',
                                    maxLength: {
                                      value: 100,
                                      maxLength: 'Max length is 100 characters.',
                                    },
                                  })}
                                />
                                {errors.streetAddress && (
                                  <Alert color="danger" className="mt-5">
                                    <ErrorMessage errors={errors} name="streetAddress" />
                                  </Alert>
                                )}
                              </div>
                            </Col>
                          </Row>

                          <Row>
                            <Col md="8">
                              <div className="form-group">
                                <label htmlFor="city">City</label>
                                <input
                                  type="text"
                                  defaultValue={lawFirm.city}
                                  name="city"
                                  className="form-control"
                                  maxLength="100"
                                  ref={register({
                                    required: 'City is required',
                                    maxLength: {
                                      value: 30,
                                      maxLength: 'Max length is 30 characters.',
                                    },
                                  })}
                                />
                                {errors.city && (
                                  <Alert color="danger" className="mt-5">
                                    <ErrorMessage errors={errors} name="city" />
                                  </Alert>
                                )}
                              </div>
                            </Col>
                          </Row>

                          <Row>
                            <Col md="4">
                              <StateSelect
                                name="stateCode"
                                label="State"
                                useRef={register({ required: 'State is required.' })}
                                defaultValue={lawFirm.stateCode}
                                setObject={setLawFirm}
                                givenObject={lawFirm}
                                errors={errors}
                              />
                            </Col>

                            <Col md="4">
                              <div className="form-group">
                                <label htmlFor="zipCode">Zip Code</label>
                                <input
                                  type="text"
                                  inputMode="numeric"
                                  maxLength="10"
                                  name="zipCode"
                                  className="form-control"
                                  ref={register({
                                    required: 'Zip Code is required.',
                                    maxLength: { value: 10, message: 'Max length is 10 characters.' },
                                    pattern: {
                                      value: /^\d{5}(?:[-\s]\d{4})?$/,
                                      message: 'The US Zip code provided is wrong.',
                                    },
                                  })}
                                  onKeyUp={getStateFromZipCode}
                                  defaultValue={lawFirm.zipCode}
                                />
                                {errors.zipCode && (
                                  <Alert color="danger" className="mt-5">
                                    <ErrorMessage errors={errors} name="zipCode" />
                                  </Alert>
                                )}
                              </div>
                            </Col>
                          </Row>

                          <Row>
                            <Col md="4">
                              <div className="form-group">
                                <label htmlFor="workingPhoneNo">Phone</label>
                                <input
                                  type="tel"
                                  name="workingPhoneNo"
                                  className="form-control"
                                  maxLength="20"
                                  ref={register({
                                    required: 'Phone is required.',
                                    maxLength: { value: 20, message: 'Max length is 20 characters.' },
                                  })}
                                  defaultValue={lawFirm.workingPhoneNo}
                                />
                                {errors.workingPhoneNo && (
                                  <Alert color="danger" className="mt-5">
                                    <ErrorMessage errors={errors} name="workingPhoneNo" />
                                  </Alert>
                                )}
                              </div>
                            </Col>

                            <Col md="4">
                              <div className="form-group">
                                <label htmlFor="tollFreeNo">Toll Free Number</label>
                                <input
                                  type="text"
                                  defaultValue={lawFirm.tollFreeNo}
                                  name="tollFreeNo"
                                  className="form-control"
                                  maxLength="20"
                                  ref={register({
                                    required: 'Toll Free Number is required',
                                    maxLength: {
                                      value: 20,
                                      maxLength: 'Max length is 20 characters.',
                                    },
                                  })}
                                />
                                {errors.tollFreeNo && (
                                  <Alert color="danger" className="mt-5">
                                    <ErrorMessage errors={errors} name="tollFreeNo" />
                                  </Alert>
                                )}
                              </div>
                            </Col>
                          </Row>

                          <Row>
                            <Col md="8">
                              <div className="form-group">
                                <label htmlFor="website">Website</label>
                                <input
                                  type="text"
                                  defaultValue={lawFirm.website}
                                  name="website"
                                  className="form-control"
                                  maxLength="100"
                                  ref={register({
                                    required: 'Website is required',
                                    maxLength: {
                                      value: 20,
                                      maxLength: 'Max length is 20 characters.',
                                    },
                                  })}
                                />
                                {errors.website && (
                                  <Alert color="danger" className="mt-5">
                                    <ErrorMessage errors={errors} name="website" />
                                  </Alert>
                                )}
                              </div>
                            </Col>
                          </Row>

                          <Row>
                            <Col md="8">
                              <div className="form-group">
                                <Label>Notes</Label>
                                <textarea
                                  className="form-control"
                                  rows="8"
                                  name="notes"
                                  defaultValue={lawFirm.notes}
                                  ref={register}
                                />
                              </div>
                            </Col>
                          </Row>

                          <Row>
                            <Col md="8">
                              <button type="submit" className="btn btn-primary mr-5">
                                Save
                              </button>
                              <button
                                type="button"
                                onClick={handleCancelButtonClicked}
                                className="btn btn-secondary"
                              >
                                Cancel
                              </button>
                            </Col>
                          </Row>
                        </Col>
                      </Row>
                    </form>
                  )}
                </div>
              </div>
            </div>
          </Col>
        </Row>
      </Container>
    </Fragment>
  );
};
export default LawFirm;
