import React, { useState, useEffect, useContext } from 'react';
import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import { useHistory, Link, withRouter } from 'react-router-dom';

import { makeStyles, useTheme, withStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableFooter from '@material-ui/core/TableFooter';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import Pagination from '@material-ui/lab/Pagination';
import EditIcon from '@material-ui/icons/Edit';
import DeleteIcon from '@material-ui/icons/Delete';
import BrokerService from '@services/BrokerService';
import AppContext from '@contexts/AppContext';
import TableSortLabel from '@material-ui/core/TableSortLabel';
import ReactLoading from 'react-loading';
import { toast } from 'react-toastify';

const StyledTableCell = withStyles((theme) => ({
  head: {
    backgroundColor: '#efefef',
    color: theme.palette.common.black,
  },
  body: {
    fontSize: 14,
  },
}))(TableCell);

const useStyles2 = makeStyles({
  table: {
    minWidth: 500,
  },
});

const BrokersGrid = () => {
  const classes = useStyles2();
  const [page, setPage] = React.useState(1);
  const [rowsPerPage, setRowsPerPage] = React.useState(15);
  const [filters, setFilters] = useState({});
  const [orderBy, setOrderBy] = useState('');
  const [orderDir, setOrderDir] = useState('ASC');
  const [rows, setRows] = useState([]);
  const [modal, setModal] = useState(false);
  const [rowIndex, setRowIndex] = useState(undefined);
  const [givenRows, setGivenRows] = useState(undefined);
  const [isLoading, setIsLoading] = useState(false);
  const [pages, setPages] = useState(undefined);
  const [activeFieldForSorting, setActiveFieldForSorting] = useState();

  const history = useHistory();
  const toggle = () => setModal(!modal);
  const appContext = useContext(AppContext);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  useEffect(() => {
    update();
  }, [page, orderBy, orderDir, rowsPerPage]);

  useEffect(() => {
    setPage(1);
  }, []);

  const update = () => {
    setIsLoading(true);

    BrokerService.search(filters, orderBy, orderDir, page, null, rowsPerPage)
      .then((response) => {
        setRows(response.data.data);
        setPages(response.data.pages.slice(-1)[0]);
        setIsLoading(false);
      })
      .catch((err) => {})
      .finally(() => {
        setIsLoading(false);
      });
  };

  const searchColumn = (event, columnName) => {
    var newFilters = filters;
    if (columnName === 'isActive' && event.target.value != 'All') {
      newFilters[columnName] = event.target.value === 'true' ? true : false;
    } else if (columnName === 'isActive' && event.target.value === 'All') {
      newFilters[columnName] = undefined;
    } else {
      newFilters[columnName] = event.target.value;
    }
    setFilters(newFilters);
    update();
  };

  const deleteItem = () => {
    if (!(appContext?.userPermissions?.indexOf('Delete Brokers') > -1)) {
      toggle();
      appContext.toastMessage.message = 'You do not have permissions to perform this action.';
      toast.error(appContext.toastMessage.message, { autoClose: 3000 });
      appContext.toastMessage.message = null;
      return;
    }

    setIsLoading(true);

    BrokerService.delete(givenRows[rowIndex].publicId)
      .then((result) => {
        let items = [...givenRows];
        items.splice(rowIndex, 1);
        setRows(items);
        toggle();
        appContext.toastMessage.message = 'Broker has been successfully deleted.';
        toast.success(appContext.toastMessage.message, { autoClose: 3000 });
        appContext.toastMessage.message = null;
      })
      .catch((err) => {})
      .finally(() => {
        setIsLoading(false);
      });
  };

  const arrange = (field) => {
    if (orderBy === field) {
      if (orderDir === 'ASC') {
        setOrderDir('DESC');
      } else {
        setOrderDir('ASC');
      }
    } else {
      setOrderBy(field);
      setOrderDir('ASC');
    }

    setPage(1);
  };

  const handlePageSizeChange = (event) => {
    setRowsPerPage(event.target.value);
  };

  return (
    <div>
      <div>
        <Modal isOpen={modal} toggle={toggle}>
          <ModalHeader toggle={toggle}>Confirmation</ModalHeader>
          <ModalBody>Are you sure you want to delete this broker?</ModalBody>
          <ModalFooter>
            <Button color="primary" onClick={deleteItem}>
              Delete
            </Button>
            <Button color="secondary" onClick={toggle}>
              Cancel
            </Button>
          </ModalFooter>
        </Modal>
      </div>

      <TableContainer component={Paper}>
        <Table className={classes.table} aria-label="custom pagination table">
          <TableHead>
            <TableRow>
              <StyledTableCell>Id</StyledTableCell>

              <StyledTableCell
                onClick={() => {
                  arrange('companyName');
                  setActiveFieldForSorting('companyName');
                }}
              >
                COMPANY NAME
                <TableSortLabel
                  active={activeFieldForSorting === 'companyName' ? true : false}
                  direction={
                    activeFieldForSorting === 'companyName' ? orderDir.toLowerCase() : 'asc'
                  }
                  onClick={() => arrange('companyName')}
                ></TableSortLabel>
              </StyledTableCell>

              <StyledTableCell
                onClick={() => {
                  arrange('referralFee');
                  setActiveFieldForSorting('referralFee');
                }}
              >
                REF. FEE
                <TableSortLabel
                  active={activeFieldForSorting === 'referralFee' ? true : false}
                  direction={
                    activeFieldForSorting === 'referralFee' ? orderDir.toLowerCase() : 'asc'
                  }
                  onClick={() => arrange('referralFee')}
                ></TableSortLabel>
              </StyledTableCell>

              <StyledTableCell
                onClick={() => {
                  arrange('buyoutComm');
                  setActiveFieldForSorting('buyoutComm');
                }}
              >
                BUYOUT COM.
                <TableSortLabel
                  active={activeFieldForSorting === 'buyoutComm' ? true : false}
                  direction={
                    activeFieldForSorting === 'buyoutComm' ? orderDir.toLowerCase() : 'asc'
                  }
                  onClick={() => arrange('buyoutComm')}
                ></TableSortLabel>
              </StyledTableCell>

              <StyledTableCell
                onClick={() => {
                  arrange('displayName');
                  setActiveFieldForSorting('displayName');
                }}
              >
                CONTACT NAME
                <TableSortLabel
                  active={activeFieldForSorting === 'displayName' ? true : false}
                  direction={
                    activeFieldForSorting === 'displayName' ? orderDir.toLowerCase() : 'asc'
                  }
                  onClick={() => arrange('displayName')}
                ></TableSortLabel>
              </StyledTableCell>

              <StyledTableCell
                onClick={() => {
                  arrange('emailAddress');
                  setActiveFieldForSorting('emailAddress');
                }}
              >
                EMAIL
                <TableSortLabel
                  active={activeFieldForSorting === 'emailAddress' ? true : false}
                  direction={
                    activeFieldForSorting === 'emailAddress' ? orderDir.toLowerCase() : 'asc'
                  }
                  onClick={() => arrange('emailAddress')}
                ></TableSortLabel>
              </StyledTableCell>

              <StyledTableCell
                onClick={() => {
                  arrange('workPhoneNo');
                  setActiveFieldForSorting('workPhoneNo');
                }}
              >
                PHONE
                <TableSortLabel
                  active={activeFieldForSorting === 'workPhoneNo' ? true : false}
                  direction={
                    activeFieldForSorting === 'workPhoneNo' ? orderDir.toLowerCase() : 'asc'
                  }
                  onClick={() => arrange('workPhoneNo')}
                ></TableSortLabel>
              </StyledTableCell>

              <StyledTableCell
                onClick={() => {
                  arrange('isActive');
                  setActiveFieldForSorting('isActive');
                }}
              >
                STATUS
                <TableSortLabel
                  active={activeFieldForSorting === 'isActive' ? true : false}
                  direction={activeFieldForSorting === 'isActive' ? orderDir.toLowerCase() : 'asc'}
                  onClick={() => arrange('isActive')}
                ></TableSortLabel>
              </StyledTableCell>

              <StyledTableCell></StyledTableCell>
              <StyledTableCell></StyledTableCell>
            </TableRow>
            <TableRow>
              <StyledTableCell>
                <input
                  type="search"
                  className="form-control"
                  placeholder="Search..."
                  onChange={(e) => searchColumn(e, 'id')}
                />
              </StyledTableCell>
              <StyledTableCell>
                <input
                  type="search"
                  className="form-control"
                  placeholder="Search..."
                  onChange={(e) => searchColumn(e, 'companyName')}
                />
              </StyledTableCell>
              <StyledTableCell>
                <input
                  type="search"
                  className="form-control"
                  placeholder="Search..."
                  onChange={(e) => searchColumn(e, 'referralFee')}
                />
              </StyledTableCell>
              <StyledTableCell>
                <input
                  type="search"
                  className="form-control"
                  placeholder="Search..."
                  onChange={(e) => searchColumn(e, 'buyoutComm')}
                />
              </StyledTableCell>
              <StyledTableCell>
                <input
                  type="search"
                  className="form-control"
                  placeholder="Search..."
                  onChange={(e) => searchColumn(e, 'displayName')}
                />
              </StyledTableCell>
              <StyledTableCell>
                <input
                  type="search"
                  className="form-control"
                  placeholder="Search..."
                  onChange={(e) => searchColumn(e, 'emailAddress')}
                />
              </StyledTableCell>
              <StyledTableCell>
                <input
                  type="search"
                  className="form-control"
                  placeholder="Search..."
                  onChange={(e) => searchColumn(e, 'workPhoneNo')}
                />
              </StyledTableCell>
              <StyledTableCell>
                <select className="form-control" onChange={(e) => searchColumn(e, 'isActive')}>
                  <option value={undefined}>All</option>
                  <option value={true}>Active</option>
                  <option value={false}>Inactive</option>
                </select>
              </StyledTableCell>
              <StyledTableCell></StyledTableCell>
              <StyledTableCell></StyledTableCell>
            </TableRow>
          </TableHead>
          {isLoading === false && (
            <TableBody>
              {rows &&
                rows.map((row, index) => (
                  <TableRow key={index}>
                    <TableCell component="th" scope="row">
                      {row.id}
                    </TableCell>
                    <TableCell>{row.companyName}</TableCell>
                    <TableCell>{row.referralFee}</TableCell>
                    <TableCell>{row.buyoutComm}</TableCell>
                    <TableCell>{row.displayName}</TableCell>
                    <TableCell>{row.emailAddress}</TableCell>
                    <TableCell>{row.workPhoneNo}</TableCell>
                    <TableCell>
                      {row.isActive === true ? (
                        <span className="text-success">Active</span>
                      ) : (
                        <span className="text-danger">Inactive</span>
                      )}
                    </TableCell>
                    <TableCell className="p-2">
                      <a className="btn float-right" href={'brokers/' + row.publicId}>
                        <EditIcon />
                      </a>
                    </TableCell>

                    <TableCell className="p-2">
                      <a
                        className="btn float-left"
                        onClick={() => {
                          setRowIndex(index);
                          setGivenRows(rows);
                          toggle();
                        }}
                      >
                        <DeleteIcon style={{ color: 'var(--red)' }} />
                      </a>
                    </TableCell>
                  </TableRow>
                ))}
            </TableBody>
          )}
          {isLoading && (
            <TableBody>
              <TableRow>
                <td></td>
                <td>
                  <ReactLoading type="bars" color="#7E8299" />
                </td>
                <td></td>
              </TableRow>
            </TableBody>
          )}

          <TableFooter></TableFooter>
        </Table>
      </TableContainer>
      <div className="float-left mt-5">
        <select onChange={handlePageSizeChange} className="form-control" defaultValue={15}>
          {[5, 10, 15, 25, 100].map((size) => (
            <option key={size} value={size}>
              {size}
            </option>
          ))}
        </select>
      </div>
      <div className="float-right mt-5">
        <Pagination
          count={pages}
          page={page}
          siblingCount={1}
          boundaryCount={1}
          variant="outlined"
          onChange={handleChangePage}
        />
      </div>
    </div>
  );
};

export default withRouter(BrokersGrid);
