import React, { useEffect, useRef } from 'react';

import { Link, withRouter, useHistory, useLocation } from 'react-router-dom';
import lasurisLogo from '../../images/lasuris_logo.png';

const Menu = () => {
  const history = useHistory();
  const location = useLocation();
  let reportFilter = useRef();

  useEffect(() => {
    const removeActiveClassFromHeaderTopLinks = () => {
      document.querySelectorAll('ul li.nav-item a').forEach((item) => {
        item.classList.remove('active');
      });
    };

    const removeActiveClassFromHeaderBottomLinks = () => {
      document.querySelectorAll('div.header-bottom ul li.menu-item').forEach((item) => {
        item.classList.remove('menu-item-active');
      });
    };

    document.querySelectorAll('ul li.nav-item a').forEach((item) => {
      item.addEventListener('click', () => {
        removeActiveClassFromHeaderTopLinks();
        item.classList.add('active');
      });
    });

    document.querySelectorAll('div.header-bottom ul li.menu-item').forEach((item) => {
      item.addEventListener('click', () => {
        removeActiveClassFromHeaderBottomLinks();
        item.classList.add('menu-item-active');
      });
    });
  });
  useEffect(() => {
    if (history.location.pathname.includes('reports/account-manager-details')) {
      reportFilter.current.value = 'account-manager-details';
    } else if (history.location.pathname.includes('reports/account-manager-summary')) {
      reportFilter.current.value = 'account-manager-summary';
    } else if (history.location.pathname.includes('reports/attorney-data')) {
      reportFilter.current.value = 'attorney-data';
    } else if (history.location.pathname.includes('reports/financial-center')) {
      reportFilter.current.value = 'financial-center';
    } else if (history.location.pathname.includes('reports/financial-summary')) {
      reportFilter.current.value = 'financial-summary';
    } else if (history.location.pathname.includes('reports/law-firm-details')) {
      reportFilter.current.value = 'law-firm-details';
    } else if (history.location.pathname.includes('reports/law-firm-summary')) {
      reportFilter.current.value = 'law-firm-summary';
    } else if (history.location.pathname.includes('reports/payoff-details')) {
      reportFilter.current.value = 'payoff-details';
    } else if (history.location.pathname.includes('reports/payoff-static-pool-analysis')) {
      reportFilter.current.value = 'payoff-static-pool-analysis';
    } else if (history.location.pathname.includes('reports/types-of-accidents-summary')) {
      reportFilter.current.value = 'types-of-accidents-summary';
    } else if (history.location.pathname.includes('reports/case-status-update-activity')) {
      reportFilter.current.value = 'case-status-update-activity';
    }
  }, []);
  const removeActiveFromAllTabs = () => {
    const tabs = document.querySelectorAll('div.tab-pane');

    tabs.forEach((tab) => {
      tab.classList.remove('active');
    });
  };

  const clickedMenuItem = (itemNumber) => {
    removeActiveFromAllTabs();
    const tabId = `#kt_header_tab_${itemNumber}`;
    document.querySelector(tabId).classList.add('active');
  };

  const removeMobileMenuItems = () => {
    const tabs = document.querySelectorAll('div.tab-pane');

    tabs.forEach((tab) => {
      tab.style.display = 'none';
    });
  };

  const clickedMobileMenuItem = (itemNumber) => {
    removeMobileMenuItems();

    const tabId = `#kt_mobile_tab_${itemNumber}`;
    const selectedMenuItem = document.querySelector(tabId);
    selectedMenuItem.style.display = 'block';
  };

  const reportSelectChanged = (event) => {
    if (event.target.value !== 'Select a Report') {
      const redirectUrl = `/reports/${event.target.value}`;
      history.push(redirectUrl);
      hideMobileMenuCard();
    }
  };

  if (
    location.pathname.match('/login') ||
    location.pathname.match('/resetpassword') ||
    location.pathname.match('/attorneycasestatus') ||
    location.pathname.match('/forgotpassword')
  ) {
    return null;
  }

  const hideUserProfileCard = () => {
    const userProfile = document.querySelector('#kt_quick_user');
    userProfile.style.display = 'none';
  };

  const hideMobileMenuCard = () => {
    const userProfile = document.querySelector('#kt_mobile_header_menu');
    userProfile.style.display = 'none';
  };

  const showUserProfileCard = () => {
    const userProfile = document.querySelector('#kt_quick_user');
    userProfile.style.display = 'block';

    hideMobileMenuCard();
  };

  const showMobileMenuCard = () => {
    const menu = document.querySelector('#kt_mobile_header_menu');
    menu.style.display = 'block';
  };

  const navigateToProfile = () => {
    hideUserProfileCard();
    history.push('/profile');
  };

  const user = JSON.parse(localStorage.getItem('lasuris-user'));

  return (
    <>
      <header>
        <div id="kt_header" className="header flex-column header-fixed">
          <div id="kt_header_mobile" class="header-mobile bg-primary  header-mobile-fixed ">
            <a href="/">
              <img alt="Logo" src={lasurisLogo} classname="max-h-30px" />
            </a>

            <div class="d-flex align-items-center">
              <button
                class="btn p-0 burger-icon burger-icon-left ml-4"
                id="kt_header_mobile_toggle"
                onClick={showMobileMenuCard}
              >
                <span></span>
              </button>

              <button
                class="btn p-0 ml-2"
                id="kt_header_mobile_topbar_toggle"
                onClick={showUserProfileCard}
              >
                <span class="svg-icon svg-icon-xl">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="24px"
                    height="24px"
                    viewBox="0 0 24 24"
                    version="1.1"
                  >
                    <g stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
                      <polygon points="0 0 24 0 24 24 0 24"></polygon>
                      <path
                        d="M12,11 C9.790861,11 8,9.209139 8,7 C8,4.790861 9.790861,3 12,3 C14.209139,3 16,4.790861 16,7 C16,9.209139 14.209139,11 12,11 Z"
                        fill="#000000"
                        fill-rule="nonzero"
                        opacity="0.3"
                      ></path>
                      <path
                        d="M3.00065168,20.1992055 C3.38825852,15.4265159 7.26191235,13 11.9833413,13 C16.7712164,13 20.7048837,15.2931929 20.9979143,20.2 C21.0095879,20.3954741 20.9979143,21 20.2466999,21 C16.541124,21 11.0347247,21 3.72750223,21 C3.47671215,21 2.97953825,20.45918 3.00065168,20.1992055 Z"
                        fill="#000000"
                        fill-rule="nonzero"
                      ></path>
                    </g>
                  </svg>
                </span>{' '}
              </button>
            </div>
          </div>

          <div className="header-top">
            <div className="container">
              <div className="d-none d-lg-flex align-items-center mr-3">
                <Link to="/" className="mr-10">
                  <img alt="Logo" src={lasurisLogo} />
                </Link>

                <ul className="header-tabs nav align-self-end" role="tablist">
                  <li className="nav-item">
                    <a
                      href="##"
                      onClick={() => clickedMenuItem(1)}
                      id="menuitem-home"
                      className="nav-link py-4 px-6"
                      data-toggle="tab"
                      data-target="#kt_header_tab_1"
                      role="tab"
                    >
                      Home
                    </a>
                  </li>

                  <li className="nav-item mr-3">
                    <a
                      href="##"
                      onClick={() => clickedMenuItem(2)}
                      id="menuitem-underwriting"
                      className="nav-link py-4 px-6"
                      data-toggle="tab"
                      data-target="#kt_header_tab_2"
                      role="tab"
                    >
                      Underwriting
                    </a>
                  </li>

                  <li className="nav-item mr-3">
                    <a
                      href="##"
                      onClick={() => clickedMenuItem(3)}
                      id="menuitem-portfolios"
                      className="nav-link py-4 px-6"
                      data-toggle="tab"
                      data-target="#kt_header_tab_3"
                      role="tab"
                    >
                      Portfolios
                    </a>
                  </li>

                  <li className="nav-item mr-3">
                    <a
                      href="##"
                      onClick={() => clickedMenuItem(4)}
                      id="menuitem-accounts"
                      className="nav-link py-4 px-6"
                      data-toggle="tab"
                      data-target="#kt_header_tab_4"
                      role="tab"
                    >
                      Accounts
                    </a>
                  </li>

                  <li className="nav-item mr-3">
                    <a
                      href="##"
                      onClick={() => clickedMenuItem(5)}
                      id="menuitem-accounting"
                      className="nav-link py-4 px-6g"
                      data-toggle="tab"
                      data-target="#kt_header_tab_5"
                      role="tab"
                    >
                      Accounting
                    </a>
                  </li>

                  <li className="nav-item mr-3">
                    <a
                      href="##"
                      onClick={() => clickedMenuItem(6)}
                      id="menuitem-reports"
                      className="nav-link py-4 px-6"
                      data-toggle="tab"
                      data-target="#kt_header_tab_6"
                      role="tab"
                    >
                      Reports
                    </a>
                  </li>

                  <li className="nav-item mr-3">
                    <a
                      href="##"
                      onClick={() => clickedMenuItem(7)}
                      id="menuitem-contacts"
                      className="nav-link py-4 px-6"
                      data-toggle="tab"
                      data-target="#kt_header_tab_7"
                      role="tab"
                    >
                      Contacts
                    </a>
                  </li>

                  <li className="nav-item mr-3">
                    <a
                      href="##"
                      onClick={() => clickedMenuItem(8)}
                      id="menuitem-settings"
                      className="nav-link py-4 px-6"
                      data-toggle="tab"
                      data-target="#kt_header_tab_8"
                      role="tab"
                    >
                      <i className="flaticon2-settings" />
                    </a>
                  </li>
                </ul>
              </div>

              <div className="topbar-item w-lg-auto d-flex align-items-center btn-lg px-2">
                {/* <input
                  type="text"
                  className="mr-5 form-control"
                  style={{ width: '150px' }}
                /> */}
                <div className="topbar-item" style={{ width: '150px' }}>
                  <div
                    onClick={showUserProfileCard}
                    className="btn btn-icon btn-hover-transparent-white w-lg-auto d-flex align-items-center btn-lg px-2"
                    id="kt_quick_user_toggle"
                  >
                    <div className="d-flex flex-column text-right pr-lg-3">
                      <span className="text-white opacity-50 font-weight-bold font-size-sm d-none d-md-inline">
                        {user != null ? user.displayName : undefined}
                      </span>
                      <span className="text-white font-weight-bolder font-size-sm d-none d-md-inline"></span>
                    </div>
                    <span className="symbol symbol-35">
                      <span className="symbol-label font-size-h5 font-weight-bold text-white bg-white-o-30">
                        {user != null ? user.displayName[0] : undefined}
                      </span>
                    </span>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="header-bottom">
            <div className="container">
              <div className="header-navs header-navs-left" id="kt_header_navs">
                <div className="tab-content">
                  <div className="tab-pane py-5 p-lg-0 show active" id="kt_header_tab_1">
                    <div
                      id="kt_header_menu"
                      className="header-menu header-menu-mobile header-menu-layout-default"
                    >
                      <ul className="menu-nav">
                        <li className="menu-item" aria-haspopup="true">
                          <Link to="/" className="menu-link">
                            <span className="menu-text">Dashboard</span>
                          </Link>
                        </li>
                      </ul>
                    </div>
                  </div>

                  <div className="tab-pane p-5 p-lg-0 justify-content-between" id="kt_header_tab_2">
                    <div className="header-menu header-menu-mobile header-menu-layout-default">
                      <ul className="menu-nav">
                        <li className="menu-item" aria-haspopup="true">
                          <Link to="/underwriting/applications" className="menu-link">
                            <span className="menu-text">Advances</span>
                          </Link>
                        </li>
                        <li className="menu-item" aria-haspopup="true">
                          <Link
                            to="/underwriting/applications-to-be-reviewed"
                            className="menu-link"
                          >
                            <span className="menu-text">Applications To Be Reviewed</span>
                          </Link>
                        </li>
                        <li className="menu-item" aria-haspopup="true">
                          <Link to="/underwriting/clients" className="menu-link">
                            <span className="menu-text">Clients</span>
                          </Link>
                        </li>
                      </ul>
                    </div>
                  </div>

                  <div className="tab-pane p-5 p-lg-0 justify-content-between" id="kt_header_tab_3">
                    <div className="d-flex flex-column flex-lg-row align-items-start align-items-lg-center">
                      <div className="header-menu header-menu-mobile header-menu-layout-default">
                        <ul className="menu-nav">
                          <li className="menu-item" aria-haspopup="true">
                            <Link to="/portfolios/payoff-letter-requests" className="menu-link">
                              <span className="menu-text">Payoff Letter Requests</span>
                            </Link>
                          </li>

                          <li className="menu-item" aria-haspopup="true">
                            <Link to="/portfolios/concession-diary" className="menu-link">
                              <span className="menu-text">Concession Diary</span>
                            </Link>
                          </li>

                          <li className="menu-item" aria-haspopup="true">
                            <Link to="/portfolios/cases-require-review" className="menu-link">
                              <span className="menu-text">Cases Require Review</span>
                            </Link>
                          </li>

                          <li className="menu-item" aria-haspopup="true">
                            <Link to="/portfolios/portfolios-list" className="menu-link">
                              <span className="menu-text">Portfolios List</span>
                            </Link>
                          </li>
                        </ul>
                      </div>
                    </div>
                  </div>

                  <div className="tab-pane p-5 p-lg-0 justify-content-between" id="kt_header_tab_4">
                    <div className="d-flex flex-column flex-lg-row align-items-start align-items-lg-center">
                      <div className="header-menu header-menu-mobile header-menu-layout-default">
                        <ul className="menu-nav">
                          <li className="menu-item" aria-haspopup="true">
                            <Link to="/accounts/active-advance-receivables" className="menu-link">
                              <span className="menu-text">Active Advance Receivables</span>
                            </Link>
                          </li>

                          <li className="menu-item" aria-haspopup="true">
                            <Link to="/accounts/account-manager-pipeline" className="menu-link">
                              <span className="menu-text">Account Manager Pipeline</span>
                            </Link>
                          </li>

                          <li className="menu-item" aria-haspopup="true">
                            <Link to="/accounts/account-fundings" className="menu-link">
                              <span className="menu-text">Account Fundings</span>
                            </Link>
                          </li>

                          <li className="menu-item" aria-haspopup="true">
                            <Link to="/accounts/law-firm-contacts" className="menu-link">
                              <span className="menu-text">Law Firm Contacts</span>
                            </Link>
                          </li>
                        </ul>
                      </div>
                    </div>
                  </div>

                  <div className="tab-pane p-5 p-lg-0 justify-content-between" id="kt_header_tab_5">
                    <div className="d-flex flex-column flex-lg-row align-items-start align-items-lg-center">
                      <div className="header-menu header-menu-mobile header-menu-layout-default">
                        <ul className="menu-nav">
                          <li className="menu-item" aria-haspopup="true">
                            <Link to="/accounting/cases-to-be-advanced" className="menu-link">
                              <span className="menu-text">Cases to Be Advanced</span>
                            </Link>
                          </li>

                          <li className="menu-item" aria-haspopup="true">
                            <Link to="/accounting/payoffs" className="menu-link">
                              <span className="menu-text">Payoffs</span>
                            </Link>
                          </li>

                          <li className="menu-item" aria-haspopup="true">
                            <Link to="/accounting/payoff-requests" className="menu-link">
                              <span className="menu-text">Payoff Requests</span>
                            </Link>
                          </li>
                          <li className="menu-item" aria-haspopup="true">
                            <Link to="/accounting/active-advance-receivables" className="menu-link">
                              <span className="menu-text">Active Advance Receivables</span>
                            </Link>
                          </li>
                        </ul>
                      </div>
                    </div>
                  </div>

                  <div className="tab-pane p-5 p-lg-0 justify-content-between" id="kt_header_tab_6">
                    <div className="d-flex flex-column flex-lg-row align-items-start align-items-lg-center">
                      <select
                        className="form-control"
                        onChange={reportSelectChanged}
                        ref={reportFilter}
                      >
                        <option value="Select a Report">Select a Report</option>
                        <option value="account-manager-details">Account Manager Details</option>
                        <option value="account-manager-summary">Account Manager Summary</option>
                        <option value="case-status-update-activity">Case Status Update Activity</option>
                        <option value="commission-report">Commission Report</option>
                        <option value="attorney-data">Attorney Data</option>
                        <option value="financial-center">Financial Center</option>
                        <option value="financial-summary">Financial Summary</option>
                        <option value="law-firm-details">Law Firm Details</option>
                        <option value="law-firm-summary">Law Firm Summary</option>
                        <option value="payoff-static-pool-analysis">
                          Payoff Static Pool Analysis
                        </option>
                        <option value="present-value">Present Value Report</option>
                        <option value="types-of-accidents-summary">
                          Types of Accidents Summary
                        </option>
                      </select>
                    </div>
                  </div>

                  <div className="tab-pane p-5 p-lg-0 justify-content-between" id="kt_header_tab_7">
                    <div className="d-flex flex-column flex-lg-row align-items-start align-items-lg-center">
                      <div className="header-menu header-menu-mobile header-menu-layout-default">
                        <ul className="menu-nav">
                          <li className="menu-item" aria-haspopup="true">
                            <Link to="/contacts/law-firms" className="menu-link">
                              <span className="menu-text">Law Firms</span>
                            </Link>
                          </li>

                          <li className="menu-item" aria-haspopup="true">
                            <Link to="/contacts/attorneys" className="menu-link">
                              <span className="menu-text">Attorneys</span>
                            </Link>
                          </li>

                          <li className="menu-item" aria-haspopup="true">
                            <Link to="/contacts/brokers" className="menu-link">
                              <span className="menu-text">Brokers</span>
                            </Link>
                          </li>

                          <li className="menu-item" aria-haspopup="true">
                            <Link to="/contacts/financial-stores" className="menu-link">
                              <span className="menu-text">Financial Stores</span>
                            </Link>
                          </li>
                        </ul>
                      </div>
                    </div>
                  </div>

                  <div className="tab-pane p-5 p-lg-0 justify-content-between" id="kt_header_tab_8">
                    <div className="d-flex flex-column flex-lg-row align-items-start align-items-lg-center">
                      <div className="header-menu header-menu-mobile header-menu-layout-default">
                        <ul className="menu-nav">
                          <li className="menu-item" aria-haspopup="true">
                            <Link to="/settings/users" className="menu-link">
                              <span className="menu-text">Users</span>
                            </Link>
                          </li>

                          <li className="menu-item" aria-haspopup="true">
                            <Link to="/settings/user-roles" className="menu-link">
                              <span className="menu-text">User Roles</span>
                            </Link>
                          </li>

                          <li className="menu-item" aria-haspopup="true">
                            <Link to="/settings/misc-settings" className="menu-link">
                              <span className="menu-text">Misc. Settings</span>
                            </Link>
                          </li>
                        </ul>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </header>

      <div
        id="kt_quick_user"
        className="offcanvas offcanvas-right p-10 offcanvas-on"
        style={{ display: 'none' }}
      >
        <div
          className="offcanvas-header d-flex align-items-center justify-content-between pb-5"
          kt-hidden-height="40"
        >
          <h3 className="font-weight-bold m-0">User Profile</h3>
          <a
            href="##"
            onClick={hideUserProfileCard}
            className="btn btn-xs btn-icon btn-light btn-hover-primary"
            id="kt_quick_user_close"
          >
            <i className="ki ki-close icon-xs text-muted" />
          </a>
        </div>
        <div
          className="offcanvas-content pr-5 mr-n5 scroll ps ps--active-y"
          style={{ height: '666px', overflow: 'hidden' }}
        >
          <div className="d-flex align-items-center mt-5">
            <div className="d-flex flex-column">
              <a
                href="##"
                className="font-weight-bold font-size-h5 text-dark-75 text-hover-primary"
                onClick={navigateToProfile}
              >
                {user != null ? user.displayName : undefined}
              </a>
              <div className="text-muted mt-1"></div>
              <div className="navi mt-2">
                <a href="##" className="navi-item" onClick={navigateToProfile}>
                  <span className="navi-link p-0 pb-2">
                    <span className="navi-icon mr-1">
                      <span className="svg-icon svg-icon-lg svg-icon-primary">
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          xmlnsXlink="http://www.w3.org/1999/xlink"
                          width="24px"
                          height="24px"
                          viewBox="0 0 24 24"
                          version="1.1"
                        >
                          <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
                            <rect x="0" y="0" width="24" height="24" />
                            <path
                              d="M21,12.0829584 C20.6747915,12.0283988 20.3407122,12 20,12 C16.6862915,12 14,14.6862915 14,18 C14,18.3407122 14.0283988,18.6747915 14.0829584,19 L5,19 C3.8954305,19 3,18.1045695 3,17 L3,8 C3,6.8954305 3.8954305,6 5,6 L19,6 C20.1045695,6 21,6.8954305 21,8 L21,12.0829584 Z M18.1444251,7.83964668 L12,11.1481833 L5.85557487,7.83964668 C5.4908718,7.6432681 5.03602525,7.77972206 4.83964668,8.14442513 C4.6432681,8.5091282 4.77972206,8.96397475 5.14442513,9.16035332 L11.6444251,12.6603533 C11.8664074,12.7798822 12.1335926,12.7798822 12.3555749,12.6603533 L18.8555749,9.16035332 C19.2202779,8.96397475 19.3567319,8.5091282 19.1603533,8.14442513 C18.9639747,7.77972206 18.5091282,7.6432681 18.1444251,7.83964668 Z"
                              fill="#000000"
                            />
                            <circle fill="#000000" opacity="0.3" cx="19.5" cy="17.5" r="2.5" />
                          </g>
                        </svg>
                      </span>
                    </span>
                    <span className="navi-text text-muted text-hover-primary">
                      {user != null ? user.emailAddress : undefined}
                    </span>
                  </span>
                </a>
                <Link
                  to="/logout"
                  className="btn btn-sm btn-light-primary font-weight-bolder py-2 px-5"
                >
                  Sign Out
                </Link>
              </div>
            </div>
          </div>

          <div className="separator separator-dashed mt-8 mb-5" />

          <div className="navi navi-spacer-x-0 p-0">
            <a href="##" className="navi-item" onClick={navigateToProfile}>
              <div className="navi-link">
                <div className="symbol symbol-40 bg-light mr-3">
                  <div className="symbol-label">
                    <span className="svg-icon svg-icon-md svg-icon-success">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        xmlnsXlink="http://www.w3.org/1999/xlink"
                        width="24px"
                        height="24px"
                        viewBox="0 0 24 24"
                        version="1.1"
                      >
                        <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
                          <rect x="0" y="0" width="24" height="24" />
                          <path
                            d="M13.2070325,4 C13.0721672,4.47683179 13,4.97998812 13,5.5 C13,8.53756612 15.4624339,11 18.5,11 C19.0200119,11 19.5231682,10.9278328 20,10.7929675 L20,17 C20,18.6568542 18.6568542,20 17,20 L7,20 C5.34314575,20 4,18.6568542 4,17 L4,7 C4,5.34314575 5.34314575,4 7,4 L13.2070325,4 Z"
                            fill="#000000"
                          />
                          <circle fill="#000000" opacity="0.3" cx="18.5" cy="5.5" r="2.5" />
                        </g>
                      </svg>
                    </span>
                  </div>
                </div>
                <div className="navi-text">
                  <div className="font-weight-bold">My Profile</div>
                  <div className="text-muted"></div>
                </div>
              </div>
            </a>
          </div>

          <div className="ps__rail-x" style={{ left: '0px', bottom: '0px' }}>
            <div className="ps__thumb-x" style={{ left: '0px', width: '0px' }} />
          </div>
          <div className="ps__rail-y" style={{ top: '0px', right: '0px', height: '666px' }}>
            <div className="ps__thumb-y" style={{ top: '0px', height: '300px' }} />
          </div>
        </div>
      </div>

      <div
        id="kt_mobile_header_menu"
        className="offcanvas offcanvas-left p-10 offcanvas-on"
        style={{ display: 'none' }}
      >
        <div
          className="offcanvas-header d-flex align-items-center justify-content-between pb-5"
          kt-hidden-height="40"
        >
          <h3 className="font-weight-bold m-0">Menu</h3>
          <a
            href="##"
            onClick={hideMobileMenuCard}
            className="btn btn-xs btn-icon btn-light btn-hover-primary"
            id="kt_quick_user_close"
          >
            <i className="ki ki-close icon-xs text-muted" />
          </a>
        </div>

        <div
          className="offcanvas-content pr-5 mr-n5 scroll ps ps--active-y"
          style={{ height: 'auto', overflow: 'hidden' }}
        >
          <div className="d-flex align-items-center mt-5">
            <div className="d-flex flex-column">
              <div className="text-muted mt-1"></div>
            </div>
          </div>

          {/* <ul classname="menu-nav">
            <li classname="menu-item  menu-item-active " aria-haspopup="true">
              <a href="index.html" classname="menu-link ">
                <span classname="menu-text">Dashboard</span>
              </a>
            </li>
          </ul> */}

          <div
            id="kt_header_menu"
            className="header-menu header-menu-mobile  header-menu-layout-default"
          >
            <ul className="menu-nav">
              <li className="menu-item">
                <a
                  href="##"
                  onClick={() => clickedMobileMenuItem(1)}
                  id="menuitem-home"
                  className="nav-link py-4 px-6"
                  data-toggle="tab"
                  data-target="#kt_header_tab_1"
                  role="tab"
                >
                  Home
                </a>
                <div
                  id="kt_mobile_tab_1"
                  className="menu-submenu menu-submenu-classic menu-submenu-left tab-pane"
                  style={{ display: 'none' }}
                >
                  <ul className="menu-subnav">
                    <li className="menu-item  menu-item-submenu" aria-haspopup="true">
                      <Link to="/" className="menu-link" onClick={hideMobileMenuCard}>
                        <span className="menu-text">Dashboard</span>
                      </Link>
                    </li>
                  </ul>
                </div>
              </li>

              <li className="menu-item">
                <a
                  href="##"
                  onClick={() => clickedMobileMenuItem(2)}
                  id="menuitem-underwriting"
                  className="nav-link py-4 px-6"
                  data-toggle="tab"
                  data-target="#kt_header_tab_2"
                  role="tab"
                >
                  Underwriting
                </a>
                <div
                  id="kt_mobile_tab_2"
                  className="menu-submenu menu-submenu-classic menu-submenu-left tab-pane"
                  style={{ display: 'none' }}
                >
                  <ul className="menu-subnav">
                    <li className="menu-item  menu-item-submenu" aria-haspopup="true">
                      <Link
                        to="/underwriting/applications"
                        className="menu-link"
                        onClick={hideMobileMenuCard}
                      >
                        <span className="menu-text">Advances</span>
                      </Link>
                    </li>
                    <li className="menu-item  menu-item-submenu" aria-haspopup="true">
                      <Link
                        to="/underwriting/applications-to-be-reviewed"
                        className="menu-link"
                        onClick={hideMobileMenuCard}
                      >
                        <span className="menu-text">Applications To Be Reviewed</span>
                      </Link>
                    </li>
                    <li
                      className="menu-item  menu-item-submenu"
                      aria-haspopup="true"
                      onClick={hideMobileMenuCard}
                    >
                      <Link to="/underwriting/clients" className="menu-link">
                        <span className="menu-text">Clients</span>
                      </Link>
                    </li>
                  </ul>
                </div>
              </li>

              <li className="menu-item">
                <a
                  href="##"
                  onClick={() => clickedMobileMenuItem(3)}
                  id="menuitem-portfolios"
                  className="nav-link py-4 px-6"
                  data-toggle="tab"
                  data-target="#kt_header_tab_3"
                  role="tab"
                >
                  Portfolios
                </a>
                <div
                  id="kt_mobile_tab_3"
                  className="menu-submenu menu-submenu-classic menu-submenu-left tab-pane"
                  style={{ display: 'none' }}
                >
                  <ul className="menu-subnav">
                    <li className="menu-item" aria-haspopup="true">
                      <Link
                        to="/portfolios/payoff-letter-requests"
                        onClick={hideMobileMenuCard}
                        className="menu-link"
                      >
                        <span className="menu-text">Payoff Letter Requests</span>
                      </Link>
                    </li>

                    <li className="menu-item" aria-haspopup="true">
                      <Link
                        to="/portfolios/concession-diary"
                        onClick={hideMobileMenuCard}
                        className="menu-link"
                      >
                        <span className="menu-text">Concession Diary</span>
                      </Link>
                    </li>

                    <li className="menu-item" aria-haspopup="true">
                      <Link
                        to="/portfolios/cases-require-review"
                        onClick={hideMobileMenuCard}
                        className="menu-link"
                      >
                        <span className="menu-text">Cases Require Review</span>
                      </Link>
                    </li>

                    <li className="menu-item" aria-haspopup="true">
                      <Link
                        to="/portfolios/portfolios-list"
                        onClick={hideMobileMenuCard}
                        className="menu-link"
                      >
                        <span className="menu-text">Portfolios List</span>
                      </Link>
                    </li>
                  </ul>
                </div>
              </li>

              <li className="menu-item">
                <a
                  href="##"
                  onClick={() => clickedMobileMenuItem(4)}
                  id="menuitem-accounts"
                  className="nav-link py-4 px-6"
                  data-toggle="tab"
                  data-target="#kt_header_tab_4"
                  role="tab"
                >
                  Accounts
                </a>

                <div
                  id="kt_mobile_tab_4"
                  className="menu-submenu menu-submenu-classic menu-submenu-left tab-pane"
                  style={{ display: 'none' }}
                >
                  <ul className="menu-subnav">
                    <li className="menu-item" aria-haspopup="true">
                      <Link
                        to="/accounts/active-advance-receivables"
                        onClick={hideMobileMenuCard}
                        className="menu-link"
                      >
                        <span className="menu-text">Active Advance Receivables</span>
                      </Link>
                    </li>

                    <li className="menu-item" aria-haspopup="true">
                      <Link
                        to="/accounts/account-manager-pipeline"
                        onClick={hideMobileMenuCard}
                        className="menu-link"
                      >
                        <span className="menu-text">Account Manager Pipeline</span>
                      </Link>
                    </li>

                    <li className="menu-item" aria-haspopup="true">
                      <Link
                        to="/accounts/account-fundings"
                        onClick={hideMobileMenuCard}
                        className="menu-link"
                      >
                        <span className="menu-text">Account Fundings</span>
                      </Link>
                    </li>

                    <li className="menu-item" aria-haspopup="true">
                      <Link
                        to="/accounts/law-firm-contacts"
                        onClick={hideMobileMenuCard}
                        className="menu-link"
                      >
                        <span className="menu-text">Law Firm Contacts</span>
                      </Link>
                    </li>
                  </ul>
                </div>
              </li>

              <li className="menu-item">
                <a
                  href="##"
                  onClick={() => clickedMobileMenuItem(5)}
                  id="menuitem-accounting"
                  className="nav-link py-4 px-6g"
                  data-toggle="tab"
                  data-target="#kt_header_tab_5"
                  role="tab"
                >
                  Accounting
                </a>
                <div
                  id="kt_mobile_tab_5"
                  className="menu-submenu menu-submenu-classic menu-submenu-left tab-pane"
                  style={{ display: 'none' }}
                >
                  <ul className="menu-subnav">
                    <li className="menu-item" aria-haspopup="true">
                      <Link
                        to="/accounting/cases-to-be-advanced"
                        onClick={hideMobileMenuCard}
                        className="menu-link"
                      >
                        <span className="menu-text">Cases to Be Advanced</span>
                      </Link>
                    </li>

                    <li className="menu-item" aria-haspopup="true">
                      <Link
                        to="/accounting/payoffs"
                        onClick={hideMobileMenuCard}
                        className="menu-link"
                      >
                        <span className="menu-text">Payoffs</span>
                      </Link>
                    </li>

                    <li className="menu-item" aria-haspopup="true">
                      <Link
                        to="/accounting/payoff-requests"
                        onClick={hideMobileMenuCard}
                        className="menu-link"
                      >
                        <span className="menu-text">Payoff Requests</span>
                      </Link>
                    </li>
                    <li className="menu-item" aria-haspopup="true">
                      <Link
                        to="/accounting/active-advance-receivables"
                        onClick={hideMobileMenuCard}
                        className="menu-link"
                      >
                        <span className="menu-text">Active Advance Receivables</span>
                      </Link>
                    </li>
                  </ul>
                </div>
              </li>

              <li className="menu-item">
                <a
                  href="##"
                  onClick={() => clickedMobileMenuItem(6)}
                  id="menuitem-reports"
                  className="nav-link py-4 px-6"
                  data-toggle="tab"
                  data-target="#kt_header_tab_6"
                  role="tab"
                >
                  Reports
                </a>
                <div
                  id="kt_mobile_tab_6"
                  className="menu-submenu menu-submenu-classic menu-submenu-left tab-pane"
                  style={{ display: 'none' }}
                >
                  <select
                    className="form-control"
                    onChange={reportSelectChanged}
                    ref={reportFilter}
                  >
                    <option value="Select a Report">Select a Report</option>
                    <option value="account-manager-details">Account Manager Details</option>
                    <option value="account-manager-summary">Account Manager Summary</option>
                    <option value="commission-report">Commision Report</option>
                    <option value="attorney-data">Attorney Data</option>
                    <option value="financial-center">Financial Center</option>
                    <option value="financial-summary">Financial Summary</option>
                    <option value="law-firm-details">Law Firm Details</option>
                    <option value="law-firm-summary">Law Firm Summary</option>
                    <option value="payoff-static-pool-analysis">Payoff Static Pool Analysis</option>
                    <option value="present-value">Present Value Report</option>
                    <option value="types-of-accidents-summary">Types of Accidents Summary</option>
                  </select>
                </div>
              </li>

              <li className="menu-item">
                <a
                  href="##"
                  onClick={() => clickedMobileMenuItem(7)}
                  id="menuitem-contacts"
                  className="nav-link py-4 px-6"
                  data-toggle="tab"
                  data-target="#kt_header_tab_7"
                  role="tab"
                >
                  Contacts
                </a>
                <div
                  id="kt_mobile_tab_7"
                  className="menu-submenu menu-submenu-classic menu-submenu-left tab-pane"
                  style={{ display: 'none' }}
                >
                  <ul className="menu-subnav">
                    <li className="menu-item" aria-haspopup="true">
                      <Link
                        to="/contacts/law-firms"
                        onClick={hideMobileMenuCard}
                        className="menu-link"
                      >
                        <span className="menu-text">Law Firms</span>
                      </Link>
                    </li>

                    <li className="menu-item" aria-haspopup="true">
                      <Link
                        to="/contacts/attorneys"
                        onClick={hideMobileMenuCard}
                        className="menu-link"
                      >
                        <span className="menu-text">Attorneys</span>
                      </Link>
                    </li>

                    <li className="menu-item" aria-haspopup="true">
                      <Link
                        to="/contacts/brokers"
                        onClick={hideMobileMenuCard}
                        className="menu-link"
                      >
                        <span className="menu-text">Brokers</span>
                      </Link>
                    </li>

                    <li className="menu-item" aria-haspopup="true">
                      <Link
                        to="/contacts/financial-stores"
                        onClick={hideMobileMenuCard}
                        className="menu-link"
                      >
                        <span className="menu-text">Financial Stores</span>
                      </Link>
                    </li>
                  </ul>
                </div>
              </li>

              <li className="menu-item">
                <a
                  href="##"
                  onClick={() => clickedMobileMenuItem(8)}
                  id="menuitem-settings"
                  className="nav-link py-4 px-6"
                  data-toggle="tab"
                  data-target="#kt_header_tab_8"
                  role="tab"
                >
                  <p>
                    <i className="flaticon2-settings"></i> Settings{' '}
                  </p>
                </a>
                <div
                  id="kt_mobile_tab_8"
                  className="menu-submenu menu-submenu-classic menu-submenu-left tab-pane"
                  style={{ display: 'none' }}
                >
                  <ul className="menu-subnav">
                    <li className="menu-item" aria-haspopup="true">
                      <Link to="/settings/users" onClick={hideMobileMenuCard} className="menu-link">
                        <span className="menu-text">Users</span>
                      </Link>
                    </li>

                    <li className="menu-item" aria-haspopup="true">
                      <Link
                        to="/settings/user-roles"
                        onClick={hideMobileMenuCard}
                        className="menu-link"
                      >
                        <span className="menu-text">User Roles</span>
                      </Link>
                    </li>

                    <li className="menu-item" aria-haspopup="true">
                      <Link
                        to="/settings/misc-settings"
                        onClick={hideMobileMenuCard}
                        className="menu-link"
                      >
                        <span className="menu-text">Misc. Settings</span>
                      </Link>
                    </li>
                  </ul>
                </div>
              </li>
            </ul>
          </div>
          <div className="ps__rail-x" style={{ left: '0px', bottom: '0px' }}>
            <div className="ps__thumb-x" style={{ left: '0px', width: '0px' }} />
          </div>
          <div className="ps__rail-y" style={{ top: '0px', right: '0px', height: '666px' }}>
            <div className="ps__thumb-y" style={{ top: '0px', height: '300px' }} />
          </div>
        </div>
      </div>
    </>
  );
};

export default withRouter(Menu);
