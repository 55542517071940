import React, { useContext, useEffect, useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import { useForm } from 'react-hook-form';
import ApplicationsService from '@services/ApplicationsService';

import ApplicationsNewMenu from '../../../Menu/ApplicationsNewMenu';
import { Alert } from 'reactstrap';
import { ErrorMessage } from '@hookform/error-message';
import ApplicationPayoffRequestService from '@services/ApplicationPayoffRequestService';
import AppContext from '@contexts/AppContext';
import ReactLoading from 'react-loading';
import '../style.css';
const user = JSON.parse(localStorage.getItem('lasuris-user'));

const NewPayoffRequest = (props) => {
  const appContext = useContext(AppContext);
  const history = useHistory();
  const { applicationId, payoffRequestId } = useParams();
  const [applicationPublicId, setApplicationPublicId] = useState();
  const [payoffRequestData, setPayoffRequestData] = useState({});
  const [payoffGoodUntil, setPayoffGoodUntil] = useState();
  const [userDetails, setUserDetails] = useState(false);
  const [successMessage, setSuccessMessage] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const { register, handleSubmit, watch, errors, formState } = useForm({ mode: 'onBlur' });

  useEffect(() => {
    ApplicationsService.search({ applicationNo: applicationId }, null, null, 1, {}, 1)
      .then((response) => {
        setApplicationPublicId(response.data.data[0].publicId);

        if (payoffRequestId === 'new-payoff-request') {
          setIsLoading(true);

          ApplicationPayoffRequestService.getPayoffGoodUntil(response.data.data[0].publicId)
            .then((res) => {
              setPayoffGoodUntil(res.data);
              setIsLoading(false);
            })
            .catch((err) => {})
            .finally(() => {
              setIsLoading(false);
            });
        } else {
          setIsLoading(true);
          ApplicationPayoffRequestService.read(payoffRequestId)
            .then((res) => {
              setPayoffRequestData(res.data);
              setIsLoading(false);
            })
            .catch((err) => {})
            .finally(() => {
              setIsLoading(false);
            });
        }
      })
      .catch((err) => {})
      .finally(() => {
        setIsLoading(false);
      });
  }, []);

  const handleFormSubmit = (data) => {
    if (payoffRequestId === 'new-payoff-request') {
      if (!(appContext?.userPermissions?.indexOf('Add Payoff Requests') > -1)) {
        appContext.toastMessage.message = 'You do not have permissions to perform this action.';
        toast.error(appContext.toastMessage.message, { autoClose: 3000 });
        appContext.toastMessage.message = null;
        return;
      }
      data.hasCaseSettled = data.hasCaseSettled === 'true' ? true : false;
      data.createdByPublicId = userDetails.publicId;
      data.payoffGoodUntilDate = new Date(data.payoffGoodUntilDate).toISOString();
      data.applicationPublicId = applicationPublicId;

      setIsLoading(true);

      ApplicationPayoffRequestService.create(data)
        .then((res) => {
          appContext.toastMessage.message = 'Payoff request has been successfully submitted.';
          setSuccessMessage('');
          history.push('/underwriting/applications/' + applicationId + '/payoffs');
        })
        .catch((err) => {})
        .finally(() => {
          setIsLoading(false);
        });
    } else {
      if (!(appContext?.userPermissions?.indexOf('Edit Payoff Requests') > -1)) {
        appContext.toastMessage.message = 'You do not have permissions to perform this action.';
        toast.error(appContext.toastMessage.message, { autoClose: 3000 });
        appContext.toastMessage.message = null;
        return;
      }

      let req = {
        publicId: payoffRequestData.publicId,
        notes: data.notes,
      };

      setIsLoading(true);

      ApplicationPayoffRequestService.update(req)
        .then((res) => {
          appContext.toastMessage.message = 'Payoff request has been successfully submitted.';
          setSuccessMessage('');
          history.push('/underwriting/applications/' + applicationId + '/payoffs');
        })
        .catch((err) => {})
        .finally(() => {
          setIsLoading(false);
        });
    }
  };

  useEffect(() => {
    setUserDetails(JSON.parse(localStorage.getItem('lasuris-user')));

    if (appContext.toastMessage.message)
      toast.success(appContext.toastMessage.message, { autoClose: 3000 });
    appContext.toastMessage.message = null;
  }, []);

  useEffect(() => {
    let item = document.querySelector('.payoffs');
    if (item) item.classList.add('active');
  }, []);

  const handleCancel = () => {
    let path = applicationId ? applicationId : 'new-application';
    history.push('/underwriting/applications/' + path + '/payoffs');
  };

  const onChangeHasCaseSettled = (event) => {
    if (event.target.value == 'true') {
      setPayoffRequestData({
        ...payoffRequestData,
        hasCaseSettled: true,
      });
    } else {
      setPayoffRequestData({
        ...payoffRequestData,
        hasCaseSettled: false,
      });
    }
  };

  if (isLoading) {
    return (
      <div className="container mt-10 mb-10">
        <div className="row">
          <div className="col-md-12 mx-auto bg-white box-shadow-border">
            <div className="p-10">
              <div className="mt-5 mb-10">
                <ReactLoading type="bars" color="#7E8299" />
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }

  return (
    <div className="container mt-10 mb-10">
      <div className="row">
        <div className="col-md-12 mx-auto bg-white box-shadow-border">
          <div className="p-10">
            <div className="mt-5 mb-10">
              <div>
                <h1>
                  {applicationId != 'new-application'
                    ? 'Application ' + applicationId + ' - '
                    : undefined}
                  {props.match.params.payoffRequestId == 'new-payoff-request'
                    ? 'New Payoff Request'
                    : 'Payoff Request Details'}
                </h1>

                <ApplicationsNewMenu applicationId={applicationId} />

                <form method="POST" onSubmit={handleSubmit(handleFormSubmit)} noValidate>
                  <div className="row">
                    <div className="col-md-6">
                      <div className="form-group">
                        <label htmlFor="date">Has Case Settled?</label>
                        <select
                          name="hasCaseSettled"
                          disabled={payoffRequestId !== 'new-payoff-request'}
                          defaultValue={payoffRequestData.hasCaseSettled == true ? true : false}
                          id="hasCaseSettled"
                          required="required"
                          ref={register()}
                          onChange={onChangeHasCaseSettled}
                          key={payoffRequestData.hasCaseSettled ? 'notLoadedYet' : 'loaded'}
                          className="form-control has-case-settled-select"
                        >
                          <option value={true}>Yes</option>
                          <option value={false}>No</option>
                        </select>
                        {errors.hasCaseSettled && (
                          <Alert color="danger" className="mt-5">
                            <ErrorMessage errors={errors} name="hasCaseSettled" />
                          </Alert>
                        )}
                      </div>
                      <div className="form-group">
                        <label htmlFor="caseSettlementOption">Case Settlement Options</label>
                        <select
                          name="caseSettlementOption"
                          disabled={payoffRequestId !== 'new-payoff-request'}
                          defaultValue={payoffRequestData.caseSettlementOption}
                          id="caseSettlementOption"
                          ref={register({ required: 'Case settlement options is required' })}
                          key={payoffRequestData.caseSettlementOption ? 'notLoadedYet' : 'loaded'}
                          className="form-control case-settlement-options-select"
                        >
                          <option value="">{'Select Case Settlement Option'}</option>
                          {payoffRequestData.hasCaseSettled && (
                            <option value="< 30 days">{'< 30 days'}</option>
                          )}
                          {payoffRequestData.hasCaseSettled && (
                            <option value="30-60 days">{'30-60 days'}</option>
                          )}
                          {payoffRequestData.hasCaseSettled && (
                            <option value="60-90 days">{'60-90 days'}</option>
                          )}
                          {payoffRequestData.hasCaseSettled && (
                            <option value="> 90 days">{'> 90 days'}</option>
                          )}

                          {!payoffRequestData.hasCaseSettled && (
                            <option value="Attorney working with Claim's Representative to settle">{`Attorney working with Claim's Representative to settle`}</option>
                          )}
                          {!payoffRequestData.hasCaseSettled && (
                            <option value="Attorney presented with a settlement offer">{`Attorney presented with a settlement offer`}</option>
                          )}
                          {!payoffRequestData.hasCaseSettled && (
                            <option value="Attorney preparing for mediation, arbitration, or a settlement conference">{`Attorney preparing for mediation, arbitration, or a settlement conference`}</option>
                          )}
                          {!payoffRequestData.hasCaseSettled && (
                            <option value="Attorney at/going to Trial">{`Attorney at/going to Trial`}</option>
                          )}
                          {!payoffRequestData.hasCaseSettled && (
                            <option value="Client requesting additional funding">{`Client requesting additional funding`}</option>
                          )}
                          {!payoffRequestData.hasCaseSettled && (
                            <option value="Competitor requesting payoff">{`Competitor requesting payoff`}</option>
                          )}
                          {!payoffRequestData.hasCaseSettled && (
                            <option value="Attorney substituted/transferred">{`Attorney substituted/transferred`}</option>
                          )}
                        </select>
                        {errors.caseSettlementOption && (
                          <Alert color="danger" className="mt-5">
                            <ErrorMessage errors={errors} name="caseSettlementOption" />
                          </Alert>
                        )}
                      </div>

                      <div className="form-group">
                        <label htmlFor="payoffGoodUntilDate">Payoff Good Until</label>
                        <input
                          type="date"
                          disabled={payoffRequestId !== 'new-payoff-request'}
                          name="payoffGoodUntilDate"
                          id="payoffGoodUntilDate"
                          className="form-control payoff-good-until-datepicker"
                          ref={register({ required: 'Payoff Good Until is required' })}
                          defaultValue={
                            payoffRequestId === 'new-payoff-request'
                              ? payoffGoodUntil
                                ? payoffGoodUntil.split('T')[0]
                                : ''
                              : payoffRequestData.payoffGoodUntilDate
                              ? payoffRequestData.payoffGoodUntilDate.split('T')[0]
                              : ''
                          }
                        />
                        {errors.payoffGoodUntilDate && (
                          <Alert color="danger" className="mt-5">
                            <ErrorMessage errors={errors} name="payoffGoodUntilDate" />
                          </Alert>
                        )}
                      </div>

                      <div className="form-group">
                        <label htmlFor="notes">Notes</label>
                        <textarea
                          name="notes"
                          id="notes"
                          rows="5"
                          defaultValue={payoffRequestData.notes}
                          className="form-control notes-textarea"
                          ref={register()}
                          required="required"
                        />
                        {errors.notes && (
                          <Alert color="danger" className="mt-5">
                            <ErrorMessage errors={errors} name="notes" />
                          </Alert>
                        )}
                      </div>

                      <div className="form-group">
                        <button type="submit" className="btn btn-primary">
                          Save
                        </button>
                        <button
                          type="button"
                          className="ml-5 btn btn-secondary"
                          onClick={() => handleCancel()}
                        >
                          Cancel
                        </button>
                      </div>
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default NewPayoffRequest;
