import axios from 'axios';
import { auth, resError } from '@services/Helpers/ServiceInterceptor';

const api = axios.create({
  baseURL: process.env.REACT_APP_API_URL + '/ApplicationConcessionRequest/',
});

api.interceptors.request.use((config) => auth(config));
api.interceptors.response.use(
  (response) => {
    return response;
  },
  (error) => resError(error)
);

class ApplicationConcessionRequestService {

  constructor() {
    if (!ApplicationConcessionRequestService.instance) {
      ApplicationConcessionRequestService.instance = this;
    }
    return ApplicationConcessionRequestService.instance;
  }

  search = (filters, orderBy, orderDir, p, fields, perPage) => {
    return api.post('/search', {
      filters: Object.keys(filters).length > 0 ? filters : null,
      orderBy,
      orderDir,
      page: p,
      fields: undefined,
      perPage: parseInt(perPage),
    });
  };

  calculate = (entity) => {
    return api.post('/calculate', entity);
  };

  read = (id) => {
    return api.get('/' + id);
  };

  update = (entity) => {
    return api.put('/', entity);
  };

  sendForApproval = (entity) => {
    return api.put('/sendforapproval', entity);
  };

  approvalordenial = (entity) => {
    return api.put('/approvalordenial', entity);
  };

  delete = (id) => {
    return api.delete('/' + id);
  };

  autoSuggest = (value) => {
    return api.post('/search', { filters: { displayName: value }, perPage: 20, page: 1 });
  };
}

const instance = new ApplicationConcessionRequestService();
Object.freeze(instance);

export default instance;