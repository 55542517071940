import React, { useContext, useEffect, useState } from 'react';
import { useHistory, useParams, Link } from 'react-router-dom';
import { toast } from 'react-toastify';
import { useForm } from 'react-hook-form';
import './style.css';
import ApplicationsNewMenu from '../../../Menu/ApplicationsNewMenu';
import { Row, Col } from 'reactstrap';
import AppContext from '@contexts/AppContext';
import ApplicationPayoffsGridService from '@services/ApplicationPayoffsGridService';
import ApplicationsService from '@services/ApplicationsService';
import Table from '@material-ui/core/Table';
import TableCell from '@material-ui/core/TableCell';
import TableRow from '@material-ui/core/TableRow';
import fileDownload from 'js-file-download';

import { makeStyles, withStyles } from '@material-ui/core/styles';

const NEW_PAYOFF_TEXT = 'new-payoff';
const user = JSON.parse(localStorage.getItem('lasuris-user'));

const SimulatePayoff = (props) => {
  const appContext = useContext(AppContext);
  const history = useHistory();
  const { applicationId, payoffId } = useParams();
  const [applicationPublicId, setApplicationPublicId] = useState();
  const [payoffData, setPayoffData] = useState({});
  const [isLoading, setIsLoading] = useState(false);
  const [successMessage, setSuccessMessage] = useState('');
  const [advances, setAdvances] = useState([]);
  const useStyles2 = makeStyles({
    table: {
      maxWidth: 510,
    },
    tableFull: {
      // maxWidth: 'auto',
      tableLayout: 'fixed',
      width: '60%',
    },
    firstColumn: {
      color: 'white',
      backgroundColor: '#000080',
      fontWeight: 'bold',
      fontSize: 13,
      position: '-webkit-sticky',
      position: 'sticky',
      width: 200,
      minWidth: 200,
      maxWidth: 200,
      left: 0,
    },
    lastColumn: {
      textAlign: 'right',
      fontSize: 12,
      width: 140,
      minWidth: 140,
      maxWidth: 140,
      left: 0,
    },
  });
  const classes = useStyles2();

  const [client, setClient] = useState(
    appContext.currentApplication &&
      appContext.currentApplication &&
      Object.keys(appContext.currentApplication).length > 0
      ? appContext.currentApplication.client
      : ''
  );
  const {
    register,
    getValues,
    handleSubmit,
    watch,
    errors,
    formState,
    isSuccessfullySubmitted,
    setIsSuccessfullySubmitted,
  } = useForm();

  const StyledTableCell = withStyles((theme) => ({
    head: {
      backgroundColor: '#efefef',
      color: theme.palette.common.black,
    },
    body: {
      fontSize: 14,
    },
  }))(TableCell);

  const tableStyle = {
    color: 'white',
    backgroundColor: '#000080',
  };

  useEffect(() => {
    if (appContext.toastMessage.message)
      toast.success(appContext.toastMessage.message, { autoClose: 3000 });

    appContext.toastMessage.message = null;
    let item = document.querySelector('.payoffs');
    if (item) {
      item.classList.add('active');
    }

    ApplicationsService.search({ applicationNo: applicationId }, null, null, 1, {}, 1).then(
      (response) => {
        setApplicationPublicId(response.data.data[0].publicId);

        loadInitialData(response.data.data[0].publicId);
      }
    );
  }, []);

  const loadInitialData = (lastClickedAppPublicId) => {
    var reqData = {
      applicationPublicId: lastClickedAppPublicId,
      amount: 0,
      marketingExpense: 0,
      notes: '',
      createdByPublicId: user.publicId,
      concessionAmount: 0,
      payoffDate: payoffData.payoffDate ?? new Date().toISOString().split('T')[0],
      isFirstTimeLoad: true,
      applicationConcessionRequestAdvances: [],
    };

    ApplicationPayoffsGridService.calculate(reqData)
      .then((res) => {
        setPayoffData({
          ...payoffData,
          totalPayoffAmount: res.data.totalPayoff,
          amountReceived: res.data.amountReceived,
        });
        payoffId = res.data.data.publicId;
        setIsLoading(false);
      })
      .catch((err) => {})
      .finally(() => {
        setIsLoading(false);
      });
  };

  const handleFormSubmit = (data) => {
    const reqData = {
      applicationPublicId: applicationPublicId,
      payoffDate: new Date(data.payoffDate).toISOString(),
      amountReceived: data.amountReceived ? parseFloat(data.amountReceived) : 0,
    };
    setIsLoading(true);
    setPayoffData({});
    ApplicationPayoffsGridService.simulate(reqData)
      .then((res) => {
        appContext.toastMessage.message = 'Payoff breakdown has been successfully created.';
        setSuccessMessage('');
        setPayoffData(res.data);

        try {
          let advArray = [[]];
          advArray[0][0] = 'Plaintiff Name:';
          advArray[0][1] = 'File #:';
          advArray[0][2] = 'Portfolio:';
          advArray[0][3] = 'Total Advanced Amount:';
          advArray[0][4] = 'Total Cash Outlay:';
          advArray[0][5] = 'Date of Advance:';
          advArray[0][6] = 'Date of Payout:';
          advArray[0][7] = 'Good Until Date:';
          advArray[0][8] = 'Days Outstanding:';
          advArray[0][9] = 'Usage/Multiplier:';
          advArray[0][10] = 'Profit:';
          advArray[0][11] = 'Present Value Payout';
          advArray[0][12] = 'Concession:';
          advArray[0][13] = 'Amount Received:';
          advArray[0][14] = 'Net Revenue:';
          advArray[0][15] = 'Full Pay APR:';
          advArray[0][16] = 'Concession APR:';
          advArray[0][17] = 'MOIC:';

          let colIndex = 1;
          if (res.data.advances) {
            for (let index = 0; index < res.data.advances.length; index++) {
              if (advArray[colIndex] === undefined) advArray[colIndex] = [];

              advArray[colIndex][0] = res.data.advances[index].plaintiffName;
              advArray[colIndex][1] = res.data.advances[index].fileNumber;
              advArray[colIndex][2] = res.data.advances[index].portfolio;
              advArray[colIndex][3] = res.data.advances[index].totalAdvanceAmount;
              advArray[colIndex][4] = res.data.advances[index].totalCashOutlay;
              advArray[colIndex][5] = res.data.advances[index].dateOfAdvance;
              advArray[colIndex][6] = res.data.advances[index].dateOfPayout;
              advArray[colIndex][7] = res.data.advances[index].goodUntilDate;
              advArray[colIndex][8] = res.data.advances[index].daysOutstanding;
              advArray[colIndex][9] = res.data.advances[index].usageMultiplier;
              advArray[colIndex][10] = res.data.advances[index].profit;
              advArray[colIndex][11] = res.data.advances[index].presentValuePayout;
              advArray[colIndex][12] = res.data.advances[index].concessionAmount;
              advArray[colIndex][13] = res.data.advances[index].amountReceived;
              advArray[colIndex][14] = res.data.advances[index].netRevenue;
              advArray[colIndex][15] = res.data.advances[index].fullPayAPR;
              advArray[colIndex][16] = res.data.advances[index].concessionAPR;
              advArray[colIndex][17] = res.data.advances[index].moic;
              colIndex++;
            }
          }

          if (res.data.summary) {
            if (advArray[colIndex] === undefined) advArray[colIndex] = [];

            advArray[colIndex][0] = 'Summary/WA';
            advArray[colIndex][1] = res.data.summary.fileNumber;
            advArray[colIndex][2] = res.data.summary.portfolio;
            advArray[colIndex][3] = res.data.summary.totalAdvanceAmount;
            advArray[colIndex][4] = res.data.summary.totalCashOutlay;
            advArray[colIndex][5] = res.data.summary.dateOfAdvance;
            advArray[colIndex][6] = res.data.summary.dateOfPayout;
            advArray[colIndex][7] = res.data.summary.goodUntilDate;
            advArray[colIndex][8] = res.data.summary.daysOutstanding;
            advArray[colIndex][9] = res.data.summary.usageMultiplier;
            advArray[colIndex][10] = res.data.summary.profit;
            advArray[colIndex][11] = res.data.summary.presentValuePayout;
            advArray[colIndex][12] = res.data.summary.concessionAmount;
            advArray[colIndex][13] = res.data.summary.amountReceived;
            advArray[colIndex][14] = res.data.summary.netRevenue;
            advArray[colIndex][15] = res.data.summary.fullPayAPR;
            advArray[colIndex][16] = res.data.summary.concessionAPR;
            advArray[colIndex][17] = res.data.summary.moic;
          }

          let traversedArray = transpose(advArray);
          setAdvances(traversedArray);
        } catch (error) {
          console.log(error);
        }
        setIsLoading(false);
      })
      .catch((err) => {})
      .finally(() => setIsLoading(false));
  };

  const handleCancel = () => {
    let path = applicationId ? applicationId : 'new-application';
    history.push('/underwriting/applications/' + path + '/payoffs');
  };

  const handleChange = (evt) => {
    const value = evt.target.value;
    setPayoffData({
      ...payoffData,
      [evt.target.name]: value,
    });
  };

  const moneyFormatter = new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: 'USD',
  });

  const parseDollarString = (val) => {
    return val ? moneyFormatter.format(parseFloat(val).toFixed(2)) : '-';
  };

  const transpose = (original) => {
    var copy = [];
    for (var i = 0; i < original.length; ++i) {
      for (var j = 0; j < original[i].length; ++j) {
        // skip undefined values to preserve sparse array
        //if (original[i][j] === undefined) continue;
        // create row if it doesn't exist yet
        if (copy[j] === undefined) copy[j] = [];
        // swap the x and y coords for the copy
        copy[j][i] = original[i][j];
      }
    }
    return copy;
  };

  const exportDataReport = (payoffDate, amountReceived) => {
    const reqData = {
      applicationPublicId: applicationPublicId,
      payoffDate: new Date(payoffDate).toISOString(),
      amountReceived: amountReceived ? parseFloat(amountReceived) : 0,
    };

    ApplicationPayoffsGridService.export(reqData)
      .then((response) => {
        const contentDisposition = response.headers['content-disposition'];
        let fileName = 'Payoff-Breakdown-Export.xlsx';
        if (contentDisposition) {
          const fileNameMatch = contentDisposition.match(/filename="(.+)"/);
          if (fileNameMatch != null && fileNameMatch.length === 2) fileName = fileNameMatch[1];
        }
        fileDownload(response.data, fileName);
        setIsLoading(false);
      })
      .catch((err) => {})
      .finally(() => {
        setIsLoading(false);
      });
  };

  return (
    <div className="container mt-10 mb-10">
      <div className="row">
        <div className="col-md-12 mx-auto bg-white box-shadow-border">
          <div className="p-10">
            <div className="mt-5 mb-10">
              <div>
                <h1>
                  {applicationId != 'new-application'
                    ? (client ? `${client} - ` : 'Application ') + applicationId + ' - '
                    : undefined}
                  {payoffId === NEW_PAYOFF_TEXT ? 'Payoff ' : 'Payoff'}
                </h1>

                <ApplicationsNewMenu applicationId={applicationId} />

                <Row>
                  <Col sm="4" className="p-10 ">
                    <form onSubmit={handleSubmit(handleFormSubmit)} noValidate>
                      <Row className="listing-rows mb-4">
                        <h6>Date of Payoff</h6>

                        <input
                          type="date"
                          name="payoffDate"
                          id="payoffDate"
                          className="form-control date-of-payoff float-right"
                          // onBlur={handleCalculateData}
                          ref={register({ required: 'Payoff Date is required' })}
                          defaultValue={
                            payoffData.payoffDate
                              ? payoffData.payoffDate.split('T')[0]
                              : new Date().toISOString().split('T')[0]
                          }
                          onChange={handleChange}
                          disabled={isLoading}
                        />
                      </Row>
                      <Row className="listing-rows mb-4">
                        <h6>Total Payoff Amount</h6>
                        <span>{payoffData.totalPayoffAmount}</span>
                      </Row>
                      <Row className="listing-rows mb-4">
                        <div>
                          <h6 style={{ verticalAlign: 'top' }}>Amount Received</h6>
                        </div>
                        <div>
                          <div className="input-group input-group-sm float-right amount">
                            <div className="input-group-prepend">
                              <span className="input-group-text">$</span>
                            </div>
                            <input
                              name="amountReceived"
                              id="amountReceived"
                              type="number"
                              // onBlur={handleCalculateData}
                              ref={register({ required: 'Amount Received is required.' })}
                              onChange={handleChange}
                              defaultValue={payoffData.amountReceived}
                              className="form-control float-right number-input"
                              disabled={isLoading}
                              required="required"
                            />
                          </div>
                        </div>
                      </Row>
                      <Row className="listing-rows mb-4 float-right">
                        <div>
                          <div className="input-group input-group-sm float-right amount">
                            (enter zero for paid off cases)
                          </div>
                        </div>
                      </Row>
                      <div style={{ clear: 'both' }} />
                      <Row className="listing-rows mb-4">
                        <div className="form-group">
                          <button 
                          type="submit" 
                          disabled={isLoading} 
                          className="btn btn-primary">
                            Payoff Breakdown
                          </button>

                          <button
                            type="button"
                            className="ml-5 btn btn-primary"
                            disabled={isLoading}
                            onClick={() => {
                              const values = getValues();
                              const payoffDate = values['payoffDate'];
                              const amountReceived = values['amountReceived'];
                              if(amountReceived === "") return;
                              exportDataReport(payoffDate, amountReceived);
                            }}
                            style={{
                              backgroundColor: 'var(--green)',
                              borderColor: 'var(--green)',
                              minWidth: '100px',
                            }}
                          >
                            Export
                          </button>

                          <button
                            type="button"
                            className="ml-5 btn btn-secondary"
                            onClick={() => handleCancel()}
                            disabled={isLoading}
                          >
                            Cancel{' '}
                          </button>
                        </div>
                      </Row>

                      <hr />
                    </form>
                  </Col>
                </Row>
                <Table
                  className={classes.table}
                  style={{ border: '1px solid rgba(224, 224, 224, 1)', borderRadius: 0 }}
                >
                  {!isLoading && (
                    <>
                      <TableRow>
                        <TableCell className={classes.firstColumn} component="th" scope="row">
                          Date of Payoff:
                        </TableCell>
                        <TableCell className={classes.lastColumn} component="th" scope="row">
                          {payoffData.payoffDate}
                        </TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell className={classes.firstColumn} component="th" scope="row">
                          Amount Received:
                        </TableCell>
                        <TableCell className={classes.lastColumn} component="th" scope="row">
                          {payoffData.amountReceived}
                        </TableCell>
                                          </TableRow>
                                         
                      <TableRow>
                        <TableCell className={classes.firstColumn} component="th" scope="row">
                          Total Payoff Amount:
                        </TableCell>
                        <TableCell className={classes.lastColumn} component="th" scope="row">
                          {payoffData.totalPayoffAmount}
                        </TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell className={classes.firstColumn} component="th" scope="row">
                          Amount Received %:
                        </TableCell>
                        <TableCell className={classes.lastColumn} component="th" scope="row">
                          {payoffData.amountReceivedPerc}
                        </TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell className={classes.firstColumn} component="th" scope="row">
                          Concession:
                        </TableCell>
                        <TableCell className={classes.lastColumn} component="th" scope="row">
                          {payoffData.concessionAmount}
                        </TableCell>
                      </TableRow>
                    <TableRow>
                        <TableCell className={classes.firstColumn} component="th" scope="row">
                            Concession %:
                        </TableCell>
                        <TableCell className={classes.lastColumn} component="th" scope="row">
                            {payoffData.concessionPerc}
                        </TableCell>
                    </TableRow>
                    <TableRow>
                        <TableCell className={classes.firstColumn} component="th" scope="row">
                          Days Outstanding:
                        </TableCell>
                        <TableCell className={classes.lastColumn} component="th" scope="row">
                          {payoffData.daysOutstanding}
                        </TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell className={classes.firstColumn} component="th" scope="row">
                          Number of Files:
                        </TableCell>
                        <TableCell className={classes.lastColumn} component="th" scope="row">
                          {payoffData.numberOfFiles}
                        </TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell className={classes.firstColumn} component="th" scope="row">
                          Full Pay APR:
                        </TableCell>
                        <TableCell className={classes.lastColumn} component="th" scope="row">
                          {payoffData.fullPayAPR}
                        </TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell className={classes.firstColumn} component="th" scope="row">
                          Concession APR:
                        </TableCell>
                        <TableCell className={classes.lastColumn} component="th" scope="row">
                          {payoffData.concessionAPR}
                        </TableCell>
                        <TableCell className={classes.lastColumn} component="th" scope="row">
                          {payoffData.moic}
                        </TableCell>
                      </TableRow>
                    </>
                  )}
                </Table>

                <br></br>

                <div class="view">
                  <div class="wrapper">
                    <Table
                      className={classes.tableFull}
                      style={{ border: '1px solid rgba(224, 224, 224, 1)', borderRadius: 0 }}
                    >
                      {!isLoading &&
                        advances &&
                        advances.map((row, index) => (
                          <TableRow>
                            {row.map((r2, ind2) => (
                              <TableCell
                                className={ind2 === 0 ? classes.firstColumn : classes.lastColumn}
                                component="th"
                                scope="row"
                              >
                                {r2}
                              </TableCell>
                            ))}
                          </TableRow>
                        ))}
                    </Table>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SimulatePayoff;
