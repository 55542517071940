import React, { useEffect, useContext, useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { Row, Col, Modal, ModalHeader } from 'reactstrap';
import { Link } from 'react-router-dom';
import { toast } from 'react-toastify';
import AppContext from '@contexts/AppContext';
import NotesGrid from './NotesGrid';
import EditNote from './EditNote/EditNote';

const Notes = () => {
  const appContext = useContext(AppContext);
  const history = useHistory();
  const [isLoading, setIsLoading] = useState(false);
  const { id } = useParams();
  const [disableTabs, setDisableTabs] = useState(false);
  const [modal, setModal] = useState(false);
  const [selectedNotePublicId, setSelectedNotePublicId] = useState('');
  const toggle = () => setModal(!modal);

  const makeMenuItemActive = () => {
    document.querySelector('#menuitem-underwriting').click();
  };

  useEffect(() => {
    
  }, []);

  useEffect(() => {
    if (appContext.toastMessage.message)
      toast.success(appContext.toastMessage.message, { autoClose: 3000 });
    appContext.toastMessage.message = null;
  });

  const handleNewItem = (publicId) => {
    if(publicId)
      setSelectedNotePublicId(publicId);
    
    setModal(true);
    // let path = applicationId ? applicationId : 'new-application';
    // history.push('/underwriting/applications/' + path + '/notes/new-note');
  };

  return (
    <div className="mb-12">
      <div>
        <Modal isOpen={modal} toggle={toggle}>
          <ModalHeader toggle={toggle}>Note</ModalHeader>
          <EditNote SelectedNotePublicId = {selectedNotePublicId} setModal ={setModal} />
        </Modal>
      </div>
      <br/>
      <Row>
        <Col md="4">
          <h1 className="mb-10">Notes</h1>
        </Col>
        <Col md="8" className="float-right">
          <Link
            onClick={() => handleNewItem('')}
            className="btn btn-primary float-right"
            style={{
              backgroundColor: 'var(--green)',
              borderColor: 'var(--green)',
            }}
          >
            New Note
          </Link>
        </Col>
      </Row>

      <Row>
        <Col md="12">
          <NotesGrid handleNewItem ={handleNewItem} modal={modal} />
        </Col>
      </Row>
    </div>
  );
};

export default Notes;
