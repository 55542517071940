import React, { useEffect, useContext } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { Row, Col } from 'reactstrap';
import { toast } from 'react-toastify';
import AppContext from '@contexts/AppContext';
import ApplicationsNewMenu from '../Menu/ApplicationsNewMenu';
import Header from '../Header';
import FinancialGrid from './FinancialGrid';

const FinancialTerms = () => {
    const appContext = useContext(AppContext);
    const history = useHistory();
    const { applicationId } = useParams();
    let application = appContext.currentApplication;

    useEffect(() => {
        if (!applicationId) {
            appContext.toastMessage.message = 'Application No was not found!';
            history.push('/underwriting/applications/new-application/');
            return;
        }
        let item = document.querySelector('.financial-terms');
        if (item) {
            item.classList.add('active');
        }
    }, []);

    useEffect(() => {
        if (appContext.toastMessage.message)
            toast.success(appContext.toastMessage.message, { autoClose: 3000 });
        appContext.toastMessage.message = null;
    }, [appContext.toastMessage.message, history]);

    return (
        <div className="container mt-10 mb-10">
            <div className="row">
                <div className="col-md-12 bg-white box-shadow-border">
                    <div className="p-10">
                        <div className="mt-5 mb-10">
                            <Header applicationId={applicationId} />

                            <ApplicationsNewMenu applicationId={applicationId} />

                        </div>

                        <FinancialGrid />
                    </div>
                </div>
            </div>
        </div>
    );
};

export default FinancialTerms;
