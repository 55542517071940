import React, { useEffect } from 'react';
import { Col, Row } from 'reactstrap';
import Grid from './Components/Grid';

const LawFirmDetails = () => {
  
  const makeMenuItemActive = () => {
    document.querySelector('#menuitem-reports').click();
    document
      .querySelector('#kt_header_menu > ul > li')
      .classList.add('menu-item-active');
  };

  useEffect(() => makeMenuItemActive(), []);

  return (
    <div className="container mt-10 mb-10 main-container">
      <div className="row">
        <div className="col-md-12 bg-white box-shadow-border">
          <div className="p-1 pb-10">
            <Row>
              <Col md="12"><Grid/></Col>
            </Row>
          </div>
        </div>
      </div>
    </div>
  );
};

export default LawFirmDetails;
