/* eslint-disable jsx-a11y/label-has-associated-control */
import React, { useContext, useEffect, useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import { useForm } from 'react-hook-form';
import ApplicationsNewMenu from '../Menu/ApplicationsNewMenu';
import { Row, Col, Input, Label, Alert, Button, Form, FormGroup, Container } from 'reactstrap';
import { ErrorMessage } from '@hookform/error-message';
import AppContext from '@contexts/AppContext';
import GridService from '@services/GridService';
import ReactLoading from 'react-loading';
import Header from '../Header';
import ApplicationsService from '@services/ApplicationsService';

const NewDocument = (props) => {

    const appContext = useContext(AppContext);
    const history = useHistory();
    const { applicationId } = useParams();
    const [disableTabs, setDisableTabs] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [document, setDocument] = useState({});
    const [submit, setSubmit] = useState(false);
    const { register, handleSubmit, watch, errors, formState } = useForm({ mode: 'onBlur' });

    const handleFormSubmit = (data) => {

        if (!(appContext?.userPermissions?.indexOf('Upload Documents') > -1)) {
            appContext.toastMessage.message = 'You do not have permissions to perform this action.';
            toast.error(appContext.toastMessage.message, { autoClose: 3000 });
            appContext.toastMessage.message = null;
            return;
        }

        setIsLoading(true)

        ApplicationsService.search({ 'applicationNo': applicationId }, null, null, 1, {}, 10).then((response) => {

            let formData = new FormData();

            formData.append('applicationPublicId', response.data.data[0].publicId);
            formData.append('createdByPublicId', appContext.user.publicId);
            formData.append('uploadDate', new Date().toISOString());
            formData.append('documentName', data.name);
            formData.append('fileName', data.file[0].name);
            formData.append('file', data.file[0]);

            const config = {
                headers: { 'content-type': 'multipart/form-data' }
            }

            GridService.create(`/ApplicationDocument`, formData, config).then((response) => {
                appContext.toastMessage.message = 'Your changes has been saved sucessfully.';
                history.push('/underwriting/applications/' + applicationId + '/documents');
            })
                .catch((err) => {
                    
                })
                .finally(() => {
                    setIsLoading(false);
                });

        });

    };

    useEffect(() => {
        if (appContext.toastMessage.message)
            toast.success(appContext.toastMessage.message);
        appContext.toastMessage.message = null;
    });

    if (isLoading) {
        return (
            <Container className="mt-10 mb-10">
                <Row>
                    <Col md="12" className="mx-auto bg-white box-shadow-border">
                        <div className="p-10 mt-5 mb-10">

                            {(disableTabs != null) &&
                                <Header applicationId={applicationId} handleSaveAndExit={() => { }} />
                            }

                            {(disableTabs != null) &&
                                <ApplicationsNewMenu disableTabs={disableTabs} applicationId={applicationId} menuItemIndex="9" />
                            }

                            <Container className="p-0">
                                <Row>
                                    <Col md="8">
                                        <Row>
                                            <Col md="4">
                                                <ReactLoading type="bars" color="#7E8299" />
                                            </Col>
                                        </Row>
                                    </Col>
                                </Row>
                            </Container>

                        </div>
                    </Col>
                </Row>
            </Container>
        )
    }

    return (
        <div className="container mt-10 mb-10">
            <div className="row">
                <div className="col-md-12 mx-auto bg-white box-shadow-border">
                    <div className="p-10">
                        <div className="mt-5 mb-10">
                            <div>
                                {(disableTabs != null) &&
                                    <Header applicationId={applicationId} handleSaveAndExit={() => { }} />
                                }

                                {(disableTabs != null) &&
                                    <ApplicationsNewMenu disableTabs={disableTabs} applicationId={applicationId} menuItemIndex="9" />
                                }

                                <h1 className="mb-5 mt-5">Upload Document</h1>

                                <form method="POST" onSubmit={handleSubmit(handleFormSubmit)} noValidate>
                                    <div className="row">
                                        <div className="col-md-3">
                                            <div className="form-group">
                                                <label htmlFor="date">Name</label>
                                                <input
                                                    name="name"
                                                    id="name"
                                                    ref={register({ required: 'Name is required.' })}
                                                    defaultValue={document.name}
                                                    className="form-control"
                                                    required="required"
                                                />
                                                {errors.name && (
                                                    <Alert color="danger" className="mt-5">
                                                        <ErrorMessage errors={errors} name="name" />
                                                    </Alert>
                                                )}
                                            </div>
                                            <div className="form-group">
                                                <label htmlFor="date">Document</label>
                                                <input
                                                    type="file"
                                                    name="file"
                                                    accept="application/pdf,application/msword,application/vnd.openxmlformats-officedocument.wordprocessingml.document"
                                                    id="file"
                                                    ref={register({ required: 'Document is required.' })}
                                                    defaultValue={document.file}
                                                    className="form-control"
                                                    required="required"
                                                />
                                                {submit && errors.file && (
                                                    <Alert color="danger" className="mt-5">
                                                        <ErrorMessage errors={errors} name="file" />
                                                    </Alert>
                                                )}
                                            </div>

                                            <div className="form-group">
                                                <button type="submit" className="btn btn-primary" onClick={() => setSubmit(true)}>
                                                    Save
                        </button>
                                                <button
                                                    type="button"
                                                    className="ml-5 btn btn-secondary"
                                                    onClick={() =>
                                                        history.push('/underwriting/applications/' + applicationId + '/documents')
                                                    }
                                                >
                                                    Cancel
                        </button>
                                            </div>
                                        </div>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default NewDocument;
