import React, { useContext, useEffect, useState, useRef } from 'react';
import { Row, Col, Alert, FormGroup, Container, Modal, ModalHeader, ModalBody } from 'reactstrap';
import { Link, useParams, withRouter, useHistory } from 'react-router-dom';
import { makeStyles, withStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableFooter from '@material-ui/core/TableFooter';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import EditIcon from '@material-ui/icons/Edit';
import ReactLoading from 'react-loading';
import AttorneyAuthService from '@services/AttorneyAuthService';
import UserService from '@services/UserService';
import GridService from '@services/GridService';
import Moment from 'moment';
import { FormattedAmount } from '@components/Utils/FormattedAmount';
import { useForm } from 'react-hook-form';
import { ErrorMessage } from '@hookform/error-message';
import AppContext from '@contexts/AppContext';
import { toast } from 'react-toastify';

const StyledTableCell = withStyles((theme) => ({
  head: {
    backgroundColor: '#efefef',
    color: theme.palette.common.black,
  },
  body: {
    fontSize: 14,
  },
}))(TableCell);

const useStyles2 = makeStyles({
  table: {
    minWidth: 500,
  },
});


const NecessityFundingCaseStatusUpdatesGrid = (prop) => {
  const appContext = useContext(AppContext);
  const history = useHistory();

  const { register, handleSubmit, watch, errors, formState } = useForm({ mode: 'onBlur' });
  const { attorneyPublicId, lawfirmPublicId } = useParams();
  const { setLawFirmName } = prop;
  const form = useRef();

  const classes = useStyles2();
  const [rows, setRows] = useState([]);

  const [selectedRow, setSelectedRow] = useState();
  const [isEditModal, setIsEditModal] = useState(false);
  const [isLoginModal, setIsLoginModal] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');

  const toggle = () => setIsEditModal(!isEditModal);
  const toggleLoginModal = () => setIsLoginModal(!isLoginModal);

  // const appContext = useContext(AppContext);

  useEffect(() => {
    const user = JSON.parse(localStorage.getItem('lasuris-user'));
    if (user) {
      update();
    } else {
      setIsLoginModal(true);
    }
  }, []);

  const update = () => {
    if (!(appContext?.userPermissions?.indexOf('View/Edit Attorney Case Status Update') > -1)) {
      appContext.toastMessage.message = 'You do not have permissions to perform this action.';
      toast.error(appContext.toastMessage.message, { autoClose: 3000 });
      appContext.toastMessage.message = null;
      return;
    }

    setIsLoading(true);

    let endpoint = `/ApplicationCaseStatusUpdate/getlawfirmapps/${lawfirmPublicId}`;

    GridService.read(endpoint)
      .then((response) => {
        setRows(response.data);
        setLawFirmName(response.data[0].lawFirm)
      })
      .catch((_) => { })
      .finally(() => {
        setIsLoading(false);
      });
  };

  const recordSave = (data) => {

    if (!(appContext?.userPermissions?.indexOf('View/Edit Attorney Case Status Update') > -1)) {
      appContext.toastMessage.message = 'You do not have permissions to perform this action.';
      toast.error(appContext.toastMessage.message, { autoClose: 3000 });
      appContext.toastMessage.message = null;
      return;
    }

    setIsLoading(true);

    let endpoint = `/ApplicationCaseStatusUpdate`;

    let requestObj = {
      publicId: selectedRow.caseUpdatePublicId,
      caseStatus: data.caseStatus,
      diaryFrame: data.diaryFrame,
      indexNumber: data.indexNumber,
      timingOutlook: data.timingOutlook,
      commentary: data.commentary,
      applicationPublicId: selectedRow.applicationPublicId,
      submittedByAttorney: attorneyPublicId
    };

    GridService.create(endpoint, requestObj)
      .then((response) => {
        var resp = response.data;
        setSelectedRow({
          ...selectedRow,
          caseStatus: resp.caseStatus,
          commentary: resp.commentary,
          diaryFrame: resp.diaryFrame,
          indexNumber: resp.indexNumber,
          timingOutlook: resp.timingOutlook,
          publicId: resp.publicId
        });

        update();
        toggle();

        // appContext.toastMessage.message = 'Row has been successfully updated.';
        // toast.success(appContext.toastMessage.message, { autoClose: 3000 });
        // appContext.toastMessage.message = null;
      })
      .catch((_) => { })
      .finally(() => {
        setIsLoading(false);
      });
  };

  const userLogin = (data) => {

    AttorneyAuthService.loginAttorney(data.email, data.password, attorneyPublicId)
      .then((res) => {
        setIsLoading(true);

        localStorage.setItem('isUserAuthenticated', true);
        appContext.toastMessage.message = 'You have logged in sucessfully.';
        appContext.toastMessage.type = 'info';

        appContext.user = res.user;

        UserService.getUserPermissionsByUserPublicId(res.user.publicId).then((response) => {
          let taskNames = response.data.data.map((x) => x.taskName);
          localStorage.setItem('userPermissions', JSON.stringify(taskNames));
          appContext.userPermissions = taskNames;

          update();

          setIsLoginModal(false);

          toast.success(appContext.toastMessage.message, { autoClose: 3000 });
        });
      })
      .catch((err) => {
        localStorage.setItem('isUserAuthenticated', false);
        setErrorMessage('Wrong email and/or password.');
      }).finally((_) => {
        setIsLoading(false);
      });
  };

  if (isLoading) {
    return (
      <div className="container mt-10 mb-10">
        <div className="row">
          <div className="col-md-12 mx-auto bg-white box-shadow-border">
            <div className="p-10">
              <div className="mt-5 mb-10">
                <ReactLoading type="bars" color="#7E8299" />
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }

  return (
    <div>
      <div>
        <Modal isOpen={isLoginModal} toggle={toggleLoginModal}>
          <ModalHeader toggle={toggleLoginModal}>
            Login
          </ModalHeader>
          <ModalBody>
            <form ref={form} onSubmit={handleSubmit(userLogin)} noValidate>
              <Container className="p-0">
                <Row>
                  <Col md="12">
                    <Row className="row">
                      <Col md="12">
                        <div className="form-group">
                          <label htmlFor="caseStatus">Username</label>
                          <input
                            className="form-control"
                            name="email"
                            ref={register({
                              required: "Username cannot be empty"
                            })}
                          />

                          {errors.email && (
                            <Alert color="danger" className="mt-5">
                              <ErrorMessage errors={errors} name="email" />
                            </Alert>
                          )}
                        </div>
                      </Col>
                    </Row>

                    <Row className="row">
                      <Col md="12">
                        <div className="form-group">
                          <label htmlFor="diaryFrame">Password</label>
                          <input
                            className="form-control"
                            name="password"
                            type="password"
                            ref={register({
                              required: "Password cannot be empty"
                            })}
                            defaultValue={selectedRow?.diaryFrame}
                          />
                          {errors.password && (
                            <Alert color="danger" className="mt-5">
                              <ErrorMessage errors={errors} name="password" />
                            </Alert>
                          )}
                        </div>
                      </Col>
                    </Row>

                    <Row>
                      <Col md="8">
                        <button type="submit" className="btn btn-primary mr-5">
                          Login
                        </button>
                        <button
                          type="button"
                          onClick={toggleLoginModal}
                          className="btn btn-secondary"
                        >
                          Cancel
                        </button>
                      </Col>
                    </Row>
                  </Col>
                </Row>
              </Container>
            </form>

          </ModalBody>

        </Modal>
      </div>

      <div>
        <Modal isOpen={isEditModal} toggle={toggle}>
          <ModalHeader toggle={toggle}>Edit Record</ModalHeader>
          <ModalBody>
            <form ref={form} onSubmit={handleSubmit(recordSave)} noValidate>
              <Container className="p-0">
                <Row>
                  <Col md="12">
                    <Row className="row">
                      <Col md="12">
                        <div className="form-group">
                          <label htmlFor="caseStatus">Case Status</label>
                          <select
                            className="form-control"
                            name="caseStatus"
                            defaultValue={selectedRow?.caseStatus}
                            ref={register({
                              required: "select one option"
                            })}
                          >
                            <option value="Active">Active</option>
                            <option value="Settled - Need to Collect">Settled - Need to Collect</option>
                            <option value="Old Check">Old Check</option>
                            <option value="Follow up">Follow up</option>
                            <option value="Paid off">Paid off</option>
                            <option value="Transferred to Different Firm">Transferred to Different Firm</option>
                            <option value="Trouble Firm">Trouble Firm</option>
                            <option value="Needs to be contacted">Needs to be contacted</option>
                            <option value="Demand Letter to be Sent">Demand Letter to be Sent</option>
                            <option value="Loss">
                              Loss
                            </option>
                          </select>

                          {errors.caseStatus && (
                            <Alert color="danger" className="mt-5">
                              <ErrorMessage errors={errors} name="caseStatus" />
                            </Alert>
                          )}
                        </div>
                      </Col>
                    </Row>

                    <Row className="row">
                      <Col md="12">
                        <div className="form-group">
                          <label htmlFor="diaryFrame">Diary Frame</label>
                          <select
                            className="form-control"
                            name="diaryFrame"
                            ref={register({
                              required: "select one option"
                            })}
                            defaultValue={selectedRow?.diaryFrame}
                          >
                            <option value="0 - 30 Days">0 - 30 Days</option>
                            <option value="0 - 60 Days">30 - 60 Days</option>
                            <option value="60 - 90 Days">60 - 90 Days</option>
                            <option value="90 + Days">90 + Days</option>

                          </select>

                          {errors.diaryFrame && (
                            <Alert color="danger" className="mt-5">
                              <ErrorMessage errors={errors} name="diaryFrame" />
                            </Alert>
                          )}
                        </div>
                      </Col>
                    </Row>

                    <Row>
                      <Col md="12">
                        <FormGroup>
                          <label htmlFor="timingOutlook">Timing Outlook</label>
                          <select
                            className="form-control"
                            name="timingOutlook"
                            ref={register({
                              required: "select one option"
                            })}
                            defaultValue={selectedRow?.timingOutlook}
                          >
                            <option value="0 - 3 Months">0 - 3 Months</option>
                            <option value="3 - 6 Months">3 - 6 Months</option>
                            <option value="12 + Months">12 + Months</option>
                            <option value="24 + Months">24 + Months</option>

                          </select>

                          {errors.timingOutlook && (
                            <Alert color="danger" className="mt-5">
                              <ErrorMessage errors={errors} name="timingOutlook" />
                            </Alert>
                          )}
                        </FormGroup>
                      </Col>
                    </Row>

                    <Row>

                      <Col md="12">
                        <FormGroup>
                          <label htmlFor="attorney-account-manager">Index Number</label>
                          <input
                            type="text"
                            defaultValue={selectedRow?.indexNumber}
                            name="indexNumber"
                            className="form-control"
                            maxLength="30"
                            ref={register({
                              required: 'Index Number is required.',
                              maxLength: {
                                value: 30,
                                message: 'Max length is 30 characters.',
                              },
                            })}
                          />
                        </FormGroup>
                      </Col>
                    </Row>

                    <Row></Row>
                    <Row>
                      <Col md="12">
                        <FormGroup>
                          <label htmlFor="notes">Commentary</label>
                          <textarea
                            name="commentary"
                            rows="8"
                            className="form-control"
                            defaultValue={selectedRow?.commentary}
                            ref={register({
                              maxLength: {
                                value: 1000,
                                message: 'Max length is 1000 characters.',
                              },
                            })}
                          />
                        </FormGroup>
                      </Col>
                    </Row>
                    <Row>
                      <Col md="8">
                        <button type="submit" className="btn btn-primary mr-5">
                          Save
                        </button>
                        <button
                          type="button"
                          onClick={toggle}
                          className="btn btn-secondary"
                        >
                          Cancel
                        </button>
                      </Col>
                    </Row>
                  </Col>
                </Row>
              </Container>
            </form>

          </ModalBody>
        </Modal>
      </div>

      <TableContainer component={Paper}>
        <Table className={classes.table} aria-label="custom pagination table">
          <TableHead>
            <TableRow>
              <StyledTableCell>Id</StyledTableCell>

              <StyledTableCell>Client Name</StyledTableCell>
              <StyledTableCell>DOB</StyledTableCell>
              <StyledTableCell>Date of Loss</StyledTableCell>
              <StyledTableCell className="amd-ctc-clmn">Total Advanced Amount</StyledTableCell>
              <StyledTableCell>Case Status</StyledTableCell>
              <StyledTableCell>Diary Frame</StyledTableCell>
              <StyledTableCell>Index Number</StyledTableCell>
              <StyledTableCell>Timing Outlook</StyledTableCell>
              <StyledTableCell>Commentary</StyledTableCell>
              <StyledTableCell>Submitted Date</StyledTableCell>
              <StyledTableCell></StyledTableCell>
              <StyledTableCell></StyledTableCell>
            </TableRow>

          </TableHead>
          {isLoading === false && (
            <TableBody>
              {rows &&
                rows.map((row, index) => (
                  <TableRow key={row?.name}>
                    <TableCell component="th" scope="row">
                      {row?.id}
                    </TableCell>
                    <TableCell>{row?.clientName}</TableCell>
                    <TableCell>
                      {row?.dateofBirth &&
                        Moment(row?.dateofBirth?.split('T')[0]).format('MM-DD-YYYY')}
                    </TableCell>
                    <TableCell>
                      {row?.dateofLoss &&
                        Moment(row?.dateofLoss?.split('T')[0]).format('MM-DD-YYYY')}
                    </TableCell>
                    <TableCell className="amd-othrdisbs-clmn">
                      {row?.totalAdvancedAmount
                        ? FormattedAmount.format(parseFloat(row.totalAdvancedAmount).toFixed(2))
                        : '-'}
                    </TableCell>

                    <TableCell>{row?.caseStatus}</TableCell>
                    <TableCell>{row?.diaryFrame}</TableCell>
                    <TableCell>{row?.indexNumber}</TableCell>
                    <TableCell>{row?.timingOutlook}</TableCell>
                    <TableCell>{row?.commentary}</TableCell>
                    <TableCell>
                            {row?.submittedDate &&
                                Moment(row?.submittedDate?.split('T')[0]).format('MM-DD-YYYY')}
                    </TableCell>
                    <TableCell className="p-2">
                      <a className="btn float-right" onClick={() => {
                        // setRowIndex(index);
                        setSelectedRow(row);
                        setIsEditModal(true);
                      }}>
                        <EditIcon />
                      </a>
                    </TableCell>


                  </TableRow>
                ))}
            </TableBody>
          )}
          {isLoading && (
            <TableBody>
              <TableRow>
                <td></td>
                <td>
                  <ReactLoading type="bars" color="#7E8299" />
                </td>
                <td></td>
              </TableRow>
            </TableBody>
          )}

          <TableFooter></TableFooter>
        </Table>
      </TableContainer>
    </div >
  );
};

export default NecessityFundingCaseStatusUpdatesGrid;
