/* eslint-disable jsx-a11y/label-has-associated-control */
import React, { useContext, useEffect, useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import { useForm } from 'react-hook-form';
import ApplicationsNewMenu from '../../Menu/ApplicationsNewMenu';
import { ErrorMessage } from '@hookform/error-message';
import { Row, Col, Input, Label, Alert, Button, Form, FormGroup, Container, Table } from 'reactstrap';
import AppContext from '@contexts/AppContext';
import GridService from '../../../../../../services/GridService';
import Header from '../../Header';
import ReactLoading from 'react-loading';

const user = JSON.parse(localStorage.getItem('lasuris-user'));

const NewDisbursement = (props) => {

    const appContext = useContext(AppContext);
    const history = useHistory();
    const [isLoading, setIsLoading] = useState(false);
    const [disableTabs, setDisableTabs] = useState(false);
    const { applicationId, advanceId, disbursementId } = useParams();
    const [disbursement, setDisbursement] = useState({});
    const { register, handleSubmit, watch, errors, formState } = useForm({ mode: 'onBlur' });

    const handleFormSubmit = (data) => {

        let endpoint = '/ApplicationAdvanceDisbursement';
        data.amount = parseFloat(parseFloat(data.amount).toFixed(2));
        data.commisionable = data.type === "Surgical" ? true : false;
        data.createdByPublicId = user.publicId;
        data.applicationAdvancePublicId = advanceId;

        if (disbursementId != undefined) {
           
            if(!(appContext?.userPermissions?.indexOf('Edit Advance Disbursements') > -1)){
                appContext.toastMessage.message = 'You do not have permissions to perform this action.';
                toast.error(appContext.toastMessage.message, { autoClose: 3000 });
                appContext.toastMessage.message = null;
                return;
            }
            
            // update disbursement
            data.publicId = disbursement.publicId;
            data.createdDate = disbursement.createdDate;
            
            setIsLoading(true);

            GridService.update(endpoint, data).then((response) => {
                appContext.toastMessage.message = 'Your changes has been saved sucessfully.';
                history.push(`/underwriting/applications/${applicationId}/advances/${advanceId}/disbursement`);
            }).catch((err) => {
                
            }).finally(() => {
                setIsLoading(false);
            });

        } else {

            if(!(appContext?.userPermissions?.indexOf('Add Advance Disbursements') > -1)){
                appContext.toastMessage.message = 'You do not have permissions to perform this action.';
                toast.error(appContext.toastMessage.message, { autoClose: 3000 });
                appContext.toastMessage.message = null;
                return;
            }

            //new disbursement
            setIsLoading(true);

            GridService.create(endpoint, data).then((response) => {
                appContext.toastMessage.message = 'Your changes has been saved sucessfully.';
                history.push(`/underwriting/applications/${applicationId}/advances/${advanceId}/disbursement`);
            }).catch((err) => {
                
            }).finally(() => {
                setIsLoading(false);
            });

        }
    };

    useEffect(() => {
        if (appContext.toastMessage.message)
            toast.success(appContext.toastMessage.message, { autoClose: 3000 });
        appContext.toastMessage.message = null;
    });

    useEffect(() => {
        if (disbursementId) {

            setIsLoading(true);

            let endpoint = `/ApplicationAdvanceDisbursement/${disbursementId}`
            GridService.read(endpoint).then((response) => {
                if (!response.data) return;
                setDisbursement(response.data);
            }).catch((err) => {
                
            }).finally(() => {
                setIsLoading(false);
            });
        }
    }, []);

    const handleCancel = () => {
        history.push(`/underwriting/applications/${applicationId}/advances/${advanceId}/disbursement`);
    };

    if (isLoading) {
        return (
            <Container className="mt-10 mb-10">
                <Row>
                    <Col md="12" className="mx-auto bg-white box-shadow-border">
                        <div className="p-10 mt-5 mb-10">

                            {(disableTabs != null) &&
                                <Header applicationId={applicationId} handleSaveAndExit={() => { }} />
                            }

                            {(disableTabs != null) &&
                                <ApplicationsNewMenu disableTabs={disableTabs} applicationId={applicationId} menuItemIndex="8" />
                            }

                            <Container className="p-0">
                                <Row>
                                    <Col md="8">
                                        <Row>
                                            <Col md="4">
                                                <ReactLoading type="bars" color="#7E8299" />
                                            </Col>
                                        </Row>
                                    </Col>
                                </Row>
                            </Container>

                        </div>
                    </Col>
                </Row>
            </Container>
        )
    }

    return (
        <div className="container mt-10 mb-10">
            <div className="row">
                <div className="col-md-12 mx-auto bg-white box-shadow-border">
                    <div className="p-10">
                        <div className="mt-5 mb-10">
                            <Header applicationId={applicationId} />
                            <ApplicationsNewMenu applicationId={applicationId} menuItemIndex={8} />
                            <div className="row"
                                style={{
                                    justifyContent: 'space-between',
                                    width: '15%',
                                    marginLeft: '0px',
                                    paddingBottom: '3px',
                                    marginBottom: "10px",
                                    cursor: "pointer"
                                }}
                            >
                                <p
                                    onClick={() => {
                                        history.push(`/underwriting/applications/${applicationId}/advances/${advanceId}/product`);
                                    }}>
                                    Financials
                                            </p>
                                <p
                                    style={{
                                        borderBottom: '1px solid #3699FF',
                                        color: "#3699FF"
                                    }}
                                    onClick={() => {
                                    }}>Disbursement</p>
                            </div>
                            <div>
                                <h5 style={{ marginBottom: "2%" }}>{disbursementId ? 'Disbursement Details' : 'New Disbursement'}</h5>
                                <form method="POST" onSubmit={handleSubmit(handleFormSubmit)} noValidate>
                                    <div className="row">
                                        <div className="col-md-4">

                                            <div className="form-group">
                                                <label htmlFor="date">Type</label>
                                                <select name="disbursementType" defaultValue={disbursement.disbursementType}
                                                    id="disbursementType"
                                                    required="required"
                                                    ref={register({ required: 'Type is required.' })} className="form-control">
                                                    <option value="Surgical">Surgical</option>
                                                    <option value="BuyOut">BuyOut</option>
                                                </select>
                                                {errors.disbursementType && (
                                                    <Alert color="danger" className="mt-5">
                                                        <ErrorMessage errors={errors} name="disbursementType" />
                                                    </Alert>
                                                )}
                                            </div>
                                            <div className="form-group">
                                                <label htmlFor="date">Purpose</label>
                                                <textarea
                                                    maxlength="30"
                                                    name="purpose"
                                                    id="purpose"
                                                    ref={register({ required: 'Purpose is required.' })}
                                                    defaultValue={disbursement.purpose}
                                                    className="form-control"
                                                    required="required"
                                                />
                                                {errors.purpose && (
                                                    <Alert color="danger" className="mt-5">
                                                        <ErrorMessage errors={errors} name="purpose" />
                                                    </Alert>
                                                )}
                                            </div>

                                            <div className="form-group">
                                                <label htmlFor="date">Amount</label>
                                                <input
                                                    name="amount"
                                                    id="amount"
                                                    ref={register({ required: 'Amount is required.' })}
                                                    defaultValue={disbursement.amount}
                                                    placeholder="$"
                                                    className="form-control"
                                                    required="required"
                                                />
                                                {errors.amount && (
                                                    <Alert color="danger" className="mt-5">
                                                        <ErrorMessage errors={errors} name="amount" />
                                                    </Alert>
                                                )}
                                            </div>

                                            <div className="form-group">
                                                <label htmlFor="date">Check#</label>
                                                <input
                                                    name="checkNo"
                                                    id="checkNo"
                                                    ref={register({ required: 'Check# is required.' })}
                                                    defaultValue={disbursement.checkNo}
                                                    className="form-control"
                                                    required="required"
                                                />
                                                {errors.checkNo && (
                                                    <Alert color="danger" className="mt-5">
                                                        <ErrorMessage errors={errors} name="checkNo" />
                                                    </Alert>
                                                )}
                                            </div>
                                            <div className="form-group">
                                                <label htmlFor="date">Payable To</label>
                                                <input
                                                    name="payableTo"
                                                    id="payableTo"
                                                    ref={register({ required: 'Payabale To is required.' })}
                                                    defaultValue={disbursement.payableTo}
                                                    className="form-control"
                                                    required="required"
                                                />
                                                {errors.payableTo && (
                                                    <Alert color="danger" className="mt-5">
                                                        <ErrorMessage errors={errors} name="payableTo" />
                                                    </Alert>
                                                )}
                                            </div>

                                            <div className="form-group">
                                                <button type="submit" className="btn btn-primary">
                                                    Save
                        </button>
                                                <button
                                                    type="button"
                                                    className="ml-5 btn btn-secondary"
                                                    onClick={() => handleCancel()}

                                                >
                                                    Cancel
                        </button>
                                            </div>
                                        </div>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default NewDisbursement;
